import { useDispatch, useSelector } from "react-redux";
import { NewTranscriptButton, OssyLogo, OssyText } from "/";
import { transcriptsType, colors, logger } from "../utility/utility.mjs";
import { getWeekAudioDuration } from "../transcriptSlice";
import DarkOverlay from "./DarkOverlay";
import { useEffect } from "react";
import LimitBarAndDescription from "./LimitBarAndDescription";

const HomeLeftMenu = ({
  smallWindow,
  menuOpen,
  supabase,
  setMenuOpen,
  selected,
  isFolderSelected,
  selectedTranscriptsType,
  setFileToUpload,
  file,
  fileInputRef,
  isOwnerOfContent,
  setNotOwnerAction,
  setDisplayNotOwnerTooltip,
  setNotOwnerPoints,
  setDisplayInSharedFolderPanel,
  setSharedContentMode,
}) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);

  useEffect(() => {
    dispatch(getWeekAudioDuration({ supabase }));
    logger("getting week audio duration");
  }, []);

  const hoverColor = darkMode ? colors.gray15 : "#ececec";
  const bgColor = darkMode ? colors.gray1 : colors.graySlightlyDarker;

  return (
    <div
      style={{
        // if menu is open, then put it on top of the whole screen.
        // otherwise put it on top of the transcripts so they don't flash on top of the menu as it closes
        // but make sure the summary panel appears on top
        zIndex: menuOpen && smallWindow ? 1000 : 1,
      }}
    >
      {/* dark overlay when menu is open on small screens */}
      {menuOpen && smallWindow && (
        <DarkOverlay onClick={() => setMenuOpen(false)} opacity={0.5} />
      )}

      <div
        style={{
          backgroundColor: darkMode ? "#2f2f2f" : colors.lightGray,
          paddingTop: 16,
          paddingBottom: 32,
          paddingLeft: 16,
          paddingRight: 16,
          minWidth: 220,
          maxWidth: 220,
          display: "flex",
          flexDirection: "column",
          position: smallWindow ? "absolute" : "static",
          height: smallWindow ? "100%" : "100%",
          top: smallWindow ? 0 : "auto",
          maxHeight: "100dvh",

          // animating opening and closing of menu
          transform: menuOpen || !smallWindow ? "none" : `translate(-250px)`,
          transition: smallWindow ? "transform 0.55s" : "",
        }}
      >
        <div style={{ display: "flex", marginBottom: 24 }}>
          <OssyLogo width={25} marginRight={4} />
          <OssyText big />
        </div>
        <NewTranscriptButton
          smallWindow={smallWindow}
          setMenuOpen={setMenuOpen}
          setFileToUpload={setFileToUpload}
          fileInputRef={fileInputRef}
          file={file}
          isOwnerOfContent={isOwnerOfContent}
          setNotOwnerAction={setNotOwnerAction}
          setNotOwnerPoints={setNotOwnerPoints}
          setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
          selected={selected}
          setDisplayInSharedFolderPanel={setDisplayInSharedFolderPanel}
          setSharedContentMode={setSharedContentMode}
        />

        <div
          style={{
            backgroundColor:
              selectedTranscriptsType === transcriptsType.HOME
                ? bgColor
                : "transparent",
            borderRadius: 6,
            padding: 6,
            cursor: "pointer",
            marginBottom: 20,
          }}
          onMouseEnter={(event) => {
            if (!isFolderSelected && selected === transcriptsType.HOME) {
              return;
            }
            event.currentTarget.style.backgroundColor = hoverColor;
          }}
          onMouseLeave={(event) => {
            if (!isFolderSelected && selected === transcriptsType.HOME) {
              return;
            }
            event.currentTarget.style.backgroundColor = "transparent";
          }}
          onClick={() => {
            if (smallWindow) {
              setMenuOpen(false);
            }
            window.location.pathname = "/";
          }}
        >
          <p
            style={{
              fontWeight: 400,
              color: darkMode ? "white" : "gray",
              fontSize: 16,
            }}
          >
            {transcriptsType.HOME}
          </p>
        </div>

        <div
          style={{
            backgroundColor:
              selectedTranscriptsType === transcriptsType.SHARED_TRANSCRIPTS
                ? bgColor
                : "transparent",
            borderRadius: 6,
            padding: 6,
            cursor: "pointer",
            marginBottom: 20,
          }}
          onMouseEnter={(event) => {
            if (
              selectedTranscriptsType === transcriptsType.SHARED_TRANSCRIPTS
            ) {
              return;
            }
            event.currentTarget.style.backgroundColor = hoverColor;
          }}
          onMouseLeave={(event) => {
            if (
              selectedTranscriptsType === transcriptsType.SHARED_TRANSCRIPTS
            ) {
              return;
            }
            event.currentTarget.style.backgroundColor = "transparent";
          }}
          onClick={() => {
            if (smallWindow) {
              setMenuOpen(false);
            }
            window.location.pathname = "/shared-with-me";
          }}
        >
          <p
            style={{
              fontWeight: 400,
              color: darkMode ? "white" : "gray",
              fontSize: 16,
            }}
          >
            {transcriptsType.SHARED_TRANSCRIPTS}
          </p>
        </div>

        {/* paid limit bar */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 48,
            // marginBottom: 8,
            overflow: "clip",
            flexGrow: 1,
            overflowY: "clip",
            height: "100",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isPaidUser && <LimitBarAndDescription showResetDate abbreviate />}
        </div>
      </div>
    </div>
  );
};

export default HomeLeftMenu;
