import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Menu, OssyLogo, OssyText } from "../components";
import { borderRadius, boxShadowHalf, colors } from "../utility/utility";
import { useEffect, useState } from "react";
import ossyScreen from "../assets/demo_screens/ossy_ai_screenshot5.png";
import aiExplanations from "../assets/demo_screens/welcome_screen/ai_explanations.png";
import summaryPanelSs from "../assets/demo_screens/welcome_screen/summary_panel.png";
import searchbarSs from "../assets/demo_screens/welcome_screen/searchbar.png";
import professorOssySs from "../assets/demo_screens/welcome_screen/professor_ossy_ss.png";
import mic from "../assets/mic.svg";
import {
  addScrollBarUI,
  boxShadow,
  getIsMac,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import WelcomePageFooter from "../components/WelcomePageFooter";
import ossyVid from "../assets/ossy_welcome_vid_2_compressed.mp4";
import SelectTranscriptModePanel from "../components/SelectTranscriptModePanel";
import { invitationWasClicked } from "../transcriptSlice";

const WelcomePage = ({ supabase }) => {
  const dispatch = useDispatch();
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${770}px)`).matches
  );
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [
    displaySelectTranscriptModePanel,
    setDisplaySelectTranscriptModePanel,
  ] = useState(false);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    const isDarkMode = false;
    if (!isMac) {
      setScrollbarLightOrDarkMode(isDarkMode);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia(`(max-width: ${770}px)`).matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const invitedEmail = searchParams.get("invitation");

    // url does not have an invitation
    if (invitedEmail === null) return;

    // log when the invitation was clicked
    dispatch(invitationWasClicked({ supabase, invitedEmail }));
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        {displaySelectTranscriptModePanel && (
          <SelectTranscriptModePanel
            setDisplaySelectTranscriptModePanel={
              setDisplaySelectTranscriptModePanel
            }
          ></SelectTranscriptModePanel>
        )}

        <Header
          color={"white"}
          // color={colors.ossyBlue3}
          height={80}
          marginInside={smallWindow ? 16 : 28}
          line
          ignoreDarkMode
          fixed
          left={
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <OssyLogo width={25} />
              <OssyText big color={colors.ossyBlue1} />
            </div>
          }
          right={
            <Menu
              breakpoint={1250}
              noMenuContent={
                <>
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      color: colors.black,
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      window.location.href = "/affiliate";
                    }}
                  >
                    Affiliate
                  </p>
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      color: colors.black,
                      whiteSpace: "nowrap",
                      marginLeft: 20,
                    }}
                    onClick={() => {
                      window.location.href = "/releases";
                    }}
                  >
                    What's New
                  </p>
                  <Button
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    content={"Login"}
                    style={{
                      fontSize: 18,
                      marginLeft: 20,
                    }}
                  />
                </>
              }
              menuContents={[
                {
                  text: "What's New",
                  onClick: () => {
                    window.location.href = "/releases";
                  },
                },
                {
                  text: "Affiliate",
                  onClick: () => {
                    window.location.href = "/affiliate";
                  },
                },
                {
                  text: "Login",
                  onClick: () => {
                    window.location.href = "/login";
                  },
                },
              ]}
            />
          }
        />
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // backgroundColor: "white",
            color: colors.black,
          }}
        >
          {/* large call to action */}
          <div
            style={{
              marginTop: 160,
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 54,
              marginRight: 54,
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: smallWindow ? 40 : 66,
                marginBottom: 12,
              }}
            >
              Transcribe. Explain. Elevate.
              {/* The Lecture Lifesaver. */}
              {/* Your AI Note-Taking Tool. */}
            </p>
            <p
              style={{
                fontSize: 22,
                color: "gray",
                fontWeight: 500,
              }}
            >
              Notes that take themselves.
            </p>
            <p
              style={{
                fontSize: 22,
                color: "gray",
                fontWeight: 500,
              }}
            >
              For students by students.
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                // width: smallWindow ? "auto" : "auto",
                // height: "fit-content",
                marginTop: smallWindow ? 0 : 40,
              }}
            >
              <Button
                content={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Start Transcribing
                    <img
                      src={mic}
                      alt="mic"
                      style={{ width: 26, marginLeft: 4 }}
                    />
                  </div>
                }
                onClick={() => {
                  setDisplaySelectTranscriptModePanel(true);
                }}
                padding={20}
                style={{
                  alignItems: "center",
                  marginTop: smallWindow ? 30 : 0,
                  fontSize: 24,
                  justifyContent: "center",
                  marginRight: smallWindow ? 0 : 20,
                }}
              />
            </div>
          </div>
          {/* {children} */}

          <div
            style={{
              marginTop: 100,
            }}
          />

          {!smallWindow && (
            <video
              loop
              muted
              playsInline
              autoPlay
              controls={false}
              src={ossyVid}
              alt="ossy"
              style={{
                boxShadow: "12px 12px 60px rgba(0, 0, 0, 0.25)",
                width: "95%",
                cursor: "auto",
                borderRadius: 16,
                display: "block",
                objectFit: "cover",
                marginBottom: 150,
                maxWidth: 1000,
              }}
            />
          )}

          {/* the mini explanations */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {[
              {
                src: professorOssySs,
                title: "Professor Ossy",
                description:
                  "An AI chatbot that is trained on all your lectures. Ask it anything, from clarification on a lecture to homework questions.\n\nProfessor Ossy is trained on all of your lectures and cites where in your lectures it's answers came from.",
              },
              {
                src: aiExplanations,
                title: "Realtime AI explanations",
                description:
                  "Watch as your lectures are explained in realtime using AI.",
              },
              {
                src: summaryPanelSs,
                title: "Text & bullet point summaries",
                description:
                  "Get AI summaries of your lectures. Toggle between in-depth textual explanations and brief bullet points.",
              },
              {
                src: searchbarSs,
                title: "Search",
                description:
                  "Easily find anything from any of your lectures by searching for words.",
              },
            ].map(({ src, title, description }) => {
              return (
                <div
                  style={{
                    marginBottom: smallWindow ? 80 : 150,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: smallWindow ? "column" : "row",
                    width: "80%",
                    maxWidth: 1400,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginRight: 40,
                      marginBottom: smallWindow ? 30 : 0,
                      width: smallWindow ? undefined : "50%",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: smallWindow ? 24 : 32,
                        marginBottom: 14,
                      }}
                    >
                      {title}
                    </p>
                    <p
                      style={{
                        fontSize: smallWindow ? 16 : 20,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {description}
                    </p>
                  </div>

                  <div
                    style={{
                      flexGrow: 1,
                      maxWidth: smallWindow ? "100%" : "none",
                      width: smallWindow ? "100%" : "50%",
                    }}
                  >
                    <img
                      src={src}
                      alt={title}
                      style={{
                        borderRadius: "16px",
                        boxShadow: boxShadowHalf,
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              // marginTop: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "70%",
              maxWidth: 1000,
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: smallWindow ? 28 : 40,
                marginBottom: 14,
              }}
            >
              Coming soon to Ossy
            </p>
            <p style={{ fontSize: 20, color: "gray" }}>
              You asked. We're delivering.
            </p>
            <div
              style={{
                boxShadow: boxShadowHalf,
                borderRadius: borderRadius,
                marginTop: 50,
                fontSize: 20,
                padding: 36,
                width: "100%",
                maxWidth: "100%",
                paddingLeft: 36,
                paddingRight: 36,
                display: "flex",
                gap: 30,
              }}
            >
              {[
                {
                  title: "Hover Definitions",
                  description:
                    "Key terms from your lectures will be highlighted in realtime. Upon hovering your mouse, they will be defined in the context of the lecture.",
                },
                {
                  title: "More In-depth Explanations",
                  description:
                    "AI explanations that are so thorough that you could sit in on an astrophysics lecture and understand what's going on.",
                },
              ].map(({ title, description }, index) => {
                return (
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: smallWindow ? 16 : 28,
                      }}
                    >
                      {title}
                    </p>
                    <p
                      style={{
                        fontSize: smallWindow ? 16 : 20,
                      }}
                    >
                      {description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* large call to action */}
          <div
            style={{
              marginTop: 150,
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 54,
              marginRight: 54,
            }}
          >
            <p
              style={{
                fontWeight: 800,
                fontSize: smallWindow ? 40 : 60,
                // marginBottom: 16,
              }}
            >
              Bring Your Lectures to Life.
              {/* The Lecture Lifesaver. */}
              {/* Your AI Note-Taking Tool. */}
            </p>
            <p style={{ fontSize: 20, color: "gray" }}>With the power of AI</p>

            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                // width: smallWindow ? "auto" : "auto",
                // height: "fit-content",
                marginTop: smallWindow ? 16 : 56,
              }}
            >
              <Button
                content={
                  <div>
                    <p>
                      Start Now{" "}
                      <span
                        style={{
                          color: "white",
                          opacity: 0.5,
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                        }}
                      >
                        (it's free)
                      </span>
                    </p>
                  </div>
                }
                onClick={() => {
                  if (isLoggedIn) {
                    window.location.pathname = "/home";
                  } else {
                    window.location.pathname = "/t/new";
                  }
                }}
                padding={20}
                style={{
                  alignItems: "center",
                  marginTop: smallWindow ? 30 : 0,
                  fontSize: 24,
                  justifyContent: "center",
                  marginRight: smallWindow ? 0 : 20,
                }}
              />
            </div>
          </div>

          {/* footer */}
          <WelcomePageFooter />
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
