import { colors } from "../utility/utility.mjs";
import { IoChevronBack } from "react-icons/io5";
import { useSelector } from "react-redux";

const BackButton = ({ destination }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        marginBottom: 24,
        display: "flex",
        alignItems: "center",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 40,
          height: 40,
          borderRadius: "100%",
          backgroundColor: darkMode ? colors.gray1 : colors.lightGray,
          cursor: "pointer",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = darkMode
            ? colors.gray2
            : colors.gray)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = darkMode
            ? colors.gray1
            : colors.lightGray)
        }
        onClick={() => {
          window.location.pathname = destination;
        }}
      >
        <IoChevronBack size={24} />
      </div>
    </div>
  );
};

export default BackButton;
