import { useSelector } from "react-redux";
import {
  colors,
  borderRadius,
  fontSizes,
  logger,
  LogCategory,
} from "../../../utility/utility";

const SummaryBlurb = ({
  index,
  summaryText,
  timestampRange,
  opacity = 1,
  highlightBlurb,
  highlightTranscriptBlurbTimeout,
  setHighlightedSummaryBlurbIndex,
  setHighlightedTranscriptBlurbIndex,
  paidBlurb,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return summaryText ? (
    <div
      style={{
        marginBottom: 16,
        color: "black",
        marginRight: 16,
        opacity: opacity,
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 4,
        borderRadius: borderRadius,
        marginLeft: 4,
        backgroundColor: highlightBlurb ? colors.ossyBlue2 : "transparent",
        transition: "background-color 0.5s",
        cursor: paidBlurb ? "auto" : "pointer",
      }}
      key={`summaryBlurb ${index}`}
      id={`summaryBlurb ${index}`}
      onMouseEnter={(e) => {
        if (paidBlurb) {
          return;
        }
        e.currentTarget.lastChild.style.background = darkMode
          ? colors.gray15
          : colors.graySlightlyDarker;
      }}
      onMouseLeave={(e) => {
        if (paidBlurb) {
          return;
        }
        e.currentTarget.lastChild.style.background = darkMode
          ? colors.gray1
          : colors.gray;
      }}
      onClick={(e) => {
        if (paidBlurb) {
          return;
        }

        const transcriptTextBlurbId = e.currentTarget.className;
        const transcriptTextBlurb = document.getElementById(
          transcriptTextBlurbId
        );
        if (transcriptTextBlurb) {
          transcriptTextBlurb.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          setHighlightedTranscriptBlurbIndex(transcriptTextBlurbId);
          setHighlightedSummaryBlurbIndex(-1);
          clearTimeout(highlightTranscriptBlurbTimeout.current);
          highlightTranscriptBlurbTimeout.current = setTimeout(() => {
            logger([LogCategory.DEBUG], "highlight blurb timeout");
            setHighlightedTranscriptBlurbIndex(-1);
          }, 1500);
        } else {
          logger([LogCategory.DEBUG], "couldn't find it");
        }
      }}
    >
      {/* timestamp range */}
      <p
        style={{
          fontWeight: 800,
          color: highlightBlurb ? "white" : "gray",
          transition: "color 0.5s",
          margin: 0,
          padding: 0,
          fontSize: fontSizes.regular,
        }}
        id={`summaryBlurb timestampRange ${index}`}
      >
        {timestampRange}
      </p>

      {/* explanation */}
      <div
        style={{
          padding: 8,
          margin: 0,
          fontSize: fontSizes.regular,
          lineHeight: 2.5,
          backgroundColor: darkMode ? colors.gray1 : colors.gray,
          borderRadius: borderRadius,
          color: darkMode ? "white" : "black",
          opacity: highlightBlurb ? 0.8 : 1,
          transition: "opacity 0.5s",
        }}
        id={`summaryBlurbText ${index}`}
      >
        <p
          style={{
            filter: paidBlurb ? "blur(3px)" : undefined,
            userSelect: paidBlurb ? "none" : undefined,
          }}
        >
          {summaryText}
        </p>
      </div>
    </div>
  ) : (
    <div key={`summaryBlurb ${index}`}></div>
  );
};

export default SummaryBlurb;
