import Header from "./Header";
import { OssyText } from "./";
import { useState } from "react";

const OssyHeader = ({ smallWindow, setMenuOpen }) => {
  const [menuColor, setMenuColor] = useState("gray");

  return (
    <Header
      left={
        smallWindow ? (
          <>
            <div
              style={{
                cursor: "pointer",
                borderRadius: 6,
                width: 22,
                height: 20,
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                justifyContent: "space-between",
                flexDirection: "column",
                padding: 0,
              }}
              onClick={() => setMenuOpen(true)}
              onMouseEnter={() => setMenuColor("lightgray")}
              onMouseLeave={() => setMenuColor("gray")}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
            </div>
          </>
        ) : (
          // show ossy in header if large window
          <OssyText />
        )
      }
      line={true}
    />
  );
};

export default OssyHeader;
