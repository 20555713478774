import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const LimitBar = ({ totalDuration }) => {
  const weekTranscriptionLimitSeconds = useSelector(
    (state) => state.routes.weekTranscriptionLimitSeconds
  );

  return (
    <div
      style={{
        width: "100%",
        height: 8,
        backgroundColor: "lightgray",
        borderRadius: 1000,
      }}
    >
      <div
        style={{
          height: 8,
          borderRadius: 1000,
          backgroundColor: colors.ossyBlue1,
          width: `${(totalDuration / weekTranscriptionLimitSeconds) * 100}%`,
          maxWidth: "100%",
        }}
      />
    </div>
  );
};

export default LimitBar;
