import { FiXCircle } from "react-icons/fi";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  colors,
  borderRadius,
  boxShadow,
  getIsMac,
  addScrollBarUI,
  logger,
  LogCategory,
} from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { fetchSummarySentences } from "../api/summarySetencesApi";
import { fetchSummaryBulletPoints } from "../api/summaryBulletPointsApi";
import Button from "../../../components/Button";

const SummaryPanel = ({
  setShowSummaryPanel,
  content,
  ownerOfContent,
  supabase,
  rightClickObject = { index: undefined },
  contentType,
}) => {
  const { summaryBulletPoints, summarySentences, contentArray } =
    content ?? rightClickObject.thing;

  const [toggleBulletPoints, setToggleBulletPoints] = useState(false);
  const defaultText = "Generating summary...";
  const [displayedSummary, setDisplayedSummary] = useState(defaultText);
  const [displayedSummarySentences, setDisplayedSummarySentences] =
    useState(defaultText);
  const [displayedSummaryBulletPoints, setDisplayedSummaryBulletPoints] =
    useState(defaultText);

  const [doneLoadingSummarySentences, setDoneLoadingSummarySentences] =
    useState(true);
  const [doneLoadingSummaryBulletPoints, setDoneLoadingSummaryBulletPoints] =
    useState(true);
  const [doneLoadingSummary, setDoneLoadingSummary] = useState(true);

  const dispatch = useDispatch();
  const summaryAutoscroll = useRef(true);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const trptRef = useRef(null);
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }

    return () => window.MathJax.typesetClear();
  }, [displayedSummary]);

  useEffect(() => {
    const textbox = document.getElementById("textbox") || trptRef.current;
    if (textbox !== null && summaryAutoscroll.current) {
      textbox.scrollTop = textbox.scrollHeight;
      textbox.scrollTo({
        top: textbox.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [summaryAutoscroll, displayedSummary]);

  // when summary bullet points and summary sentences are done loading
  useEffect(() => {
    if (
      doneLoadingSummarySentences === true &&
      doneLoadingSummaryBulletPoints === true
    ) {
      setDoneLoadingSummary(true);
    }
  }, [doneLoadingSummaryBulletPoints, doneLoadingSummarySentences]);

  // determines the summary text that is diplayed either sentence or bullet point format
  useEffect(() => {
    if (toggleBulletPoints === true) {
      // display summary bullet points
      setDisplayedSummary(displayedSummaryBulletPoints);
    } else {
      // display summary sentences
      setDisplayedSummary(displayedSummarySentences);
    }
    return () => {
      setDisplayedSummary(defaultText);
    };
  }, [
    toggleBulletPoints,
    displayedSummarySentences,
    displayedSummaryBulletPoints,
  ]);

  useEffect(() => {
    // when the summary panel initially displays check if summarySentences and summaryBulletPoints have content
    if (summarySentences.length > 0 && summaryBulletPoints.length > 0) {
      setDisplayedSummarySentences(summarySentences);
      setDisplayedSummaryBulletPoints(
        summaryBulletPoints.map((bullet, index) =>
          bullet !== "" ? <li key={index}>{bullet}</li> : null
        )
      );
      // if not get the summary sentences and bullet points
    } else {
      fetchSummarySentencesAndBulletPoints();
    }
  }, []);

  // fetch a new summary sentences and summary bullet points
  const fetchSummarySentencesAndBulletPoints = () => {
    logger("about to start fetching summary");
    logger("content array...");
    logger(contentArray);

    // start loading symbol
    setDoneLoadingSummaryBulletPoints(false);
    setDoneLoadingSummarySentences(false);
    setDoneLoadingSummary(false);

    // reset displayed text
    setDisplayedSummary(defaultText);
    setDisplayedSummaryBulletPoints(defaultText);
    setDisplayedSummarySentences(defaultText);

    // fetch new summary setences
    fetchSummarySentences({
      supabase,
      dispatch,
      contentArray,
      setDisplayedSummarySentences,
      setDoneLoadingSummarySentences,
      index: rightClickObject.index,
      contentType,
    });

    // fetch new summary bullet points
    fetchSummaryBulletPoints({
      supabase,
      dispatch,
      contentArray,
      setDisplayedSummaryBulletPoints,
      setDoneLoadingSummaryBulletPoints,
      index: rightClickObject.index,
      contentType,
    });
  };

  return (
    <>
      {/* dark overlay */}
      <DarkOverlay zIndex={3} />

      <div
        style={{
          alignItems: "center",
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          boxShadow: boxShadow,
          color: darkMode ? "white" : "black",
          display: "flex",
          flexDirection: "column",
          left: "50%",
          maxHeight: "80%",
          maxWidth: "85%",
          marginBottom: 6,
          position: "fixed",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          zIndex: 3,
        }}
      >
        {/* close summary panel button */}
        <div
          style={{
            position: "fixed",
            // height: 10,
            // width: 10,
            left: 20,
            top: 20,
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => setShowSummaryPanel(false)}
        >
          <FiXCircle
            color="gray"
            style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20 }}
          />
        </div>

        <div
          style={{
            textAlign: "left",
            fontSize: 20,
            margin: 20,
            fontWeight: 500,
            marginBottom: 0,
          }}
        >
          Summary
        </div>

        {/* toggle sentences/bullet points */}
        <div
          style={{
            background: `rgba(${
              darkMode ? "255, 255, 255" : "170, 170, 170"
            }, 0.3)`,
            borderRadius: 4,
            position: "absolute",
            display: "flex",
            right: 8,
            top: 8,
            flexDirection: "row",
            height: "fit-content",
            padding: 3,
          }}
        >
          {/* sentences */}
          <div
            style={{
              width: 20,
              height: 20,
              backgroundColor: toggleBulletPoints
                ? "lightgray"
                : colors.ossyBlue1,
              padding: 4,
              marginRight: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: 4,
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = 0.5)}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = 1)}
            onClick={(e) => {
              setToggleBulletPoints(false);
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: darkMode ? "gray" : "white",
                height: 2,
                marginTop: 1,
              }}
            />
            <div
              style={{
                width: "100%",
                backgroundColor: darkMode ? "gray" : "white",
                height: 2,
              }}
            />
            <div
              style={{
                width: "100%",
                backgroundColor: darkMode ? "gray" : "white",
                height: 2,
              }}
            />
            <div
              style={{
                width: "100%",
                backgroundColor: darkMode ? "gray" : "white",
                height: 2,
                marginBottom: 1,
              }}
            />
          </div>

          {/* bullet points */}
          <div
            style={{
              width: 20,
              height: 20,
              // backgroundColor: darkMode ? "lightgray" : "gray",
              backgroundColor: toggleBulletPoints
                ? colors.ossyBlue1
                : "lightgray",
              padding: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              borderRadius: 4,
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = 0.5)}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = 1)}
            onClick={(e) => {
              setToggleBulletPoints(true);
            }}
          >
            {/* bullet point */}
            <div
              style={{
                width: "100%",
                height: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 1000,
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 4,
                  width: 4,
                  marginRight: 2,
                }}
              />
              <div
                style={{
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 2,
                  flexGrow: 1,
                }}
              />
            </div>

            {/* bullet point */}
            <div
              style={{
                width: "100%",
                height: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 1000,
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 4,
                  width: 4,
                  marginRight: 2,
                }}
              />
              <div
                style={{
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 2,
                  flexGrow: 1,
                }}
              />
            </div>

            {/* bullet point */}
            <div
              style={{
                width: "100%",
                height: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 1000,
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 4,
                  width: 4,
                  marginRight: 2,
                }}
              />
              <div
                style={{
                  backgroundColor: darkMode ? "gray" : "white",
                  height: 2,
                  flexGrow: 1,
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 0,
            paddingTop: 0,
            fontSize: 16,
            overflowY: "auto",
            padding: 24,
            margin: 5,
            fontWeight: 300,
            width: "100%",
            boxSizing: "border-box",
          }}
          ref={trptRef}
          id={"summary_textbox"}
          className={"textbox"}
          onScroll={(event) => {
            const isMac = getIsMac();
            if (!isMac) {
              addScrollBarUI(event);
            }

            const textbox = event.target;
            summaryAutoscroll.current =
              Math.abs(
                textbox.scrollTop + textbox.offsetHeight - textbox.scrollHeight
              ) <= 20;
          }}
        >
          <p
            style={{
              textAlign: "left",
            }}
          >
            {displayedSummary}
          </p>
        </div>

        {ownerOfContent && (
          <Button
            style={{
              fontSize: 14,
              borderRadius: borderRadius,
              alignSelf: "flex-end",
              margin: 15,
            }}
            loading={!doneLoadingSummary}
            onClick={(event) => {
              logger("What the fuck.");
              logger([LogCategory.DEBUG], "Generating new summary");
              // don't generate another summary while waiting for the first one
              if (!doneLoadingSummary) {
                return;
              }
              fetchSummarySentencesAndBulletPoints();
            }}
            content="Generate New Summary"
          />
        )}
      </div>
    </>
  );
};

export default SummaryPanel;
