import { useSelector, useDispatch } from "react-redux";
import { addToFolder } from "../transcriptSlice";
import { BsFillFolderFill } from "react-icons/bs";
import { emToPixels } from "../utility/utility";
import { contentTypes } from "../utility/utility.mjs";

const SelectFolder = ({
  rightClickContentIndex,
  folder: { folderName, folderUrl, isPrivate },
  index,
  setSelected,
  setSelectedTranscriptsType,
  setIsFolderSelected,
  setSelectFolderPanel,
  supabase,
  setDisplayAddToSharedFolder,
  setAddToSharedContentIndex,
  setAddToSharedFolderIndex,
}) => {
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.routes.folders);
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 70 + 2 * emToPixels,
        // height: "100%",
        width: "100%",
        justifyContent: "flex-start",
        flexGrow: index !== folders.length - 1 ? 1 : 0,
        alignItems: "center",
        overflow: "clip",
        cursor: "pointer",
        color: darkMode ? "white" : "black",
      }}
      onClick={() => {
        if (isPrivate) {
          dispatch(
            addToFolder({
              supabase: supabase,
              contentIndex: rightClickContentIndex,
              folderUrl: folderUrl,
            })
          );
        } else {
          setAddToSharedFolderIndex(index);
          setAddToSharedContentIndex(rightClickContentIndex);
          setDisplayAddToSharedFolder(true);
        }
        setSelectFolderPanel(false);
      }}
    >
      <BsFillFolderFill
        color="#4bbcde"
        style={{
          minHeight: 70,
          minWidth: 70,
          width: "100%",
          opacity: 0.5,
        }}
      />
      <p
        style={{
          maxWidth: 80,
          textOverflow: "ellipsis",
          overflow: "clip",
          whiteSpace: "nowrap",
          fontSize: 14,
          lineHeight: 1.2,
          fontWeight: 300,
        }}
      >
        {folderName}
      </p>
    </div>
  );
};
export default SelectFolder;
