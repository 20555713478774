import { Button } from "../../../components";
import { initialState } from "../../../data/initialState";
import {
  apiEndpoint,
  borderRadius,
  colors,
  logger,
} from "../../../utility/utility.mjs";
import info from "../../../assets/info.svg";
import { useEffect, useState } from "react";
import { buttonStyles } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIfUserChangesPlan,
  fetchUserData,
} from "../../../transcriptSlice";
import { cardIcons } from "../../../data/cardIcons";
import whiteInfo from "../../../assets/whiteInfo.svg";

export const planTypes = {
  OssyPlus: 100,
  OssyFree: 101,
};

const PlanTypesPanels = ({
  supabase,
  currentPlanType,
  displayCurrentPlanType,
  showCurrentSubscriptionBanner,
  displayPaymentSection,
  setShowChangePaymentMethodPanel,
  defaultPaymentMethod,
  setDefaultPaymentMethod = () => {},
}) => {
  const ossyPrice = initialState.ossyPrice;
  const dispatch = useDispatch();
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const userId = useSelector((state) => state.routes.userId);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const [nextBillDate, setNextBillDate] = useState(undefined);
  const darkMode = useSelector((state) => state.routes.darkMode);

  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 800px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  useEffect(() => {
    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));

    dispatch(checkIfUserChangesPlan({ supabase }));

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      if (!isPaidUser) {
        return;
      }

      fetch(`${apiEndpoint}/get_subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          customerId: customerId,
          referralId: (window || {}).promotekit_referral,
        }),
      }).then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          return;
        }

        const { subscription } = responseJson;
        logger("got subscription: ", subscription);
        updateSubscriptionValues(subscription);
      });
    });
  }, [defaultPaymentMethod]);

  const updateSubscriptionValues = (subscription) => {
    if (subscription.default_payment_method === null) {
      // setMissingDefaultPaymentMethod(true);
    } else {
      setDefaultPaymentMethod(subscription.default_payment_method);
    }

    const _nextBillDate = new Date(subscription.current_period_end * 1000);
    logger(_nextBillDate);
    setNextBillDate(_nextBillDate);
  };
  return [
    {
      planName: "Ossy Plus",
      message: "",
      features: [
        "Unlimited transcription",
        "Unlimited realtime explanations",
        "Unlimited questions to Professor Ossy",
        "Cancel anytime",
      ],
      headerColor: colors.ossyBlue1,
      buttonText: "Select",
      buttonLink: "upgrade",
      displayPrice: true,
      planType: planTypes.OssyPlus,
      textColor: "white",
    },
    {
      planName: "Ossy Free",
      message: "Switching to free will cancel your premium plan.",
      features: [
        "1 hours/week of free transcription",
        "1 hours/week of AI explanations",
        "10 Professor Ossy questions per transcript",
      ],
      headerColor: colors.graySlightlyDarker,
      buttonText: "Cancel plan",
      buttonLink: "account/cancel/stats",
      displayPrice: false,
      planType: planTypes.OssyFree,
      textColor: "rgba(0, 0, 0, 1)",
    },
  ].map(
    ({
      planName,
      message,
      features,
      headerColor,
      buttonText,
      buttonLink,
      displayPrice,
      planType,
      textColor,
    }) => {
      if (
        (currentPlanType === planType && displayCurrentPlanType) ||
        (currentPlanType !== planType && displayCurrentPlanType === false)
      )
        return (
          <div
            style={{
              // border: `2px solid ${colors.ossyBlue1}`,
              // padding: 16,
              borderRadius: borderRadius,
              width: "100%",
              marginBottom: 24,
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: 32,
                width: "100%",
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
                backgroundColor: `${headerColor}`,
                boxSizing: "border-box",
                color: textColor,
              }}
            >
              {showCurrentSubscriptionBanner &&
                currentPlanType === planType && (
                  <div
                    style={{
                      fontSize: 15,
                      backgroundColor: colors.ossyDarkBlue3,
                      borderTopLeftRadius: borderRadius,
                      borderTopRightRadius: borderRadius,
                      padding: "3px 16px",
                    }}
                  >
                    Current subscription
                  </div>
                )}

              <p
                style={{
                  paddingTop: 48,
                  paddingBottom: 48,
                  paddingLeft: 28,
                  paddingRight: 28,
                }}
              >
                {planName}
              </p>
            </div>
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 2,
                backgroundColor: darkMode ? colors.gray1 : colors.lightGray,
                padding: 28,
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: 14,
                  backgroundColor: darkMode ? "gray" : colors.gray,
                  borderRadius: 8,
                  lineHeight: 1.6,
                  display:
                    planType === planTypes.OssyFree &&
                    currentPlanType !== planTypes.OssyFree
                      ? "flex"
                      : "none",
                  alignItems: "flex-start",
                  marginBottom: 20,
                  gap: 12,
                }}
              >
                <img
                  src={darkMode ? whiteInfo : info}
                  alt="info"
                  style={{
                    width: 24,
                  }}
                />
                <p>Switching to Free will cancel your Premium plan.</p>
              </div>

              {/* body */}
              <div
                style={{
                  display: "flex",
                  flexDirection: smallWindow ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                {/* left column (features) */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 16,
                    color: darkMode ? "white" : "black",
                  }}
                >
                  {features.map((feature) => (
                    <p>• &nbsp; {feature}</p>
                  ))}
                </div>

                {/* Right column */}
                {displayPaymentSection &&
                  defaultPaymentMethod &&
                  nextBillDate && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 16,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "gray",
                          height: 1,
                          marginTop: 12,
                          marginBottom: 12,
                          display: smallWindow ? "inline" : "none",
                        }}
                      />
                      <div
                        style={{
                          fontSize: 24,
                          fontWeight: 700,
                          lineHeight: 1,
                          marginBottom: 20,
                        }}
                      >
                        Payment
                      </div>
                      {nextBillDate && (
                        <div>
                          <span
                            style={{
                              color: darkMode ? "white" : "black",
                              lineHeight: 2,
                            }}
                          >
                            Your next bill is for{" "}
                            <span
                              style={{
                                fontWeight: 700,
                                color: darkMode ? "white" : "black",
                              }}
                            >
                              {ossyPrice}
                            </span>{" "}
                            on{" "}
                            <span
                              style={{
                                fontWeight: 700,
                                color: darkMode ? "white" : "black",
                              }}
                            >
                              {nextBillDate.getMonth() + 1}/
                              {nextBillDate.getDate()}/
                              {String(nextBillDate.getFullYear()).slice(2)}
                            </span>
                            .
                          </span>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {defaultPaymentMethod &&
                          [defaultPaymentMethod].map(
                            ({
                              card: { brand, last4, exp_month, exp_year },
                              id: paymentMethodId,
                            }) => {
                              const icon = cardIcons[brand];
                              const formattedBrand =
                                brand.charAt(0).toUpperCase() + brand.slice(1);
                              const expYear = String(exp_year).slice(2);
                              return (
                                <div
                                  key={paymentMethodId}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: 8,
                                    marginRight: 16,
                                  }}
                                >
                                  {/* card icon */}
                                  <img
                                    src={icon}
                                    alt={`${brand} card icon`}
                                    style={{ width: 28 }}
                                  />

                                  {/* card details */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      fontWeight: 600,
                                      color: darkMode
                                        ? colors.gray
                                        : colors.black,
                                      fontSize: 14,
                                      lineHeight: 1.5,
                                    }}
                                  >
                                    <p>
                                      {formattedBrand} ending in {last4}
                                    </p>

                                    <p
                                      style={{
                                        color: "gray",
                                      }}
                                    >
                                      Expires: {exp_month}/{expYear}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )}

                        <div
                          style={{
                            cursor: "pointer",
                            fontWeight: 700,
                            color: "gray",
                          }}
                          onMouseEnter={(event) => {
                            event.currentTarget.style.color = darkMode
                              ? "white"
                              : colors.black;
                          }}
                          onMouseLeave={(event) => {
                            event.currentTarget.style.color = "gray";
                          }}
                          onClick={() => {
                            setShowChangePaymentMethodPanel(true);
                          }}
                        >
                          Update
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              {/* the line across */}
              <div
                style={{
                  width: "100%",
                  display: !displayPaymentSection ? "flex" : "none",
                  backgroundColor: "gray",
                  height: 1,
                  marginTop: 10,
                  marginBottom: 20,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: displayPrice ? "space-between" : "flex-end",
                  alignItems: "center",
                }}
              >
                {!displayPaymentSection && (
                  <div style={{ width: "100%" }}>
                    {/* price */}
                    {displayPrice && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          lineHeight: 1,
                        }}
                      >
                        <p style={{ fontWeight: 800, fontSize: 20 }}>
                          {ossyPrice}
                        </p>
                        <p style={{ fontSize: 14, marginTop: 8 }}>per month</p>
                      </div>
                    )}
                  </div>
                )}

                {currentPlanType !== planType && (
                  <Button
                    content={<div>{buttonText}</div>}
                    buttonStyle={buttonStyles.white}
                    onClick={() => {
                      window.location.pathname = `${buttonLink}`;
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );

      return <></>;
    }
  );
};

export default PlanTypesPanels;
