import lock from "../../../assets/locked.png";
import lock_unlocked from "../../../assets/unlocked.png";
import { useDispatch, useSelector } from "react-redux";
import { togglePrivacy } from "../../../transcriptSlice";
import { logger, LogCategory } from "../../../utility/utility";
import { contentTypes } from "../../../utility/utility.mjs";

const PrivacyLock = ({
  supabase,
  contentType,
  moreContainerStyles,
  moreLockStyles,
  index = undefined,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isPrivate = useSelector((state) => {
    return index >= 0
      ? // toggling privacy from the homepage
        contentType.id === contentTypes.FOLDER.id
        ? state.routes.folders[index].isPrivate
        : state.routes.contentsArray[index].isPrivate
      : // // within folder, document, or transcript
        state.routes[contentType.name].isPrivate;
  });
  const dispatch = useDispatch();
  logger(
    [LogCategory.INFO],
    `privacy: ${isPrivate}, typeof: ${typeof isPrivate}`
  );

  return (
    <div
      style={{
        minWidth: 32,
        maxWidth: 32,
        borderRadius: 6,
        margin: 0,
        backgroundColor: darkMode ? "gray" : "lightgray",
        maxHeight: 32,
        minHeight: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        ...moreContainerStyles,
      }}
      onMouseEnter={(event) => {
        event.currentTarget.firstChild.style.transform = "scale(1.2)";
      }}
      onMouseLeave={(event) => {
        event.currentTarget.firstChild.style.transform = "scale(1)";
      }}
      onClick={(event) => {
        logger([LogCategory.DEBUG], `locked: ${isPrivate}`);
        dispatch(togglePrivacy({ supabase, contentType, index }));
      }}
    >
      <img
        src={isPrivate ? lock : lock_unlocked}
        alt="privacy controls"
        style={{ width: 14, transition: ".1s transform", ...moreLockStyles }}
      />
    </div>
  );
};

export default PrivacyLock;
