import React from "react";

const CarrotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-encore-id="icon"
    role="img"
    aria-hidden="true"
    fill={props.color || "#ffffff"}
    class="Svg-sc-ytk21e-0 hPIlex sc-c630f568-2 dIjLwQ"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M2.793 15.957a1 1 0 0 0 1.414 0L12 8.164l7.793 7.793a1 1 0 1 0 1.414-1.414L12 5.336l-9.207 9.207a1 1 0 0 0 0 1.414z"></path>
  </svg>
);

export default CarrotIcon;
