import { initialState } from "../../../data/initialState";
import { saveSummaryBulletPointsBackend } from "../../../transcriptSlice";
import {
  apiEndpoint,
  logger,
  LogCategory,
  filterTranscriptArray,
  contentTypes,
} from "../../../utility/utility.mjs";

export const fetchSummaryBulletPoints = async ({
  supabase,
  dispatch,
  contentArray,
  setDisplayedSummaryBulletPoints,
  setDoneLoadingSummaryBulletPoints,
  index = undefined,
  contentType,
}) => {
  try {
    let text;
    if (contentType === contentTypes.TRANSCRIPT) {
      // only select blurbs that have fewer than 1000 characters in the contentArray
      const maxNumCharacters = 1000;
      const filteredTranscriptArray = filterTranscriptArray(
        contentArray,
        maxNumCharacters
      );
      text = filteredTranscriptArray.join(" ");
    } else {
      text = contentArray.join(" ");
    }
    const body = JSON.stringify({
      input: { text: text },
    });

    fetch(`${apiEndpoint}/summary_bullet_points`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(async (response) => {
      let summaryBulletPoints = [];
      let currentBullet = "";
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          async function pump() {
            return reader.read().then(async ({ done, value }) => {
              const tempText = new TextDecoder().decode(value);
              for (const char of tempText) {
                if (char === "•") {
                  summaryBulletPoints.push(currentBullet);
                  currentBullet = "";
                } else {
                  currentBullet += char;
                }
              }
              setDisplayedSummaryBulletPoints(
                summaryBulletPoints.map((bullet, index) =>
                  bullet !== "" ? <li key={index}>{bullet}</li> : null
                )
              );
              // When no more data needs to be consumed, close the stream
              if (done) {
                summaryBulletPoints.push(currentBullet);
                logger(
                  [LogCategory.DEBUG],
                  "New summary bullet points: ",
                  summaryBulletPoints
                );
                setDisplayedSummaryBulletPoints(
                  summaryBulletPoints.map((bullet, index) =>
                    bullet !== "" ? <li key={index}>{bullet}</li> : null
                  )
                );
                setDoneLoadingSummaryBulletPoints(true);

                await dispatch(
                  saveSummaryBulletPointsBackend({
                    supabase,
                    newSummaryBulletPoints: summaryBulletPoints,
                    index,
                    contentType,
                  })
                ).unwrap();
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    });
  } catch (error) {
    logger([LogCategory.ERROR], `cancelled fetch: ${error}`);
  }
};
