import { logo } from "../assets";

const OssyLogo = ({ width = 24, marginRight = 0, noClick }) => {
  return (
    <a
      style={{
        textDecoration: "none",
        color: "black",
        cursor: noClick ? "auto" : "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      href={"/home"}
      onClick={(e) => {
        if (noClick) {
          e.preventDefault();
        }
      }}
    >
      <img src={logo} alt="logo" width={width} style={{ marginRight }} />
    </a>
  );
};

export default OssyLogo;
