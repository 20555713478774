import { initialState } from "../../../data/initialState";
import { saveSummarySentencesBackend } from "../../../transcriptSlice";
import {
  apiEndpoint,
  logger,
  LogCategory,
  filterTranscriptArray,
  contentTypes,
} from "../../../utility/utility.mjs";

export const fetchSummarySentences = async ({
  supabase,
  dispatch,
  contentArray,
  setDisplayedSummarySentences,
  setDoneLoadingSummarySentences,
  index = undefined,
  contentType,
}) => {
  logger("feetching summary sentences");
  try {
    let text;
    if (contentType === contentTypes.TRANSCRIPT) {
      // only select blurbs that have fewer than 1000 characters in the contentArray
      const maxNumCharacters = 1000;
      const filteredTranscriptArray = filterTranscriptArray(
        contentArray,
        maxNumCharacters
      );
      text = filteredTranscriptArray.join(" ");
    } else {
      text = contentArray.join(" ");
    }

    const body = JSON.stringify({
      input: { text: text },
    });

    fetch(`${apiEndpoint}/summary_sentences`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(async (response) => {
      let summaryText = "";
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          async function pump() {
            return reader.read().then(async ({ done, value }) => {
              summaryText += new TextDecoder().decode(value);
              setDisplayedSummarySentences(summaryText);

              // When no more data needs to be consumed, close the stream
              if (done) {
                logger(
                  [LogCategory.DEBUG],
                  "New summary sentences: ",
                  summaryText
                );
                setDoneLoadingSummarySentences(true);
                await dispatch(
                  saveSummarySentencesBackend({
                    supabase,
                    newSummarySentences: summaryText,
                    index,
                    contentType,
                  })
                ).unwrap();
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    });
  } catch (error) {
    logger([LogCategory.ERROR], `cancelled fetch: ${error}`);
  }
};
