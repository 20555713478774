import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, DarkOverlay } from "../../../components";
import { cardIcons } from "../../../data/cardIcons";
import {
  apiEndpoint,
  borderRadius,
  colors,
  logger,
} from "../../../utility/utility";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import CollectCardInfoForm from "./CollectCardInfoForm";
import { BsCreditCard } from "react-icons/bs";
import { buttonStyles } from "../../../components/Button";

const SavedPaymentCards = ({
  paymentMethods,
  setPaymentMethods,
  setShowSavedCards,
  showSavedCards,
  subscriptionCancelled,
  defaultPaymentMethod,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const ossyPrice = useSelector((state) => state.routes.ossyPrice);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(-1);
  const userId = useSelector((state) => state.routes.userId);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const stripe = useStripe();
  const autoscroll = useRef(true);
  const elements = useElements();

  const [errorMsg, setErrorMsg] = useState("");
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false);

  let cardSelected = ![-1, -2].includes(selectedPaymentMethodId);
  const [removingSavedCard, setRemovingSavedCard] = useState(false);
  const [addingNewCard, setAddingNewCard] = useState(false);

  useEffect(() => {
    if (selectedPaymentMethodId === -2) {
      const paymentMethodsList = document.getElementById(
        "saved-payment-methods-container"
      );

      paymentMethodsList.scrollTop = paymentMethodsList.scrollHeight;
    }
  }, [selectedPaymentMethodId]);

  return (
    <>
      <DarkOverlay
        onClick={() => {
          setShowSavedCards(false);
          setSelectedPaymentMethodId(-1);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          position: "absolute",
          paddingTop: 16,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          display: "flex",
          flexDirection: "column",
          maxWidth: "80%",
          //   height: "100%",
          maxHeight: "90%",
          // height: 600,
          width: 512,
          overflow: "auto",
        }}
      >
        <div style={{ marginLeft: 16, marginRight: 16 }}>
          <p style={{ fontWeight: 700, fontSize: 28, marginBottom: 0 }}>
            {cardSelected ? "Saved card" : "Saved payment cards"}
          </p>

          {!cardSelected && (
            <>
              <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 16 }}>
                {subscriptionCancelled || !isPaidUser
                  ? `Manage your payment details.`
                  : `${ossyPrice} per month. An update to your current subscription
                will be reflected on your next billing date.`}
              </p>

              <p
                style={{
                  marginBottom: 20,
                  marginTop: 18,
                  fontWeight: 800,
                  fontSize: 18,
                }}
              >
                My cards
              </p>
            </>
          )}
        </div>

        <div
          style={{
            borderRadius: 4,
            overflowY: "auto",
          }}
          id="saved-payment-methods-container"
          onScroll={(e) => (autoscroll.current = false)}
        >
          {[...paymentMethods, { card: { brand: "" }, id: -2, addCard: true }]
            .filter(({ card: { brand }, id, addCard }) => {
              return !cardSelected || selectedPaymentMethodId === id;
            })
            .map(
              (
                {
                  card: { brand, last4, exp_month, exp_year, fingerprint },
                  id: paymentMethodId,
                  addCard,
                },
                index
              ) => {
                const icon = cardIcons[brand];
                // logger(`icon: ${icon}`);
                const formattedBrand =
                  brand.charAt(0).toUpperCase() + brand.slice(1);
                const expYear = String(exp_year).slice(2);
                const isSelected = paymentMethodId === selectedPaymentMethodId;
                return (
                  <div
                    key={paymentMethodId}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // flexDirection: "row",
                      cursor: addCard || cardSelected ? "default" : "pointer",
                      border: `1px solid ${darkMode ? "gray" : "lightgray"}`,
                      marginBottom: 12,
                      borderRadius: 6,
                      marginLeft: 16,
                      marginRight: 16,
                      height: !(addCard && isSelected) ? 70 : undefined,
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => {
                      if (isSelected || addCard) {
                        return;
                      }
                      e.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray2
                        : colors.gray;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                    onClick={(e) => {
                      if (isSelected || addCard) {
                        return;
                      }
                      e.currentTarget.style.backgroundColor = "transparent";
                      setSelectedPaymentMethodId(paymentMethodId);
                    }}
                  >
                    {/* card details container */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 8,
                        padding: 14,
                        width: "calc(100% - 28px)",
                      }}
                    >
                      {/* card icon */}
                      {!addCard && (
                        <>
                          <img
                            src={icon}
                            alt={`${brand} card icon`}
                            style={{ width: 28 }}
                          />

                          {/* card details */}
                          <div>
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 14,
                                color: colors.gray,
                              }}
                            >
                              <p style={{ color: "gray" }}>{formattedBrand}</p>

                              <p
                                style={{
                                  color: darkMode ? "lightgray" : "gray",
                                }}
                              >
                                {last4} | {exp_month}/{expYear}
                              </p>
                            </p>
                          </div>
                        </>
                      )}

                      {addCard && !isSelected && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            height: "100%",
                            alignSelf: "center",
                            justifySelf: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", color: "gray", gap: 8 }}
                          >
                            <BsCreditCard size={24} color={"gray"} />
                            <p style={{ fontWeight: 600 }}>0000 | MM/YY</p>
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              alignSelf: "flex-end",
                              justifySelf: "flex-end",
                            }}
                          >
                            <p
                              style={{
                                color: colors.ossyBlue1,
                                fontSize: 16,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.color = colors.ossyBlue2)
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.color = colors.ossyBlue1)
                              }
                              onClick={(e) => {
                                e.currentTarget.style.color = colors.ossyBlue1;
                                setSelectedPaymentMethodId(paymentMethodId);
                              }}
                            >
                              Add card
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* card collection form */}
                    {addCard && isSelected && (
                      <div
                        style={{
                          fontWeight: 700,
                          cursor: "auto",
                          padding: 16,
                          paddingTop: 0,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 600,

                            lineHeight: 1,
                            marginBottom: 8,
                          }}
                        >
                          Credit or debit card
                        </p>

                        <div>
                          <img
                            src={cardIcons["visa"]}
                            alt={`$visa card icon`}
                            style={{ width: 40, marginRight: 8 }}
                          />
                          <img
                            src={cardIcons["mastercard"]}
                            alt={`$visa card icon`}
                            style={{ width: 40, marginRight: 8 }}
                          />
                          <img
                            src={cardIcons["amex"]}
                            alt={`${brand} card icon`}
                            style={{ width: 40, marginRight: 8 }}
                          />
                          <img
                            src={cardIcons["discover"]}
                            alt={`${brand} card icon`}
                            style={{ width: 40, marginRight: 8 }}
                          />
                        </div>
                        <p
                          style={{
                            marginTop: 22,
                            marginBottom: 22,
                            fontSize: 18,
                          }}
                        >
                          Payment details
                        </p>

                        <CollectCardInfoForm />

                        <Button
                          content={"Save"}
                          loading={addingNewCard}
                          style={{
                            fontSize: 16,
                            padding: 12,
                            marginTop: 24,
                            alignSelf: "center",
                            justifySelf: "center",
                          }}
                          onClick={async (e) => {
                            setAddingNewCard(true);
                            const card = elements.getElement(CardNumberElement);
                            const { error, paymentMethod } =
                              await stripe.createPaymentMethod({
                                type: "card",
                                card: card,
                              });
                            logger("it worked...");
                            logger(error);
                            logger(paymentMethod);
                            if (error) {
                              setAddingNewCard(false);
                              return;
                            }
                            let paymentMethodId = paymentMethod.id;

                            fetch(`${apiEndpoint}/add_payment_method_to_user`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                userId: userId,
                                paymentMethodId: paymentMethodId,
                              }),
                            })
                              .then(async (response) => {
                                const responseJson = await response.json();

                                // user tried to add a duplicate card
                                if (responseJson.error) {
                                  setAddingNewCard(false);
                                  setErrorMsg(responseJson.error);
                                  setDisplayErrorMsg(true);
                                  return;
                                }
                                const newPaymentMethod = responseJson;
                                setSelectedPaymentMethodId(-1);
                                setAddingNewCard(false);

                                // new payment method, add it to the list
                                setPaymentMethods([
                                  newPaymentMethod,
                                  ...paymentMethods,
                                ]);
                              })
                              .catch((reason) => {
                                logger(`it failed for some reason: ${reason}`);
                                setSelectedPaymentMethodId(-1);
                                setAddingNewCard(false);
                              });
                          }}
                        />

                        {displayErrorMsg && (
                          <p
                            style={{
                              color: "tomato",
                              fontWeight: 500,
                              textAlign: "center",
                              marginTop: 16,
                            }}
                          >
                            {errorMsg}
                          </p>
                        )}

                        <p
                          style={{
                            color: "gray",
                            marginTop: 32,
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.color = "lightgray")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = "gray")
                          }
                          onClick={(e) => {
                            e.currentTarget.style.color = "lightgray";
                            setSelectedPaymentMethodId(-1);
                            setDisplayErrorMsg(false);
                          }}
                        >
                          Cancel
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            )}

          {cardSelected && displayErrorMsg && (
            <p
              style={{
                color: "tomato",
                fontWeight: 500,
                textAlign: "center",
                marginTop: 16,
                marginRight: 16,
                marginLeft: 16,
              }}
            >
              {errorMsg}
            </p>
          )}
        </div>

        {cardSelected && (
          <>
            <Button
              content={"Remove saved card"}
              style={{
                fontSize: 14,
                alignSelf: "center",
                marginBottom: 24,
                marginTop: 24,
              }}
              loading={removingSavedCard}
              buttonStyle={buttonStyles.tomatoInverted}
              onClick={() => {
                // have less than 1 card
                if (isPaidUser && paymentMethods.length <= 1) {
                  setErrorMsg("You must have at least 1 card saved");
                  setDisplayErrorMsg(true);
                }
                // removing the default payment card
                else if (
                  defaultPaymentMethod &&
                  defaultPaymentMethod.id === selectedPaymentMethodId
                ) {
                  setErrorMsg(
                    "Cannot remove a card that is your default payment method"
                  );
                  setDisplayErrorMsg(true);
                } else {
                  setRemovingSavedCard(true);
                  fetch(`${apiEndpoint}/remove_card`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      userId: userId,
                      paymentMethodId: selectedPaymentMethodId,
                    }),
                  })
                    .then(async (response) => {
                      const deletedPaymentMethod = await response.json();
                      setSelectedPaymentMethodId(-1);
                      setRemovingSavedCard(false);

                      // new payment method, add it to the list
                      setPaymentMethods(
                        paymentMethods.filter(
                          ({ card: { fingerprint }, id: paymentMethodId }) =>
                            deletedPaymentMethod.id !== paymentMethodId
                        )
                      );
                    })
                    .catch((reason) => {
                      logger("it failed for some reason");
                      setSelectedPaymentMethodId(-1);
                      setRemovingSavedCard(false);
                    });
                }
              }}
            />

            <p
              style={{
                textAlign: "center",
                fontWeight: 600,
                color: "gray",
                marginBottom: 24,
                cursor: "pointer",
                width: "fit-content",
                alignSelf: "center",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.color = darkMode ? "white" : "lightgray")
              }
              onMouseLeave={(e) => (e.currentTarget.style.color = "gray")}
              onClick={(e) => {
                e.currentTarget.style.color = "white";
                setSelectedPaymentMethodId(-1);
                setDisplayErrorMsg(false);
              }}
            >
              Go back
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default SavedPaymentCards;
