import { useSelector, useDispatch } from "react-redux";
import { deleteFolder, updateFolderTitle } from "../transcriptSlice";
import { colors, logger, LogCategory } from "../utility/utility";
import { addToFolder } from "../transcriptSlice";
import { contentTypes } from "../utility/utility.mjs";
import { useRef, useState } from "react";
import { ellipsis } from "../assets/ellipsis2";
import { MdFolder } from "react-icons/md";
import { MdFolderShared } from "react-icons/md";

const Folder = ({
  selected,
  smallWindow,
  setMenuOpen,
  index,
  folderTitleEditIndex,
  setSelected,
  isFolderSelected,
  setPoints,
  setRightClickObject,
  setFolderTitleEditIndex,
  supabase,
  folder,
  isOwner,
  setNotOwnerAction,
  setDisplayNotOwnerTooltip,
  setNotOwnerPoints,
  setDisplayAddToSharedFolder,
  setAddToSharedFolderIndex,
  setAddToSharedContentIndex,
}) => {
  const folders = useSelector((state) => state.routes.folders);

  const { folderUrl, isPrivate } = folder;
  const darkMode = useSelector((state) => state.routes.darkMode);
  const defaultBgColor = darkMode ? "#2f2f2f" : "white";
  const hoverBgColor = darkMode ? "#454545" : "white";
  const hoverShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.2)";
  const defaultShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.13)";
  const { folderName } = folder;
  const [contextMenu, setContextMenu] = useState(
    isFolderSelected && selected === folderName
  );

  // const bgColor = darkMode ? colors.gray1 : "orange";

  const hoveringEllipsis = useRef(false);

  const dispatch = useDispatch();

  const FolderTitle =
    folderName === "" || folderTitleEditIndex === index ? "input" : "p";

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    if (isOwner) {
      event.preventDefault();
      const contentIndex = event.dataTransfer.getData("text/plain");
      if (isPrivate) {
        dispatch(
          addToFolder({
            supabase: supabase,
            contentIndex: contentIndex,
            folderUrl: folderUrl,
          })
        );
      } else {
        setAddToSharedFolderIndex(index);
        setAddToSharedContentIndex(contentIndex);
        setDisplayAddToSharedFolder(true);
      }
    } else {
      setNotOwnerAction("add a transcript to a shared fodler");
      setNotOwnerPoints({ x: event.pageX, y: event.pageY });
      setDisplayNotOwnerTooltip(true);
    }
  };

  const FolderIcon = isPrivate ? MdFolder : MdFolderShared;
  return (
    <div
      style={{
        width: "100%",
        // width: smallWindow ? "100%" : 200,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // marginBottom: 20,
        boxSizing: "border-box",
        padding: 6,
        backgroundColor: contextMenu ? hoverBgColor : defaultBgColor,
        boxShadow: contextMenu ? hoverShadow : defaultShadow,
        // backgroundColor:
        //   selected === folderName && isFolderSelected
        //     ? bgColor
        //     : rightClickFolderIndex === index && rightClickedFolder
        //     ? textHoverColor
        //     : "transparent",
        borderRadius: 6,
        cursor: "pointer",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={(e) => {
        e.currentTarget.style.backgroundColor = hoverBgColor;
      }}
      onDragLeave={(e) => {
        e.currentTarget.style.backgroundColor = defaultBgColor;
        logger([LogCategory.DEBUG], "exited");
      }}
      onClick={() => {
        if (smallWindow) {
          setMenuOpen(false);
        }
        // not currently editing the title
        if (FolderTitle !== "input" && !hoveringEllipsis.current) {
          window.location.pathname = `/f/${folderUrl}`;
        }
        return;
      }}
      onMouseEnter={(event) => {
        if (isFolderSelected && selected === folderName) {
          return;
        }

        event.currentTarget.style.backgroundColor = hoverBgColor;
        event.currentTarget.style.boxShadow = hoverShadow;
      }}
      onMouseLeave={(event) => {
        if (isFolderSelected && selected === folderName) {
          return;
        }

        event.currentTarget.style.backgroundColor = contextMenu
          ? hoverBgColor
          : defaultBgColor;
        event.currentTarget.style.boxShadow = contextMenu
          ? hoverShadow
          : defaultShadow;
        // event.currentTarget.style.backgroundColor =
        //   selected === folderName && isFolderSelected
        //     ? bgColor
        //     : rightClickFolderIndex === index && rightClickedFolder
        //     ? textHoverColor
        //     : "transparent";
      }}
      value={index}
      id={index}
      // right click menu
      onContextMenu={(event) => {
        event.preventDefault();
        setRightClickObject({
          type: contentTypes.FOLDER,
          index: index,
          thing: folder,
          active: true,
        });
        logger(
          [LogCategory.DEBUG],
          `YOU JUST CLICKED ON A FOLDER: ${event.pageX}, ${event.pageY}`
        );
        setPoints({ x: event.pageX, y: event.pageY });
        logger(`index: ${index}`);
      }}
    >
      {/* folder icon + name */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "hidden",
          flexGrow: 1,
        }}
      >
        <FolderIcon
          style={{
            minWidth: 32,
            minHeight: 32,
            maxWidth: 32,
            maxHeight: 32,
            marginRight: 12,
            // minHeight: "100%",
          }}
          color={colors.ossyBlue2}
          // color={darkMode ? "white" : "gray"}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <FolderTitle
            autoFocus={true}
            style={{
              border: "none",
              fontFamily: "poppins",
              margin: 0,
              // backgroundColor: "transparent",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",

              color: darkMode ? "white" : "gray",
              padding: 0,
              fontSize: 16,
            }}
            defaultValue={folderName}
            onKeyDown={(event) => {
              if (["Enter", "Escape"].includes(event.key)) {
                event.currentTarget.blur();
              }
            }}
            // update folder title
            onBlur={(event) => {
              event.preventDefault();
              setFolderTitleEditIndex(-1);

              // if another folder already has this name, block it
              if (
                ![index, -1].includes(
                  folders.indexOf(event.currentTarget.value)
                ) &&
                event.currentTarget.value !== ""
              ) {
                // empty folder title; delete it
                if (["", undefined].includes(folders[index].folderTitle)) {
                  logger([LogCategory.DEBUG], "here?");
                  dispatch(deleteFolder({ indexToRemove: index }));
                }

                alert(
                  `Sorry, but it seems that a folder with the name '${event.currentTarget.value}' already exists. Please choose a different name or use the existing folder. `
                );

                return;
              }

              // update folder name if non-empty
              if (event.currentTarget.value !== "") {
                if (
                  isFolderSelected &&
                  selected === folders[index].folderName
                ) {
                  setSelected(event.currentTarget.value);
                }

                dispatch(
                  updateFolderTitle({
                    supabase: supabase,
                    folderIndex: index,
                    newFolderTitle: event.currentTarget.value,
                  })
                );

                // if empty but previously wasn't, return to previous
              } else if (
                event.currentTarget.value === "" &&
                !["", undefined].includes(folders[index].folderName)
              ) {
                return;

                // if empty and just created, delete it
              } else if (event.currentTarget.value === "") {
                dispatch(deleteFolder({ indexToRemove: index }));
              }
            }}
          >
            {FolderTitle === "p" ? folderName : null}
          </FolderTitle>

          <div
            style={{
              // display:
              //   displayMoreOptions ||
              //   (isFolderSelected && selected === folderName)
              //     ? "flex"
              //     : "none",
              display: "flex",
              alignItems: "center",
              color: darkMode ? "white" : "gray",
              padding: 0,
              margin: 0,
            }}
            onClick={(event) => {
              event.preventDefault();
              setRightClickObject({
                type: contentTypes.FOLDER,
                index,
                thing: folder,
                active: true,
              });
              logger(
                [LogCategory.DEBUG],
                `YOU JUST CLICKED ON A FOLDER: ${event.pageX}, ${event.pageY}`
              );
              setPoints({ x: event.pageX, y: event.pageY + 8 });
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.opacity = 1;
              hoveringEllipsis.current = false;
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.opacity = 0.8;
              hoveringEllipsis.current = true;
            }}
          >
            {ellipsis({
              color: darkMode ? "white" : "gray",
              size: 20,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Folder;
