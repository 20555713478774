import { useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";
import { boxShadow, colors, contentTypes } from "../utility/utility.mjs";
import PrivacyLock from "../features/transcription/components/PrivacyLock";
import Button, { buttonStyles } from "./Button";
import { useState } from "react";
import { BsCheck } from "react-icons/bs";

const SharePanel = ({
  setDisplaySharePanel,
  supabase,
  isOwnerOfContent,
  rightClickObject,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const contentType = rightClickObject.type;
  const index = rightClickObject.index;
  const { pageUrl, isPrivate, isSaved, id, folderUrl } = useSelector(
    (state) => {
      return index >= 0
        ? // toggling privacy from the homepage
          contentType.id === contentTypes.FOLDER.id
          ? state.routes.folders[index]
          : state.routes.contentsArray[index]
        : // within folder, document, or transcript
          state.routes[contentType.name];
    }
  );

  const title =
    contentType.id === contentTypes.FOLDER.id
      ? rightClickObject.thing.folderName
      : rightClickObject.thing.title;

  const { pathLetter } = contentType;
  const url = `https://ossy.ai/${pathLetter}/${
    contentType.id === contentTypes.FOLDER.id ? folderUrl : pageUrl
  }`;
  const [userCopied, setUserCopied] = useState(false);

  return (
    <>
      <DarkOverlay
        zIndex={3}
        onClick={() => {
          setDisplaySharePanel(false);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 400,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1
            style={{
              fontSize: 22,
              marginTop: 0,
              width: "100%",
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            Share "{title}"
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // padding: 16,
            // justifyContent: "center",
          }}
        >
          {isOwnerOfContent && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrivacyLock
                    supabase={supabase}
                    contentType={contentType}
                    moreContainerStyles={{ minWidth: 32, minHeight: 32 }}
                    moreLockStyles={{ width: 16 }}
                    index={index}
                  />
                  <div
                    style={{ marginLeft: 12, width: "100%", textAlign: "left" }}
                  >
                    <p style={{ fontSize: 15 }}>
                      {isPrivate ? `Private` : `Public`}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        color: darkMode ? "white" : colors.black,
                      }}
                    >
                      {isPrivate
                        ? `Only you can view this ${contentType.name}`
                        : `Anyone with the link can view this ${contentType.name}`}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              content={
                <div
                  style={
                    {
                      // color: "gray",
                    }
                  }
                >
                  {userCopied ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      Link Copied
                      <BsCheck size={20} />
                    </div>
                  ) : (
                    "Copy link"
                  )}
                </div>
              }
              onClick={() => {
                if (userCopied) {
                  return;
                }
                navigator.clipboard.writeText(url);
                setUserCopied(true);
                setTimeout(() => setUserCopied(false), 2500);
              }}
              buttonStyle={
                darkMode ? { ...buttonStyles.black } : { ...buttonStyles.white }
              }
            />
            <Button
              content={"Done"}
              onClick={() => setDisplaySharePanel(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SharePanel;
