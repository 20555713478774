import { useSelector } from "react-redux";
import { fontSizes, colors, borderRadius } from "../../../utility/utility.mjs";

const MessageBlurbText = ({
  index,
  thinking,
  text,
  bold,
  cursor = "default",
  underline = false,
  color,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <span
      key={index}
      style={{
        fontSize: thinking ? 40 : fontSizes.regular,
        lineHeight: thinking ? 1 : 2.5,
        borderRadius: borderRadius,
        color: color ? color : darkMode ? "white" : "black",
        cursor: cursor,
        fontWeight: bold ? 700 : "normal",
        opacity: 1,
        textDecoration: underline
          ? `underline solid ${colors.ossyBlue1}`
          : undefined,
        textDecorationThickness: 2,
        overflowWrap: "anywhere",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {text}
    </span>
  );
};

export default MessageBlurbText;
