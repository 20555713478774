import Header from "./Header";
import { SearchBar, ProfileButton, OssyText, OssyLogo, Button } from "./";
import { useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../utility/utility.mjs";

const HomeHeader2 = ({
  smallWindow,
  setMenuOpen,
  supabase,
  search = true,
  showSale = true,
  zIndex = 1,
  showUpgrade = false,
}) => {
  const [menuColor, setMenuColor] = useState("gray");

  return (
    <Header
      zIndex={zIndex}
      showLeft={smallWindow}
      left={
        <div
          style={{
            cursor: "pointer",
            borderRadius: 6,
            width: 22,
            height: 20,
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 0,
          }}
          onClick={() => setMenuOpen(true)}
          onMouseEnter={() => setMenuColor("lightgray")}
          onMouseLeave={() => setMenuColor("gray")}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: menuColor,
              height: 2,
            }}
          />
          <div
            style={{
              width: "100%",
              backgroundColor: menuColor,
              height: 2,
            }}
          />
          <div
            style={{
              width: "100%",
              backgroundColor: menuColor,
              height: 2,
            }}
          />
        </div>
      }
      center={search ? <SearchBar supabase={supabase} /> : undefined}
      right={
        // profile button in top right
        <div
          style={{
            color: "black",
            // alignSelf: "center",
            // alignItems: "center",
            fontSize: 12,
            display: "flex",
            cursor: "pointer",
            justifyContent: "flex-end",
            gap: 8,
          }}
        >
          {showUpgrade && (
            <Button
              content={"Upgrade"}
              onClick={() => (window.location.pathname = "/upgrade")}
            />
          )}
          <ProfileButton includeName={!smallWindow && !showUpgrade} />
        </div>
      }
      line={true}
      //   color={colors.lightGray}
    />
  );
};

export default HomeHeader2;
