import { colors, boxShadow } from "../utility/utility";

const JumpToBottomButton = ({
  jumpToBottomVisibility,
  jumpToBottomButtonVisibilityTimeout,
  clickedJumpToBottomButton,
  setJumpToBottomVisibility,
}) => {
  return (
    <div
      style={{
        // padding: 12,
        width: 40,
        height: 40,
        borderRadius: 1000,
        backgroundColor: colors.ossyBlue1,
        fontSize: 16,
        cursor: "pointer",
        position: "absolute",
        bottom: 12,
        fontWeight: 600,
        boxShadow: boxShadow,
        opacity: jumpToBottomVisibility ? 1 : 0,
        transition: `opacity ${jumpToBottomVisibility ? 0.1 : 1}s`,
        visibility: jumpToBottomVisibility ? "visible" : "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      id="jumpToBottomButton"
      onMouseEnter={(event) => {
        event.currentTarget.style.backgroundColor = colors.ossyDarkBlue3;
        event.currentTarget.style.opacity = 1;
        clearTimeout(jumpToBottomButtonVisibilityTimeout.current);
        setJumpToBottomVisibility(true);
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.backgroundColor = colors.ossyBlue1;
        clearTimeout(jumpToBottomButtonVisibilityTimeout.current);

        setJumpToBottomVisibility(true);
        jumpToBottomButtonVisibilityTimeout.current = setTimeout(() => {
          setJumpToBottomVisibility(false);
        }, 2200);
      }}
      onClick={(event) => {
        const transcriptPanel = document.getElementById(`transcribed_textbox`);
        clickedJumpToBottomButton.current = true;

        // don't display the button for at least 1s after user clicks on it.
        // wait for page to finish scrolling
        setTimeout(() => (clickedJumpToBottomButton.current = false), 1000);
        if (transcriptPanel === null) return;

        transcriptPanel.scrollTo({
          top: transcriptPanel.scrollHeight,
          behavior: "smooth",
        });

        // instantly disappear on click
        clearTimeout(jumpToBottomButtonVisibilityTimeout.current);

        event.currentTarget.style.visibility = "hidden";
      }}
    >
      ⬇
    </div>
  );
};

export default JumpToBottomButton;
