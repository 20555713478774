import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  addScrollBarUI,
  apiEndpoint,
  borderRadius,
  colors,
  getIsMac,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import OssyPlusTable from "../features/payment/components/OssyPlusTable";
import { buttonStyles } from "../components/Button";
import { logCancelReason } from "../transcriptSlice";
import { IoChevronBack } from "react-icons/io5";
import BackButton from "./BackButton";

const CancelFeedbackPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  const [cancelReasonOption, setCancelReasonOption] = useState();
  const [cancelingSubscription, setCancelingSubscription] = useState(false);
  const userId = useSelector((state) => state.routes.userId);
  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  // sets the reason they cancel
  const handleRadioChange = (cancelReason) => {
    setDisplayErrorMessage(false);
    setCancelReasonOption(cancelReason);
  };

  // for the option: Other (please explain below)
  const handleTextInputChange = (event) => {
    const text = event.target.value;
    const response = `Other: ${text}`;
    setCancelReasonOption(response);
  };

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  const handleCancelOssyPlus = async () => {
    if (cancelReasonOption === undefined) {
      setDisplayErrorMessage(true);
    } else {
      await dispatch(
        logCancelReason({ supabase, cancelReason: cancelReasonOption })
      ).unwrap();
      setCancelingSubscription(true);
      fetch(`${apiEndpoint}/cancel_subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
        }),
      }).then(async (response) => {
        const json = await response.json();
        if (!json.error) {
          setCancelingSubscription(false);
          setSubscriptionCancelled(true);
          window.location.pathname = "settings";
        }
      });
    }
  };

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} search={false} />
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 80,
            paddingBottom: 40,
            maxWidth: 700,
            width: "100%",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <BackButton destination={"account/cancel/pause"} />

          <div
            style={{
              textAlign: "center",
              color: darkMode ? "white" : "black",
              fontSize: 16,
              fontWeight: 500,
              marginBottom: 24,
            }}
          >
            <p style={{ fontWeight: 700, fontSize: 32, marginBottom: 24 }}>
              {"We're sad to see you go :("}
            </p>
            <p>
              Before you cancel, please let us know the reason why you are
              leaving. Every bit of feedback helps!
            </p>
          </div>

          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 400,
                  width: "100%",
                }}
              >
                {[
                  { cancelReason: "Technical Issues" },
                  { cancelReason: "Too Expensive" },
                  { cancelReason: "Switching to another product" },
                  { cancelReason: "Missing features I need" },
                ].map(({ cancelReason }) => (
                  <label
                    style={{
                      marginBottom: 16,
                      cursor: "pointer",
                    }}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      type="radio"
                      checked={cancelReasonOption === cancelReason}
                      onChange={() => handleRadioChange(cancelReason)}
                    />
                    {cancelReason}
                  </label>
                ))}

                <label
                  style={{
                    marginBottom: "4px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginBottom: 16 }}>
                    <input
                      style={{ cursor: "pointer" }}
                      type="radio"
                      checked={cancelReasonOption === "Other"}
                      onChange={() => handleRadioChange("Other")}
                    />
                    Other (please explain below)
                  </div>
                  <div>
                    <textarea
                      type="text"
                      rows={5}
                      onBlur={(event) => handleTextInputChange(event)}
                      onClick={() => handleRadioChange("Other")}
                      placeholder="Anthing you want to share? (Optional)"
                      style={{
                        borderRadius: borderRadius,
                        padding: "12px",
                        boxSizing: "border-box",
                        border: 0,
                        width: "100%",
                        resize: "none",
                        backgroundColor: darkMode ? "" : colors.gray,
                        fontFamily: "poppins",
                        fontSize: 16,
                      }}
                    />
                  </div>
                </label>
              </form>
              {displayErrorMessage && (
                <div
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: 12,
                    color: "red",
                    marginTop: 8,
                  }}
                >
                  Please provide feedback to cancel your account.
                </div>
              )}
            </div>
          }

          <Button
            style={{
              marginTop: 40,
              maxWidth: 240,
              width: "100%",
              boxSizing: "border-box",
              justifyContent: "center",
            }}
            buttonStyle={darkMode ? buttonStyles.black : buttonStyles.white}
            content={<div>Cancel Account</div>}
            loading={isPaidUser && cancelingSubscription}
            onClick={handleCancelOssyPlus}
          />
          <Button
            style={{
              marginTop: 16,
              maxWidth: 240,
              width: "100%",
              boxSizing: "border-box",
              justifyContent: "center",
            }}
            content={<div>Never mind, I don't want to cancel</div>}
            onClick={() => {
              window.location.pathname = "settings";
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelFeedbackPage;
