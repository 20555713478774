import { colors } from "../utility/utility.mjs";

const TabUnderline = ({ active, children }) => {
  return (
    <div
      style={{
        borderBottomColor: active ? colors.ossyBlue1 : undefined,
        borderBottomStyle: active ? "solid" : undefined,
        borderBottomWidth: active ? 2 : undefined,
        display: "flex",
        flexGrow: active ? 1 : 0,
        flexShrink: active ? 0 : 1,
        overflow: "hidden",
        maxWidth: "fit-content",
      }}
    >
      {children}
    </div>
  );
};
export default TabUnderline;
