import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { boxShadow, colors, logger } from "../utility/utility";
import logo from "../assets/logo.png";
import actions from "../features/login/components/actions.json";
import { Button, DarkOverlay, HomeHeader, LogInPanel } from "../components";
import {
  claimProfessorOssy,
  fetchUserData,
  giveTopUsersProfessorOssy,
  updateEmailPreferences,
} from "../transcriptSlice";
import { BsCheck } from "react-icons/bs";
import { buttonStyles } from "../components/Button";

const ClaimProfessorOssyPage = ({ supabase }) => {
  useEffect(() => {
    document.title = "Claim Professor Ossy";
  });

  const dispatch = useDispatch();
  useEffect(() => {
    logger("giving top users professor ossy rn...");
    // dispatch(giveTopUsersProfessorOssy({ supabase }));
  }, [dispatch, supabase]);

  const darkMode = useSelector((state) => state.routes.darkMode);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const professorOssyAccess = useSelector(
    (state) => state.routes.professorOssyAccess
  );
  const [showLogInPanel, setShowLogInPanel] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showClaimedPanel, setShowClaimedPanel] = useState(false);
  const [userJoinedNewsletter, setUserJoinedNewsletter] = useState(false);
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 575px)").matches
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserData({ supabase }));
    }
  }, [isLoggedIn, dispatch, supabase]);

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia(`(max-width: 575px)`).matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader search={false} />
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showLogInPanel && (
          <LogInPanel
            setLogInPanel={setShowLogInPanel}
            supabase={supabase}
            userAction={actions.CLAIM_PROFESSOR_ATTEMPT}
          />
        )}

        {showWaitlist && (
          <>
            <DarkOverlay
              blur
              zIndex={1}
              onClick={() => {
                setShowWaitlist(false);
              }}
            />
            <div
              style={{
                backgroundColor: darkMode ? colors.gray1 : "white",
                borderRadius: 6,
                boxShadow: boxShadow,
                padding: 20,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: darkMode ? "white" : "black",
                width: 500,
                maxWidth: "80%",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* {userAction.id === actions.VIEW_TRANSCRIPT_ATTEMPT.id && (
          <div
            style={{
              // position: "fixed",
              top: 20,
              left: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setLogInPanel(false);
              if (setLoading) {
                setLoading(false);
              }
            }}
          >
            <FiXCircle
              style={{ width: 18, height: 18 }}
              color={darkMode ? "white" : "gray"}
            />
          </div>
        )} */}
              <h1
                style={{
                  fontSize: 24,
                  paddingTop: 0,
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                Join The Waitlist
              </h1>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: 80,
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                Unfortunately you don't have access to Professor Ossy. Would you
                like to join the waitlist? You'll be the first to know when it
                drops.
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  content={"Maybe later"}
                  buttonStyle={buttonStyles.gray}
                  onClick={() => setShowWaitlist(false)}
                />
                <div style={{ marginLeft: 12 }} />
                <Button
                  content={
                    userJoinedNewsletter ? (
                      <BsCheck size={20} />
                    ) : (
                      "Join Waitlist"
                    )
                  }
                  onClick={() => {
                    dispatch(
                      updateEmailPreferences({
                        newsletters: true,
                        supabase: supabase,
                      })
                    );
                    setUserJoinedNewsletter(true);
                    setTimeout(() => {
                      setUserJoinedNewsletter(false);
                      setShowWaitlist(false);
                    }, 2500);
                  }}
                />
              </div>
              <p style={{ fontSize: 12, textAlign: "left", marginTop: 12 }}>
                *Joining the waitlist will subscribe you to the Ossy newsletter.
              </p>
            </div>
          </>
        )}

        {showClaimedPanel && (
          <>
            <DarkOverlay
              blur
              zIndex={1}
              onClick={() => {
                setShowClaimedPanel(false);
              }}
            />
            <div
              style={{
                backgroundColor: darkMode ? colors.gray1 : "white",
                borderRadius: 6,
                boxShadow: boxShadow,
                padding: 20,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: darkMode ? "white" : "black",
                width: 500,
                maxWidth: "80%",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: 24,
                  paddingTop: 0,
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                Claimed!
              </h1>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                You now have Professor Ossy. Open or create a transcript to
                start using.
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginLeft: 12 }} />
                <Button
                  content={"Start Now"}
                  onClick={() => {
                    window.location.pathname = "/home";
                  }}
                />
              </div>
            </div>
          </>
        )}
        <p
          style={{
            marginBottom: 18,
            fontSize: 60,
            fontWeight: 700,
            textAlign: "center",
            whiteSpace: "pre-wrap",
          }}
        >
          {smallWindow ? "Professor Ossy \n🔥🔥🔥" : "🔥 Professor Ossy 🔥"}
        </p>
        <p style={{ marginBottom: 12 }}>Click to claim.</p>

        <img
          src={logo}
          alt="logo"
          width={150}
          style={{
            marginBottom: 14,
            cursor: "pointer",
            animation: "zoom-in-and-out infinite 0.5s alternate",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.opacity = 0.6;
            e.currentTarget.style.animation = undefined;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.opacity = 1;
            e.currentTarget.style.animation =
              "zoom-in-and-out infinite 0.5s alternate";
          }}
          onClick={() => {
            if (!isLoggedIn) {
              setShowLogInPanel(true);
            } else if (!professorOssyAccess) {
              setShowWaitlist(true);
            } else {
              dispatch(claimProfessorOssy({ supabase }));
              setShowClaimedPanel(true);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ClaimProfessorOssyPage;
