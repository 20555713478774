import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../utility/utility";
import { Button, Header, HomeHeader, OssyText } from "../components";
import logo from "../assets/logo.png";
import {
  addScrollBarUI,
  getIsMac,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";

const ReleasesPage = () => {
  useEffect(() => {
    document.title = "Releases";
  }, []);

  const darkMode = useSelector((state) => state.routes.darkMode);

  let smallCutoffPixels = 765;
  let bigCutoffPixels = 1250;

  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${smallCutoffPixels}px)`).matches
  );

  const [mediumWindow, setMediumWindow] = useState(
    window.matchMedia(
      `(min-width: ${smallCutoffPixels}px) and (max-width: ${bigCutoffPixels}px)`
    ).matches
  );

  const [bigWindow, setBigWindow] = useState(
    window.matchMedia(`(min-width: ${bigCutoffPixels}px)`).matches
  );

  const [padding, setPadding] = useState(
    smallWindow ? 30 : mediumWindow ? 60 : 100
  );

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia(
        `(max-width: ${smallCutoffPixels}px)`
      ).matches;
      setSmallWindow(newSmallWindow);

      const newMediumWindow = window.matchMedia(
        `(min-width: ${smallCutoffPixels}px) and (max-width: ${bigCutoffPixels}px)`
      ).matches;
      setMediumWindow(newMediumWindow);

      const newBigWindow = window.matchMedia(
        `(min-width: ${bigCutoffPixels}px)`
      ).matches;
      setBigWindow(newBigWindow);

      if (newBigWindow === true) {
        setPadding(100);
      } else if (newMediumWindow === true) {
        setPadding(60);
      } else if (newSmallWindow === true) {
        setPadding(30);
      }
    };
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        backgroundColor: darkMode ? colors.black : "white",
        color: darkMode ? "white" : colors.black,
        alignItems: "center",
      }}
      onScroll={(event) => {
        const isMac = getIsMac();
        if (!isMac) {
          addScrollBarUI(event);
        }
      }}
    >
      <Header left={<OssyText big />} height={80} marginInside={28} />
      <div
        style={{
          margin: padding,
          maxWidth: bigWindow ? 1200 : 680,
          marginTop: smallWindow ? 50 : mediumWindow ? 150 : 100,
        }}
      >
        <p
          style={{
            fontSize: smallWindow ? 24 : mediumWindow ? 40 : 54,
            fontWeight: 600,
            marginBottom: 18,
          }}
        >
          Releases
        </p>
        <p style={{ fontSize: smallWindow ? 16 : 20 }}>
          Here's the latest features we've dropped.
        </p>

        <hr
          style={{
            borderTopColor: colors.gray,
            color: colors.gray,
            borderStyle: "solid",
            marginTop: smallWindow ? 40 : 80,
            marginBottom: smallWindow ? 40 : 80,
          }}
        />
        {[
          // {
          //   date: "May 3, 2024",
          //   featureTitle: "Panopto links",
          //   description:
          //     "In addition to YouTube links, you can now paste a Panopto video link into Ossy and get transcription, explanations, and question-asking.",
          // },
          {
            date: "June 2, 2024",
            featureTitle: "Discord Launch",
            description:
              "Visit the Ossy Discord to join a community of learners, get quick support for issues, and stay  updated with the latest Ossy features.",
          },
          {
            date: "May 7, 2024",
            featureTitle: "Shareable folders",
            description:
              "In addition to sharing individual transcripts, you can now share folders containing all your transcripts with a friend.",
          },
          {
            date: "April 17, 2024",
            featureTitle: "Uploading documents",
            description:
              "Upload documents and use Professor Ossy to answer your questions about the content.",
          },
          {
            date: "April 17, 2024",
            featureTitle: "YouTube links",
            description:
              "Now you can paste a YouTube video link into Ossy and get a transcription, explanations, and question-asking.",
          },
          {
            date: "April 8, 2024",
            featureTitle: "Uploading lecture slides",
            description:
              "In addition to live transcription, AI explanations, and Professor Ossy, you can now upload your lecture slides and view them on Ossy AI.",
          },
          {
            date: "April 8, 2024",
            featureTitle: "Auto-titling transcripts",
            description:
              "If you forget to title your transcript, Ossy will automatically generate a title a few minutes into your lecture.",
          },
          {
            date: "April 8, 2024",
            featureTitle: "More in-depth realtime explanations",
            description:
              "Our updated explanation feature offers a more comprehensive and deeper understanding of lectures than they previously did.",
          },
          {
            date: "April 8, 2024",
            featureTitle: "Professor Ossy: selecting transcript blurbs",
            description:
              "When asking Professor Ossy a question in a lecture, you can click to select the parts of your lecture that you are referencing in your question.",
          },

          {
            date: "April 1, 2024",
            featureTitle: "Professor Ossy: folder-level",
            description:
              "In addition to asking questions to individual transcripts, you can now ask your questions to your folders. Professor Ossy will use all of the lectures in the folder to guide in answering your questions.",
          },

          {
            date: "April 1, 2024",
            featureTitle: "Enhanced transcript accuracy",
            description:
              "We've made various improvements to our system using AI, enhancing the readability and accuracy of transcription.",
          },
          {
            date: "March 18, 2024",
            featureTitle: "Professor Ossy 🔥",
            description:
              "Feeling confused about something in lecture? You can now chat with 'Professor Ossy', a chatbot that answers your questions and shows you exactly where each concept is mentioned in each lecture. Ask it anything, from lecture clarification to homework questions.",
          },
          {
            date: "February 20, 2024",
            featureTitle: "Pin shared transcripts",
            description:
              "You can now pin transcripts that have been shared with you and view them from your home screen under 'Shared with me'. When viewing a shared transcript, click the share icon at the top left of the page and 'Add to your Library' to save.",
          },
          {
            date: "February 12, 2024",
            featureTitle: "Increased transcript accuracy",
            description:
              "Ossy now offers a wider selection of subjects to choose from and increased accuracy for very niche terms.",
          },
          {
            date: "February 12, 2024",
            featureTitle: "Syncing transcript & explanations",
            description:
              "When a transcript blurb is clicked, the corresponding explanation blurb is highlighted, making it easier to find.",
          },
          {
            date: "February 5, 2024",
            featureTitle: "Text to LaTeX conversion",
            description:
              "Your transcript is automatically parsed for mathematical expressions that are converted to LaTeX in realtime.",
          },
          {
            date: "February 5, 2024",
            featureTitle: "Bullet point summaries",
            description:
              "In addition to text summaries, you can now generate bullet-point summaries for your transcripts.",
          },
          {
            date: "January 23, 2024",
            featureTitle: "Audio upload transcripts",
            description:
              "In addition to realtime transcripts, you can now create a transcript by uploading an audio recording.",
          },
          {
            date: "December 27, 2023",
            featureTitle: "Shareable transcript urls",
            description:
              "You can now share a transcript with friends by making the transcript public and sharing the url.",
          },
          {
            date: "November 24, 2024",
            featureTitle: "Dark mode",
            description: "For our users that don't like bright screens.",
          },
          {
            date: "November 17, 2024",
            featureTitle: "Timestamp bookmarks",
            description:
              "Bookmark a timestamp by clicking on it to easily find it again at a later point.",
          },
          {
            date: "November 8, 2024",
            featureTitle: "Searchbar",
            description:
              "Easily find any of your transcripts with a search bar. Search by title or by the words in the transcript.",
          },
          {
            date: "November 8, 2024",
            featureTitle: "AI explanations",
            description: "AI explanations of your transcript. In realtime",
          },
          {
            date: "September 21, 2024",
            featureTitle: "Edit mode",
            description:
              "If something is transcribed incorrectly, you can click the pencil icon to modify the transcript.",
          },
        ].map(({ date, featureTitle, description }) => {
          return (
            <div style={{ marginBottom: 80 }}>
              <div style={{ color: "gray", fontSize: 16, marginBottom: 10 }}>
                {date}
              </div>
              <div style={{ fontWeight: 600, fontSize: 20, marginBottom: 10 }}>
                {featureTitle}
              </div>
              <div
                style={{
                  fontSize: smallWindow ? 16 : 20,
                  color: darkMode ? "white" : colors.black,
                  lineHeight: 1.6,
                }}
              >
                {description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReleasesPage;
