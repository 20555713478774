import { useSelector } from "react-redux";
import { colors } from "../utility/utility.mjs";

const ProgressBar = ({ color, percentComplete, transcribingFile }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        height: 4,
        width: "100%",
        backgroundColor: darkMode ? colors.gray : colors.gray1,
        borderRadius: 8,
        overflow: "hidden", // Ensures the animation doesn't overflow outside the bar
      }}
    >
      <div
        style={{
          height: "100%",
          width: `${percentComplete}%`,
          backgroundColor: color,
          borderRadius: "inherit",
          transition: "width 1s ease-in-out",
          backgroundImage: transcribingFile
            ? "linear-gradient(90deg, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.5) 75%)"
            : "none",
          backgroundSize: "200% 100%", // Makes the animation span beyond the container
          animation: transcribingFile ? "move 2s linear infinite" : "none", // Moves only when progress is < 100
        }}
      ></div>

      {/* Keyframes for the continuous moving effect */}
      <style jsx="true">{`
        @keyframes move {
          0% {
            background-position: 200% 0;
          }
          100% {
            background-position: -200% 0;
          }
        }
      `}</style>
    </div>
  );
};

export default ProgressBar;
