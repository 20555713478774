import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  redirect,
} from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import {
  AdminPage,
  ErrorPage,
  HomePage,
  LogInPage,
  NotFoundPage,
  ReleasesPage,
  SettingsPage,
  TranscriptRouter,
  UpgradePage,
  CheckoutPage,
  WelcomePage,
  ClaimProfessorOssyPage,
  WelcomeToOssyPlus,
} from "./pages";
import FolderChat from "./components/FolderChat";
import { getIsMac, logger, LogCategory } from "./utility/utility.mjs";
import DocumentRouter from "./pages/DocumentRouter";
import FolderRouter from "./pages/FolderRouter";
import CancelStatsPage from "./pages/CancelStatsPage";
import CancelFeedbackPage from "./pages/CancelFeedbackPage";
import CancelPauseSubscriptionPage from "./pages/CancelPauseSubscriptionPage";
import ChangePlanPage from "./pages/ChangePlanPage";
import ManagePlanPage from "./pages/ManagePlanPage";
import AffilaiteProgramPage from "./pages/AffiliateProgramPage";
import { PostHogProvider } from "posthog-js/react";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const response = await supabase.auth.signInWithPassword({
  email: process.env.REACT_APP_SUPABASE_EMAIL,
  password: process.env.REACT_APP_SUPABASE_PASSWORD,
});

if (response.error) {
  logger([LogCategory.ERROR], "couldn't sign into supabase");
}

window.addEventListener("storage", (event) => {
  if (
    event.key === "ossy-logout-event" &&
    window.location.pathname !== "/login"
  ) {
    logger("logged out");
    window.location.pathname = "/login";
  } else if (
    event.key === "ossy-login-event" &&
    window.location.pathname !== "/home"
  ) {
    window.location.pathname = "/home";
  }
});

// determine if a device is a MAC
const isMac = getIsMac();
if (!isMac) {
  document.body.classList.add("scrollbar");
}

// if a user navigates to a webpage but they are logged out, automatically send them to the welcome page
const welcomeUserIfLoggedOut = () => {
  if (localStorage.getItem("app_state") === null) {
    throw redirect("/welcome");
  }
  const { isLoggedIn } = JSON.parse(localStorage.getItem("app_state")).routes;
  if (isLoggedIn !== true) {
    throw redirect("/welcome");
  }
};

const redirectToLoginPageIfLoggedOut = () => {
  if (localStorage.getItem("app_state") === null) {
    throw redirect("/login");
  }
  const { isLoggedIn } = JSON.parse(localStorage.getItem("app_state")).routes;
  if (isLoggedIn !== true) {
    throw redirect("/login");
  }
};

const redirectToSettingsIfNotPaidUser = () => {
  if (localStorage.getItem("app_state") === null) {
    throw redirect("/login");
  }

  const { isLoggedIn, isPaidUser } = JSON.parse(
    localStorage.getItem("app_state")
  ).routes;
  // redirect to login page if not logged in
  if (isLoggedIn !== true) {
    throw redirect("/login");
  }
  // redirect to settings page if not a paid user
  if (isPaidUser === false) {
    throw redirect("/settings");
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage supabase={supabase} isOwnerOfContent={true} />,
    errorElement: <ErrorPage />,
    loader: () => {
      welcomeUserIfLoggedOut();
      return {};
    },
  },
  {
    path: "/welcome",
    element: <WelcomePage supabase={supabase} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/affiliate",
    element: <AffilaiteProgramPage supabase={supabase} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LogInPage supabase={supabase} />,
    errorElement: <ErrorPage />,
    loader: () => {
      // automatically log the user in if they're logged in
      if (localStorage.getItem("app_state") === null) {
        return {};
      }
      const { isLoggedIn } = JSON.parse(
        localStorage.getItem("app_state")
      ).routes;
      if (isLoggedIn) {
        throw redirect("/home");
      }
      return {};
    },
  },
  {
    path: "/home",
    element: <HomePage supabase={supabase} isOwnerOfContent={true} />,
    errorElement: <ErrorPage />,
    loader: () => {
      welcomeUserIfLoggedOut();
      return {};
    },
  },
  {
    path: "/t/:transcriptUrl",
    // errorElement: <ErrorPage />,
    element: <TranscriptRouter supabase={supabase} />,
    loader: ({ params: { transcriptUrl } }) => {
      return transcriptUrl;
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/t/new/:transcriptType",
    // errorElement: <ErrorPage />,
    element: <TranscriptRouter supabase={supabase} />,
    loader: ({ params: { transcriptType } }) => {
      return `new/${transcriptType}`;
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/d/:documentUrl",
    // errorElement: <ErrorPage />,
    element: <DocumentRouter supabase={supabase} />,
    loader: ({ params: { documentUrl } }) => {
      return documentUrl;
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/f/:folderUrl",
    // errorElement: <ErrorPage />,
    element: <FolderRouter supabase={supabase} />,
    loader: ({ params: { folderUrl } }) => {
      return folderUrl;
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/f/:folderUrl/chat",
    element: <FolderChat supabase={supabase} />,
    loader: ({ params: { folderUrl } }) => {
      welcomeUserIfLoggedOut();
      return folderUrl;
    },
  },
  {
    path: "/shared-with-me",
    element: <HomePage supabase={supabase} shared isOwnerOfContent={false} />,
    loader: () => {
      welcomeUserIfLoggedOut();
      return {};
    },
  },
  {
    path: "/t/",
    element: <Navigate to="/" replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings",
    element: <SettingsPage supabase={supabase} />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToLoginPageIfLoggedOut();
      return {};
    },
  },
  {
    path: "/admin",
    element: <AdminPage supabase={supabase} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/claimProfessorOssy",
    element: <ClaimProfessorOssyPage supabase={supabase} />,
  },
  { path: "/releases", element: <ReleasesPage />, errorElement: <ErrorPage /> },
  {
    path: "/upgrade",
    element: <UpgradePage />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToLoginPageIfLoggedOut();
      return {};
    },
  },
  {
    path: "/checkout",
    element: <CheckoutPage supabase={supabase} />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToLoginPageIfLoggedOut();
      return {};
    },
  },
  {
    path: "/welcomeToOssyPlus",
    element: <WelcomeToOssyPlus />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToLoginPageIfLoggedOut();
      return {};
    },
  },
  {
    path: "account/manage-your-plan",
    element: <ManagePlanPage />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToSettingsIfNotPaidUser();
      return {};
    },
  },
  {
    path: "account/change-plan",
    element: <ChangePlanPage />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToSettingsIfNotPaidUser();
      return {};
    },
  },
  {
    path: "account/cancel/stats",
    element: <CancelStatsPage />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToSettingsIfNotPaidUser();
      return {};
    },
  },
  {
    path: "account/cancel/pause",
    element: <CancelPauseSubscriptionPage />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToSettingsIfNotPaidUser();
      return {};
    },
  },
  {
    path: "account/cancel/feedback",
    element: <CancelFeedbackPage supabase={supabase} />,
    errorElement: <ErrorPage />,
    loader: () => {
      redirectToSettingsIfNotPaidUser();
      return {};
    },
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{ api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST }}
    >
      <RouterProvider router={router} />
    </PostHogProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
