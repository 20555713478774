export const download = require("./download.png");
export const micActive = require("./mic_active.png");
export const micInactive = require("./mic_inactive.png");
export const pencil = require("./pencil.png");
export const loadingSymbol = require("./loading.gif");
export const downloadWhite = require("./download_white.png");
export const pencilWhite = require("./pencil_white.png");
export const logo = require("./logo.png");
export const micInactiveWhite = require("./mic_inactive_white.png");
export const copy = require("./copy.png");
export const shareIcon = require("./share_icon.png");
export const shareIcon2 = require("./share_icon2.png");
export const trophy = require("./trophy.png");
export const visa = require("./cards/visa.svg");
export const amex = require("./cards/amex.svg");
export const discover = require("./cards/discover.svg");
export const mastercard = require("./cards/mastercard.svg");
export const carrot = require("./cards/carrot.svg");

// export const carrot = (
//   <svg
//     data-encore-id="icon"
//     role="img"
//     aria-hidden="true"
//     class="Svg-sc-ytk21e-0 hPIlex sc-c630f568-2 dIjLwQ"
//     viewBox="0 0 24 24"
//     width="40"
//     height="40"
//     color="white"
//   >
//     <path d="M2.793 15.957a1 1 0 0 0 1.414 0L12 8.164l7.793 7.793a1 1 0 1 0 1.414-1.414L12 5.336l-9.207 9.207a1 1 0 0 0 0 1.414z"></path>
//   </svg>
// );
