import { colors, borderRadius } from "../utility/utility";
import { useDispatch, useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";
import { FiXCircle } from "react-icons/fi";
import { boxShadow, transcriptModeTypes } from "../utility/utility.mjs";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { BiMicrophone } from "react-icons/bi";
import Button, { buttonStyles } from "./Button";

const SelectTranscriptModePanel = ({ setDisplaySelectTranscriptModePanel }) => {
  return (
    <>
      <DarkOverlay
        zIndex={1}
        onClick={() => {
          setDisplaySelectTranscriptModePanel(false);
        }}
      />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              top: 20,
              left: 20,
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => setDisplaySelectTranscriptModePanel(false)}
          >
            {/* <FiXCircle color="black" style={{ width: 24 }} /> */}
          </div>
          <h1
            style={{
              fontSize: 20,
              marginTop: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            Select A Transcript Mode
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginBottom: 24,
              gap: 8,
            }}
          >
            {[
              {
                header: "Live Transcript",
                icon: <BiMicrophone size={32} color={colors.ossyBlue1} />,
                url: `t/${transcriptModeTypes.LIVE_TRANSCRIPTION.url}`,
              },
              {
                header: "Upload Audio File",
                icon: (
                  <MdOutlineFileUpload size={32} color={colors.ossyBlue1} />
                ),
                url: `t/${transcriptModeTypes.UPLOAD_AUDIO.url}`,
              },
              {
                header: "Paste YouTube Link",
                icon: <FaYoutube size={32} color={"red"} />,
                url: `t/${transcriptModeTypes.VIDEO_LINK.url}`,
              },
            ].map(({ header, icon, url }) => {
              return (
                <div style={{ flex: "1 1 0px", alignItems: "flex-start" }}>
                  <Button
                    buttonStyle={buttonStyles.white}
                    style={{
                      padding: 16,
                      height: "100%",
                      maxWidth: "100%",
                    }}
                    content={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          color: "gray",
                          width: "100%",
                        }}
                      >
                        {icon}
                        <div
                          style={{
                            marginTop: 8,
                            fontSize: 12,
                          }}
                        >
                          {header}
                        </div>
                      </div>
                    }
                    onClick={() => {
                      window.location.pathname = url;
                    }}
                  ></Button>
                </div>
              );
            })}
          </div>
          <Button
            buttonStyle={buttonStyles.text}
            content={<div style={{ fontSize: 13 }}>Log in</div>}
            onClick={() => {
              window.location.href = "/login";
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default SelectTranscriptModePanel;
