import {
  claimFreeHourStatus,
  contentTypes,
  getDate,
  transcriptsType,
} from "../utility/utility.mjs";

// parent class of document and transcript
const content = {
  id: "",
  contentArray: [],
  folderUrl: "",
  pageUrl: "",
  summarySentences: "",
  summaryBulletPoints: [],
  embeddings: [],
  messages: [],
  isPrivate: true,
  isSaved: false,
};

const folder = {
  folderName: "",
  id: "",
  folderUrl: "",
  messages: [],
  isSaved: false,
  isPrivate: true,
};

// subclass of content
const document = {
  ...content,
  fileUrl: "",
  createdAt: "",
  title: "",
  contentType: contentTypes.DOCUMENT,
};

// subclass of content
const transcript = {
  ...content,
  audioUrl: "",
  slidesUrl: "",
  videoUrl: "",
  bookmarks: [],
  creationDate: "", // move creationDate to createdAt
  title: "Untitled Transcript",
  displayPostTranscriptFeedbackPanel: false,
  debugJson: {
    settings: {
      customVocab: false,
      filterProfanity: false,
      filterDisfluencies: false,
      saveAudio: false,
    },
    deviceType: "",
    numAutoPauses: 0,
    uploadAudio: false,
    uploadVideo: false,
    generatedAITitle: "",
  },
  gptJsonArray: [],
  timestamps: [],
  paidBlurbIndex: -1,
  audioDuration: 0,
  contentType: contentTypes.TRANSCRIPT,
  uploadAudioProgress: 0,
};

export const initialState = {
  // profile values
  pfpUrl: "",
  isLoggedIn: false,
  fullName: "",
  userId: "",
  isNewUser: false,
  email: "",

  // current values
  startIndex: 0,
  prevEndTimestamp: -1,
  unfinishedText: "",
  transcriptIndex: 0,
  pausedTimestamp: 0,

  folders: [],

  audioUploading: false,
  audioUploadProgress: 0,

  // used to indicate whether or not the
  // previous transcription was partial or final
  PARTIAL: 100,
  FINAL: 101,

  // this is used to:
  // 1. create a new blurb whenever we get a result that is 'final'
  // 2. to only generate a live summary upon reaching a threshold
  // of new 'final' transcript text
  prevTranscriptType: 101,

  claimedProfessorOssy: false,
  professorOssyAccess: false,

  darkMode: false,

  emailPreferences: { newsletters: true, sales: true },

  isPaidUser: false,
  isSubscriptionPaused: false,

  usageInfo: {
    isPaidUser: false,
    lifetimeTranscriptCount: 0,
    deletedTranscriptCount: 0,
    firstVisitDate: getDate(),
  },
  justLoggedOut: false,

  FILE_DOES_NOT_EXIST: 102,
  FILE_HAS_BEEN_DELETED: 103,
  FILE_IS_PRIVATE: 104,

  folderUrlToName: {},

  folder: folder,

  contentsArray: [],

  // subclass of content
  document: document,
  transcript: transcript,

  embeddingObj: { embedding: [], startIndex: 0, endIndex: 0 },

  session: {
    sessionId: null,
    startDate: null,
    endDate: null,
    userId: null,
    transcriptId: null,
    newUser: null,
  },

  unmergedIndex: 0,
  unlatexedIndex: 0,
  unautoCorrectedIndex: 0,
  unsummarizedIndex: 0,
  currentlyMerging: false,

  lastFinalBlurbIndex: -1,
  transcribingFile: false,

  analytics: {
    totalNumUsers: 0,
    totalNumActiveUsers: 0,
    usersByDay: { dates: [], numUsers: [] },
    usersCumulative: { dates: [], numUsers: [] },
    transcriptsByDay: { dates: [], numTranscripts: [] },
    currentlyUsing: 0,
    activeNotNew: 0,
    currentlyActiveUsers: [],
    activeNotNewUsers: [],
    avgTranscriptLength: 0,
    dailyActiveUsers: [],
    percentSaveAudio: "0%",
    dailyNewTranscripts: 0,
    percentDarkMode: "0%",
    autoPausesByDay: [],
    percentOnPhone: "0%",
    uploadAudio: "0%",
    percentCreatedFolder: "0%",
    percentPublicTranscripts: "0%",
    weeklyRetention: { weeks: [], retentionRates: [] },
    usersRanked: [],
    weeklyTranscriptionTime: {
      // freeUsers: [{ x: 1, y: 1 }],
      freeUsers: [],
      paidUsers: [],
    },
    professorOssyQuestions: {},
    percentProfessorOssy: "0%",
    professorOssyQuestionsPercent: {
      numQuestions: [],
      percentTranscriptsEqualOrGreater: [],
    },
    transcriptsVisitations: {
      numVisits: [],
      percentTranscriptsForNumVisits: [],
    },
    numUsersToNumTranscripts: {
      numUsersThatMadeTranscripts: [],
      numTranscriptsForNumUsers: [],
    },
    dailyExpenditure: {
      daysExpenditure: [],
      expenditureByDay: [],
    },
    totalTranscriptCount: 0,

    numTranscriptsPerType: {
      transcriptionTypes: [],
      numberOfTranscriptsForType: [],
    },
    avgLifetimeCostFreeUser: 0,
    avgLifetimeCostPaidUser: 0,
    avgLifetimeTranscriptionDuration: 0,
    paidUserToFreeUserRatio: "0%",
    avgNumMonthsPaidUser: 0,
    paidUsers: [],
  },

  user: {
    firstName: null,
    lastName: null,
    numTranscriptsCreated: 0,
    userId: null,
  },

  freeWeeklyTranscriptionLimitSeconds: 1 * 60 * 60,
  weekTranscriptionLimitSeconds: 1 * 60 * 60,

  ossyPrice: "$10.86",
  ossyPriceNum: 10.86,
  weekAudioDuration: 0,
  uploadAudioFileLength: 0,

  claimedStatus: claimFreeHourStatus.unclaimed,
};
