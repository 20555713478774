import { Button, HomeHeader } from "../components";
import { colors } from "../utility/utility";
import { useSelector, useDispatch } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import OssyPlusTable from "../features/payment/components/OssyPlusTable";

const WelcomeToOssyPlus = () => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${770}px)`).matches
  );
  const darkMode = useSelector((state) => state.routes.darkMode);

  window.addEventListener("resize", (event) => {
    setSmallWindow(window.matchMedia(`(max-width: ${770}px)`).matches);
  });

  // const stripe = useStripe();
  // Retrieve the "payment_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  // Retrieve the PaymentIntent
  // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //   const message = document.querySelector("#message");

  //   // Inspect the PaymentIntent `status` to indicate the status of the payment
  //   // to your customer.
  //   //
  //   // Some payment methods will [immediately succeed or fail][0] upon
  //   // confirmation, while others will first enter a `processing` state.
  //   //
  //   // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
  //   switch (paymentIntent.status) {
  //     case "succeeded":
  //       message.innerText = "Success! Payment received.";
  //       break;

  //     case "processing":
  //       message.innerText =
  //         "Payment processing. We'll update you when payment is received.";
  //       break;

  //     case "requires_payment_method":
  //       message.innerText =
  //         "Payment failed. Please try another payment method.";
  //       // Redirect your user back to your payment page to attempt collecting
  //       // payment again
  //       break;

  //     default:
  //       message.innerText = "Something went wrong.";
  //       break;
  //   }
  // });
  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        width: "100%",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
      }}
    >
      <HomeHeader search={false} />
      <p
        style={{
          marginTop: smallWindow ? 60 : 80,
          fontWeight: 600,
          fontSize: smallWindow ? 40 : 60,
          marginBottom: 64,
          textAlign: "center",
          maxWidth: "80%",
          color: darkMode ? "white" : "black",
        }}
      >
        Welcome to Ossy Plus.
      </p>

      <div
        style={{
          color: darkMode ? "lightgray" : "gray",
          textAlign: "center",
          fontWeight: 500,
        }}
      >
        <p style={{ marginBottom: 12 }}>Unlimited lecture transcription.</p>
        <p style={{ marginBottom: 12 }}>Unlimited lecture explanations.</p>
        <p style={{ marginBottom: 12 }}>
          Unlimited questions to Professor Ossy.
        </p>
      </div>

      <OssyPlusTable />
      <Button
        content={"Go Home"}
        style={{ padding: 12, fontSize: 16, marginTop: 32, marginBottom: 64 }}
        onClick={() => (window.location.pathname = "/home")}
      />
    </div>
  );
};

export default WelcomeToOssyPlus;
