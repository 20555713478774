import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  LogCategory,
  addScrollBarUI,
  apiEndpoint,
  colors,
  getIsMac,
  logger,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import OssyPlusTable from "../features/payment/components/OssyPlusTable";
import { buttonStyles } from "../components/Button";
import { checkIfUserChangesPlan, fetchUserData } from "../transcriptSlice";
import BackButton from "./BackButton";

const CancelStatsPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.routes.userId);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const [nextBillDate, setNextBillDate] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  useEffect(() => {
    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));
    dispatch(checkIfUserChangesPlan({ supabase }));

    if (!isPaidUser) {
      return;
    }

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      fetch(`${apiEndpoint}/get_subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          customerId: customerId,
          referralId: window.promotekit_referral,
        }),
      }).then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          return;
        }

        const { subscription } = responseJson;
        updateSubscriptionValues(subscription);
      });
    });
  }, []);

  const updateSubscriptionValues = (subscription) => {
    const _nextBillDate = new Date(subscription.current_period_end * 1000);
    setNextBillDate(_nextBillDate);
  };

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} search={false} />
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 80,
            paddingBottom: 40,
            maxWidth: 700,
            width: "100%",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <BackButton destination={"account/change-plan"} />

          <div
            style={{
              textAlign: "center",
              fontSize: 16,
              fontWeight: 500,
              marginBottom: 24,
              color: darkMode ? "white" : "black",
            }}
          >
            <p style={{ fontWeight: 700, fontSize: 32, marginBottom: 24 }}>
              How you transcribe lectures will change
            </p>
            <p>
              If you cancel, you'll change to our free plan on{" "}
              {nextBillDate && (
                <span
                  style={{
                    fontWeight: 700,
                    color: darkMode ? "white" : "black",
                  }}
                >
                  {nextBillDate.getMonth() + 1}/{nextBillDate.getDate()}/
                  {String(nextBillDate.getFullYear()).slice(2)}
                </span>
              )}
            </p>
            <p>Here is how your transcription will change on that date:</p>
          </div>

          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <OssyPlusTable />
          </div>

          <Button
            style={{ marginTop: 40 }}
            buttonStyle={darkMode ? buttonStyles.black : buttonStyles.white}
            content={<div style={{ width: 144 }}>Continue to cancel</div>}
            onClick={() => {
              window.location.pathname = "account/cancel/pause";
            }}
          />
          <Button
            style={{ marginTop: 16 }}
            content={<div style={{ width: 144 }}>Back to settings</div>}
            onClick={() => {
              window.location.pathname = "settings";
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelStatsPage;
