import React, { useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../utility/utility";

const StarRating = ({
  totalStars = 5,
  setRatings,
  question,
  displayMoreQuestions,
  setDisplayMoreQuestions,
}) => {
  const [selectedStars, setSelectedStars] = useState(0);

  const handleStarClick = (selectedCount) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: selectedCount,
    }));
    setSelectedStars(selectedCount);

    // when displaying the start pause for a second before displaying more questions
    if (!displayMoreQuestions) {
      setTimeout(() => {
        setDisplayMoreQuestions(true);
      }, 500);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {[...Array(totalStars)].map((_, index) => (
        <Star
          key={index}
          isSelected={index < selectedStars}
          onClick={() => handleStarClick(index + 1)}
        />
      ))}
    </div>
  );
};

const Star = ({ isSelected, onClick }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        cursor: "pointer",
        fontSize: 48,
        color: isSelected
          ? colors.ossyBlue1
          : darkMode
          ? colors.gray3
          : colors.gray,
        lineHeight: 1,
        height: "48px",
        verticalAlign: "center",
      }}
      onClick={onClick}
    >
      {"★ "}
    </div>
  );
};

export default StarRating;
