import { useEffect } from "react";
import { useSelector } from "react-redux";
import { colors } from "../utility/utility";
import { Button } from "../components";
import logo from "../assets/logo.png";

const ErrorPage = () => {
  useEffect(() => {
    document.title = "Uh oh";
  }, []);

  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <img src={logo} alt="logo" width={150} style={{ marginBottom: 14 }} />
      <p
        style={{
          marginBottom: 18,
        }}
      >
        Uh oh! Looks like we ran into an error.
      </p>
      <Button
        onClick={() => (window.location.href = "/home")}
        content={"Go To Home"}
      />
    </div>
  );
};

export default ErrorPage;
