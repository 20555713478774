import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  borderRadius,
  colors,
  formatTimestamp,
  fontSizes,
  logger,
  LogCategory,
  apiEndpoint,
  addScrollBarUI,
  getIsMac,
  unformatTimestamp,
} from "../../../utility/utility.mjs";
import {
  updateGptJsonArray,
  updateUnsummarizedIndex,
  addTranscriptEmbedding,
} from "../../../transcriptSlice";
import SummaryBlurb from "./SummaryBlurb";
import { removeBlurbState } from "../../../utility/utility.mjs";

const GPTPanel = ({
  GPT,
  supabase,
  highlightedSummaryBlurbIndex,
  setHighlightedTranscriptBlurbIndex,
  setHighlightedSummaryBlurbIndex,
  highlightTranscriptBlurbTimeout,
  paused,
}) => {
  const { gptJsonArray, timestamps, contentArray, paidBlurbIndex, id } =
    useSelector((state) => state.routes.transcript);

  const fetchingSummary = useRef(false);
  const gptAutoscroll = useRef(true);
  const dispatch = useDispatch();
  const unsummarizedIndex = useSelector(
    (state) => state.routes.unsummarizedIndex
  );
  const unlatexedIndex = useSelector((state) => state.routes.unlatexedIndex);
  const unmergedIndex = useSelector((state) => state.routes.unmergedIndex);

  const darkMode = useSelector((state) => state.routes.darkMode);
  const [awaitingNewExplanation, setAwaitingNewExplanation] = useState(false);
  const [tsRangeFetching, setTsRangeFetching] = useState(0);
  const doneFastInitalSummary = useRef(false);
  const { uploadAudio, uploadVideo } = useSelector(
    (state) => state.routes.transcript.debugJson
  );

  // clicking on transcript blurb should auto-jump to corresponding explanation
  useEffect(() => {
    if (gptJsonArray.length === 0) {
      return;
    }
    // logger([LogCategory.INFO], `setting up clicks between blurbs`);
    const gptTimestamps = gptJsonArray.map(
      ({ timestamp, summary }) => timestamp
    );
    const gptSummaries = gptJsonArray.map(({ timestamp, summary }) => summary);
    let timestampIndex = 0;
    let timestamp = formatTimestamp(timestamps[timestampIndex]);
    const gptMinLength = Math.min(gptTimestamps.length, gptSummaries.length);

    for (let i = 0; i < gptMinLength; i++) {
      const rangeEndTimestamp = gptTimestamps[i].split("-").at(-1);

      const summaryBlurb = document.getElementById(`summaryBlurb ${i}`);
      // clicking on a summary blurb to find transcript blurb
      // assign this summary index to all transcript blurbs that correspond to this summary
      if (summaryBlurb) {
        summaryBlurb.className = `transcriptBlurbText ${timestampIndex}`;
      } else {
        logger([LogCategory.DEBUG], "could not find the summaryBlurb blurb");
      }

      while (
        timestampIndex < timestamps.length &&
        (timestamp <= rangeEndTimestamp ||
          (i === gptMinLength - 1 && timestampIndex < timestamps.length))
      ) {
        const transcriptBlurbText = document.getElementById(
          `transcriptBlurbText ${timestampIndex}`
        );

        // clicking on a transcript blurb to find summary blurb
        // assign summary blurb id to all transcript blurbs
        if (transcriptBlurbText) {
          transcriptBlurbText.className = `summaryBlurb ${i}`;
        } else {
          logger([LogCategory.DEBUG], "could not find the transcript blurb");
        }

        timestampIndex += 1;
        timestamp = formatTimestamp(timestamps[timestampIndex]);
      }
    }

    const lastTranscriptBlurbText = document.getElementById(
      `transcriptBlurbText ${timestampIndex}`
    );
    if (lastTranscriptBlurbText) {
      lastTranscriptBlurbText.id = gptTimestamps.length - 1;
    }
  }, [gptJsonArray, timestamps, GPT]);

  const generateGPTPanelSummary = (startIndex, endIndex) => {
    // startIndex = unsummarizedIndex, endIndex: unlatexedIndex
    fetchingSummary.current = true;

    // include the previous 5 summarized blurbs when summarzing.
    // summarize from [startIndex, endIndex] (inclusive)
    const transcriptToSummarize = [
      ...contentArray.slice(Math.max(0, startIndex - 5), endIndex + 1),
    ];
    const timestampsToSummarize = [
      ...timestamps.slice(Math.max(0, startIndex - 5), endIndex + 1),
    ];

    const requestText = transcriptToSummarize.reduce(
      (prevText, blurbText, i) =>
        prevText +
        `${formatTimestamp(timestampsToSummarize[i])} - "${removeBlurbState(
          blurbText
        )}"\n`,
      ""
    );

    const startTimestamp = timestamps[startIndex];
    const endTimestamp = timestamps[endIndex];
    // get ts of last final transcription result
    const endTimestampFormatted = formatTimestamp(endTimestamp);
    const startTimestampFormatted = formatTimestamp(startTimestamp);
    const lastSummary = gptJsonArray[gptJsonArray.length - 1];
    // met conditions to get new summary
    logger([LogCategory.DEBUG], `fetcing another summary`);

    setAwaitingNewExplanation(true);
    setTsRangeFetching(timestamps[startIndex]);

    logger([LogCategory.DEBUG], "HERE IS THE REQUEST INFORMATION");
    logger([LogCategory.DEBUG], `full text: ${requestText}`);
    logger([LogCategory.DEBUG], `start_ts: ${startTimestampFormatted}`);
    logger([LogCategory.DEBUG], `end_ts: ${endTimestampFormatted}`);
    logger([LogCategory.DEBUG], `prev_gpt_json: ${lastSummary}`);

    const input = {
      full_text: requestText,
      start_ts: startTimestampFormatted,
      end_ts: endTimestampFormatted,
      prev_gpt_json:
        uploadVideo === true || uploadAudio === true ? {} : lastSummary,
      include_examples:
        uploadVideo === true || uploadAudio === true ? false : true,
      transcript_id: id,
    };

    const body = JSON.stringify({
      input: input,
    });
    // fetch the summary blurb
    fetch(`${apiEndpoint}/realtime_summary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        const tempNewGptJsons = await response.json();
        logger(
          [LogCategory.DEBUG],
          `finished receiving json. ${tempNewGptJsons}`
        );

        // set new gpt json to be the last summary in gptJsonArray
        // if tempNewGptJsons is not an array becuase when updating gpt json array
        // the last summary is popped and replaced with a duplicate of the last summary
        // with potentially more information
        const newGptJsons = Array.isArray(tempNewGptJsons)
          ? tempNewGptJsons
          : gptJsonArray[gptJsonArray.length - 1] !== undefined
          ? [gptJsonArray[gptJsonArray.length - 1]]
          : [];
        // set new unsummarizedIndex to be startIndex + 1 if tempNewGptJsons is not an array
        const newIndex = Array.isArray(tempNewGptJsons)
          ? endIndex + 1
          : startIndex + 1;

        await dispatch(
          updateGptJsonArray({
            newGptJsons: newGptJsons,
            supabase: supabase,
          })
        ).unwrap();

        dispatch(
          updateUnsummarizedIndex({
            newIndex: newIndex,
          })
        );

        // don't start fetching another summary until we have finalized this one.
        fetchingSummary.current = false;
        setAwaitingNewExplanation(false);

        // autoscroll to the bottom AFTER receiving the summary if GPT panel is open
        if (gptAutoscroll.current && GPT) {
          const aiExplanationsElement =
            document.getElementById("ai_explanations");
          if (aiExplanationsElement) {
            aiExplanationsElement.scrollTop =
              aiExplanationsElement.scrollHeight;
            logger(
              [LogCategory.DEBUG],
              `just tried to scroll to the bottom of ai explanations`
            );
          }
        }
      })
      .catch((reason) => {
        // if this fails for whatever reason,
        // we want to pretend it succeeded so we don't try to infinitely summarize
        // text that will continue to fail for the same reason
        dispatch(
          updateUnsummarizedIndex({
            newIndex: startIndex + 1,
          })
        );
        fetchingSummary.current = false;
        setAwaitingNewExplanation(false);

        console.error("Failed to generate gpt panel summary", reason);
      });
  };

  // warn user if they try to reload the page while generating AI summaries when uploading audio or video
  useEffect(() => {
    const preventUnload = (e) => {
      if (
        (uploadAudio === true || uploadVideo === true) &&
        lastGPTJSONArrayTimestamp <= lastUnsummarizedTimestamp
      ) {
        e.preventDefault();
        e.returnValue =
          "Are you sure? Reloading this page will stop generating your AI summaries.";
      }
    };

    // last timestamp shown in the gptPanel
    const lastGPTJSONArrayTimestamp =
      gptJsonArray.length > 0
        ? unformatTimestamp(
            gptJsonArray[gptJsonArray.length - 1].timestamp.split("-")[1]
          )
        : 0;
    // last unsummarized timestamp
    const lastUnsummarizedTimestamp = timestamps[unsummarizedIndex];

    if (
      (uploadAudio === true || uploadVideo === true) &&
      (awaitingNewExplanation ||
        lastGPTJSONArrayTimestamp <= lastUnsummarizedTimestamp)
    ) {
      window.addEventListener("beforeunload", preventUnload);
    } else {
      window.removeEventListener("beforeunload", preventUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, [unsummarizedIndex, awaitingNewExplanation]);

  // generate AI summaries for upload audio and video
  useEffect(() => {
    // if the no transcript has been generated when you upload audio or video
    if (contentArray.length === 0) {
      return;
    }

    // last timestamp shown in the gptPanel
    const lastGPTJSONArrayTimestamp =
      gptJsonArray.length > 0
        ? unformatTimestamp(
            gptJsonArray[gptJsonArray.length - 1].timestamp.split("-")[1]
          )
        : 0;
    // last unsummarized timestamp
    const lastUnsummarizedTimestamp = timestamps[unsummarizedIndex];

    // when uploading audio or video and the lastGPTJSONArray has not reached the lastUnsummarizedTimestamp
    if (
      (uploadAudio === true || uploadVideo === true) &&
      lastGPTJSONArrayTimestamp <= lastUnsummarizedTimestamp
      // &&
      // we need to exclude the case where unsummarized index is 0 because it defaults to 0 for transcripts that have just been opened.
      // unsummarizedIndex !== 0
    ) {
      const stepSize = 25;
      const endIndex = Math.min(
        unsummarizedIndex + stepSize - 1,
        contentArray.length - 1
      );
      generateGPTPanelSummary(unsummarizedIndex, endIndex);
    }
  }, [contentArray, unsummarizedIndex]);

  // automatically generating the realtime summaries
  useEffect(
    () => {
      // if we are already fetching a summary then wait nigga.
      if (fetchingSummary.current) {
        logger([LogCategory.DEBUG], "already fetching");
        return;
      }

      // there is no transcript to summarize
      if (contentArray.length === 0) {
        return;
      }

      // user uploaded audio or video rather than live transcript
      if (uploadAudio === true || uploadVideo === true) {
        return;
      }

      const intialUnsummarizedText = contentArray
        .slice(0, unmergedIndex)
        .map((blurb) => removeBlurbState(blurb))
        .join(" ");
      // want at least 20 words for the fast inital summary
      // only generate teh fast initial summary once
      if (
        doneFastInitalSummary.current === false &&
        intialUnsummarizedText.split(" ").length > 20
      ) {
        doneFastInitalSummary.current = true;
        generateGPTPanelSummary(0, unmergedIndex - 1);
        return;
      }

      // we only want to summarize blurbs that are done processing (latex has been parsed)
      // and that haven't been summarized
      const numBlurbsSinceLastSummary = unlatexedIndex - unsummarizedIndex;

      // at least 3 new transcript blurbs
      if (numBlurbsSinceLastSummary < 3) {
        return;
      }

      const unsummarizedText = contentArray
        .slice(unsummarizedIndex, unlatexedIndex)
        .map((blurb) => removeBlurbState(blurb))
        .join(" ");

      // we want at least 75 new words to transcribe
      if (unsummarizedText.split(" ").length < 75) {
        return;
      }

      generateGPTPanelSummary(unsummarizedIndex, unlatexedIndex - 1);
    },
    // only create a summary when receiving
    // new text or when coming out of edit mode.
    [unsummarizedIndex, contentArray, paused]
  );

  return (
    <div
      style={{
        flex: 1,
        color: "gray",
        flexDirection: "column",
        alignItems: "center",
        display: GPT ? "flex" : "none",
        fontSize: fontSizes.big,
        fontWeight: 400,
        lineHeight: 2.4,
        // justifyContent: "center",
        marginTop: 0,
        boxSizing: "border-box",
        resize: "none",
        overflow: "hidden",
        backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
        borderRadius: borderRadius,
        overflowY: "auto",
      }}
    >
      <div
        style={{
          paddingTop: 20,
          width: "100%",
          overflowX: "hidden",
          textAlign: "left",
          fontSize: 20,
          flexGrow: 1,
          lineHeight: 2.5,
          display:
            gptJsonArray.length === 0 && !awaitingNewExplanation
              ? "none"
              : "flex",
          opacity: gptJsonArray.length !== 0 ? 1 : 0.5,
          justifyContent: "flex-start",
          alignItems:
            gptJsonArray.length !== 0 || awaitingNewExplanation
              ? "flex-start"
              : "center",
          flexDirection: "column",

          // this line stops scrolbar from appearing when
          // there isn't enough text for it to be there
          overflowY: "auto",
        }}
        id="ai_explanations"
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }

          const aiPanel = event.target;
          // determine whether or not to continue autoscrolling
          gptAutoscroll.current =
            Math.abs(
              aiPanel.scrollTop + aiPanel.offsetHeight - aiPanel.scrollHeight
            ) <= 20;
        }}
      >
        {/* display the summary blurbs */}
        {gptJsonArray.length !== 0 &&
          gptJsonArray.map(({ summary, timestamp: timestampRange }, index) => {
            let [startTimestamp, endTimestamp] = timestampRange.split("-");
            startTimestamp = unformatTimestamp(startTimestamp);
            const paidTimestamp = Math.floor(timestamps[paidBlurbIndex]);
            logger(`startTimestamp: ${startTimestamp}`);
            logger(`paidTimestamp: ${paidTimestamp}`);
            const paidBlurb = startTimestamp >= paidTimestamp;

            return (
              <SummaryBlurb
                key={index}
                index={index}
                summaryText={summary}
                timestampRange={timestampRange}
                setHighlightedTranscriptBlurbIndex={
                  setHighlightedTranscriptBlurbIndex
                }
                highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                highlightBlurb={
                  highlightedSummaryBlurbIndex === `summaryBlurb ${index}`
                }
                setHighlightedSummaryBlurbIndex={
                  setHighlightedSummaryBlurbIndex
                }
                highlightTranscriptBlurbTimeout={
                  highlightTranscriptBlurbTimeout
                }
                paidBlurb={paidBlurb}
              />
            );
          })}

        {/* let user know if we're generating a new summary */}
        {awaitingNewExplanation && (
          <SummaryBlurb
            index={1001}
            summaryText={"Generating summary..."}
            timestampRange={`${formatTimestamp(tsRangeFetching)}-`}
            opacity={0.5}
            setHighlightedSummaryBlurbIndex={setHighlightedSummaryBlurbIndex}
            highlightTranscriptBlurbTimeout={highlightTranscriptBlurbTimeout}
          />
        )}
      </div>

      {gptJsonArray.length === 0 && !awaitingNewExplanation && (
        <span
          style={{
            color: "gray",
            fontWeight: 600,
            opacity: 0.5,
            fontSize: fontSizes.big,
          }}
        >
          AI Summaries will appear here.
        </span>
      )}
    </div>
  );
};

export default GPTPanel;
