import { FiLock, FiUnlock } from "react-icons/fi";
import { colors } from "../../../utility/utility";
import { Button } from "../../../components";
import { useSelector } from "react-redux";
import { claimFreeHourStatus } from "../../../utility/utility.mjs";

const TranscriptUpgradeButton = ({
  showTranscriptUpgradeButton,
  setShowCheckout,
  setShowInviteFriendsPanel,
}) => {
  const claimedStatus = useSelector((state) => state.routes.claimedStatus);
  const { claimed, unclaimed } = claimFreeHourStatus;

  return (
    <Button
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: colors.ossyBlue1,
        padding: 6,
        borderRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        fontSize: 14,
        fontWeight: 600,
        alignItems: "center",
        visibility: showTranscriptUpgradeButton ? "visible" : "hidden",
        opacity: showTranscriptUpgradeButton ? 1 : 0,
        transition: "opacity 1s",
      }}
      onClick={() => {
        if (claimedStatus === claimed) {
          setShowCheckout(true);
        } else {
          setShowInviteFriendsPanel(true);
        }
      }}
      content={
        <>
          Unlock Full Transcript
          <FiLock style={{ fontWeight: 900, marginLeft: 4 }} />
        </>
      }
    />
  );
};

export default TranscriptUpgradeButton;
