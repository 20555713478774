import { HomeHeader } from "../components";
import { apiEndpoint, borderRadius, colors } from "../utility/utility";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../features/payment/components/CheckoutForm";
import { initialState } from "../data/initialState";
import OssyPlusTable from "../features/payment/components/OssyPlusTable";
import { logger } from "../utility/utility.mjs";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = ({ supabase }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const fullName = useSelector((state) => state.routes.fullName);
  const userId = useSelector((state) => state.routes.userId);
  const email = useSelector((state) => state.routes.email);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const ossyPrice = initialState.ossyPrice;
  const [clientSecret, setClientSecret] = useState("");

  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  window.onresize = () => {
    const newSmallWindow = window.matchMedia("(max-width: 950px)").matches;
    setSmallWindow(newSmallWindow);
  };

  useEffect(() => {
    logger(isPaidUser);
    if (isPaidUser) {
      return;
    }

    logger(`email: ${email}`);
    logger(`fullName: ${fullName}`);
    logger(`userId: ${userId}`);

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    })
      .then(async (response) => {
        const customerId = await response.json();
        fetch(`${apiEndpoint}/get_subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: customerId,
            userId: userId,
            referralId: (window || {}).promotekit_referral,
          }),
        }).then(async (response) => {
          const data = await response.json();
          setClientSecret(data["clientSecret"]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const appearance = {
    theme: darkMode ? "night" : "flat",
    variables: {
      fontWeightLight: 500,
      fontWeightNormal: 500,
      fontWeightMedium: 600,
      borderRadius: "3px",
      fontWeightBold: 800,
      colorDanger: "red",
      colorBackground: darkMode ? colors.gray1 : colors.gray,
    },
    rules: {
      ".Label": { color: "white" },
    },
  };
  const options = {
    clientSecret,
    appearance,
    loader: "always",
  };
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: darkMode ? colors.black : "white",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
      }}
    >
      {/* <Header left={<OssyLogo />} line /> */}
      <HomeHeader search={false} supabase={supabase} />

      {/* page holder */}
      <div
        style={{
          width: smallWindow ? "90%" : "auto",
        }}
      >
        {!isPaidUser && (
          <>
            {!smallWindow && (
              <p
                style={{
                  color: darkMode ? "white" : "black",
                  fontSize: 28,
                  fontWeight: 700,
                  marginBottom: 16,
                  marginTop: 30,
                }}
              >
                Check out to unlock Ossy Plus
              </p>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                justifyContent: "flex-start",

                marginBottom: 32,
                // width: "80%",
              }}
            >
              {/* order */}
              <div
                style={{
                  minWidth: smallWindow ? "auto" : 350,
                  marginRight: smallWindow ? 0 : 40,
                  marginTop: smallWindow ? 30 : 0,
                }}
              >
                <p
                  style={{
                    color: darkMode ? "white" : "black",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  Your order
                </p>
                <p
                  style={{
                    fontWeight: 800,
                    color: colors.ossyBlue1,
                    fontSize: 32,
                  }}
                >
                  Ossy Plus
                </p>

                <div
                  style={{
                    width: "100%",
                    backgroundColor: darkMode ? "white" : "lightgray",
                    height: 1,
                    marginTop: 16,
                    marginBottom: 10,
                  }}
                />

                {/* monthly plan */}
                <div
                  style={{
                    borderRadius: borderRadius,
                    padding: 16,
                    borderStyle: "solid",
                    borderWidth: 4,
                    borderColor: darkMode ? colors.gray : "lightgray",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: darkMode ? "white" : "gray",
                        marginRight: 8,
                        fontWeight: 800,
                      }}
                    >
                      Monthly
                    </p>
                  </div>
                  <p
                    style={{ color: darkMode ? "white" : "gray" }}
                  >{`${ossyPrice} / month`}</p>
                </div>

                <p
                  style={{
                    marginTop: 12,
                    color: darkMode ? "white" : "gray",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  1 month of Ossy Plus
                </p>

                <div
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 8,
                    color: darkMode ? "white" : "gray",
                    fontWeight: 700,
                    fontSize: 14,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p>Due today:</p>
                  <p>{ossyPrice}</p>
                </div>
              </div>

              {/* checkout */}
              <div
                style={{
                  height: "100%",
                  marginTop: smallWindow ? 30 : 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm
                      style={{
                        width: "100%",
                        color: "white",
                        maxWidth: "100%",
                      }}
                      supabase={supabase}
                      redirect={true}
                    />
                  </Elements>
                )}
              </div>
            </div>
          </>
        )}

        {isPaidUser && (
          <div
            style={{
              color: darkMode ? "white" : "black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: smallWindow ? 30 : 40,
                marginTop: 60,
                textAlign: "center",
              }}
            >
              Experience the difference
            </p>

            <p style={{ fontSize: smallWindow ? 16 : 20, textAlign: "center" }}>
              Get unlimited access to Ossy with Ossy Plus. Cancel Anytime.
            </p>

            <OssyPlusTable />

            <p
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginBottom: 8,
                marginTop: 60,
              }}
            >
              Your Current Plan
            </p>
            <div
              style={{
                border: `4px solid ${colors.ossyBlue1}`,
                padding: 16,
                borderRadius: borderRadius,
                width: 400,
                maxWidth: "90%",
                marginBottom: 32,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 24,
                  }}
                >
                  Ossy Plus
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 700 }}>{ossyPrice}</p>
                  <p style={{ fontSize: 11 }}>PER MONTH</p>
                </div>
              </div>

              <div
                style={{
                  marginTop: 16,
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 2,
                }}
              >
                <p>• &nbsp; Unlimited transcription</p>
                <p>• &nbsp; Unlimited realtime explanations</p>
                <p>• &nbsp; Unlimited questions to Professor Ossy</p>
                <p>• &nbsp; Cancel anytime</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;
