import DarkOverlay from "./DarkOverlay";
import { useSelector } from "react-redux";
import { colors, boxShadow } from "../utility/utility";
import Button from "./Button";

const AutoPausePanel = ({ setAutoPausePanel }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <>
      <DarkOverlay blur />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          //   width: 400,
          //   height: 150,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <h1 style={{ fontSize: 18, paddingTop: 0, marginTop: 0 }}>
            We noticed it went quiet for a while so we paused your
            transcription.
          </h1>
        </div>

        <div
          style={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <Button
            onClick={() => setAutoPausePanel(false)}
            style={{
              width: 50,
              minWidth: 50,
              justifyContent: "center",
            }}
            content={<p>Ok</p>}
          />
        </div>
      </div>
    </>
  );
};
export default AutoPausePanel;
