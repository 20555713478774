import { borderRadius, boxShadow, colors } from "../utility/utility";
import { useState } from "react";
import { useSelector } from "react-redux";

let hoverTimer;
const ToolTip = ({ text, top = true, removeOnClick = true }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const [longHover, setLongHover] = useState(false);
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        width: "100%",
        height: "100%",
        // zIndex: 99999,
        left: 0,
        top: 0,
        borderRadius: "inherit",
        fontWeight: 200,
        opacity: longHover ? 1 : 0,
      }}
      onMouseEnter={(event) => {
        hoverTimer = setTimeout(() => {
          setLongHover(true);
        }, 200);
      }}
      onMouseLeave={(event) => {
        clearTimeout(hoverTimer);
        setLongHover(false);
      }}
      onClick={(event) => {
        if (removeOnClick) {
          clearTimeout(hoverTimer);
          setLongHover(false);
        }
      }}
    >
      <div
        style={{
          color: darkMode ? "white" : "gray",
          display: longHover ? "flex" : "none",
          borderRadius: 6,
          backgroundColor: darkMode ? "gray" : "white",
          width: "fit-content",
          padding: 6,
          boxShadow: darkMode ? "" : boxShadow,
          //   position: "fixed",
          position: "absolute",
          // overflow-x:hidden;
          opacity: 1,
          fontSize: 12,
          zIndex: 99999,

          alignItems: "center",
          justifyContent: "center",
          marginBottom: top ? 70 : -70,
          boxSizing: "border-box",
          transform: "scale(1)",
        }}
      >
        <p
          style={{
            whiteSpace: "nowrap",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontSize: 12,
            opacity: 1,
            margin: 0,
            zIndex: 99999,
            padding: 0,
            // position: "fixed",
            cursor: "default",
            lineHeight: 1,
            fontFamily: "poppins",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default ToolTip;
