import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addScrollBarUI,
  apiEndpoint,
  colors,
  getIsMac,
  logger,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import { buttonStyles } from "../components/Button";
import { IoChevronBack } from "react-icons/io5";
import BackButton from "./BackButton";
import { loadStripe } from "@stripe/stripe-js";
import ChangePaymentMethodForm from "../features/payment/components/ChangePaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";
import { checkIfUserChangesPlan, fetchUserData } from "../transcriptSlice";
import PlanTypesPanels, {
  planTypes,
} from "../features/cancelSubscription/api/PlanTypesPanels";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const ManagePlanPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  const darkMode = useSelector((state) => state.routes.darkMode);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const planType = isPaidUser ? planTypes.OssyPlus : planTypes.OssyFree;
  const userId = useSelector((state) => state.routes.userId);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const [clientSecret, setClientSecret] = useState("");
  const [showChangePaymentMethodPanel, setShowChangePaymentMethodPanel] =
    useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: { colorBackground: "#fffff" },
    },
  };
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(undefined);
  const [missingDefaultPaymentMethod, setMissingDefaultPaymentMethod] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 800px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  useEffect(() => {
    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));
    dispatch(checkIfUserChangesPlan({ supabase }));

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      // Create a Checkout Session as soon as the page loads
      fetch(`${apiEndpoint}/create-setup-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: userId }),
      }).then(async (res) => {
        const data = await res.json();
        setClientSecret(data.clientSecret);
      });

      fetch(`${apiEndpoint}/get_payment_methods`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
        }),
      }).then(async (response) => {
        const _paymentMethods = await response.json();
        logger("paymentMethods");
        logger(_paymentMethods);
        setPaymentMethods(_paymentMethods);
      });
    });
  }, []);

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} search={false} />
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 80,
            paddingBottom: 40,
            maxWidth: 700,
            width: "100%",
            overflowY: "auto",
          }}
        >
          <BackButton destination={"settings"} />

          <div
            style={{
              textAlign: "left",
              fontSize: 16,
              fontWeight: 500,
              marginBottom: 24,
              width: "100%",
              color: darkMode ? "white" : "black",
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: 40,
              }}
            >
              Manage your plan
            </p>
          </div>

          <PlanTypesPanels
            supabase={supabase}
            currentPlanType={planType}
            displayCurrentPlanType={true}
            showCurrentSubscriptionBanner={false}
            displayPaymentSection={true}
            setShowChangePaymentMethodPanel={setShowChangePaymentMethodPanel}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            defaultPaymentMethod={defaultPaymentMethod}
          />

          <Button
            buttonStyle={darkMode ? buttonStyles.black : buttonStyles.white}
            content={"Change plan"}
            onClick={() => {
              window.location.pathname = "account/change-plan";
            }}
          />
        </div>
        {showChangePaymentMethodPanel && clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <ChangePaymentMethodForm
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              setDefaultPaymentMethod={setDefaultPaymentMethod}
              setShowChangePaymentMethodPanel={setShowChangePaymentMethodPanel}
              setMissingDefaultPaymentMethod={setMissingDefaultPaymentMethod}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default ManagePlanPage;
