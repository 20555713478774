import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addScrollBarUI,
  apiEndpoint,
  colors,
  getIsMac,
  logger,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import { buttonStyles } from "../components/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ConfirmPausePanel from "../features/cancelSubscription/api/confirmPausePanel";
import BackButton from "./BackButton";
import { checkIfUserChangesPlan, fetchUserData } from "../transcriptSlice";

const CancelPauseSubscriptionPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );
  const darkMode = useSelector((state) => state.routes.darkMode);
  const [pauseUntilDate, setPauseUntilDate] = useState();
  const userId = useSelector((state) => state.routes.userId);
  const tomorrow = dayjs().add(1, "day");
  const [displayConfirmPausePanel, setDisplayConfirmPausePanel] =
    useState(false);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const [nextBillDate, setNextBillDate] = useState();
  const noDateSelectedErrorMessage =
    "Plase select the date you want to reactivate your account in order to pause your subscription.";
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  // get the date of the next bill date
  useEffect(() => {
    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));
    dispatch(checkIfUserChangesPlan({ supabase }));

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      fetch(`${apiEndpoint}/get_subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          customerId: customerId,
          referralId: (window || {}).promotekit_referral,
        }),
      }).then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          return;
        }

        const { subscription } = responseJson;
        logger("got subscription: ", subscription);
        const _nextBillDate = new Date(subscription.current_period_end * 1000);
        logger(_nextBillDate);
        setNextBillDate(_nextBillDate);
      });
    });
  }, []);

  const handlePauseSubscription = () => {
    // display and error message is no date is selected
    if (pauseUntilDate === undefined) {
      setErrorMsg(noDateSelectedErrorMessage);
      setDisplayErrorMessage(true);
    } else {
      setDisplayConfirmPausePanel(true);
    }
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          alignItems: "center",
          backgroundColor: darkMode ? colors.black : "white",
          // justifyContent: "center",
          color: darkMode ? "white" : colors.black,
        }}
      >
        <HomeHeader supabase={supabase} search={false} />
        <div
          style={{
            overflowY: "auto",
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          onScroll={(event) => {
            const isMac = getIsMac();
            if (!isMac) {
              addScrollBarUI(event);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 80,
              paddingBottom: 40,
              maxWidth: 700,
              width: "100%",
              alignItems: "center",
              overflowY: "auto",
            }}
          >
            <BackButton destination={"account/cancel/stats"} />

            <div
              style={{
                textAlign: "center",
                color: darkMode ? "white" : "black",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 24,
              }}
            >
              <p style={{ fontWeight: 700, fontSize: 32, marginBottom: 24 }}>
                Pause your subscription instead?
              </p>
              <p>
                You can pause your subscription until later when you're ready to
                supercharge your notetaking again.
              </p>
            </div>

            <div
              style={{
                textAlign: "center",
                color: darkMode ? "white" : "black",
                fontSize: 16,
                // fontWeight: 500,
              }}
            >
              <p style={{ marginBottom: 8 }}>Reactivation date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label=""
                  value={pauseUntilDate}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: darkMode ? "white" : "black",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: darkMode ? "white" : "black",
                    },
                    "& .MuiInputLabel-root": {
                      color: darkMode ? "white" : "black",
                    },
                    "& .MuiSvgIcon-root": {
                      color: darkMode ? "white" : "black",
                    },
                  }}
                  minDate={
                    nextBillDate ? dayjs(nextBillDate).add(1, "day") : tomorrow
                  }
                  onChange={(newDate) => {
                    setDisplayErrorMessage(false);
                    setPauseUntilDate(newDate);
                  }}
                />
              </LocalizationProvider>
            </div>

            <Button
              style={{ marginTop: 40 }}
              buttonStyle={darkMode ? buttonStyles.black : buttonStyles.white}
              content={<div style={{ width: 220 }}>No, please cancel</div>}
              onClick={() => {
                setDisplayErrorMessage(false);
                window.location.pathname = "account/cancel/feedback";
              }}
            />
            <Button
              style={{ marginTop: 16 }}
              content={<div style={{ width: 220 }}>Yes! Pause for now.</div>}
              onClick={() => handlePauseSubscription()}
            />
            {displayErrorMessage && (
              <div
                style={{
                  whiteSpace: "pre-line",
                  fontSize: 12,
                  color: "red",
                  marginTop: 8,
                  textAlign: "center",
                  maxWidth: 300,
                }}
              >
                {errorMsg}
              </div>
            )}
          </div>
        </div>
      </div>

      {displayConfirmPausePanel && (
        <ConfirmPausePanel
          setDisplayConfirmPausePanel={setDisplayConfirmPausePanel}
          setErrorMsg={setErrorMsg}
          setDisplayErrorMessage={setDisplayErrorMessage}
          pauseUntilDate={pauseUntilDate}
          nextBillDate={nextBillDate}
        />
      )}
    </>
  );
};

export default CancelPauseSubscriptionPage;
