import {
  apiEndpoint,
  borderRadius,
  colors,
  formatDuration,
  months,
} from "../utility/utility";
import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDarkMode,
  fetchUserData,
  updateEmailPreferences,
  getWeekAudioDuration,
  checkIfUserChangesPlan,
  updatePausedSubscriptionStatus,
  updateUserPlan,
  cancelPauseSubscription,
  fetchWeekTranscriptionLimit,
} from "../transcriptSlice";
import { logger, LogCategory } from "../utility/utility";
import LimitBar from "../components/LimitBar";
import { startOfWeek } from "date-fns";
import { BsCreditCard } from "react-icons/bs";
import ChangePaymentMethodForm from "../features/payment/components/ChangePaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SavedPaymentCards from "../features/payment/components/SavedPaymentCards";
import RenewPlanPanel from "../features/payment/components/RenewPlanPanel";
import { buttonStyles } from "../components/Button";
import loadingSymbol from "../assets/loading.gif";
import { SECONDS_IN_AN_HOUR } from "../utility/utility.mjs";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const SettingsPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );

  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  const userId = useSelector((state) => state.routes.userId);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const isSubscriptionPaused = useSelector(
    (state) => state.routes.isSubscriptionPaused
  );
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(undefined);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const ossyPrice = useSelector((state) => state.routes.ossyPrice);

  const weekTranscriptionLimitSeconds = useSelector(
    (state) => state.routes.weekTranscriptionLimitSeconds
  );

  const [cancelingSubscription, setCancelingSubscription] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [showChangePaymentMethodPanel, setShowChangePaymentMethodPanel] =
    useState(false);

  const [missingDefaultPaymentMethod, setMissingDefaultPaymentMethod] =
    useState(false);

  const [nextBillDate, setNextBillDate] = useState(undefined);
  const [resumeDate, setResumeDate] = useState();
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [renewPlanPanel, setRenewPlanPanel] = useState(false);

  const [showSavedCards, setShowSavedCards] = useState();
  const [cancelingAutomaticResume, setCancelingAutomaticResume] =
    useState(false);

  const formatPaymentMethod = (paymentMethod) => {
    if (!paymentMethod || !paymentMethod.card) return;
    const { brand, last4 } = paymentMethod.card;
    const brandFormatted = brand.slice(0, 1).toUpperCase() + brand.slice(1);
    return `${brandFormatted} ending in ${last4}`;
  };

  const emailPreferences = useSelector(
    (state) => state.routes.emailPreferences
  );

  const weekAudioDuration = useSelector(
    (state) => state.routes.weekAudioDuration
  );
  const endOfWeekDate = startOfWeek(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  );

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: { colorBackground: "#fffff" },
    },
  };

  const handleResumeSubscription = () => {
    logger([LogCategory.DEBUG], "resuming subscription");
    // resume the subscription within stripe and update supabase
    const body = JSON.stringify({
      userId: userId,
      chargeNow: !isPaidUser ? true : false,
    });
    fetch(`${apiEndpoint}/resume_subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(async (response) => {
      const data = await response.json();
      if (data.isPaused === false) {
        // update the paused status locally
        dispatch(
          updatePausedSubscriptionStatus({ isSubscriptionPaused: false })
        );
        dispatch(updateUserPlan({ isPaidUser: true }));
      }
    });
  };

  useEffect(() => {
    dispatch(getWeekAudioDuration({ supabase }));

    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));

    dispatch(checkIfUserChangesPlan({ supabase }));

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      // Create a Checkout Session as soon as the page loads
      fetch(`${apiEndpoint}/create-setup-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: userId }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));

      fetch(`${apiEndpoint}/get_payment_methods`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
        }),
      }).then(async (response) => {
        const _paymentMethods = await response.json();
        logger("paymentMethods");
        logger(_paymentMethods);
        setPaymentMethods(_paymentMethods);
      });

      fetch(`${apiEndpoint}/get_subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          customerId: customerId,
          referralId: (window || {}).promotekit_referral,
        }),
      }).then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          return;
        }

        const { subscription } = responseJson;
        logger("got subscription");
        logger(subscription);
        updateSubscriptionValues(subscription);
      });
    });
  }, [isPaidUser, isSubscriptionPaused]);

  const updateSubscriptionValues = (subscription) => {
    if (subscription.default_payment_method === null) {
      setMissingDefaultPaymentMethod(true);
    } else {
      setDefaultPaymentMethod(subscription.default_payment_method);
    }

    setSubscriptionCancelled(subscription.cancel_at_period_end);

    const _nextBillDate = new Date(subscription.current_period_end * 1000);
    logger(_nextBillDate);
    setNextBillDate(_nextBillDate);

    if (subscription.pause_collection !== null) {
      const unixResumeDate = subscription.pause_collection.resumes_at;
      const numMillisecondsInSeconds = 1000;
      const resumeDateInMilliseconds =
        unixResumeDate * numMillisecondsInSeconds;
      setResumeDate(new Date(resumeDateInMilliseconds));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  useEffect(() => {
    dispatch(fetchWeekTranscriptionLimit({ supabase }));
  }, []);

  const formatMessage = (weekAudioDuration) => {
    const duration = formatDuration({
      duration: weekAudioDuration,
      maxDurationHours: weekTranscriptionLimitSeconds / SECONDS_IN_AN_HOUR,
    });
    if (weekAudioDuration > weekTranscriptionLimitSeconds) {
      return [
        "You've used up your ",
        formatDuration({
          duration: weekTranscriptionLimitSeconds,
          maxDurationHours: weekTranscriptionLimitSeconds / SECONDS_IN_AN_HOUR,
        }),
        " of free transcription for this week.",
      ];
    }
    return [
      "You've used up ",
      duration,
      ` of your ${formatDuration({
        duration: weekTranscriptionLimitSeconds,
        maxDurationHours: weekTranscriptionLimitSeconds / SECONDS_IN_AN_HOUR,
      })} of free transcription this week.`,
    ];
  };
  const message = formatMessage(weekAudioDuration);

  const handleCancelPausedSubscription = () => {
    setCancelingAutomaticResume(true);

    // resume the subscription within stripe and update supabase
    const body = JSON.stringify({
      userId: userId,
    });

    fetch(`${apiEndpoint}/remove_scheduled_resume_subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(async (response) => {
      // make the user unpaid and unpaused locally
      dispatch(cancelPauseSubscription({}));

      const body = await response.json();
      let subscription = body.subscription;
      if (subscription) {
        setCancelingAutomaticResume(false);
        logger("got subscription");
        logger(subscription);
        updateSubscriptionValues(subscription);
      }
    });
  };

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} search={false} />

      {[
        {
          title: "Your Plan",
          content: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: isPaidUser ? "flex" : undefined,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 8,
                }}
              >
                <div style={{}}>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 28,
                      marginBottom: isPaidUser ? 4 : 26,
                      color: isPaidUser
                        ? colors.ossyBlue1
                        : darkMode
                        ? "white"
                        : "black",
                    }}
                  >
                    {/* Only have Ossy Plus if you are a paid user that has not paused the subscription  */}
                    {isPaidUser ? "Ossy Plus" : "Free Plan"}
                  </p>

                  {/* paid user and not paused */}
                  {isPaidUser && !isSubscriptionPaused && (
                    <div>
                      {nextBillDate && (
                        <span
                          style={{
                            color: darkMode ? "white" : "black",
                            lineHeight: 2,
                          }}
                        >
                          {subscriptionCancelled ? (
                            "Your Ossy Plus membership ends on "
                          ) : (
                            <>
                              Your next bill is for{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  color: darkMode ? "white" : "black",
                                }}
                              >
                                {ossyPrice}
                              </span>{" "}
                              on{" "}
                            </>
                          )}
                          {nextBillDate && (
                            <span
                              style={{
                                fontWeight: 700,
                                color: darkMode ? "white" : "black",
                              }}
                            >
                              {nextBillDate.getMonth() + 1}/
                              {nextBillDate.getDate()}/
                              {String(nextBillDate.getFullYear()).slice(2)}
                            </span>
                          )}
                          .
                        </span>
                      )}
                      {!subscriptionCancelled && (
                        <p
                          style={{
                            color: missingDefaultPaymentMethod
                              ? "red"
                              : darkMode
                              ? "white"
                              : "black",
                            lineHeight: 2,
                          }}
                        >
                          {missingDefaultPaymentMethod
                            ? "You're missing a payment method."
                            : formatPaymentMethod(defaultPaymentMethod)}
                        </p>
                      )}
                    </div>
                  )}

                  {/* paid user and paused */}
                  {isPaidUser && isSubscriptionPaused && (
                    <div>
                      <p style={{ marginBottom: 6 }}>
                        Your Ossy Plus subscription ends on{" "}
                        <span
                          style={{
                            fontWeight: 700,
                            color: darkMode ? "white" : "black",
                          }}
                        >
                          {nextBillDate !== undefined
                            ? new Intl.DateTimeFormat(
                                "en-US",
                                dateOptions
                              ).format(nextBillDate)
                            : null}
                        </span>
                        {" and resumes on "}
                        <span
                          style={{
                            fontWeight: 700,
                            color: darkMode ? "white" : "black",
                          }}
                        >
                          {resumeDate !== undefined
                            ? new Intl.DateTimeFormat(
                                "en-US",
                                dateOptions
                              ).format(resumeDate)
                            : null}
                        </span>
                        .
                      </p>
                    </div>
                  )}

                  {/* free plan*/}
                  {!isPaidUser && (
                    <div>
                      {/* paused and not paused subscription */}
                      <p style={{ marginBottom: 6 }}>
                        {message[0]}
                        <span
                          style={{
                            color: darkMode ? "white" : colors.black,
                            fontWeight: 700,
                          }}
                        >
                          {message[1]}
                        </span>
                        {message[2]}
                      </p>
                      <LimitBar totalDuration={weekAudioDuration} />
                      <p
                        style={{
                          textAlign: "right",
                          marginTop: 6,
                          fontSize: 12,
                        }}
                      >
                        {`Resets midnight on Sunday, ${
                          months[endOfWeekDate.getMonth()]
                        } ${endOfWeekDate.getDate()}`}
                      </p>
                    </div>
                  )}
                </div>

                {isPaidUser && (
                  <div>
                    <OssyLogo width={32} marginRight={0} noClick />
                  </div>
                )}
              </div>

              {(!isPaidUser || nextBillDate) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    marginTop: 24,
                  }}
                >
                  <Button
                    content={(() => {
                      // paused subscirption
                      if (isSubscriptionPaused) {
                        return "Resume Ossy Plus Now";
                      }
                      // paid user
                      if (isPaidUser) {
                        if (subscriptionCancelled) {
                          return "Renew Plan";
                        } else {
                          return "Manage Plan";
                        }
                      } else {
                        // free user
                        return "Upgrade Your Plan";
                      }
                    })()}
                    style={{
                      fontSize: 16,
                      alignSelf: "flex-end",
                      // marginTop: 16,
                    }}
                    buttonStyle={
                      isPaidUser ? buttonStyles.gray : buttonStyles.default
                    }
                    loading={isPaidUser && cancelingSubscription}
                    onClick={() => {
                      // paused subscirption
                      if (isSubscriptionPaused) {
                        return handleResumeSubscription();
                      }

                      // paid user
                      if (isPaidUser) {
                        if (subscriptionCancelled) {
                          return setRenewPlanPanel(true);
                        } else {
                          return (window.location.pathname =
                            "account/manage-your-plan");
                        }
                      } else {
                        // free user
                        return (window.location.href = "/upgrade");
                      }
                    }}
                  />
                  {isSubscriptionPaused &&
                    !isPaidUser &&
                    resumeDate !== null && (
                      <div
                        style={{
                          marginBottom: 6,
                          fontSize: 12,
                          textAlign: "right",
                          marginTop: 8,
                          display: "flex",
                          alignSelf: "flex-end",
                          whiteSpace: "pre",
                          justifyContent: "center",
                          lineHeight: 1,
                          color: darkMode ? "white" : "black",
                        }}
                      >
                        <span style={{ color: "inherit" }}>
                          *Your Ossy Plus subscription automatically resumes on{" "}
                          {resumeDate &&
                            new Intl.DateTimeFormat(
                              "en-US",
                              dateOptions
                            ).format(resumeDate)}
                          {". "}
                        </span>
                        <span
                          style={{
                            color: darkMode ? "white" : colors.black,
                            fontWeight: 700,
                            cursor: "pointer",
                            overflow: "clip",
                          }}
                          onClick={() => {
                            handleCancelPausedSubscription();
                          }}
                        >
                          Cancel
                        </span>
                        <img
                          style={{
                            height: 10,
                            marginLeft: 4,
                            display: cancelingAutomaticResume ? "flex" : "none",
                          }}
                          src={loadingSymbol}
                          alt="loading"
                        />
                      </div>
                    )}
                </div>
              )}
            </div>
          ),
          extraContent: (
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <BsCreditCard style={{ marginBottom: 8 }} size={24} />
              <p style={{ fontWeight: 500, fontSize: 13, textAlign: "center" }}>
                Update Card
              </p>
            </div>
          ),
        },
        {
          title: "Payment",
          buttons: true,
          content: (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 8,
                  alignItems: "center",
                  padding: 16,
                  // width: "100%",
                  width: "calc(100% - 32px)",
                  borderRadius: borderRadius,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  setShowSavedCards(true);
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = darkMode
                    ? colors.gray2
                    : "lightgray";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              >
                <BsCreditCard size={24} />
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Saved payment cards
                </p>
              </div>
            </>
          ),
        },
        {
          title: "Dark Mode",
          row: true,
          content: (
            <>
              {/* dark mode off */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* dark mode off image */}
                <div
                  style={{
                    display: "flex",
                    borderRadius: borderRadius,
                    backgroundColor: "white",
                    // alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 100,
                    width: 100,
                    // padding: 8,

                    marginBottom: 8,
                    border: `5px solid ${
                      darkMode ? colors.gray1 : colors.darkBlue
                    }`,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      setDarkMode({ supabase: supabase, darkMode: false })
                    );
                  }}
                >
                  <div
                    style={{
                      backgroundColor: colors.ossyBlue1,
                      height: 28,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                      width: "100%",
                    }}
                  />
                </div>
                <p
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Off
                </p>
              </div>
              {/* dark mode on */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* dark mode image */}
                <div
                  style={{
                    display: "flex",
                    borderRadius: borderRadius,
                    backgroundColor: colors.black,
                    // alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 100,
                    width: 100,
                    // padding: 8,

                    marginBottom: 8,
                    border: `5px solid ${
                      darkMode ? colors.darkBlue : colors.gray
                    }`,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      setDarkMode({ supabase: supabase, darkMode: true })
                    );
                  }}
                ></div>
                <p style={{ fontWeight: 600 }}>On</p>
              </div>
            </>
          ),
        },
        {
          title: "Email Preferences",
          column: true,
          content: (
            <>
              {" "}
              <p style={{ fontWeight: 600, fontSize: 18 }}>
                Control what emails you get from ossy.ai
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  fontWeight: 400,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={emailPreferences.newsletters}
                  style={{ marginRight: 8 }}
                  onChange={(event) => {
                    logger(`value: ${event.currentTarget.checked}`);
                    dispatch(
                      updateEmailPreferences({
                        newsletters: event.currentTarget.checked,
                        supabase: supabase,
                      })
                    );
                  }}
                />
                <label>Ossy.ai updates, newsletters, and recommendations</label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  fontWeight: 400,
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={emailPreferences.sales}
                  style={{ marginRight: 8 }}
                  onChange={(event) => {
                    logger(`value: ${event.currentTarget.checked}`);
                    dispatch(
                      updateEmailPreferences({
                        sales: event.currentTarget.checked,
                        supabase: supabase,
                      })
                    );
                  }}
                />
                <label>Ossy.ai sales, giveaways, and promotions</label>
              </div>
            </>
          ),
        },
      ].map(
        (
          { title, content, row, column, extraContent, buttons, show = true },
          index
        ) => {
          return (
            <div
              style={{
                display: show ? "flex" : "none",
                flexDirection: smallWindow ? "column" : "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "980px",
                maxWidth: "95%",
                marginTop: index === 0 ? 50 : 24,
              }}
              key={title}
            >
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  marginRight: 50,
                  marginBottom: 8,
                  maxWidth: smallWindow ? "100%" : 200,
                  minWidth: 200,
                }}
              >
                {title}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: darkMode ? colors.gray1 : colors.gray,
                    borderRadius: borderRadius,
                    display: "flex",
                    flexDirection: row ? "row" : "column",
                    alignItems: "flex-start",
                    gap: 16,
                    maxWidth: "100%",
                    flexGrow: 1,
                    padding: buttons ? 0 : 16,
                    width: smallWindow ? "calc(100% - 32px)" : "auto",
                    maxHeight: "fit-content",
                  }}
                >
                  {content}
                </div>
                {extraContent && (
                  <div
                    style={{
                      marginLeft: 8,
                      backgroundColor: darkMode ? colors.gray1 : colors.gray,
                      borderRadius: borderRadius,
                      display:
                        isPaidUser && !subscriptionCancelled && nextBillDate
                          ? "flex"
                          : "none",
                      flexDirection: row ? "row" : "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 16,
                      maxWidth: "100%",
                      flexGrow: 1,
                      flex: 1,
                      padding: 16,
                      cursor: "pointer",
                      alignSelf: "stretch",
                      width: smallWindow ? "calc(100% - 32px)" : "auto",
                    }}
                    onClick={(e) => setShowChangePaymentMethodPanel(true)}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray2
                        : "lightgray")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray1
                        : colors.gray)
                    }
                  >
                    {extraContent}
                  </div>
                )}
              </div>
            </div>
          );
        }
      )}

      <div style={{ padding: 50 }} />

      {showSavedCards && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <SavedPaymentCards
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            setShowSavedCards={setShowSavedCards}
            showSavedCards={showSavedCards}
            subscriptionCancelled={subscriptionCancelled}
            defaultPaymentMethod={defaultPaymentMethod}
          />
        </Elements>
      )}

      {renewPlanPanel && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <RenewPlanPanel
            paymentMethods={paymentMethods}
            setRenewPlanPanel={setRenewPlanPanel}
            updateSubscriptionValues={updateSubscriptionValues}
            setMissingDefaultPaymentMethod={setMissingDefaultPaymentMethod}
          />
        </Elements>
      )}

      {showChangePaymentMethodPanel && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <ChangePaymentMethodForm
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            setShowChangePaymentMethodPanel={setShowChangePaymentMethodPanel}
            setMissingDefaultPaymentMethod={setMissingDefaultPaymentMethod}
          />
        </Elements>
      )}
    </div>
  );
};

export default SettingsPage;
