import { useDispatch, useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";
import {
  boxShadow,
  colors,
  contentTypes,
  documentModeTypes,
  transcriptModeTypes,
} from "../utility/utility.mjs";
import { FiXCircle } from "react-icons/fi";
import Button, { buttonStyles } from "./Button";
import { goToContent } from "../transcriptSlice";
import { initialState } from "../data/initialState";

const CreateInSharedFolderPanel = ({
  setDisplayInSharedFolderPanel,
  sharedContentMode,
  fileInputRef,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const dispatch = useDispatch();
  const { folderUrl } = useSelector((state) => state.routes.folder);

  return (
    <>
      <DarkOverlay
        zIndex={3}
        onClick={() => {
          setDisplayInSharedFolderPanel(false);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1
            style={{
              fontSize: 20,
              marginTop: 0,
              width: "100%",
              textAlign: "left",
              fontWeight: 500,
            }}
          >
            Create In Shared Folder?
          </h1>
        </div>
        <div
          style={{
            displaye: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 8,
          }}
        >
          <p style={{ fontSize: 12, textAlign: "left" }}>
            The created item will be shared to everyone who has acess to the
            folder.
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              content={"Cancel"}
              buttonStyle={{
                ...buttonStyles.white,
                style: { ...buttonStyles.white.style, color: "gray" },
              }}
              style={{ marginRight: 16 }}
              onClick={() => setDisplayInSharedFolderPanel(false)}
            />
            <Button
              content={"Create and share"}
              onClick={() => {
                switch (true) {
                  // clicked on live transcript, upload audio or vidoe link for new transcript
                  case sharedContentMode ===
                    transcriptModeTypes.LIVE_TRANSCRIPTION.name ||
                    sharedContentMode ===
                      transcriptModeTypes.UPLOAD_AUDIO.name ||
                    sharedContentMode === transcriptModeTypes.VIDEO_LINK.name:
                    const transcriptPathName =
                      folderUrl === initialState.folder.folderUrl
                        ? `new/${sharedContentMode}`
                        : `new/${sharedContentMode}?f=${folderUrl}`;
                    dispatch(
                      goToContent({
                        contentPathName: transcriptPathName,
                        newTab: true,
                        contentType: contentTypes.TRANSCRIPT,
                      })
                    );
                    break;
                  // clicked on upload document
                  case sharedContentMode === documentModeTypes.UPLOAD.name:
                    fileInputRef.current.click();
                    break;
                  default:
                    break;
                }
                setDisplayInSharedFolderPanel(false);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInSharedFolderPanel;
