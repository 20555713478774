import { useEffect, useState } from "react";
import { colors, ossyDiscordLink } from "../utility/utility.mjs";
import { useSelector } from "react-redux";
import Button from "./Button";

const WelcomePageFooter = () => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${770}px)`).matches
  );

  const [footerCutoff, setFooterCutoff] = useState(
    window.matchMedia(`(max-width: ${920}px)`).matches
  );

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia(`(max-width: ${770}px)`).matches);
      setFooterCutoff(window.matchMedia(`(max-width: ${920}px)`).matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);

  return (
    <div
      style={{
        fontSize: 15,
        //   color: "white",
        borderTop: `1px solid ${colors.gray}`,
        width: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 60,
        paddingBottom: 60,
        marginTop: 200,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          maxWidth: "fit-content",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: smallWindow ? "center" : "flex-start",
          gap: 80,
        }}
      >
        <div
          style={{
            width: "fit-content",
            display: "flex",
            flexDirection: smallWindow ? "column" : "row",
            gap: smallWindow ? 50 : 0,
          }}
        >
          <div
            style={{
              maxWidth: 240,
              display: smallWindow ? "none" : "inline",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                marginBottom: 10,
                fontSize: 26,
              }}
            >
              Ossy AI
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                paddingRight: 64,
                fontSize: 14,
                marginBottom: 20,
                color: "gray",
              }}
            >
              <p>Your personal</p>
              <p>notetaking assistant.</p>
            </div>
            <Button
              content={"Start for free"}
              style={{ fontSize: 16 }}
              onClick={() => {
                if (isLoggedIn) {
                  window.location.pathname = "/home";
                } else {
                  window.location.pathname = "/t/new";
                }
              }}
              padding={10}
            />
          </div>
          {[
            // social
            {
              title: "Social",
              links: [
                {
                  text: "Instagram",
                  href: "https://www.instagram.com/ossy.ai/",
                },
                {
                  text: "TikTok",
                  href: "https://www.tiktok.com/@ossy.ai",
                },
                {
                  text: "Discord",
                  href: `${ossyDiscordLink}`,
                },
              ],
            },

            // contacts
            {
              title: "Chat with us",
              links: [
                {
                  text: "developers@ossy.ai",
                  href: "mailto:developers@ossy.ai",
                },
              ],
            },

            // {
            //   title: "Features",
            //   links: [
            //     {
            //       href: "https://ossy.ai/features/ai-notetaking",
            //       text: "AI Notetaking",
            //     },

            //     {
            //       href: "https://ossy.ai/features/ai-youtube-video-summarization",
            //       text: "AI YouTube Video Summarization",
            //     },
            //     {
            //       href: "https://ossy.ai/features/audio-file-summarization",
            //       text: "AI Audio File Summarization",
            //     },

            //     {
            //       href: "https://ossy.ai/features/chat-with-documents",
            //       text: "Chat With Documents",
            //     },
            //   ],
            // },
          ].map(({ title, links }) => (
            <div style={{ textAlign: smallWindow ? "center" : "start" }}>
              <div
                style={{
                  fontWeight: 600,
                  marginBottom: 18,
                  fontSize: 18,
                }}
              >
                {title}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 14,
                  paddingRight: smallWindow ? 0 : 80,
                  fontSize: 14,
                  color: "gray",
                }}
              >
                {links.map(({ text, href }) => (
                  <a
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "gray",
                    }}
                  >
                    {text}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <p style={{ color: "gray" }}>
          © 2024 Ossy AI, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default WelcomePageFooter;
