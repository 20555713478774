import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, DarkOverlay } from "../../../components";
import { cardIcons } from "../../../data/cardIcons";
import {
  apiEndpoint,
  borderRadius,
  colors,
  logger,
} from "../../../utility/utility.mjs";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import CollectCardInfoForm from "./CollectCardInfoForm";

const ChangePaymentMethodForm = ({
  paymentMethods,
  setPaymentMethods,
  setDefaultPaymentMethod,
  setShowChangePaymentMethodPanel,
  setMissingDefaultPaymentMethod,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const ossyPrice = useSelector((state) => state.routes.ossyPrice);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(-1);
  const [changingPaymentMethod, setChangingPaymentMethod] = useState(false);
  const userId = useSelector((state) => state.routes.userId);
  const stripe = useStripe();
  const autoscroll = useRef(true);
  const elements = useElements();

  useEffect(() => {
    if (selectedPaymentMethodId === -2) {
      const paymentMethodsList = document.getElementById(
        "payment-methods-container"
      );

      paymentMethodsList.scrollTop = paymentMethodsList.scrollHeight;
    }
  }, [selectedPaymentMethodId]);

  return (
    <>
      <DarkOverlay
        onClick={() => {
          setShowChangePaymentMethodPanel(false);
          setSelectedPaymentMethodId(-1);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          position: "absolute",
          padding: 16,
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          display: "flex",
          flexDirection: "column",
          maxWidth: "80%",
          maxHeight: "80%",
          // height: 600,
          width: 480,
          overflow: "auto",
        }}
      >
        <p style={{ fontWeight: 700, fontSize: 28, marginBottom: 16 }}>
          Change payment details
        </p>
        <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 16 }}>
          {ossyPrice} per month. An update to your current subscription will be
          reflected on your next billing date.
        </p>

        <div
          style={{
            border: `1px solid ${darkMode ? "gray" : "lightgray"}`,
            borderRadius: 4,
            maxHeight: 400,
            overflowY: "auto",
          }}
          id="payment-methods-container"
          onScroll={(e) => (autoscroll.current = false)}
        >
          {[
            ...paymentMethods,
            { card: { brand: "" }, id: -2, newCard: true },
          ].map(
            (
              {
                card: { brand, last4, exp_month, exp_year, fingerprint },
                id: paymentMethodId,
                newCard,
              },
              index
            ) => {
              const icon = cardIcons[brand];
              // logger(`icon: ${icon}`);
              const formattedBrand =
                brand.charAt(0).toUpperCase() + brand.slice(1);
              const expYear = String(exp_year).slice(2);
              const isSelected = paymentMethodId === selectedPaymentMethodId;
              return (
                <div key={paymentMethodId}>
                  {/* card selector */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      if (isSelected) {
                        return;
                      }
                      e.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray2
                        : colors.gray;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                    onClick={(e) => {
                      if (isSelected) {
                        return;
                      }
                      e.currentTarget.style.backgroundColor = "transparent";
                      setSelectedPaymentMethodId(paymentMethodId);
                    }}
                  >
                    {/* button circle + card details container */}
                    <div
                      style={{
                        padding: 14,
                        display: "flex",
                      }}
                    >
                      {/* button circle */}
                      <div
                        style={{
                          height: 18,
                          width: 18,
                          border: isSelected
                            ? `5px solid ${colors.ossyBlue1}`
                            : "1px solid gray",
                          borderRadius: 16, //
                          marginRight: 8,
                          boxSizing: "border-box",
                        }}
                      />

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            fontWeight: 600,

                            lineHeight: 1,
                            marginBottom: 12,
                          }}
                        >
                          {newCard
                            ? `Credit or debit card`
                            : `Saved ${formattedBrand}`}
                        </p>
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: darkMode ? "lightgray" : "gray",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* last 4 digits */}
                          {/* {visa} */}
                          {!newCard && (
                            <>
                              <img
                                src={icon}
                                alt={`${brand} card icon`}
                                style={{ width: 40, marginRight: 12 }}
                              />
                              <p>
                                {last4} | {exp_month}/{expYear}
                              </p>
                            </>
                          )}

                          {newCard && (
                            <>
                              <img
                                src={cardIcons["visa"]}
                                alt={`$visa card icon`}
                                style={{ width: 40, marginRight: 8 }}
                              />
                              <img
                                src={cardIcons["mastercard"]}
                                alt={`$visa card icon`}
                                style={{ width: 40, marginRight: 8 }}
                              />
                              <img
                                src={cardIcons["amex"]}
                                alt={`${brand} card icon`}
                                style={{ width: 40, marginRight: 8 }}
                              />
                              <img
                                src={cardIcons["discover"]}
                                alt={`${brand} card icon`}
                                style={{ width: 40, marginRight: 8 }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {newCard && isSelected && (
                      <div
                        style={{
                          fontWeight: 700,
                          cursor: "auto",
                          padding: 16,
                          paddingTop: 0,
                        }}
                      >
                        <p
                          style={{
                            marginBottom: 22,
                            fontSize: 18,
                          }}
                        >
                          Payment details
                        </p>
                        <CollectCardInfoForm />
                      </div>
                    )}
                  </div>

                  {/* separator line */}

                  {index !== paymentMethods.length && (
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: darkMode ? "gray" : "lightgray",
                      }}
                    />
                  )}
                </div>
              );
            }
          )}
        </div>
        {selectedPaymentMethodId !== -1 && (
          <Button
            content={"Change payment details"}
            style={{
              fontSize: 16,
              padding: 12,
              marginTop: 12,
              alignSelf: "flex-end",
            }}
            onClick={async (e) => {
              setChangingPaymentMethod(true);
              let paymentMethodId = selectedPaymentMethodId;
              if (selectedPaymentMethodId === -2) {
                const card = elements.getElement(CardNumberElement);
                const { error, paymentMethod } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: card,
                  });
                logger("it worked...");
                logger(error);
                logger(paymentMethod);
                if (error) {
                  setChangingPaymentMethod(false);
                  return;
                }
                paymentMethodId = paymentMethod.id;
              }

              fetch(`${apiEndpoint}/change_payment_details`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: userId,
                  paymentMethodId: paymentMethodId,
                }),
              })
                .then(async (response) => {
                  const _defaultPaymentMethod = await response.json();
                  setDefaultPaymentMethod(_defaultPaymentMethod);
                  setShowChangePaymentMethodPanel(false);
                  setSelectedPaymentMethodId(-1);
                  setChangingPaymentMethod(false);
                  setMissingDefaultPaymentMethod(false);

                  // new payment method, add it to the list
                  if (selectedPaymentMethodId === -2) {
                    setPaymentMethods([
                      _defaultPaymentMethod,
                      ...paymentMethods,
                    ]);
                  }
                })
                .catch((reason) => {
                  logger(`it failed for some reason: ${reason}`);
                  setShowChangePaymentMethodPanel(false);
                  setSelectedPaymentMethodId(-1);
                  setChangingPaymentMethod(false);
                });
            }}
            loading={changingPaymentMethod}
          />
        )}
      </div>
    </>
  );
};

export default ChangePaymentMethodForm;
