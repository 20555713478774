import { useEffect, useState } from "react";
import { borderRadius, colors, emailRegex } from "../utility/utility.mjs";
import { useSelector } from "react-redux";

const FriendInviteInput = ({
  count,
  inviteeEmail,
  setInviteeEmail,
  errorMessage,
  setErrorMessage,
}) => {
  const ordinalForm = {
    0: "0th",
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
  };
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 12,
        justifyContent: "center",
      }}
    >
      <p style={{ fontSize: 16, fontWeight: 800 }}>{count}. </p>
      <div style={{ width: "100%" }}>
        <input
          style={{
            width: "100%",
            borderRadius: borderRadius,
            padding: 12,
            fontSize: 14,
            boxSizing: "border-box",
            fontFamily: "poppins",
            backgroundColor: darkMode ? "white" : colors.lightGray,
            border: `${errorMessage ? "2" : "0"}px solid red`,
          }}
          //   pattern="[a-zA-z0-9._:$!%\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
          placeholder={`enter ${ordinalForm[count]} email address`}
          required
          value={inviteeEmail}
          onChange={(e) => {
            setInviteeEmail(e.currentTarget.value);
            setErrorMessage("");
          }}
        />
        <p
          style={{
            display: errorMessage ? "flex" : "none",
            color: "red",
            fontSize: 16,
          }}
        >
          {errorMessage}
        </p>
      </div>
    </div>
  );
};

export default FriendInviteInput;
