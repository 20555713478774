import { FiXCircle } from "react-icons/fi";
import { colors } from "../../../utility/utility.mjs";
import { useSelector } from "react-redux";

const TranscriptBlurbPreview = ({
  tentative = false,
  index,
  setSelectedTranscriptBlurbIndices,
  selectedTranscriptBlurbIndices,
  timestampText,
  transcriptText,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        // padding: 8,
        backgroundColor: darkMode ? colors.gray1 : colors.gray,
        borderRadius: 8,
        // height: 20,
        // marginLeft: 8,
        // marginRight: 8,

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 4,
        marginTop: 4,
        paddingLeft: 6,
        paddingRight: 4,
        // overflow: "clip",
        opacity: tentative ? 1 : 0.6,
      }}
    >
      {tentative && (
        <FiXCircle
          style={{
            cursor: "pointer",
            marginRight: 4,
            width: 16,
            minWidth: 16,
            maxWidth: 16,
            color: "gray",
          }}
          onClick={(e) => {
            setSelectedTranscriptBlurbIndices(
              selectedTranscriptBlurbIndices.filter(
                (_index) => _index !== index
              )
            );
          }}
        />
      )}
      <p
        style={{
          color: "gray",
          padding: 0,
          fontSize: 16,
          fontWeight: 700,
        }}
      >
        {timestampText}
      </p>
      <div style={{ marginRight: 6, overflow: "clip", overflowY: "clip" }} />
      <p
        style={{
          padding: 0,
          overflow: "hidden",

          textOverflow: "ellipsis",
          fontSize: 16,
          whiteSpace: "nowrap",
          //   maxWidth: "100%",
          color: darkMode ? "white" : "black",
          //   color: "green",
        }}
      >
        {transcriptText}
      </p>
    </div>
  );
};

export default TranscriptBlurbPreview;
