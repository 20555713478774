import "../App.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFolder } from "../transcriptSlice";
import { useLoaderData } from "react-router-dom";
import { initialState } from "../data/initialState";
import TranscriptDoesNotExistPage from "./TranscriptDoesNotExistPage";
import TranscriptHasBeenDeletedPage from "./TranscriptHasBeenDeletedPage";
import TranscriptIsPrivatePage from "./TranscriptIsPrivatePage";
import { logger, LogCategory, contentTypes } from "../utility/utility.mjs";
import HomePage from "./HomePage";

const FolderRouter = ({ supabase }) => {
  const folderUrl = useLoaderData();
  const dispatch = useDispatch();
  const [page, setPage] = useState();

  // get the transcript data
  useEffect(() => {
    logger([LogCategory.DEBUG], "fetching transcript...");

    const fetchTranscript_ = async () => {
      // fetch the transcript data from the database
      const folderData = await dispatch(
        fetchFolder({ supabase: supabase, folderUrl: folderUrl })
      ).unwrap();

      // invalid url, transcript doesn't exist
      if (folderData === initialState.FILE_DOES_NOT_EXIST) {
        setPage(
          <TranscriptDoesNotExistPage contentType={contentTypes.FOLDER} />
        );
        return;

        // the transcript used to exist but doesn't anymore
      } else if (folderData === initialState.FILE_HAS_BEEN_DELETED) {
        setPage(
          <TranscriptHasBeenDeletedPage contentType={contentTypes.FOLDER} />
        );
        return;

        // the transcript is private and the user is not the owner
      } else if (folderData === initialState.FILE_IS_PRIVATE) {
        setPage(<TranscriptIsPrivatePage contentType={contentTypes.FOLDER} />);
        return;

        // user can access the transcript with no issues
      } else {
        setPage(
          <HomePage
            supabase={supabase}
            isOwnerOfContent={folderData.isOwnerOfFolder}
          />
        );
        return;
      }
    };

    fetchTranscript_();
  }, [dispatch, folderUrl, supabase]);

  return page;
};

export default FolderRouter;
