import { Button, HomeHeader, OssyLogo } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addScrollBarUI,
  apiEndpoint,
  borderRadius,
  colors,
  getIsMac,
  logger,
  setScrollbarLightOrDarkMode,
} from "../utility/utility.mjs";
import { buttonStyles } from "../components/Button";
import { initialState } from "../data/initialState";
import OssyPlusTable from "../features/payment/components/OssyPlusTable";
import PlanTypesPanels, {
  planTypes,
} from "../features/cancelSubscription/api/PlanTypesPanels";
import { IoChevronBack } from "react-icons/io5";
import BackButton from "./BackButton";
import { checkIfUserChangesPlan, fetchUserData } from "../transcriptSlice";

const ChangePlanPage = ({ supabase }) => {
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);
  const planType = isPaidUser ? planTypes.OssyPlus : planTypes.OssyFree;
  const userId = useSelector((state) => state.routes.userId);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const [nextBillDate, setNextBillDate] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;

      setSmallWindow(newSmallWindow);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  // get the date of the next bill date
  useEffect(() => {
    // fetch user data. if user changes setting on another device/tab,
    // should reflect here as well (on page reload)
    dispatch(fetchUserData({ supabase }));
    dispatch(checkIfUserChangesPlan({ supabase }));

    fetch(`${apiEndpoint}/get_customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        userId: userId,
      }),
    }).then(async (response) => {
      const customerId = await response.json();

      fetch(`${apiEndpoint}/get_subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          customerId: customerId,
          referralId: (window || {}).promotekit_referral,
        }),
      }).then(async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          return;
        }

        const { subscription } = responseJson;
        logger("got subscription: ", subscription);
        const _nextBillDate = new Date(subscription.current_period_end * 1000);
        logger(_nextBillDate);
        setNextBillDate(_nextBillDate);
      });
    });
  }, []);

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} search={false} />
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 80,
            paddingBottom: 40,
            maxWidth: 700,
            width: "100%",
            color: darkMode ? "white" : "black",
            height: "fit-content",
          }}
        >
          <BackButton destination={"account/manage-your-plan"} />

          <div style={{ width: "100%" }}>
            <PlanTypesPanels
              supabase={supabase}
              currentPlanType={planType}
              displayCurrentPlanType={true}
              showCurrentSubscriptionBanner={true}
              displayPaymentSection={false}
            />
          </div>

          <div style={{ width: "100%", marginTop: 16 }}>
            <p style={{ fontWeight: 700, fontSize: 40, marginBottom: 16 }}>
              Available plans
            </p>
            <PlanTypesPanels
              supabase={supabase}
              currentPlanType={planType}
              displayCurrentPlanType={false}
              showCurrentSubscriptionBanner={false}
              displayPaymentSection={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanPage;
