import "../App.css";
import { useState, useEffect, useRef } from "react";
import {
  FeedbackButton,
  FeedbackForm,
  GPTPanel,
  SummaryPanel,
  ProfileButton,
  OssyText,
  LogInPanel,
  OssyLogo,
  Header,
  Button,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import {
  createSession,
  updateSessionEndDate,
  fetchUserData,
  getWeekAudioDuration,
  editDocumentTitle,
  saveContent,
} from "../transcriptSlice";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { slide as Menu } from "react-burger-menu";
import { onSignInGSI } from "../features/login/api/loginApi";
import {
  colors,
  GOOGLE_CLIENT_ID,
  logger,
  LogCategory,
  setScrollbarLightOrDarkMode,
  getIsMac,
  contentTypes,
  getIsNewTranscriptUrl,
} from "../utility/utility.mjs";
import ShareButton from "../components/ShareButton";
import ProfessorOssyPanel from "../features/professorOssy/components/professorOssyPanel";
import actions from "../features/login/components/actions.json";
import {
  BsChat,
  BsChatFill,
  BsFileEarmarkPdf,
  BsFileEarmarkPdfFill,
} from "react-icons/bs";
import { buttonStyles } from "../components/Button";
import SlidesPanel from "../features/slides/components/SlidesPanel";
import TabUnderline from "../components/TabUnderline";
import DocumentControlPanel from "../components/DocumentControlPanel";
import { initialState } from "../data/initialState";
import NotOwnerTooltip from "../components/NotOwnerTooltip";
import SharePanel from "../components/SharePanel";

const DocumentPage = ({ supabase, ownerOfDocument, dummy = false }) => {
  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
  const [highlightedSummaryBlurbIndex, setHighlightedSummaryBlurbIndex] =
    useState(-1);

  const dispatch = useDispatch();
  const contentType = initialState.document.contentType;

  const shareButtonRef = useRef(null);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const {
    title,
    pageUrl,
    isPrivate,
    contentArray,
    summarySentences,
    summaryBulletPoints,
    id,
  } = useSelector((state) => state.routes.document);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const justLoggedOut = useSelector((state) => state.routes.justLoggedOut);

  useEffect(() => {
    dispatch(getWeekAudioDuration({ supabase }));
  }, [dispatch, supabase]);

  const [guestUserAction, setGuestUserAction] = useState(
    actions.VIEW_DOCUMENT_ATTEMPT.id
  );

  const [downloadOptionsVisible, setDownloadOptionsVisible] = useState(false);
  const [showSummaryPanel, setShowSummaryPanel] = useState(false);

  const [logInPanel, setLogInPanel] = useState(false);
  const [loading, setLoading] = useState(false);

  // feedback form
  const [feedbackForm, setFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const [functionAfterLogin, setFunctionAfterLogIn] = useState(() => () => {});

  const [selectedTranscriptBlurbIndices, setSelectedTranscriptBlurbIndices] =
    useState([]);

  // resizing the window
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [panelCutoff, setPanelCutoff] = useState(
    window.matchMedia("(max-width: 650px)").matches
  );

  // these panels should start off closed if the screen is small
  const [chat, setChat] = useState(!panelCutoff);
  const [slides, setSlides] = useState(true);

  const [menuWindowSize, setMenuWindowSize] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  const [notOwnerPoints, setNotOwnerPoints] = useState({ x: 0, y: 0 });
  const [displayNotOwnerTooltip, setDisplayNotOwnerTooltip] = useState(false);
  const [notOwnerAction, setNotOwnerAction] = useState();

  const [displaySharePanel, setDisplaySharePanel] = useState(false);
  const documentObj = useSelector((state) => state.routes.document);
  const rightClickObject = {
    type: contentTypes.DOCUMENT,
    index: -1,
    thing: {
      ...documentObj,
      type: contentTypes.DOCUMENT,
    },
    active: false,
  };

  useEffect(() => {
    logger([LogCategory.DEBUG], `document privacy: ${isPrivate}`);
    // new transcript, no need to do anything
    if (getIsNewTranscriptUrl(window.location.pathname)) {
      return;
    }

    // user arrived at this page logged out and it's not a new transcript, make them sign in to see
    else if (!isLoggedIn) {
      setGuestUserAction(actions.VIEW_DOCUMENT_ATTEMPT);
      setLogInPanel(true);
    } else if (isLoggedIn && !ownerOfDocument) {
      // immediately save the document if you are not the owner of the document
      dispatch(saveContent({ supabase, contentId: id, contentType }));
    }
  }, [isLoggedIn, justLoggedOut]);

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    if (!isMac) {
      setScrollbarLightOrDarkMode(darkMode);
    }
  }, [darkMode]);

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia("(max-width: 768px)").matches);
      setMenuWindowSize(window.matchMedia("(max-width: 1000px)").matches);

      const newPanelCutoff = window.matchMedia("(max-width: 650px)").matches;
      setPanelCutoff(newPanelCutoff);
    };
    window.addEventListener("resize", handleResize, {
      passive: true,
    });

    return () => {
      window.removeEventListener("resize", handleResize, {
        passive: true,
      });
    };
  }, []);

  useEffect(() => {
    if (panelCutoff) {
      closeAllPanelsExcept(PanelType.Slides);
    } else {
      setSlides(true);
      if (!chat) {
        closeAllPanelsExcept(PanelType.Chatbot);
      }
    }
  }, [panelCutoff]);

  const transcriptTitleRef = useRef(null);
  const [displayedTranscriptTitle, setDisplayedTranscriptTitle] =
    useState(title);

  const [highlightedTranscriptBlurbIndex, setHighlightedTranscriptBlurbIndex] =
    useState(-1);
  const highlightTranscriptBlurbTimeout = useRef(null);
  const highlightSummaryBlurbTimeout = useRef(null);

  const [showUpgradePanel, setShowUpgradePanel] = useState(false);

  // Controls when to stop screen from sleeping/dimming
  const wakeLock = useRef(null);

  const trptRef = useRef(null);

  const headerHeight = 80;

  // the bottom should have more height when it's also displaying the audio seekbar
  const bottomHeight = 100;
  // !started && contentArray.length !== 0 && audioUrl !== "" ? 150 : 100;

  // convert all the latex on initial page load,
  // when exiting edit mode, and when more audio is transcribed
  useEffect(() => {
    try {
      if (window.MathJax) {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    } catch (e) {
      console.error(`couldn't display latex: ${e}`);
      try {
        if (window.MathJax) {
          window.MathJax.typesetClear();
          window.MathJax.typeset();
        }
      } catch (e2) {
        console.error(`failed again at parsing latex: ${e2}`);
      }
    }

    return () => window.MathJax.typesetClear();
  }, [
    showSummaryPanel,

    // parse any latex in the professor ossy chat
    chat,
  ]);

  // we have to change the color of the latex to white to see it in dark mode
  useEffect(() => {
    let styleElement = document.createElement("style");
    styleElement.innerHTML = `mjx-container {color: ${
      darkMode ? "white" : "black"
    };}`;

    document.head.appendChild(styleElement);
  }, [darkMode]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserData({ supabase }));
    }
  }, [dispatch, isLoggedIn, supabase]);

  // Create or update transcript session data
  useEffect(() => {
    if (isLoggedIn && pageUrl !== null) {
      // every minute, log the end of the session
      const logSessionInterval = setInterval(() => {
        dispatch(updateSessionEndDate({ supabase }));
      }, 60000);

      return () => clearInterval(logSessionInterval);
    }
  }, [dispatch, isLoggedIn, supabase, pageUrl]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  // keyboard shortcuts for ossy
  useEffect(() => {
    const handleKeyUp = (event) => {
      // ignore key presses if user is editing a transcription blurb
      if (
        feedbackForm ||
        document.activeElement.classList.contains("transcriptBlurbText") ||
        document.activeElement.id === "title_box"
      ) {
        return;
      }

      if (
        ["input", "select", "button", "textarea"].includes(
          document.activeElement.tagName.toLowerCase()
        ) ||
        document.activeElement.isContentEditable
      ) {
        return;
      }

      // ignore key presses if user is typing feedback
      if (feedbackForm) {
        return;
      }

      // ctrl + s should enter/edit edit mode
      if (["S", "s"].includes(event.key) && event.ctrlKey) {
        setShowSummaryPanel(!showSummaryPanel);
      }

      // ctrl + d should enter/edit edit mode
      if (["D", "d"].includes(event.key) && event.ctrlKey) {
        setDownloadOptionsVisible(!downloadOptionsVisible);
      }
    };

    document.addEventListener("keyup", handleKeyUp, { passive: true });
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("keyup", handleKeyUp, { passive: true });
    };
  }, [showSummaryPanel, downloadOptionsVisible, feedbackForm]);

  const smallWindowMenu = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      right: 16,
      top: "26px",
    },

    // the three horizontal bars
    bmBurgerBars: {
      borderRadius: 10,
      backgroundColor: "gray",
      background: "gray",
    },

    // three horizontal bars on hover
    bmBurgerBarsHover: {
      background: "lightgray",
      display: "none",
      backgroundColor: "lightgray",
      // background: "lightgray",
    },

    bmCrossButton: {
      height: "24px",
      width: "24px",
    },

    // the x to close it
    bmCross: {
      backgroundColor: "white",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },

    // the menu itself
    bmMenu: {
      background: "rgba(0, 0, 0, 0.5)",
      paddingTop: "2em",
      // backgroundColor: "white",
      fontSize: "1.15em",
      flex: 1,
      // left: 0,
      // position: "absolute",
      // position: "absolute",
      // left: 0,
    },

    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      width: "100%",
      margin: 0,
    },

    // container for items in menu
    bmItem: {
      display: "flex",
      padding: 5,
      fontSize: 20,
      width: "100%",
    },

    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      width: "100%",
      height: "100%",
    },
  };

  const PanelType = {
    GPT: "GPT",
    Chatbot: "Chatbot",
    Transcript: "Transcript",
    Slides: "slides",
  };

  const closeAllPanelsExcept = (openPanel) => {
    // close all panels except openPanel
    if (openPanel !== PanelType.Chatbot) setChat(false);
    if (openPanel !== PanelType.Slides && panelCutoff) setSlides(false);

    // open the panel corresponding to openPanel
    if (openPanel === PanelType.Chatbot) setChat(true);
    if (openPanel === PanelType.Slides && panelCutoff) setSlides(true);
  };

  return (
    <>
      <div>
        {/* right menu when screen is small */}
        {!isLoggedIn && menuWindowSize && (
          <Menu styles={smallWindowMenu} width={250} right>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    onSignInGSI(credentialResponse, dispatch, supabase);

                    // start a session becuase user just logged in on transcript page
                    dispatch(createSession({ supabase }));
                  }}
                  onError={() => {
                    logger([LogCategory.DEBUG], "Login Failed");
                  }}
                  // useOneTap
                  auto_select
                  width={200}
                  shape="square"
                />
              </GoogleOAuthProvider>
            </div>
            {smallWindow && (
              <FeedbackButton setFeedbackForm={setFeedbackForm} />
            )}
          </Menu>
        )}

        <div
          className="App"
          style={{ backgroundColor: darkMode ? colors.black : "white" }}
        >
          <div
            style={{
              width: "90%",
              maxWidth: "min(1500px, 90%)",
              height: headerHeight + 4,
            }}
          >
            <Header
              marginInside={0}
              left={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 12,
                    fontWeight: 900,
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <OssyLogo width={25} marginRight={4} />
                  {!panelCutoff && <OssyText big />}
                </div>
              }
              center={
                <div
                  style={{
                    borderRadius: 6,
                    width: "90%",
                    maxWidth: 800,
                    margin: "auto",
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: 35,
                    minHeight: 35,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {displaySharePanel && (
                    <SharePanel
                      setDisplaySharePanel={setDisplaySharePanel}
                      supabase={supabase}
                      isOwnerOfContent={ownerOfDocument}
                      rightClickObject={rightClickObject}
                    />
                  )}

                  <ShareButton
                    setDisplaySharePanel={setDisplaySharePanel}
                    shareButtonRef={shareButtonRef}
                    contentType={contentType}
                  />

                  <input
                    ref={transcriptTitleRef}
                    type="text"
                    name="name"
                    autoComplete="off"
                    id="title_box"
                    defaultValue={title}
                    value={displayedTranscriptTitle}
                    // you can't rename a transcript you don't own
                    onKeyDown={(event) => {
                      if (["Enter", "Escape"].includes(event.key)) {
                        event.currentTarget.blur();
                      }
                    }}
                    onChange={(event) => {
                      if (ownerOfDocument) {
                        const newTranscriptTitle = event.target.value;
                        setDisplayedTranscriptTitle(newTranscriptTitle);
                      } else {
                        setNotOwnerPoints({ x: event.pageX, y: 50 });
                        setNotOwnerAction("edit the title");
                        setDisplayNotOwnerTooltip(true);
                      }
                    }}
                    onBlur={(e) => {
                      // edit transcript title if logged in
                      if (isLoggedIn) {
                        if (
                          e.currentTarget.value !== "" &&
                          e.currentTarget.value !== title
                        ) {
                          logger(
                            [LogCategory.DEBUG],
                            `pushing new title to database: ${e.currentTarget.value}`
                          );
                          dispatch(
                            editDocumentTitle({
                              newTitle: e.currentTarget.value,
                              supabase: supabase,
                            })
                          );
                        }
                        // otherwise show log in panel
                      } else if (e.currentTarget.value !== "") {
                        setGuestUserAction(actions.TITLE_TRANSCRIPT_ATTEMPT);
                        e.currentTarget.value = title;
                        setLogInPanel(true);
                      }
                    }}
                    style={{
                      // border: "none",
                      fontWeight: 700,
                      fontSize: 20,
                      backgroundColor: darkMode
                        ? colors.gray1
                        : colors.lightGray,
                      color: darkMode ? "white" : "black",
                      alignSelf: "center",
                      textAlign: "center",
                      // minWidth: 300,

                      // safari gives the input this ugly margin
                      margin: 0,

                      // safari gives the input this ugly weird border
                      border: 0,
                      justifySelf: "center",
                      overflowX: "hidden",
                      flexGrow: 1,
                      borderTopRightRadius: 6,
                      borderBottomRightRadius: 6,
                      maxHeight: 35,
                      minHeight: 35,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 0,
                      paddingBottom: 0,
                      // flex: 0.5,
                    }}
                  />
                </div>
              }
              right={
                <div
                  style={{
                    color: "black",
                    // alignSelf: "center",
                    alignItems: "center",
                    fontSize: 12,
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  {/* google sign in */}
                  {!menuWindowSize && !isLoggedIn && (
                    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          onSignInGSI(credentialResponse, dispatch, supabase);
                        }}
                        onError={() => {
                          logger([LogCategory.ERROR], "Login Failed");
                        }}
                        // useOneTap
                        auto_select
                        width={200}
                        shape="square"
                      />
                    </GoogleOAuthProvider>
                  )}

                  {/* profile options in top right */}
                  {isLoggedIn && (
                    <ProfileButton removeNameWhenSmall={768} left />
                  )}
                </div>
              }
            />
          </div>

          {/* panels */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              flexGrow: 1,
              maxHeight: `calc(100dvh - ${headerHeight + bottomHeight}px)`,
              width: "90%",
              maxWidth: "min(1500px, 90%)",
              justifyContent: "center",
              gap: 12,
            }}
          >
            {/* slides panel holder */}
            <div
              style={{
                flex: 1,
                flexGrow: 1,
                position: "static",
                overflow: "clip",
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
                maxWidth: "min(1500px, 100%)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  overflowX: "clip",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    marginBottom: 8,
                    width: "100%",
                    borderBottomColor: darkMode ? colors.gray1 : colors.gray,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                  }}
                >
                  {/* Slides button */}
                  <TabUnderline active={slides}>
                    <Button
                      style={{
                        flexGrow: slides ? 1 : 0,
                        flexShrink: slides ? 0 : 1,
                        overflow: "clip",
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                            overflow: "clip",
                          }}
                        >
                          {slides ? (
                            <BsFileEarmarkPdfFill
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          ) : (
                            <BsFileEarmarkPdf
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Slides
                          </p>
                        </div>
                      }
                      isClickable={!slides}
                      onClick={() => {
                        closeAllPanelsExcept(PanelType.Slides);
                      }}
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                    />
                  </TabUnderline>

                  {panelCutoff && (
                    <>
                      {/* Professor Ossy button */}
                      <TabUnderline active={chat}>
                        <Button
                          style={{
                            overflow: "clip",
                            flexGrow: chat ? 1 : 0,
                            flexShrink: chat ? 0 : 1,
                          }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 4,
                                alignItems: "center",
                                overflow: "clip",
                              }}
                            >
                              {chat ? (
                                <BsChatFill
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              ) : (
                                <BsChat
                                  style={{ minWidth: 14, maxWidth: 14 }}
                                />
                              )}
                              <p
                                style={{
                                  overflow: "clip",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Chatbot (Professor Ossy)
                              </p>
                            </div>
                          }
                          isClickable={
                            !chat
                            // || [COMPLETED_OR_UPLOADING_AUDIO, CURRENT_LIVE_TRANSCRIPT].includes(
                            //   state
                            // )
                          }
                          onClick={() => {
                            closeAllPanelsExcept(PanelType.Chatbot);
                          }}
                          buttonStyle={
                            darkMode
                              ? buttonStyles.panelTabsDarkMode
                              : buttonStyles.panelTabs
                          }
                        />
                      </TabUnderline>
                    </>
                  )}
                </div>
              </div>

              <SlidesPanel
                supabase={supabase}
                slides={slides}
                setSlides={setSlides}
                setGuestUserAction={setGuestUserAction}
                setLogInPanel={setLogInPanel}
                setFunctionAfterLogIn={setFunctionAfterLogIn}
                courseMaterialType={contentTypes.DOCUMENT}
              />

              {panelCutoff && (
                <>
                  <ProfessorOssyPanel
                    chat={chat}
                    setChat={setChat}
                    supabase={supabase}
                    paused={false}
                    started={false}
                    panelCutoff={panelCutoff}
                    highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                    setHighlightedSummaryBlurbIndex={
                      setHighlightedSummaryBlurbIndex
                    }
                    setHighlightedTranscriptBlurbIndex={
                      setHighlightedTranscriptBlurbIndex
                    }
                    highlightTranscriptBlurbTimeout={
                      highlightTranscriptBlurbTimeout
                    }
                    selectedTranscriptBlurbIndices={
                      selectedTranscriptBlurbIndices
                    }
                    setSelectedTranscriptBlurbIndices={
                      setSelectedTranscriptBlurbIndices
                    }
                    contentType={contentType}
                  />
                </>
              )}
            </div>

            {!panelCutoff && (
              <div
                style={{
                  flex: 1,
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                  maxWidth: "min(1500px, 90%)",
                  // this line is very necessary. keeps the panel from overflowing when text is really long
                  overflow: "clip",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    marginBottom: 8,
                    borderBottomColor: darkMode ? colors.gray1 : colors.gray,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                  }}
                >
                  <TabUnderline active={chat}>
                    <Button
                      style={{
                        overflow: "clip",
                        flexGrow: chat ? 1 : 0,
                        flexShrink: chat ? 0 : 1,
                      }}
                      content={
                        <div
                          style={{
                            display: "flex",
                            overflow: "clip",
                            flexDirection: "row",
                            gap: 4,
                            alignItems: "center",
                          }}
                        >
                          {chat ? (
                            <BsChatFill
                              style={{ minWidth: 14, maxWidth: 14 }}
                            />
                          ) : (
                            <BsChat style={{ minWidth: 14, maxWidth: 14 }} />
                          )}
                          <p
                            style={{
                              overflow: "clip",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Chatbot (Professor Ossy)
                          </p>
                        </div>
                      }
                      isClickable={ownerOfDocument && !chat}
                      buttonStyle={
                        darkMode
                          ? buttonStyles.panelTabsDarkMode
                          : buttonStyles.panelTabs
                      }
                      onClick={() => {
                        closeAllPanelsExcept(PanelType.Chatbot);
                      }}
                    />
                  </TabUnderline>
                </div>

                <ProfessorOssyPanel
                  chat={chat}
                  setChat={setChat}
                  supabase={supabase}
                  paused={false}
                  started={false}
                  panelCutoff={panelCutoff}
                  highlightedSummaryBlurbIndex={highlightedSummaryBlurbIndex}
                  setHighlightedSummaryBlurbIndex={
                    setHighlightedSummaryBlurbIndex
                  }
                  setHighlightedTranscriptBlurbIndex={
                    setHighlightedTranscriptBlurbIndex
                  }
                  highlightTranscriptBlurbTimeout={
                    highlightTranscriptBlurbTimeout
                  }
                  selectedTranscriptBlurbIndices={
                    selectedTranscriptBlurbIndices
                  }
                  setSelectedTranscriptBlurbIndices={
                    setSelectedTranscriptBlurbIndices
                  }
                  contentType={contentType}
                />
              </div>
            )}
          </div>

          {/* control panel */}
          <div
            style={{
              // minHeight: bottomHeight,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DocumentControlPanel
              loading={loading}
              setShowSummaryPanel={setShowSummaryPanel}
              downloadOptionsVisible={downloadOptionsVisible}
              setDownloadOptionsVisible={setDownloadOptionsVisible}
              ownerOfDocument={ownerOfDocument}
              supabase={supabase}
              chat={chat}
              setChat={setChat}
            />
          </div>

          {/* summary panel for lectures */}
          {showSummaryPanel && (
            <SummaryPanel
              setShowSummaryPanel={setShowSummaryPanel}
              supabase={supabase}
              ownerOfContent={ownerOfDocument}
              content={{
                contentArray,
                summarySentences,
                summaryBulletPoints,
              }}
              contentType={contentType}
            />
          )}

          {/* feedback form */}
          {feedbackForm && (
            <FeedbackForm
              setFeedbackForm={setFeedbackForm}
              email={email}
              feedback={feedback}
              setEmail={setEmail}
              setFeedback={setFeedback}
              supabase={supabase}
              setSubmitFeedbackLoading={setSubmitFeedbackLoading}
              submitFeedbackLoading={submitFeedbackLoading}
            />
          )}

          {logInPanel && (
            <LogInPanel
              setLogInPanel={setLogInPanel}
              setLoading={setLoading}
              supabase={supabase}
              userAction={guestUserAction}
              functionAfterLogin={functionAfterLogin}
            />
          )}

          {displayNotOwnerTooltip && (
            <NotOwnerTooltip
              notOwnerPoints={notOwnerPoints}
              contentType={contentType}
              action={notOwnerAction}
              setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentPage;
