import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { onSignInGSI } from "../features/login/api/loginApi";
import homeScreen from "../assets/demo_screens/home_screen.png";
import transcriptionScreen from "../assets/demo_screens/transcription_screen.png";
import searchScreen from "../assets/demo_screens/search.png";
import {
  boxShadowHalf,
  colors,
  GOOGLE_CLIENT_ID,
  logger,
  LogCategory,
} from "../utility/utility.mjs";
import { atLoginPage } from "../transcriptSlice";

const LogInPage = ({ supabase }) => {
  const userId = useSelector((state) => state.routes.userId);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);

  const dispatch = useDispatch(); // setting up supabase for submitting feedback
  const [homeImage, setHomeImage] = useState(transcriptionScreen);

  // this code is necessary because when the user signs out, I want ossy.ai to direct them to the login page.
  // however, the code keeps taking them to the new transcript page to start a transcription as if they were a first time visitor
  useEffect(() => {
    dispatch(atLoginPage());
  }, [dispatch, userId]);

  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia("(max-width: 950px)").matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  // const [homeImage, setHomeImage] = useState(transcriptionScreen);
  useEffect(() => {
    const imageWheel = setInterval(() => {
      if (homeImage === transcriptionScreen) {
        setHomeImage(homeScreen);
      } else if (homeImage === homeScreen) {
        setHomeImage(searchScreen);
      } else if (homeImage === searchScreen) {
        setHomeImage(transcriptionScreen);
      } else {
      }
    }, 4000);
    return () => clearInterval(imageWheel);
  }, [homeImage]);

  // automatically login if user is already logged in
  useEffect(() => {
    if (isLoggedIn) {
      const protocol = window.location.protocol
        ? window.location.protocol + "//"
        : "";
      const host = window.location.host;
      window.location.replace(`${protocol}${host}/home`);
    }
  }, [isLoggedIn]);

  const [splashScreen, setSplashScreen] = useState(false);
  setTimeout(() => setSplashScreen(false), 1000);

  return (
    <>
      {splashScreen && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            backgroundColor: "white",
            height: "100%",
            zIndex: 5000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.ossyBlue1,
            fontSize: 14,
            fontWeight: "1000",
          }}
        >
          <h1>ossy</h1>
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
        </div>
      )}

      {/* full screen */}
      <div
        style={{
          flex: 1,
          height: "100dvh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!smallWindow && (
          <>
            <img
              src={homeImage}
              alt="home"
              style={{
                height: 350,
                boxShadow: boxShadowHalf,
                borderRadius: 8,
                marginLeft: 16,
                // maxWidth: "60%",
              }}
            />

            {/* spacing */}
            <div
              style={{
                maxWidth: 50,
                width: 50,
                minWidth: 16,
              }}
            />
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "0.1px solid lightgray",
            borderRadius: 6,
            paddingLeft: 50,
            paddingRight: 50,
            justifyContent: "center",
            // maxHeight: 350,
            height: 350,
            marginRight: !smallWindow ? 16 : 0,
            // minHeight: 350,
          }}
        >
          <p style={{ fontSize: 32, fontWeight: 400, marginBottom: 20 }}>
            ossy.ai
          </p>
          {/* <p>Live Transcription.</p>
          <p>AI Explanations.</p>
          <p>Summarization.</p> */}
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                onSignInGSI(credentialResponse, dispatch, supabase);
              }}
              onError={() => {
                logger([LogCategory.ERROR], "Login Failed");
              }}
              // useOneTap
              auto_select
              width={200}
              shape="square"
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </>
  );
};
export default LogInPage;
