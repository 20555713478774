import { useSelector } from "react-redux";
import { borderRadius, colors } from "../utility/utility";
import SelectFolder from "./SelectFolder";
import { FiXCircle } from "react-icons/fi";

const SelectFolderPanel = ({
  setSelectFolderPanel,
  setSelected,
  rightClickObject: { type, index: rightClickIndex, thing },
  setIsFolderSelected,
  setSelectedTranscriptsType,
  supabase,
  setDisplayAddToSharedFolder,
  setAddToSharedContentIndex,
  setAddToSharedFolderIndex,
}) => {
  const folders = useSelector((state) => state.routes.folders);
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        zIndex: 1,
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          opacity: 0.5,
          backgroundColor: "black",
        }}
        onClick={() => setSelectFolderPanel(false)}
      />
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          backgroundColor: darkMode ? colors.gray1 : "white",
          top: "50%",
          left: "50%",
          borderRadius: borderRadius,
          padding: 16,
          maxWidth: "50%",
          maxHeight: "80%",
          textAlign: "center",
          overflow: "clip",
          display: "flex",
          flexDirection: "column",
          overflowY: "clip",
        }}
      >
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <FiXCircle
            style={{
              cursor: "pointer",
              minWidth: 20,
              maxWidth: 20,
              minHeight: 20,
              maxHeight: 20,
            }}
            color={darkMode ? "white" : "gray"}
            onClick={() => {
              setSelectFolderPanel(false);
            }}
          />
          <p
            style={{
              fontWeight: 500,
              width: 500,
              color: darkMode ? "white" : "black",
            }}
          >
            Select A Folder
          </p>
        </div>
        <div
          style={{
            // position: "auto",
            alignItems: "flex-start",
            flexDirection: "row",
            maxHeight: "100%",
            overflowY: "auto",
            scrollbarWidth: "thin",
            width: "100%",

            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns: "repeat(auto-fill, 100px)",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          {folders.map((folder, index) => (
            <SelectFolder
              rightClickContentIndex={rightClickIndex}
              setSelected={setSelected}
              setSelectedTranscriptsType={setSelectedTranscriptsType}
              setIsFolderSelected={setIsFolderSelected}
              folder={folder}
              setSelectFolderPanel={setSelectFolderPanel}
              supabase={supabase}
              index={index}
              key={index}
              setDisplayAddToSharedFolder={setDisplayAddToSharedFolder}
              setAddToSharedFolderIndex={setAddToSharedFolderIndex}
              setAddToSharedContentIndex={setAddToSharedContentIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectFolderPanel;
