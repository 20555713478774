import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const OssyText = ({ big, color }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const textColor = darkMode ? "white" : colors.ossyBlue1;

  return (
    <a
      style={{
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
      }}
      href={isLoggedIn ? "/home" : "/welcome"}
    >
      <div>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: big ? 24 : 16,
            fontWeight: 600,
            color: color ? color : textColor,
          }}
        >
          ossy
        </p>
      </div>
    </a>
  );
};

export default OssyText;
