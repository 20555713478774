import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  borderRadius,
  colors,
  fontSizes,
  logger,
  LogCategory,
  addScrollBarUI,
  getIsMac,
} from "../../../utility/utility";
import { initialState } from "../../../data/initialState";
import { extractYoutubeVideoId } from "../../../utility/utility.mjs";
import { FaYoutube } from "react-icons/fa";
import { PiVideoFill } from "react-icons/pi";

const VideoPanel = ({
  supabase,
  displayVideo,
  setGuestUserAction,
  setLogInPanel,
  setFunctionAfterLogIn,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const videoUrl = useSelector((state) => state.routes.transcript.videoUrl);
  const embedId = extractYoutubeVideoId(videoUrl);

  return (
    <div
      style={{
        flex: 1,
        color: "gray",
        // flexGrow: 1,
        flexDirection: "column",
        alignItems: "center",
        display: displayVideo ? "flex" : "none",
        fontSize: fontSizes.big,
        fontWeight: 400,
        lineHeight: 2.4,
        justifyContent:
          videoUrl === initialState.transcript.videoUrl
            ? "flex-start"
            : "center",
        marginTop: 0,
        boxSizing: "border-box",
        resize: "none",
        overflow: "hidden",
        backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
        borderRadius: borderRadius,
        overflowY: "auto",
        // minHeight: "400px",
        height: "100%",
      }}
    >
      {videoUrl === initialState.transcript.videoUrl && (
        <span
          style={{
            color: "gray",
            fontWeight: 600,
            opacity: 0.5,
            fontSize: fontSizes.big,
          }}
        >
          Video will appear here.
        </span>
        // <PiVideoFill size={128} />
      )}
      {videoUrl !== initialState.transcript.videoUrl && (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${embedId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default VideoPanel;
