import "../App.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTranscript } from "../transcriptSlice";
import { useLoaderData } from "react-router-dom";
import { initialState } from "../data/initialState";
import TranscriptDoesNotExistPage from "./TranscriptDoesNotExistPage";
import TranscriptHasBeenDeletedPage from "./TranscriptHasBeenDeletedPage";
import TranscriptIsPrivatePage from "./TranscriptIsPrivatePage";
import TranscriptPage from "./TranscriptPage";
import {
  transcriptModeTypes,
  logger,
  LogCategory,
  contentTypes,
} from "../utility/utility.mjs";

const TranscriptRouter = ({ supabase }) => {
  const transcriptUrl = useLoaderData();
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  let transcriptMode = transcriptModeTypes.LIVE_TRANSCRIPTION.id;

  const getTranscriptMode = () => {
    switch (transcriptUrl) {
      case transcriptModeTypes.UPLOAD_AUDIO.url:
        transcriptMode = transcriptModeTypes.UPLOAD_AUDIO.id;
        break;
      case transcriptModeTypes.VIDEO_LINK.url:
        transcriptMode = transcriptModeTypes.VIDEO_LINK.id;
        break;
      default:
        break;
    }
  };

  // get the transcript data
  useEffect(() => {
    logger([LogCategory.DEBUG], "fetching transcript...");

    // https://ossy.ai/t/ -> https://ossy.ai/
    if (transcriptUrl === "") {
      window.location.pathname = "";
    }

    const fetchTranscript_ = async () => {
      // fetch the transcript data from the database
      const transcriptData = await dispatch(
        fetchTranscript({ supabase: supabase, transcriptUrl: transcriptUrl })
      ).unwrap();

      // invalid url, transcript doesn't exist
      if (transcriptData === initialState.FILE_DOES_NOT_EXIST) {
        setPage(
          <TranscriptDoesNotExistPage contentType={contentTypes.TRANSCRIPT} />
        );
        return;

        // the transcript used to exist but doesn't anymore
      } else if (transcriptData === initialState.FILE_HAS_BEEN_DELETED) {
        setPage(
          <TranscriptHasBeenDeletedPage contentType={contentTypes.TRANSCRIPT} />
        );
        return;

        // the transcript is private and the user is not the owner
      } else if (transcriptData === initialState.FILE_IS_PRIVATE) {
        setPage(
          <TranscriptIsPrivatePage contentType={contentTypes.TRANSCRIPT} />
        );
        logger([LogCategory.DEBUG], "detecting the right thing");
        return;

        // user can access the transcript with no issues
      } else {
        getTranscriptMode();
        setPage(
          <TranscriptPage
            supabase={supabase}
            ownerOfTranscript={transcriptData.ownerOfTranscript}
            transcriptMode={transcriptMode}
          />
        );
        return;
      }
    };

    fetchTranscript_();
  }, [dispatch, transcriptUrl, supabase]);

  return page;
};

export default TranscriptRouter;
