import { colors, borderRadius } from "../utility/utility";
import loadingSymbol from "../assets/loading.gif";

const defaultStyle = {
  backgroundColor: colors.ossyBlue1,
  color: "white",
  border: undefined,
  notClickableColor: "white",
  notClickableBorder: "none",
  notClickableBgColor: colors.ossyBlue2,
};

export const buttonStyles = {
  default: {
    style: { ...defaultStyle },
    moreStyle: {},
    notClickableAnimateOnHover: false,
    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue2;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue1;
    },
  },

  panelTabs: {
    style: {
      ...defaultStyle,
      backgroundColor: "transparent",
      color: "#696969",
      notClickableColor: colors.ossyBlue1,
      notClickableBgColor: "transparent",
    },
    notClickableAnimateOnHover: true,
    moreStyle: {
      boxShadow: "none",
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginRight: 0,
      marginBottom: 2,
      fontSize: 14,
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue4;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = "transparent";
    },
  },

  panelTabsDarkMode: {
    style: {
      ...defaultStyle,
      backgroundColor: "transparent",
      color: "lightgray",
      notClickableColor: colors.ossyBlue1,
      notClickableBgColor: "transparent",
    },
    notClickableAnimateOnHover: true,
    moreStyle: {
      boxShadow: "none",
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginRight: 0,
      marginBottom: 2,
      fontSize: 14,
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue4;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = "transparent";
    },
  },

  inverted: {
    style: {
      ...defaultStyle,
      // backgroundColor: colors.ossyBlue2,
      notClickableBgColor: colors.ossyBlue1,
      notClickableColor: "white",
      color: colors.ossyBlue1,
      border: `0.4mm solid ${colors.ossyBlue1}`,
      backgroundColor: "transparent",
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue4;
      // e.currentTarget.style.color = "white";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = "transparent";
      // e.currentTarget.style.color = colors.ossyBlue1;
    },
  },

  outline: {
    style: {
      ...defaultStyle,
      // backgroundColor: colors.ossyBlue2,
      notClickableBgColor: "transparent",
      color: colors.ossyBlue1,
      notClickableColor: colors.ossyBlue2,
      border: `0.4mm solid ${colors.ossyBlue1}`,
      notClickableBorder: `0.4mm solid ${colors.ossyBlue2}`,
      backgroundColor: "transparent",
    },
    // onMouseEnter: (e) => {
    //   e.currentTarget.style.backgroundColor = colors.ossyBlue4;
    //   // e.currentTarget.style.color = "white";
    // },
    // onMouseLeave: (e) => {
    //   e.currentTarget.style.backgroundColor = "transparent";
    //   // e.currentTarget.style.color = colors.ossyBlue1;
    // },

    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue4;

      // e.currentTarget.style.color = colors.ossyBlue2;
      // e.currentTarget.style.border = `0.4mm solid ${colors.ossyBlue2}`;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = "transparent";

      // e.currentTarget.style.color = colors.ossyBlue1;
      // e.currentTarget.style.border = `0.4mm solid ${colors.ossyBlue1}`;
    },
  },

  gray: {
    style: { ...defaultStyle, backgroundColor: "gray" },
    onMouseEnter: (e) => {
      e.currentTarget.style.backgroundColor = colors.ossyBlue2;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.backgroundColor = "gray";
    },
  },

  green: {
    style: { ...defaultStyle, backgroundColor: colors.green },
    onMouseEnter: (e) => {
      e.currentTarget.style.opacity = 0.8;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.opacity = 1;
      // e.currentTarget.style.color = "gray";
    },
  },

  lightgray: {
    style: { ...defaultStyle, color: "gray", backgroundColor: "lightgray" },
    onMouseEnter: (e) => {
      e.currentTarget.style.opacity = 0.8;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.opacity = 1;
      e.currentTarget.style.color = "gray";
    },
  },

  tomato: {
    style: {
      ...defaultStyle,
      color: "white",
      backgroundColor: "tomato",
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.transform = "scale(1.05)";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.transform = "scale(1)";
    },
  },

  tomatoInverted: {
    style: {
      ...defaultStyle,
      color: "tomato",
      border: "0.4mm solid tomato",
      backgroundColor: "transparent",
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.transform = "scale(1.05)";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.transform = "scale(1)";
    },
  },

  white: {
    style: {
      ...defaultStyle,
      backgroundColor: "white",
      border: "0.5mm solid lightgray",
      color: "gray",
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.color = colors.ossyBlue1;
      e.currentTarget.style.border = `0.5mm solid ${colors.ossyBlue1}`;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.color = "gray";
      e.currentTarget.style.border = `0.5mm solid lightgray`;
    },
  },

  black: {
    style: {
      ...defaultStyle,
      backgroundColor: "transparent",
      border: "0.5mm solid gray",
      color: "lightgray",
    },
    onMouseEnter: (e) => {
      e.currentTarget.style.color = colors.ossyBlue1;
      e.currentTarget.style.border = `0.5mm solid ${colors.ossyBlue1}`;
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.color = "lightgray";
      e.currentTarget.style.border = `0.5mm solid gray`;
    },
  },

  text: {
    style: {
      ...defaultStyle,
      backgroundColor: "transparent",
      color: "gray",
      boxShadow: "none",
      borderStyle: "none",
      border: "none",
    },
    moreStyle: { boxShadow: "none", padding: 0 },
    onMouseEnter: (e) => {
      e.currentTarget.style.color = "lightgray";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.color = "gray";
    },
  },
};

const Button = ({
  onClick,
  content,
  style: inlineStyle,
  loading,
  disabled = false,
  padding = 8,
  id,
  buttonStyle: {
    style,
    onMouseEnter,
    onMouseLeave,
    moreStyle,
    notClickableAnimateOnHover,
  } = buttonStyles.default,
  isClickable = true,
  toolTip,
}) => {
  return (
    <button
      style={{
        // color: bgColor === "lightgray" ? "gray" : "white",
        fontSize: 12,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "max-content",
        fontFamily: "poppins",
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: padding * 1.7,
        paddingRight: padding * 1.7,
        borderRadius: 10,
        fontWeight: 600,
        cursor:
          (loading || !isClickable) && !notClickableAnimateOnHover
            ? "default"
            : "pointer",
        backgroundColor: isClickable
          ? style.backgroundColor
          : style.notClickableBgColor,
        color: isClickable ? style.color : style.notClickableColor,
        border: isClickable ? style.border : style.notClickableBorder,
        position: toolTip ? "relative" : undefined,
        ...inlineStyle,
        ...moreStyle,
      }}
      id={id}
      onMouseEnter={(event) => {
        if (loading) {
          return;
        }

        if (isClickable || notClickableAnimateOnHover) {
          onMouseEnter(event);
        }
      }}
      onMouseLeave={(event) => {
        if (!isClickable && !notClickableAnimateOnHover) {
          return;
        }
        onMouseLeave(event);
      }}
      onClick={
        !loading && isClickable
          ? (e) => {
              onClick(e);
              onMouseLeave(e);
            }
          : (e) => {
              e.preventDefault();
            }

        // !loading && isClickable
        //   ? onClick
        //   : (e) => {
        //       e.preventDefault();
        //     }
      }
      disabled={loading || disabled}
    >
      {toolTip}
      {content}
      {loading && (
        <img
          src={loadingSymbol}
          style={{ width: 16, height: 16, marginLeft: 8 }}
          alt="Loading"
        />
      )}
    </button>
  );
};

export default Button;
