import { useDispatch, useSelector } from "react-redux";
import ProfessorOssyPanel from "../features/professorOssy/components/professorOssyPanel";
import {
  colors,
  contentTypes,
  getIsMac,
  transcriptsType,
} from "../utility/utility.mjs";
import ProfileButton from "./ProfileButton";
import Transcript from "./Transcript";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import {
  fetchFolder,
  fetchFolderChats,
  fetchFolderContentAndEmbeddings,
} from "../transcriptSlice";
import caretWhite from "../assets/caret_white.svg";
import caretBlack from "../assets/caret_black.svg";
import RightClickTranscriptPanel from "./RightClickTranscriptDropdown";

const FolderChat = ({ supabase }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const folder = useSelector((state) => state.routes.folder);
  const { folderName } = folder;
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 575px)").matches
  );
  const [rightClickContentIndex, setRightClickContentIndex] = useState(-1);
  const [editingTranscriptTitle, setEditingTranscriptTitle] = useState(false);

  const [transcriptTitleEditIndex, setTranscriptTitleEditIndex] = useState(-1);
  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [rightClickedTranscript, setRightClickedTranscript] = useState(false);

  const dispatch = useDispatch();
  const contentsArray = useSelector((state) => state.routes.contentsArray);
  const [ownerOfFolder, setOwnerOfFolder] = useState(false);
  const [doneFetchingMessages, setDoneFetchingMessages] = useState(false);

  const folderUrl = useLoaderData();
  useEffect(() => {
    const fetchFolderData = async () => {
      const { folder, ownerOfFolder } = await dispatch(
        fetchFolder({ supabase, folderUrl })
      ).unwrap();

      dispatch(
        fetchFolderContentAndEmbeddings({
          supabase,
          folderUrl: folder.folderUrl,
        })
      );

      await dispatch(
        fetchFolderChats({ supabase, folderId: folder.id })
      ).unwrap();
      setDoneFetchingMessages(true);
    };
    fetchFolderData();
  }, []);

  return (
    <>
      {/* right click transcript */}
      {rightClickedTranscript && (
        <RightClickTranscriptPanel
          points={points}
          setRightClickedTranscript={setRightClickedTranscript}
          setTranscriptTitleEditIndex={setTranscriptTitleEditIndex}
          setEditingTranscriptTitle={setEditingTranscriptTitle}
          rightClickContentIndex={rightClickContentIndex}
          isOwner={ownerOfFolder}
          supabase={supabase}
          setRightClickContentIndex={setRightClickContentIndex}
          transcript={contentsArray[rightClickContentIndex]}
        />
      )}
      <div
        style={{
          flexGrow: 1,
          height: "100dvh",
          display: "flex",
          fontSize: 24,
          // display: "flex",
          flexDirection: "row",
          overflow: "clip",
          backgroundColor: darkMode ? colors.black : "white",
          overflowY: "clip",
          minHeight: "max-content",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            overflowY: "auto",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {/* title and back arrow */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
              justifyContent: "center",
              alignSelf: "flex-start",
              width: "fit-content",
              cursor: "pointer",
            }}
            onClick={(e) => {
              window.location = `${window.origin}/f/${folderUrl}/`;
            }}
          >
            <img
              src={darkMode ? caretWhite : caretBlack}
              alt="caret"
              width={12}
              style={{ transform: "rotate(-90deg)", cursor: "pointer" }}
            />
            <p
              style={{
                fontWeight: 500,
                overflow: "hidden",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                alignItems: "center",
                marginLeft: 2,
                fontSize: 20,
                color: darkMode ? "white" : colors.black,
              }}
            >
              {folderName}
            </p>
          </div>
          <ProfessorOssyPanel
            supabase={supabase}
            folderLevel
            // panelCutoff={0}
            folderName={folderName}
            chat={true}
            folder={folder}
            doneFetchingMessages={doneFetchingMessages}
            contentType={contentTypes.FOLDER}

            // chat,
            // highlightTranscriptBlurbTimeout,
            // setChat,
            // setHighlightedTranscriptBlurbIndex,
            // setHighlightedSummaryBlurbIndex,
            // paused,
            // started,
          />
        </div>
      </div>
    </>
  );
};

export default FolderChat;
