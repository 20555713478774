import { FiXCircle } from "react-icons/fi";
import DarkOverlay from "../../../components/DarkOverlay";
import {
  boxShadow,
  colors,
  GOOGLE_CLIENT_ID,
  logger,
} from "../../../utility/utility";
import { useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { onSignInGSI } from "../api/loginApi";
import { useDispatch } from "react-redux";
import { createSession } from "../../../transcriptSlice";
import actions from "./actions.json";
import { useEffect } from "react";

const LogInPanel = ({
  setLogInPanel,
  supabase,
  setLoading,
  userAction,
  functionAfterLogin,
}) => {
  const dispatch = useDispatch(); // setting up supabase for submitting feedback

  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <>
      <DarkOverlay
        blur
        zIndex={1}
        onClick={() => {
          if (
            userAction.id === actions.VIEW_TRANSCRIPT_ATTEMPT.id ||
            userAction.id === actions.VIEW_DOCUMENT_ATTEMPT.id ||
            userAction.id === actions.VIEW_FOLDER_ATTEMPT.id
          ) {
            return;
          }

          setLogInPanel(false);
          if (setLoading) {
            setLoading(false);
          }
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 400,
          height: 150,
          zIndex: 2,
          maxWidth: "80%",
        }}
      >
        {false && (
          <div
            style={{
              // position: "fixed",
              top: 20,
              left: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              setLogInPanel(false);
              if (setLoading) {
                setLoading(false);
              }
            }}
          >
            <FiXCircle
              style={{ width: 18, height: 18 }}
              color={darkMode ? "white" : "gray"}
            />
          </div>
        )}
        <h1
          style={{
            fontSize: 24,
            paddingTop: 0,
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {userAction.message}
        </h1>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: 80,
          }}
        >
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const { firstName, lastName, userId, email, fullName, pfpUrl } =
                  await onSignInGSI(credentialResponse, dispatch, supabase);

                // trigger a function to start live transcript, upload video, upload audio, etc.
                setLogInPanel(false);

                functionAfterLogin({
                  firstName,
                  lastName,
                  userId,
                  email,
                  fullName,
                  pfpUrl,
                });

                // start a log session becuase user just logged in on transcript page
                dispatch(createSession({ supabase }));
              }}
              onError={() => {
                logger("Login Failed");
              }}
              // useOneTap
              auto_select
              width={200}
              shape="square"
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </>
  );
};

export default LogInPanel;
