import { useDispatch, useSelector } from "react-redux";
import { Button, DropDown, PlusSign } from "../components";
import { useRef, useState } from "react";
import { createNewFolderLocal, goToContent } from "../transcriptSlice";
import { initialState } from "../data/initialState";
import {
  BsFileEarmarkPdf,
  BsFileEarmarkText,
  BsFolderPlus,
} from "react-icons/bs";
import { BiMicrophone } from "react-icons/bi";
import { MdOutlineFileUpload } from "react-icons/md";
import {
  colors,
  contentTypes,
  documentModeTypes,
  logger,
  transcriptModeTypes,
  transcriptsType,
} from "../utility/utility.mjs";
import { FaYoutube } from "react-icons/fa";

const NewTranscriptButton = ({
  smallWindow,
  setMenuOpen,
  setFileToUpload,
  fileInputRef,
  isOwnerOfContent,
  setNotOwnerAction,
  setDisplayNotOwnerTooltip,
  setNotOwnerPoints,
  selected,
  setDisplayInSharedFolderPanel,
  setSharedContentMode,
}) => {
  const dispatch = useDispatch();
  const [showNewDropDown, setShowNewDropDown] = useState(false);
  const {
    id: folderId,
    isPrivate,
    folderUrl,
  } = useSelector((state) => state.routes.folder);

  const createNewTranscript = (transcriptMode) => {
    const transcriptPathName =
      folderUrl === initialState.folder.folderUrl
        ? `new/${transcriptMode}`
        : `new/${transcriptMode}?f=${folderUrl}`;
    logger("transcriptPathName: ", transcriptPathName);

    if (isPrivate === false) {
      setSharedContentMode(transcriptMode);
      setDisplayInSharedFolderPanel(true);
    } else {
      dispatch(
        goToContent({
          contentPathName: transcriptPathName,
          newTab: true,
          contentType: contentTypes.TRANSCRIPT,
        })
      );
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "fit-content",
      }}
    >
      <Button
        style={{
          color: "white",
          padding: 12,
          fontSize: 16,
          borderRadius: 6,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "dodgerblue",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "max-content",
          marginBottom: 16,
        }}
        onClick={(event) => {
          if (
            isOwnerOfContent ||
            selected === transcriptsType.SHARED_TRANSCRIPTS
          ) {
            setShowNewDropDown(true);
          } else {
            setNotOwnerAction("create a transcript");
            setNotOwnerPoints({ x: event.pageX, y: event.pageY });
            setDisplayNotOwnerTooltip(true);
          }
        }}
        disabled={showNewDropDown}
        isClickable={!showNewDropDown}
        content={
          <>
            {/* the plus sign */}
            <PlusSign color={"white"} />
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              New
            </p>
          </>
        }
      />
      {showNewDropDown && (
        <DropDown
          func={() => {
            setShowNewDropDown(false);
            if (smallWindow && setMenuOpen) {
              setMenuOpen(false);
            }
          }}
          zIndex={100000}
          top={0}
          left={84}
          links={[
            {
              name: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                    padding: 4,
                    fontWeight: 500,
                  }}
                >
                  <BiMicrophone size={16} color={colors.ossyBlue1} />
                  <p>Live Transcript</p>
                </div>
              ),
              onClick: () => {
                const transcriptMode =
                  transcriptModeTypes.LIVE_TRANSCRIPTION.name;
                createNewTranscript(transcriptMode);
              },
            },
            {
              name: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                    padding: 4,
                    fontWeight: 500,
                  }}
                >
                  <MdOutlineFileUpload size={16} color={colors.ossyBlue1} />
                  <p>Upload Audio</p>
                </div>
              ),
              onClick: () => {
                const transcriptMode = transcriptModeTypes.UPLOAD_AUDIO.name;
                createNewTranscript(transcriptMode);
              },
            },
            {
              name: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                    padding: 4,
                    fontWeight: 500,
                  }}
                >
                  <FaYoutube size={16} color={"red"} />
                  <p>Paste YouTube Link</p>
                </div>
              ),
              onClick: () => {
                const transcriptMode = transcriptModeTypes.VIDEO_LINK.name;
                createNewTranscript(transcriptMode);
              },
            },
            {
              name: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                    padding: 4,
                    fontWeight: 500,
                  }}
                >
                  <BsFileEarmarkPdf color={"red"} size={16} />
                  <p>Upload PDF Document</p>
                </div>
              ),
              onClick: () => {
                if (isPrivate === false) {
                  const documentMode = documentModeTypes.UPLOAD.name;
                  setSharedContentMode(documentMode);
                  setDisplayInSharedFolderPanel(true);
                } else {
                  fileInputRef.current.click();
                }
              },
            },

            folderId === initialState.folder.id
              ? {
                  name: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                        padding: 4,
                        fontWeight: 500,
                      }}
                    >
                      <BsFolderPlus size={16} />
                      <p>New Folder</p>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(createNewFolderLocal({}));
                  },
                }
              : undefined,
          ].filter((link) => link !== undefined)}
        />
      )}
    </div>
  );
};

export default NewTranscriptButton;
