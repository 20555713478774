import { configureStore } from "@reduxjs/toolkit/dist";
import routesReducer from "./transcriptSlice";
import { initialState } from "./data/initialState";
// import throttle from "lodash/throttle";
import { logger, LogCategory } from "./utility/utility";

export const saveState = (state) => {
  try {
    const isLoggedIn = state.routes.isLoggedIn;
    if (!isLoggedIn) {
      window.localStorage.removeItem("app_state");
      return;
    }

    // Convert the state to a JSON string
    const serialisedState = JSON.stringify(state);
    // Save the serialised state to localStorage against the key 'app_state'
    window.localStorage.setItem("app_state", serialisedState);
  } catch (err) {
    logger([LogCategory.ERROR], "could not save state");
  }
};

// check if app state is saved in localStorage
const loadState = () => {
  // wrapped in a try in case the user's privacy settings don't allow accessing state
  try {
    // Load the data saved in localStorage, against the key 'app_state'
    const serialisedState = window.localStorage.getItem("app_state");

    // If no data is saved, return undefined
    if (serialisedState === null) {
      return undefined;
    }

    const jsonState = JSON.parse(serialisedState);

    if (!jsonState.routes.isLoggedIn) {
      return undefined;
    }
    const finalState = { routes: { ...initialState, ...jsonState.routes } };

    // De-serialise the saved state, and return it.
    return finalState;
  } catch (err) {
    logger([LogCategory.ERROR], "could not find state");
    // Return undefined if localStorage is not available,
    // or data could not be de-serialised,
    // or there was some other error
    return undefined;
  }
};

const oldState = loadState();

export const store = configureStore({
  reducer: {
    routes: routesReducer,
  },
  preloadedState: oldState,
});

// Add a change listener to the store and invoke saveState function
store.subscribe(
  // save state a maximum of once every 1000 ms
  // throttle(() => {
  () => {
    const state = store.getState();
    saveState({
      routes: {
        isLoggedIn: state.routes.isLoggedIn || initialState.isLoggedIn,
        userId: state.routes.userId || initialState.userId,
        fullName: state.routes.fullName || initialState.fullName,
        email: state.routes.email || initialState.email,
        pfpUrl: state.routes.pfpUrl || initialState.pfpUrl,
        darkMode: state.routes.darkMode || initialState.darkMode,
        emailPreferences:
          state.routes.emailPreferences || initialState.emailPreferences,
        isPaidUser: state.routes.isPaidUser || initialState.isPaidUser,
        weekAudioDuration:
          state.routes.weekAudioDuration || initialState.weekAudioDuration,
      },
    });
  }
  // }),
  // 1000
);
