import { boxShadow } from "../utility/utility";
import { useSelector } from "react-redux";

const NotOwnerTooltip = ({
  notOwnerPoints,
  contentType,
  action,
  setDisplayNotOwnerTooltip,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  // close the not owner tooltip after 2 seconds
  setTimeout(() => {
    setDisplayNotOwnerTooltip(false);
  }, 2000);

  return (
    <div
      style={{
        color: darkMode ? "white" : "gray",
        borderRadius: 6,
        backgroundColor: darkMode ? "gray" : "white",
        width: "200px",
        padding: 6,
        boxShadow: darkMode ? "" : boxShadow,
        position: "absolute",
        top: notOwnerPoints.y,
        left: notOwnerPoints.x,
        zIndex: 1,
      }}
    >
      <p
        style={{
          //   whiteSpace: "nowrap",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontSize: 12,
          padding: 0,
          cursor: "default",
          lineHeight: 1,
          fontFamily: "poppins",
          textAlign: "center",
        }}
      >
        {`You must be the owner of the ${contentType.name} to ${action}`}
      </p>
    </div>
  );
};

export default NotOwnerTooltip;
