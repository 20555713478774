import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const Switch = ({ size = 25, style, updateState }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const unselectedBgColor = darkMode ? "gray" : "lightgray";

  const height = size;
  const width = height * 1.75;
  return (
    <label
      style={{
        position: "relative",
        display: "inline-block",
        width: `${width}px`,
        height: `${height}px`,
        ...style,
      }}
      onClick={(event) => {
        const checkbox = event.currentTarget.firstChild;
        updateState(checkbox.checked);

        const slider = event.currentTarget.children[1];
        const sliderCard = event.currentTarget.lastChild;
        const sliderCircle = sliderCard.firstChild;

        let switchBgColor = unselectedBgColor;
        let transform = "";
        let circleColor = "white";

        if (checkbox.checked) {
          switchBgColor = colors.ossyBlue2;
          transform = `translateX(${width - size}px)`;
          circleColor = colors.ossyBlue1;
        }

        slider.style.backgroundColor = switchBgColor;
        sliderCard.style.transform = transform;
        sliderCircle.style.backgroundColor = circleColor;
      }}
    >
      <input
        type="checkbox"
        style={{
          opacity: 0,
          width: 0,
          height: 0,
        }}
      />

      {/* slider */}
      <div
        style={{
          position: "absolute",
          cursor: "pointer",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: unselectedBgColor,
          transition: ".4s",
          borderRadius: "30px",
        }}
      />

      {/* slider card */}
      <div
        style={{
          position: "absolute",
          content: "",
          height: `${size - 10}px`,
          width: `${size - 10}px`,
          borderRadius: "20px",
          left: "5px",
          bottom: "5px",
          transition: ".4s",
          pointerEvents: "none",
          backgroundColor: colors.ossyBlue1,
        }}
      >
        {/* slider circle */}
        <div
          style={{
            position: "absolute",
            inset: 0,
            backfaceVisibility: "hidden",
            perspective: "1000px",
            borderRadius: "50%",
            transition: ".4s transform",
            backgroundColor: "white",
          }}
        />
      </div>
    </label>
  );
};

export default Switch;
