import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  borderRadius,
  colors,
  fontSizes,
  apiEndpoint,
  logger,
  addScrollBarUI,
  getIsMac,
  formatTimestamp,
} from "../../../utility/utility";
import MessageBlurb from "./MessageBlurb";
import { Button, ToolTip } from "../../../components";
import {
  createEmbeddingObjLastFewBlurbs,
  getQueryContext,
  logAmplitudeEvent,
  updateMessagesBackend,
} from "../../../transcriptSlice";
import senders from "./senders.json";
import TranscriptBlurbPreview from "./BlurbPreview";
import { contentTypes } from "../../../utility/utility.mjs";

const ProfessorOssyPanel = ({
  supabase,
  chat,
  highlightTranscriptBlurbTimeout,
  setHighlightedTranscriptBlurbIndex,
  setHighlightedSummaryBlurbIndex,
  paused,
  started,
  folderLevel = false,
  folder: { folderName, folderUrl } = false,
  doneFetchingMessages = false,
  selectedTranscriptBlurbIndices = [],
  setSelectedTranscriptBlurbIndices = () => {},
  contentType,
}) => {
  const [userQuery, setUserQuery] = useState("");
  const { contentArray, timestamps } = useSelector(
    (state) => state.routes.transcript
  );

  const _messages = useSelector(
    (state) => state.routes[contentType.name].messages
  );

  const [messages, setMessages] = useState(_messages);

  let placeholder;
  let disabled = false;
  if (contentType === contentTypes.FOLDER) {
    placeholder = `Ask a question to your folder...`;
  } else if (contentType === contentTypes.TRANSCRIPT) {
    if (contentArray.length === 0) {
      placeholder = "Start transcribing to ask a question...";
      disabled = true;
    } else {
      placeholder = `Ask a question to your transcript...`;
    }
  } else if (contentType === contentTypes.DOCUMENT) {
    placeholder = "Ask a question to your document...";
  }

  // we need this because the messages are still being fetched when we first display professor ossy
  // at a folder level
  useEffect(() => {
    if (doneFetchingMessages) {
      setMessages(_messages);
    }
  }, [doneFetchingMessages]);

  const updateMessages = (newMessages, saveBackend = false) => {
    setMessages(newMessages);

    if (saveBackend)
      dispatch(
        updateMessagesBackend({ supabase, messages: newMessages, contentType })
      );
  };

  const submitQuery = async () => {
    logger("submitting query...");

    const eventName =
      contentType === contentTypes.FOLDER
        ? "Professor Ossy Question: Folder"
        : "Professor Ossy Question: Transcript";
    dispatch(logAmplitudeEvent({ eventName }));

    // all messages preceding professor ossy response
    const currentTime = new Date().toISOString();
    const messagesWithoutPrompt = [...messages];
    const newMessage = {
      sender: "You",
      content: userQuery,
      date: currentTime,
      queryTranscriptBlurbs: selectedTranscriptBlurbIndices.map((index) => {
        return {
          timestampText: formatTimestamp(timestamps[index]),
          transcriptText: contentArray[index],
        };
      }),
    };

    const messagesSoFar = [...messages, newMessage];
    setSelectedTranscriptBlurbIndices([]);

    setUserQuery("");

    const professorOssyResponse = {
      sender: senders.professorOssy,
      thinking: true,
      error: false,
      content: "",
    };

    // save the messages to supabase
    updateMessages([...messagesSoFar, { ...professorOssyResponse }], true);

    setGeneratingResponse(true);

    let mostRecentBlurbsEmbeddingObj = undefined;

    // a transcription is currently in-session, make an embedding out of last few blurbs
    if (started && !paused) {
      logger("transcript in-session, making new embedding...");
      mostRecentBlurbsEmbeddingObj = await dispatch(
        createEmbeddingObjLastFewBlurbs({ supabase })
      ).unwrap();
      logger(`got embedding:`);
      logger(mostRecentBlurbsEmbeddingObj);
    }

    logger("getting context...");
    const queryContext = await dispatch(
      getQueryContext({
        userQuery,
        mostRecentBlurbsEmbeddingObj,
        contentType,
        folderUrl,
      })
    ).unwrap();
    logger("context:");
    logger(queryContext);

    fetch(`${apiEndpoint}/fetch_professor_ossy_response`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        userQuery: userQuery,
        queryContext: queryContext,
        previousMessages: messagesWithoutPrompt,
        chatType: contentType.name,
        queryBlurbs: newMessage.queryTranscriptBlurbs,
      }),
    })
      .then(async (response) => {
        // done thinking
        professorOssyResponse.thinking = false;
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            async function pump() {
              return reader.read().then(({ done, value }) => {
                professorOssyResponse.content += new TextDecoder().decode(
                  value
                );

                updateMessages([
                  ...messagesSoFar,
                  { ...professorOssyResponse },
                ]);

                // close the stream
                if (done) {
                  controller.close();

                  // we're done responding
                  setGeneratingResponse(false);
                  updateMessages(
                    [...messagesSoFar, { ...professorOssyResponse }],
                    true
                  );
                  return;
                }

                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .catch((error) => {
        setGeneratingResponse(false);
        professorOssyResponse.content =
          "Something went wrong. Please try making another request.";
        professorOssyResponse.thinking = false;
        professorOssyResponse.error = true;

        updateMessages(
          [...messagesSoFar, { ...professorOssyResponse }],
          supabase
        );
      });
  };

  const [generatingResponse, setGeneratingResponse] = useState(false);
  const queryInput = useRef(null);
  const messageAutoscroll = useRef(true);
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);

  // jump to bottom when user first opens professor ossy chat bot
  useEffect(() => {
    if (chat) {
      const messagesPanel = document.getElementById("messagesPanel");

      if (messagesPanel) {
        logger("autoscrolling");
        messagesPanel.scrollTop = messagesPanel.scrollHeight;
      }
    }
  }, [chat]);

  useEffect(() => {
    try {
      if (window.MathJax) {
        window.MathJax.typesetClear();
        window.MathJax.typeset();
      }
    } catch (e) {
      console.error(`couldn't display latex: ${e}`);
      try {
        if (window.MathJax) {
          window.MathJax.typesetClear();
          window.MathJax.typeset();
        }
      } catch (e2) {
        console.error(`failed again at parsing latex: ${e2}`);
      }
    }

    return () => window.MathJax.typesetClear();
  }, [messages, selectedTranscriptBlurbIndices]);

  // we have to change the color of the latex to white to see it in dark mode
  useEffect(() => {
    let styleElement = document.createElement("style");
    styleElement.innerHTML = `mjx-container {color: ${
      darkMode ? "white" : "black"
    };}`;
    document.head.appendChild(styleElement);
  }, [darkMode]);

  // autoscroll when a new message is created
  // no way to check for asynchronous state update so have to use a useEffect
  useEffect(() => {
    if (!messageAutoscroll.current) {
      return;
    }
    const messagesPanel = document.getElementById("messagesPanel");

    if (messagesPanel) {
      messagesPanel.scrollTop = messagesPanel.scrollHeight;
    }
  }, [messages]);

  const startMessage =
    contentType === contentTypes.FOLDER
      ? `Welcome to your class ${folderName}. How can I assist you today?`
      : `How can I assist you today?`;

  // automatically focus the input everytime professor ossy is opened
  useEffect(() => {
    if (chat && queryInput.current) {
      queryInput.current.focus();
    }
  }, [chat]);

  return (
    <div
      style={{
        flex: 1,
        color: "gray",
        flexDirection: "column",
        alignItems: "center",
        display: chat ? "flex" : "none",
        fontSize: fontSizes.big,
        fontWeight: 400,
        lineHeight: 2.4,
        width: folderLevel ? "100%" : undefined,
        maxWidth: folderLevel ? "100%" : undefined,
        justifyContent: "center",
        borderRadius: borderRadius,
        boxSizing: "border-box",
        resize: "none",

        // keeps the div in place
        overflow: "hidden",
        backgroundColor: folderLevel
          ? "transparent"
          : darkMode
          ? colors.gray2
          : colors.lightGray,
        marginBottom: folderLevel ? 20 : 0,
      }}
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          overflowX: "hidden",
          textAlign: "left",
          fontSize: 20,
          flexGrow: 1,
          lineHeight: 2.5,
          display: "flex",
          justifyContent: messages.length ? "flex-start" : "center",
          alignItems: folderLevel
            ? "center"
            : messages.length
            ? "flex-start"
            : "center",
          flexDirection: "column",

          // this line stops scrolbar from appearing when
          // there isn't enough text for it to be there
          overflowY: "auto",
        }}
        id="messagesPanel"
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }

          if (!event.isTrusted) {
            return;
          }
          const messagesPanel = event.target;
          // determine whether or not to continue autoscrolling
          messageAutoscroll.current =
            Math.abs(
              messagesPanel.scrollTop +
                messagesPanel.offsetHeight -
                messagesPanel.scrollHeight
            ) <= 20;
        }}
        onResizeCapture={(e) => {
          logger("resizing");
        }}
      >
        <div
          style={{
            maxWidth: folderLevel ? 800 : undefined,
            paddingTop: 16,

            /* 
            very critical line of code:
            width: "inherit"
            this line of code prevents the div from overflowing when a blurb is selected. 
            */
            width: "inherit",
          }}
        >
          {/* display the previous messages */}
          {messages.map(
            ({ sender, content, thinking, queryTranscriptBlurbs }, index) => {
              return (
                <MessageBlurb
                  index={index}
                  sender={sender}
                  content={content}
                  thinking={thinking}
                  key={index}
                  queryTranscriptBlurbs={queryTranscriptBlurbs}
                  setHighlightedTranscriptBlurbIndex={
                    setHighlightedTranscriptBlurbIndex
                  }
                  setHighlightedSummaryBlurbIndex={
                    setHighlightedSummaryBlurbIndex
                  }
                  highlightTranscriptBlurbTimeout={
                    highlightTranscriptBlurbTimeout
                  }
                  folderLevel={folderLevel}
                />
              );
            }
          )}

          {messages.length === 0 && (
            <p
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                color: darkMode ? "white" : undefined,
              }}
            >
              {startMessage}
            </p>
          )}
        </div>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: folderLevel ? 800 : undefined,
        }}
      >
        <div
          style={{
            maxHeight: 200,
            overflow: "auto",
            marginLeft: 8,
            marginRight: 8,
            overflowX: "auto",
          }}
        >
          {selectedTranscriptBlurbIndices.map((index) => (
            <TranscriptBlurbPreview
              timestampText={formatTimestamp(timestamps[index])}
              transcriptText={contentArray[index]}
              tentative
              index={index}
              setSelectedTranscriptBlurbIndices={
                setSelectedTranscriptBlurbIndices
              }
              selectedTranscriptBlurbIndices={selectedTranscriptBlurbIndices}
            />
          ))}
        </div>

        {/* input for asking questions */}
        <textarea
          ref={queryInput}
          autoFocus={true}
          rows={1}
          disabled={disabled}
          placeholder={placeholder}
          style={{
            margin: folderLevel ? 0 : 8,
            padding: 12,
            lineHeight: 2,
            border: `1px solid ${darkMode ? "gray" : "lightgray"}`,
            // borderTop: "none",
            boxSizing: "border-box",
            width: folderLevel ? "100%" : `calc(100% - 16px)`,
            paddingRight: 50,
            fontFamily: "inherit",
            resize: "none",
            outline: "none",
            verticalAlign: "top",
            textAlign: "left",
            color: darkMode ? "white" : "black",
            fontSize: 16,
            borderRadius: borderRadius,
            maxHeight: 200,
            backgroundColor: darkMode ? colors.black : "white",
          }}
          onChange={(e) => {
            setUserQuery(e.currentTarget.value);
            queryInput.current.style.height = "auto";
            queryInput.current.style.height = `${
              queryInput.current.scrollHeight + 2
            }px`;

            if (messageAutoscroll.current) {
              const messagesPanel = document.getElementById("messagesPanel");
              messagesPanel.scrollTop = messagesPanel.scrollHeight;
            }
          }}
          onFocus={(e) => {
            e.preventDefault();
            e.currentTarget.style.border = `1px solid ${
              darkMode ? "lightgray" : "darkgray"
            }`;
          }}
          onBlur={(e) => {
            e.currentTarget.style.border = `1px solid ${
              darkMode ? "gray" : "lightgray"
            }`;
          }}
          value={userQuery}
          onKeyDown={(e) => {
            // user hit enter without shift
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              submitQuery();
              queryInput.current.style.height = "auto";
            }
          }}
          onInput={(e) => {
            //   e.preventDefault();
            //   setUserQuery(e.currentTarget.innerText);
          }}
          id="inputProfessorOssy"
          onScroll={(event) => {
            const isMac = getIsMac();
            if (!isMac) {
              addScrollBarUI(event);
            }
          }}
        />
        {/* {userQuery} */}
        {/* </div> */}

        <Button
          content={"↑"}
          style={{
            minWidth: 34,
            minHeight: 34,
            maxWidth: 34,
            maxHeight: 34,
            position: "absolute",
            bottom: folderLevel ? 12 : 20,
            right: folderLevel ? 10 : 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 10000,
            fontSize: 20,
          }}
          isClickable={userQuery.length && !generatingResponse}
          onClick={() => submitQuery()}
        >
          <ToolTip text={"send message"} />
        </Button>
      </div>
    </div>
  );
};

export default ProfessorOssyPanel;
