import { boxShadow, borderRadius, colors } from "../utility/utility";
import { FiXCircle } from "react-icons/fi";
import DarkOverlay from "./DarkOverlay";
import { useSelector } from "react-redux";
import Button, { buttonStyles } from "./Button";
import { claimFreeHourStatus } from "../utility/utility.mjs";
import LimitBarAndDescription from "./LimitBarAndDescription";

const OssyPlusPaywall = ({
  showUpgradePanel,
  setShowUpgradePanel,
  setShowInviteFriendsPanel,
  setLoading,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const { unclaimed, claimed } = claimFreeHourStatus;
  const claimedStatus = useSelector((state) => state.routes.claimedStatus);

  return (
    <>
      <DarkOverlay />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          opacity: showUpgradePanel ? 1 : 0,
          transition: "opacity 1s",
        }}
      >
        <div
          style={{
            position: "fixed",
            margin: 0,
            padding: 0,
            top: 12,
            left: 12,
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => {
            setLoading(false);
            setShowUpgradePanel(false);
          }}
        >
          <FiXCircle
            color={darkMode ? "white" : "gray"}
            style={{
              width: 20,
              margin: 0,
              padding: 0,
              height: 20,
            }}
          />
        </div>
        <div
          style={{
            borderRadius: borderRadius,
            marginTop: 24,
            backgroundColor: darkMode ? colors.gray1 : "white",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: 26,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 4,
              color: colors.ossyBlue1,
            }}
          >
            You've reached your free weekly limit.
          </p>

          <LimitBarAndDescription right />

          <div style={{ marginTop: 40 }} />

          <Button
            content={(() => {
              switch (claimedStatus) {
                case unclaimed:
                  return "Get 1 Hour for FREE";
                case claimed:
                  return "Upgrade to Ossy Plus";
                default:
                  return "Upgrade to Ossy Plus";
              }
            })()}
            style={{
              fontSize: 20,
              fontWeight: 700,
              marginTop: 16,
              padding: 12,
              minWidth: "100%",
              justifyContent: "center",
            }}
            onClick={(e) => {
              if (claimedStatus === claimed) {
                window.location.pathname = "/checkout";
              } else {
                setShowUpgradePanel(false);
                setShowInviteFriendsPanel(true);
                setLoading(false);
              }
            }}
          />

          {claimedStatus === unclaimed && (
            <Button
              content="or upgrade to Ossy Plus"
              buttonStyle={buttonStyles.text}
              style={{
                alignSelf: "center",
                justifySelf: "center",
                marginTop: 6,
                fontSize: 14,
              }}
              onClick={() => {
                window.location.pathname = "/checkout";
              }}
            />
          )}

          <div
            style={{
              backgroundColor: "lightgray",
              color: "black",
              height: 1,
              marginTop: 20,
              marginBottom: 20,
              width: "100%",
            }}
          />
          {[
            "Unlimited Transcriptions",
            "Unlimited Summaries",
            "Unlimited AI Explanations",
          ].map((text, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: colors.ossyBlue1,
                  width: 8,
                  height: 8,
                  marginRight: 16,
                  borderRadius: 1000,
                }}
              />
              <p style={{ fontSize: 14, lineHeight: 2, fontWeight: 500 }}>
                {text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OssyPlusPaywall;
