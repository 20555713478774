import { useDispatch, useSelector } from "react-redux";
import { goToContent, editTranscriptTitle } from "../transcriptSlice";
import { borderRadius, colors, formatDate } from "../utility/utility";

import React, { useEffect, useRef, useState } from "react";
import {
  LogCategory,
  logger,
  contentTypes,
  rightClickTypes,
} from "../utility/utility.mjs";
import { ellipsis } from "../assets/ellipsis2";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillSound } from "react-icons/ai";
import { BsFileEarmarkText } from "react-icons/bs";

const Transcript = ({
  index,
  editingTranscriptTitle,
  setEditingTranscriptTitle,
  setPoints,
  isFolderSelected,
  supabase,
  smallWindow,
  transcriptTitleEditIndex,
  setTranscriptTitleEditIndex,
  rightClickObject: {
    type: rightClickType,
    index: rightClickIndex,
    thing,
    active,
  },
  setRightClickObject,
  content,
  condensed,
}) => {
  const contentsArray = useSelector((state) => state.routes.contentsArray);
  const rightClickedTranscript = rightClickType === rightClickTypes.TRANSCRIPT;
  const [transcriptPreview, setTranscriptPreview] = useState("");

  const folderUrlToName = useSelector((state) => state.routes.folderUrlToName);

  const [transcriptPaidPreview, setTranscriptPaidPreview] = useState("");

  const ellipsisRef = useRef(null);

  const hoveringEllipsis = useRef(false);

  const {
    title,
    contentArray: contentArray,
    folderUrl,
    creationDate,
    paidBlurbIndex,
    pageUrl,
    audioUrl,
    videoUrl,
  } = content;

  useEffect(() => {
    if (paidBlurbIndex !== -1) {
      const freeTranscriptText = contentArray
        .slice(0, paidBlurbIndex)
        .join(" ");
      const paidTranscriptText = contentArray.slice(paidBlurbIndex).join(" ");
      const newTranscriptPreview = freeTranscriptText.slice(0, 800);

      if (freeTranscriptText.length < 800) {
        const remainingChars = 800 - newTranscriptPreview.length;
        const newPaidTranscriptPreview = paidTranscriptText.slice(
          0,
          remainingChars
        );
        setTranscriptPaidPreview(newPaidTranscriptPreview);
      }

      setTranscriptPreview(newTranscriptPreview);
    } else {
      setTranscriptPreview(contentArray.join(" ").slice(0, 800));
    }
  }, [paidBlurbIndex, contentArray]);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }

    return () => window.MathJax.typesetClear();
  }, [transcriptPreview, transcriptPaidPreview]);

  const darkMode = useSelector((state) => state.routes.darkMode);
  const editingThisTitle = JSON.parse(transcriptTitleEditIndex) === index;
  const TextType = editingTranscriptTitle && editingThisTitle ? "input" : "div";
  const dispatch = useDispatch();
  const dragImageRef = useRef(null);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text", index);
    // Set the drag image content
    const dragImage = dragImageRef.current;
    dragImage.textContent = title;
    dragImage.style.display = "block";

    // Adjust styles if needed (e.g., font size, padding)
    dragImage.style.backgroundColor = darkMode ? "gray" : "lightgray"; // Set a solid background color
    // dragImage.style.fontSize = "16px";
    dragImage.style.padding = "8px";
    // dragImage.style.borderRadius = 6;

    // Use the custom element as the drag image
    event.dataTransfer.setDragImage(dragImage, 0, 0);

    // Optionally hide the drag image element again after a short delay
    setTimeout(() => {
      dragImage.style.display = "none";
    }, 0);
  };

  const defaultBgColor = darkMode ? "#2f2f2f" : "white";
  const hoverBgColor = darkMode ? "#454545" : "white";
  const hoverShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.2)";
  const defaultShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.13)";
  const [contextMenu, setContextMenu] = useState(
    rightClickedTranscript && rightClickIndex === index && active
  );

  useEffect(() => {
    setContextMenu(
      rightClickedTranscript && rightClickIndex === index && active
    );
  }, [rightClickedTranscript, rightClickIndex, index, active]);

  let TranscriptIcon;
  let iconColor;

  if (videoUrl) {
    TranscriptIcon = IoLogoYoutube;
    iconColor = "red";
  } else if (audioUrl) {
    TranscriptIcon = AiFillSound;
    iconColor = colors.ossyBlue1;
  } else {
    TranscriptIcon = BsFileEarmarkText;
    iconColor = colors.ossyBlue1;
  }

  return (
    <div
      style={{
        height: condensed ? undefined : smallWindow ? 200 : 240,
        width: "100%",
        //   width: "100%",
        borderRadius: 6,
        cursor: "pointer",
        float: "left",
        display: "flex",
        flexDirection: "column",
        backgroundColor: contextMenu ? hoverBgColor : defaultBgColor,
        boxShadow: contextMenu ? hoverShadow : defaultShadow,
      }}
      data-testid={`transcript ${index}`}
      title={title}
      // users have reported that it is frustrating not being able to select the
      // title and rename it, so disable dragging when title is being edited
      draggable={!editingThisTitle ? "true" : undefined}
      onDragStart={!editingThisTitle ? handleDragStart : undefined}
      onDragEnd={(event) => event.currentTarget.classList.remove("highlight")}
      onMouseEnter={(event) => {
        event.currentTarget.style.backgroundColor = hoverBgColor;
        event.currentTarget.style.boxShadow = hoverShadow;
        ellipsisRef.current.style.display = "flex";
        ellipsisRef.current.style.backgroundColor = hoverBgColor;
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.backgroundColor = contextMenu
          ? hoverBgColor
          : defaultBgColor;
        event.currentTarget.style.boxShadow = contextMenu
          ? hoverShadow
          : defaultShadow;
        ellipsisRef.current.style.display =
          rightClickedTranscript && rightClickIndex === index && active
            ? "flex"
            : "none";
        ellipsisRef.current.style.backgroundColor =
          rightClickedTranscript && rightClickIndex === index && active
            ? hoverBgColor
            : defaultBgColor;
      }}
      onClick={() => {
        if (hoveringEllipsis.current) {
          return;
        }

        dispatch(
          goToContent({
            contentPathName: pageUrl,
            newTab: true,
            contentType: contentTypes.TRANSCRIPT,
          })
        );
      }}
      id={`transcript ${index}`}
      onContextMenu={(event) => {
        event.preventDefault();
        setPoints({ x: event.pageX, y: event.pageY });
        setRightClickObject({
          type: contentTypes.TRANSCRIPT,
          index,
          thing: contentsArray[index],
          active: true,
        });
      }}
    >
      <div
        ref={dragImageRef}
        style={{
          display: "none",
          position: "absolute",
          zIndex: "-1",
          borderRadius: borderRadius,
          fontSize: 14,
        }}
      >
        {/* This element will be used as the drag image */}
      </div>
      {/* show some of the text of the transcript */}

      {!condensed && (
        <>
          <div
            style={{
              overflow: "hidden",
              padding: 8,
              flex: 4,
              lineHeight: 0.7,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
            }}
          >
            <span
              style={{
                fontSize: 11,
                margin: 0,
                padding: 0,
                color: darkMode ? "white" : "gray",
                fontWeight: 400,
              }}
            >
              {/* only show the first 400 characters, significant performance improvements but same result */}
              {transcriptPreview}
            </span>
            <span
              style={{
                fontSize: 11,
                margin: 0,
                padding: 0,
                color: darkMode ? "white" : "gray",
                fontWeight: 400,
                filter: "blur(3px)",
              }}
            >
              {` ${transcriptPaidPreview}`}
            </span>
          </div>

          <div
            style={{
              backgroundColor: darkMode ? "gray" : colors.lightGray,
              height: 0.5,
            }}
          />
        </>
      )}

      <div
        style={{
          flex: 1,
          // width: "100%",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          height: "100%",
          fontSize: 14,
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          <TextType
            style={{
              fontWeight: 500,
              // height: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              border: "none",
              fontSize: 14,
              fontFamily: "poppins",
              padding: 0,
              margin: 0,
              textAlign: "start",
              display: "flex",
              alignItems: "flex-start",
              lineHeight: "1.5em",
              maxHeight: "5em",
              whiteSpace: "nowrap",
              background: "transparent",
              color: darkMode ? "white" : "black",
            }}
            data-testid="transcript title input"
            autoFocus={true}
            defaultValue={title}
            onKeyDown={(event) => {
              if (["Enter", "Escape"].includes(event.key)) {
                event.currentTarget.blur();
              }
            }}
            onBlur={(event) => {
              setEditingTranscriptTitle(false);
              setTranscriptTitleEditIndex(-1);
              if (
                event.currentTarget.value !== "" &&
                event.currentTarget.value !== title
              ) {
                dispatch(
                  editTranscriptTitle({
                    supabase: supabase,
                    newTitle: event.currentTarget.value,
                    index: index,
                  })
                );
              }
            }}
          >
            {TextType === "input" ? null : (
              <div
                style={{ display: "flex", alignItems: "center", gap: 4 }}
                data-testid="transcript title text"
              >
                <TranscriptIcon
                  color={iconColor}
                  style={{ minWidth: 14, maxWidth: 14 }}
                />
                {title}
              </div>
            )}
          </TextType>

          {/* Triple dots for more: ... */}
          <div
            ref={ellipsisRef}
            style={{
              display:
                rightClickedTranscript && rightClickIndex === index && active
                  ? "flex"
                  : "none",
              alignItems: "center",
              color: darkMode ? "white" : "gray",
              backgroundColor:
                rightClickedTranscript && rightClickIndex === index && active
                  ? hoverBgColor
                  : defaultBgColor,
              height: "100%",
              paddingLeft: 8,
              position: "absolute",
              right: 0,
            }}
            onClick={(event) => {
              event.preventDefault();
              logger(
                [LogCategory.DEBUG],
                `YOU JUST CLICKED ON A transcript: ${event.pageX}, ${event.pageY}`
              );
              setPoints({ x: event.pageX, y: event.pageY + 8 });
              setRightClickObject({
                type: contentTypes.TRANSCRIPT,
                index,
                thing: content,
                active: true,
              });
            }}
            onMouseLeave={(event) => {
              hoveringEllipsis.current = false;
            }}
            onMouseEnter={(event) => {
              hoveringEllipsis.current = true;
            }}
          >
            {ellipsis({
              color: darkMode ? "white" : "gray",
              size: 20,
            })}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: 400,
            fontSize: 12,
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontWeight: 300,
              fontSize: 12,
              whiteSpace: "nowrap",
              marginRight: 10,
            }}
          >
            {/* October 20, 2023 */}
            {formatDate(creationDate)}
          </p>
          {!isFolderSelected && (
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {folderUrlToName[folderUrl]}
            </p>
          )}
        </div>
      </div>
    </div>
    // </a>
  );
};
export default Transcript;
