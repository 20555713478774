import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const Header = ({
  left,
  center,
  right,
  line,
  height,
  color,
  fixed,
  ignoreDarkMode = false,
  zIndex = undefined,
  showLeft = true,
  marginInside = 20,
}) => {
  let darkMode = useSelector((state) => state.routes.darkMode);
  if (ignoreDarkMode) darkMode = false;
  const bgColor = darkMode ? colors.black : "white";

  return (
    <div
      style={{
        width: "100%",
        top: 0,
        position: fixed ? "fixed" : "static",
        zIndex: zIndex,
        padding: 0,
        margin: 0,
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: color === undefined ? bgColor : color,
          display: "flex",
          height: height ? height : 60,
          width: `calc(100% - ${marginInside * 2}px)`,
          gap: 8,
          paddingLeft: marginInside,
          paddingRight: marginInside,
        }}
      >
        {/* left header */}
        <div
          style={{
            alignItems: "center",
            display: showLeft ? "flex" : "none",
            float: "left",
            height: "100%",
            maxWidth: "fit-content",
            minWidth: "fit-content",
            width: "fit-content",
          }}
        >
          {left}
        </div>

        {/* center header */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            height: "100%",
            alignItems: "center",
          }}
        >
          {center}
        </div>

        {/* right header */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            float: "left",
            height: "100%",
            justifyContent: "flex-end",
            margin: 0,
            maxWidth: "fit-content",
            minWidth: "fit-content",
            padding: 0,
            width: "fit-content",
          }}
        >
          {right}
        </div>
      </div>

      {/* border line */}
      {line && (
        <div
          style={{
            backgroundColor: darkMode ? colors.gray1 : colors.gray,
            height: 0.5,
          }}
        />
      )}
    </div>
  );
};

export default Header;
