import Header from "./Header";
import { SearchBar, ProfileButton, OssyText, OssyLogo, Button } from "./";
import { useState } from "react";
import { useSelector } from "react-redux";

const HomeHeader = ({
  smallWindow,
  setMenuOpen,
  supabase,
  search = true,
  showSale = true,
}) => {
  const [menuColor, setMenuColor] = useState("gray");

  return (
    <Header
      left={
        smallWindow ? (
          <>
            <div
              style={{
                cursor: "pointer",
                borderRadius: 6,
                width: 22,
                height: 20,
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                justifyContent: "space-between",
                flexDirection: "column",
                padding: 0,
              }}
              onClick={() => setMenuOpen(true)}
              onMouseEnter={() => setMenuColor("lightgray")}
              onMouseLeave={() => setMenuColor("gray")}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
            </div>
          </>
        ) : (
          // show ossy in header if large window
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OssyLogo width={25} marginRight={4} />
            <OssyText big />
          </div>
        )
      }
      center={search ? <SearchBar supabase={supabase} /> : undefined}
      right={
        // profile button in top right
        <div
          style={{
            color: "black",
            // alignSelf: "center",
            // alignItems: "center",
            right: 0,
            fontSize: 12,
            display: "flex",
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          <ProfileButton removeNameWhenSmall={768} includeName={true} />
        </div>
      }
      line={true}
    />
  );
};

export default HomeHeader;
