import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { initialState } from "./data/initialState";
import { v4 as uuidv4 } from "uuid";
import {
  getDate,
  logger,
  LogCategory,
  months,
  apiEndpoint,
  formatTimestamp,
  blurbState,
  displayBlurbState,
  removeBlurbState,
  transcriptsType,
  filterTranscriptArray,
  unformatTimestamp,
  contentTypes,
  databaseInfo,
  transcriptModeTypes,
  getIsNewTranscriptUrl,
  sqlQueries,
  claimFreeHourStatus,
} from "./utility/utility.mjs";
import senders from "./features/professorOssy/components/senders.json";
import { startOfWeek, endOfWeek } from "date-fns";
import * as amplitude from "@amplitude/analytics-browser";

const TRANSCRIPTION_COST_PER_SECOND_REV_AI_CENTS = 0.02 / 60;

// updating the user's google data (new users or new email, user_id, or names)
export const updateGoogleData = createAsyncThunk(
  "users/updateGoogleData",
  async (supabase, { getState }) => {
    const { userId, firstName, lastName, email } = getState().routes;
    logger([LogCategory.INFO], "updating user info");
    // if the user's personal info changed, update it in the database
    const { data: upsertNewData, error: upsertNewError } = await supabase
      .from("user data")
      .upsert(
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          user_id: userId,
        },
        { onConflict: "user_id", ignoreDuplicates: false }
      )
      .select();

    if (upsertNewError) {
      logger(
        [LogCategory.ERROR],
        "upsert error: " + JSON.stringify(upsertNewError)
      );
    }
  }
);

export const updateMessagesBackend = createAsyncThunk(
  "users/updateMessagesBackend",
  async ({ supabase, messages, contentType }, { getState }) => {
    logger(
      `saving messages to supabase. contentId: ${
        getState().routes[contentType.name].id
      }`
    );
    logger(messages);
    logger(contentType);

    const { data, error } = await supabase
      .from(databaseInfo[contentType.databaseObjKey].messagesTable)
      .update({
        [databaseInfo[contentType.databaseObjKey].tableMessages]: messages,
      })
      .eq(
        databaseInfo[contentType.databaseObjKey].tableId,
        getState().routes[contentType.name].id
      )
      .eq("user_id", getState().routes.userId);

    if (error) {
      console.error("Error updating the messages in the backend: ", error);
    }
    return { messages };
  }
);

const getDeviceType = () => {
  let mobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      mobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return mobile ? "mobile device" : "computer/ipad";
};

export const inviteEmailsAndAddFreeHour = createAsyncThunk(
  "users/inviteEmailsAndAddFreeHour",
  async ({ supabase, emails }, { dispatch, getState }) => {
    for (const inviteeEmail of emails) {
      let response = await (
        await fetch(`${apiEndpoint}/invite_email_to_ossy`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            inviteeEmail,
            inviterUserId: getState().routes.userId,
          }),
        })
      ).json();
      if (response.success) {
        logger("successfully sent invitiation email");
      } else {
        console.error(response.error);
      }
    }

    let newPaidBlurbIndex = -1;

    // if we are currently viewing a blurred transcript, unblur up to 1 more hour
    if (getState().routes.transcript.id !== initialState.transcript.id) {
      const { paidBlurbIndex } = dispatch(findPaidBlurbIndex({ supabase }));
      newPaidBlurbIndex = paidBlurbIndex;
      supabase
        .from("transcripts")
        .update({ paid_blurb_index: newPaidBlurbIndex })
        .eq("transcript_id", getState().routes.transcript.id);
    }

    return { success: true, paidBlurbIndex: newPaidBlurbIndex };
  }
);

/**
 * When 3 emails are entered to gain 1 free hour, we want to update the paid blurb index to give the user 1 more hour
 * @param {*} timestamps
 * @returns
 */
export const findPaidBlurbIndex = createAsyncThunk(
  "users/findPaidBlurbIndex",
  async ({ supabase }, { dispatch, getState }) => {
    const weekTranscriptionTime = dispatch(
      getWeekAudioDuration({ supabase, includeCurrent: false })
    );
    const weekTranscriptionLimitSeconds =
      getState().routes.weekTranscriptionLimitSeconds;
    const timestamps = getState().routes.transcript.timestamps.length;

    let paidBlurbIndex = -1;
    for (let i = 0; i < timestamps; i++) {
      if (
        weekTranscriptionTime + timestamps[i] >
        weekTranscriptionLimitSeconds
      ) {
        paidBlurbIndex = i;
        break;
      }
    }
    return { paidBlurbIndex };
  }
);

export const dropOssyPlus = createAsyncThunk("users/dropOssyPlus", async () => {
  // move them to a paid user
  return { isPaidUser: false };
});

export const updateEmailPreferences = createAsyncThunk(
  "users/updateEmailPreferences",
  async ({ supabase, newsletters, sales }, { getState, dispatch }) => {
    const emailPreferences = { ...getState().routes.emailPreferences };
    logger(`old prefs: ${JSON.stringify(emailPreferences)}`);
    if (newsletters !== undefined) {
      emailPreferences.newsletters = newsletters;
    }
    if (sales !== undefined) {
      emailPreferences.sales = sales;
    }

    // update email preferences locally
    dispatch(
      updateEmailPreferencesLocal({ emailPreferences: emailPreferences })
    );

    // update email preferences backend
    supabase
      .from("user data")
      .update({
        email_preferences: emailPreferences,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const handleFileUpload = createAsyncThunk(
  "users/handleFileUpload",
  async ({ supabase }) => {}
);

export const fetchFolder = createAsyncThunk(
  "users/fetchFolder",
  async ({ supabase, folderUrl }, { dispatch, getState }) => {
    // get the folder
    const { data: folderData, error: selectFolderDataError } = await supabase
      .from("folders")
      .select(
        "folder_id, folder_url, folder_privacy, folder_name, deleted, user_id"
      )
      .eq("folder_url", folderUrl);

    logger("we're not making it here I guess");
    if (selectFolderDataError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching transcript: " +
          JSON.stringify(selectFolderDataError)
      );
    }

    // couldn't find any matches; this folder doesn't exist
    if (folderData.length === 0) {
      logger([LogCategory.DEBUG], "couldn't find the requested folder");
      return initialState.FILE_DOES_NOT_EXIST;
    }

    const {
      folder_id,
      folder_url,
      folder_privacy,
      folder_name,
      user_id,
      deleted,
    } = folderData[0];
    let isFolderSaved = false;

    logger(folderData);

    // couldn't find any matches; this folder doesn't exist
    if (deleted) {
      // logger([LogCategory.DEBUG], "couldn't find the requested folder");
      return initialState.FILE_HAS_BEEN_DELETED;
    }

    // user is accessing a folder they don't own
    if (user_id !== getState().routes.userId) {
      // folder is private, can't access
      if (folder_privacy) {
        logger([LogCategory.DEBUG], "tried to access a private folder");
        return initialState.FILE_IS_PRIVATE;

        // folder is public, can access
      } else {
        // get the ids of the saved transcripts
        const {
          data: [{ saved_folder_ids: savedFolderIds }],
        } = await supabase
          .from("user data")
          .select("saved_folder_ids")
          .eq("user_id", getState().routes.userId);

        // this folder is saved by the user
        if (savedFolderIds.includes(folder_id)) {
          isFolderSaved = true;
        }
      }
    }

    return {
      folder: {
        id: folder_id,
        folderName: folder_name,
        folderUrl: folder_url,
        folderMessages: initialState.folder.folderMessages,
        isPrivate: folder_privacy,
        isSaved: isFolderSaved,
      },
      isOwnerOfFolder: user_id === getState().routes.userId,
    };
  }
);

const getTranscriptIdsToExpenses = async ({ supabase }) => {
  const { data: transcriptExpenses, error } = await supabase
    .from("transcript_expenses")
    .select("transcript_id, merge_blurbs, autocorrect, parse_latex, gpt_json");

  const transcriptIdsToExpenses = {};

  for (const {
    transcript_id,
    merge_blurbs,
    autocorrect,
    parse_latex,
    gpt_json,
  } of transcriptExpenses) {
    const {
      num_calls: num_calls0,
      total_input_tokens: input_tokens_1,
      total_output_tokens: output_tokens_1,
    } = merge_blurbs;
    const {
      num_calls: num_calls1,
      total_input_tokens: input_tokens_2,
      total_output_tokens: output_tokens_2,
    } = autocorrect;
    const {
      num_calls: num_calls2,
      total_input_tokens: input_tokens_3,
      total_output_tokens: output_tokens_3,
    } = parse_latex;
    const {
      num_calls: num_calls3,
      total_input_tokens: input_tokens_4,
      total_output_tokens: output_tokens_4,
    } = gpt_json;

    const total_input_tokens =
      input_tokens_1 + input_tokens_2 + input_tokens_3 + input_tokens_4;
    const total_output_tokens =
      output_tokens_1 + output_tokens_2 + output_tokens_3 + output_tokens_4;
    const GPT_4o_MINI_INPUT_COST_PER_INPUT_TOKEN = 0.15 / 1000000;
    const GPT_4o_MINI_INPUT_COST_PER_OUTPUT_TOKEN = 0.6 / 1000000;

    transcriptIdsToExpenses[transcript_id] =
      total_input_tokens * GPT_4o_MINI_INPUT_COST_PER_INPUT_TOKEN +
      total_output_tokens * GPT_4o_MINI_INPUT_COST_PER_OUTPUT_TOKEN;
  }

  return transcriptIdsToExpenses;
};

const getDailyExpenditure = async ({ supabase }) => {
  try {
    const dateToExpenditures = {};
    const DB_START_LOGGING_EXPENSES = "2024-05-21T00:00:00";

    // get all expenses
    const { data: allTranscripts, error: errorTranscripts } = await supabase
      .from("transcripts")
      .select(
        "created_at, timestamps, debug_json, audio_duration"
        // "created_at, timestamps, audio_duration, json_extract_path_text(merge_blurb_inputs, 'inputTokens')"
      )
      .order("creation_date", { ascending: true })
      .order("created_at", { ascending: true });
    // .gte("created_at", DB_START_LOGGING_EXPENSES);

    // console.log(errorTranscripts);
    // console.log(`where the fuck did this go:`);

    let totalAllTimeTranscriptionCost = 0;

    for (const {
      created_at,
      merge_blurbs_inputs,
      autocorrect_inputs,
      audio_duration,
      debug_json,
      timestamps,
    } of allTranscripts) {
      if (debug_json.uploadVideo) {
        continue;
      }

      let totalTranscriptCost = 0;

      let transcriptDurationSeconds = audio_duration ?? timestamps.at(-1) ?? 0;

      // rev ai imposes a 15 second minimum
      transcriptDurationSeconds = Math.max(transcriptDurationSeconds, 15);

      let rawTranscriptionCost =
        transcriptDurationSeconds * TRANSCRIPTION_COST_PER_SECOND_REV_AI_CENTS;

      totalTranscriptCost += rawTranscriptionCost;

      const createdDate = new Date(created_at);
      const dateString = `${
        createdDate.getMonth() + 1
      }/${createdDate.getDate()}`;

      dateToExpenditures[dateString] = dateToExpenditures[created_at] ?? 0;
      dateToExpenditures[dateString] += totalTranscriptCost;

      totalAllTimeTranscriptionCost += totalTranscriptCost;
    }

    return {
      daysExpenditure: Object.keys(dateToExpenditures),
      expenditureByDay: Object.values(dateToExpenditures),
    };
  } catch (e) {
    console.error(e);
  }
};

export const fetchAnalytics = createAsyncThunk(
  "analytics/fetchAnalytics",
  async ({ supabase }, { dispatch, getState }) => {
    try {
      logger([LogCategory.INFO], `fetching analytics...`);

      // get all users data
      const { data: allUsers, error: error1 } = await supabase
        .from("user data")
        .select(
          "created_at, first_name, email, last_name, user_id, folders, dark_mode, email_preferences, paid_user, saved_transcript_ids, saved_content_ids, paused_subscription, payment_history"
        )
        .order("created_at", { ascending: true });

      logger(`got all users`);

      const OSSY_TEAM_USER_IDS = [
        "108781556780827791335",
        "107078028757205445398",
        "100506880516048180169",
        "106927638594237061682",
        "102632499383452397900",
        "113552740858223115582",
        "116624764323219026842",
        "114001338352660678799",
      ];

      const DB_START_DATE_ISO_STR = "2023-10-14T00:00:00";
      const todaysDate = new Date();
      const oneDayAgo = new Date(todaysDate.getTime() - 24 * 60 * 60000);
      const fiveMinutesAgo = new Date(todaysDate.getTime() - 5 * 60000);
      const twoWeeksAgo = new Date(
        // 14 days in milliseconds
        todaysDate.getTime() - 14 * 24 * 60 * 60 * 1000
      );
      const twoWeeksAgoISO = twoWeeksAgo.toISOString();

      const paidUsers = [];
      const paidUserById = {};
      for (const { user_id, paid_user, first_name, last_name } of allUsers) {
        const fullName = first_name + " " + last_name;

        paidUserById[user_id] = paid_user;
        if (paid_user && !OSSY_TEAM_USER_IDS.includes(user_id)) {
          paidUsers.push(fullName);
        }
      }

      // set total number of users
      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "totalNumUsers",
              value: allUsers.length,
            },
            {
              data: "paidUsers",
              value: paidUsers,
            },
          ],
        })
      );

      const transcriptIdsToExpensesPromise = getTranscriptIdsToExpenses({
        supabase,
      });

      // get active users (last 2 weeks, last 24 hours, and last 5 minutes)
      const {
        activeUsersIds,
        dailyActiveUsersIds,
        currentlyActiveUserIds,
        transcriptsVisitations,
      } = await getActiveUsers({
        supabase,
        twoWeeksAgoISO,
        oneDayAgo,
        fiveMinutesAgo,
        OSSY_TEAM_USER_IDS,
      });

      // total number of active users
      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "totalNumActiveUsers",
              value: activeUsersIds.length,
            },
            {
              data: "transcriptsVisitations",
              value: transcriptsVisitations,
            },
          ],
        })
      );

      let transcriptCount = 0;
      let avgTranscriptLength = 0;

      const professorOssyQuestions = {};
      let percentProfessorOssy = 0;

      // get all transcripts
      const { data: allTranscripts, error: errorTranscripts } = await supabase
        .from("transcripts")
        .select(
          "created_at, timestamps, audio_url, user_id, debug_json, transcript_privacy, creation_date, audio_duration, transcript_id"
        )
        .order("creation_date", { ascending: true })
        .order("created_at", { ascending: true });

      dispatch(
        updateAnalytics({
          stuff: [
            { data: "totalTranscriptCount", value: allTranscripts.length },
          ],
        })
      );

      const {
        data: allTranscriptsQuestions,
        error: fetchTranscriptsQuestionsError,
      } = await supabase
        .from("transcripts")
        .select("messages")
        .order("creation_date", { ascending: true })
        .order("created_at", { ascending: true });

      let numSaveAudio = 0;

      let dailyNewTranscripts = 0;
      const transcriptDates = [];
      const numTranscripts = [];

      // database begins at october 14 2023
      let startDateTranscripts = new Date(DB_START_DATE_ISO_STR);
      let endDateTranscripts = new Date(
        startDateTranscripts.getTime() + 24 * 60 * 60000
      );

      let transcriptCounter = 0;
      let dateStringTranscript = `${
        startDateTranscripts.getMonth() + 1
      }/${startDateTranscripts.getDate()}`;

      let autoPauseDayCounter = 0;
      let computerOrIpadCount = 0;

      let deviceCounter = 0;

      let uploadAudioTranscriptCounter = 0;
      let uploadAudioCounter = 0;

      let youTubeCounter = 0;
      let youTubeTranscriptCounter = 0;

      let transcriptLengthCounter = 0;
      let publicTranscriptCount = 0;

      const numAutoPausesList = [];
      logger([LogCategory.INFO], "...");

      const idsToUsers = {};

      const userIdsThatMadeFolders = await getUserIdsThatMadeFolders({
        supabase,
        OSSY_TEAM_USER_IDS,
      });

      const weeklyTranscriptionTime = {
        freeUsers: [],
        paidUsers: [],
      };

      let weekNumber = 0;
      let userIdToTranscriptionAmountForThisWeek = {};

      // week we are currently iterating on
      let currentWeek = new Date(DB_START_DATE_ISO_STR);

      const numTranscriptsPerType = {
        "YouTube Link": 0,
        "Live Transcription": 0,
        "Upload Audio": 0,
      };

      const transcriptIdsToExpenses = await transcriptIdsToExpensesPromise;

      for (let i = 0; i < allTranscripts.length; i++) {
        const {
          created_at,
          timestamps,
          audio_url,
          user_id,
          debug_json,
          transcript_privacy,
          creation_date,
          transcript_id,
        } = allTranscripts[i];

        const { messages } = allTranscriptsQuestions[i];

        const messagesUserAsked = messages.filter(
          ({ sender }) => sender === "You"
        );

        professorOssyQuestions[messagesUserAsked.length] =
          professorOssyQuestions[messagesUserAsked.length] ?? 0;
        professorOssyQuestions[messagesUserAsked.length] += 1;

        percentProfessorOssy += messagesUserAsked.length ? 1 : 0;

        let creationDateOfTranscript = new Date(created_at);

        if (creationDateOfTranscript > currentWeek) {
          // add all the points from this week to the graph
          for (let userId in userIdToTranscriptionAmountForThisWeek) {
            if (OSSY_TEAM_USER_IDS.includes(userId)) {
              continue;
            }
            const { weekNumber, minutesOfTranscription, paidUser } =
              userIdToTranscriptionAmountForThisWeek[userId];
            if (paidUser) {
              weeklyTranscriptionTime.paidUsers.push({
                x: weekNumber,
                y: Math.max(0, minutesOfTranscription / 60),
              });
            } else {
              weeklyTranscriptionTime.freeUsers.push({
                x: weekNumber,
                y: Math.max(0, minutesOfTranscription / 60),
              });
            }
          }

          // move to the next week
          weekNumber += 1;
          currentWeek.setDate(currentWeek.getDate() + 7);
          userIdToTranscriptionAmountForThisWeek = {};
        }

        idsToUsers[user_id] = idsToUsers[user_id] ?? {
          numTranscripts: 0,
          firstName: null,
          lastName: null,
          lifetimeTranscriptionAmount: 0,
          lifetimeGPTCost: 0,
        };

        idsToUsers[user_id].lifetimeGPTCost +=
          transcriptIdsToExpenses[transcript_id];

        idsToUsers[user_id].numTranscripts += 1;
        idsToUsers[user_id].lifetimeTranscriptionAmount += Math.max(
          0,
          timestamps.at(-1) ?? 0
        );

        // filter out empty transcripts
        if (timestamps.length === 0) {
          continue;
        }

        if (!userIdToTranscriptionAmountForThisWeek.hasOwnProperty(user_id)) {
          userIdToTranscriptionAmountForThisWeek[user_id] = {
            weekNumber: weekNumber,
            minutesOfTranscription: 0,
            paidUser: paidUserById[user_id],
          };
        }
        userIdToTranscriptionAmountForThisWeek[
          user_id
        ].minutesOfTranscription += timestamps.at(-1);

        // we need to filter ourselves out because we have created so many transcripts
        if (OSSY_TEAM_USER_IDS.includes(user_id)) {
          continue;
        }

        if (transcript_privacy === false) {
          publicTranscriptCount += 1;
        }

        let numAutoPauses = 0;
        if (debug_json.numAutoPauses !== undefined) {
          numAutoPauses = debug_json.numAutoPauses;
        }

        if (debug_json.uploadAudio !== undefined) {
          uploadAudioTranscriptCounter += 1;
          logger(
            [LogCategory.INFO],
            `upload audio transcript counter: ${uploadAudioTranscriptCounter}\nvalue: ${debug_json.uploadAudio}`
          );
          if (debug_json.uploadAudio === true) {
            uploadAudioCounter += 1;
            numTranscriptsPerType["Upload Audio"] += 1;
          }
        }

        if (debug_json.uploadVideo !== undefined) {
          youTubeTranscriptCounter += 1;
          if (debug_json.uploadVideo === true) {
            youTubeCounter += 1;
            numTranscriptsPerType["YouTube Link"] += 1;
          }
        }

        if (!debug_json.uploadAudio && !debug_json.uploadAudio) {
          numTranscriptsPerType["Live Transcription"] += 1;
        }

        logger([LogCategory.INFO], `device type: ${debug_json.deviceType}`);
        if (debug_json.deviceType === "computer/ipad") {
          computerOrIpadCount += 1;
          deviceCounter += 1;
        } else if (debug_json.deviceType === "mobile device") {
          deviceCounter += 1;
        }

        transcriptCount += 1;
        if (timestamps.at(-1) >= 0) {
          avgTranscriptLength += timestamps.at(-1);
          transcriptLengthCounter += 1;
        }
        numSaveAudio = audio_url === "" ? numSaveAudio : (numSaveAudio += 1);
        dailyNewTranscripts =
          creationDateOfTranscript >= oneDayAgo
            ? dailyNewTranscripts + 1
            : dailyNewTranscripts;

        // this date is greater than the end date,
        while (creationDateOfTranscript > endDateTranscripts) {
          // add the current value to the lists
          // usersCumulative.push((usersCumulative.at(-1) ?? 0) + userCount);
          numTranscripts.push(transcriptCounter);
          transcriptDates.push(dateStringTranscript);
          // datesCumulative.push(dateStringTranscript);

          numAutoPausesList.push(autoPauseDayCounter);

          transcriptCounter = 0;
          autoPauseDayCounter = 0;

          dateStringTranscript = `${
            startDateTranscripts.getMonth() + 1
          }/${startDateTranscripts.getDate()}`;

          startDateTranscripts.setDate(startDateTranscripts.getDate() + 1);
          endDateTranscripts.setDate(endDateTranscripts.getDate() + 1);
        }

        transcriptCounter += 1;
        logger([LogCategory.INFO], `num auto pauses: ${numAutoPauses}`);
        autoPauseDayCounter += numAutoPauses;
      }

      // add all the points from this week to the graph
      for (let userId in userIdToTranscriptionAmountForThisWeek) {
        const { weekNumber, minutesOfTranscription, paidUser } =
          userIdToTranscriptionAmountForThisWeek[userId];
        if (paidUser) {
          weeklyTranscriptionTime.paidUsers.push({
            x: weekNumber,
            y: Math.max(0, minutesOfTranscription / 60),
          });
        } else {
          weeklyTranscriptionTime.freeUsers.push({
            x: weekNumber,
            y: Math.max(0, minutesOfTranscription / 60),
          });
        }
      }

      // move to the next week
      weekNumber += 1;
      currentWeek.setDate(currentWeek.getDate() + 7);
      userIdToTranscriptionAmountForThisWeek = {};

      // add all the points from this week to the graph
      for (let userId in userIdToTranscriptionAmountForThisWeek) {
        const { weekNumber, minutesOfTranscription, paidUser } =
          userIdToTranscriptionAmountForThisWeek[userId];
        if (paidUser) {
          weeklyTranscriptionTime.paidUsers.push({
            x: weekNumber,
            y: Math.max(0, minutesOfTranscription / 60),
          });
        } else {
          weeklyTranscriptionTime.freeUsers.push({
            x: weekNumber,
            y: Math.max(0, minutesOfTranscription / 60),
          });
        }
      }

      // move to the next week
      weekNumber += 1;
      currentWeek.setDate(currentWeek.getDate() + 7);
      userIdToTranscriptionAmountForThisWeek = {};

      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "numTranscriptsPerType",
              value: {
                transcriptionTypes: Object.keys(numTranscriptsPerType),
                numberOfTranscriptsForType: Object.values(
                  numTranscriptsPerType
                ),
              },
            },
          ],
        })
      );

      const numTranscriptsToUserCount = {};
      for (const userId in idsToUsers) {
        const numTranscripts = Number(idsToUsers[userId].numTranscripts);
        numTranscriptsToUserCount[numTranscripts] =
          numTranscriptsToUserCount[numTranscripts] ?? 0;
        numTranscriptsToUserCount[numTranscripts] += 1;
      }

      const temp = [];
      for (const numTranscripts in numTranscriptsToUserCount) {
        temp.push({
          numTranscripts: Number(numTranscripts),
          userCount: numTranscriptsToUserCount[numTranscripts],
        });
      }

      temp.sort((a, b) => a.numTranscripts - b.numTranscripts);

      const numUsersToNumTranscripts = {
        numUsersThatMadeTranscripts: temp.map(({ userCount }) => userCount),
        numTranscriptsForNumUsers: temp.map(
          ({ numTranscripts }) => numTranscripts
        ),
      };

      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "numUsersToNumTranscripts",
              value: numUsersToNumTranscripts,
            },
          ],
        })
      );

      const dailyExpenditure = await getDailyExpenditure({ supabase });

      dispatch(
        updateAnalytics({
          stuff: [{ data: "dailyExpenditure", value: dailyExpenditure }],
        })
      );

      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "dailyExpenditure",
              value: dailyExpenditure,
            },
          ],
        })
      );

      numTranscripts.push(transcriptCounter);
      transcriptDates.push(dateStringTranscript);

      avgTranscriptLength /= transcriptLengthCounter;
      avgTranscriptLength /= 60;

      const professorOssyQuestionsPercent = [];
      const numsQuestions = Object.keys(professorOssyQuestions).slice(1);
      const numTranscriptsWithAQuestion = Object.values(professorOssyQuestions)
        .slice(1)
        .reduce((partialSum, a) => partialSum + a, 0);
      let counter = numTranscriptsWithAQuestion;
      for (let i = 0; i < numsQuestions.length; i++) {
        const questionNumber = numsQuestions[i];
        professorOssyQuestionsPercent[i] =
          (counter / numTranscriptsWithAQuestion) * 100;

        counter -= professorOssyQuestions[questionNumber];
      }

      dispatch(
        updateAnalytics({
          stuff: [
            { data: "weeklyTranscriptionTime", value: weeklyTranscriptionTime },
            { data: "autoPausesByDay", value: numAutoPausesList },
            {
              data: "transcriptsByDay",
              value: {
                dates: transcriptDates,
                numTranscripts: numTranscripts,
              },
            },
            { data: "dailyNewTranscripts", value: dailyNewTranscripts },
            {
              data: "percentSaveAudio",
              value: `${((numSaveAudio / transcriptCount) * 100).toFixed(2)}%`,
            },
            {
              data: "percentOnPhone",
              value: `${(
                100 -
                (computerOrIpadCount / deviceCounter) * 100
              ).toFixed(2)}%`,
            },
            {
              data: "uploadAudio",
              value: `${(
                (uploadAudioCounter / uploadAudioTranscriptCounter) *
                100
              ).toFixed(2)}%`,
            },
            {
              data: "youTube",
              value: `${(
                (youTubeCounter / youTubeTranscriptCounter) *
                100
              ).toFixed(2)}%`,
            },
            {
              data: "percentPublicTranscripts",
              value: `${(
                (publicTranscriptCount / transcriptCount) *
                100
              ).toFixed(2)}%`,
            },
            {
              data: "avgTranscriptLength",
              value: avgTranscriptLength.toFixed(2),
            },
            { data: "professorOssyQuestions", value: professorOssyQuestions },
            {
              data: "professorOssyQuestionsPercent",
              value: {
                numQuestions: numsQuestions,
                percentTranscriptsEqualOrGreater: professorOssyQuestionsPercent,
              },
            },
            {
              data: "percentProfessorOssy",
              value: `${(
                (percentProfessorOssy / transcriptCount) *
                100
              ).toFixed(2)}%`,
            },
          ],
        })
      );

      // number of new users per day
      const numUsers = [];
      const dates = [];
      const usersCumulative = [];
      const datesCumulative = [];
      const currentlyActiveUsers = [];

      // database begins at october 14 2023
      let startDate = new Date(DB_START_DATE_ISO_STR);
      let endDate = new Date("2023-10-15T00:00:00");
      let userDayCount = 0;
      let dateString = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
      let activeNotNewUserIds = [...activeUsersIds];

      const activeNotNewUsers = [];
      const dailyActiveUsers = [];

      let numDarkMode = 0;
      let userCount = 0;

      const weeks = [];
      const retentionRates = [];
      const weekNewUserCounts = [];
      const weekActiveUserCounts = [];
      let weekNewUserCount = 0;
      let weekActiveUserCount = 0;

      // sunday, october 15, 2023
      let weekStartDate = new Date("2023-10-15T00:00:00");
      let weekEndDate = new Date("2023-10-22T00:00:00");

      for (const {
        created_at,
        user_id,
        paid_user,
        email,
        first_name,
        last_name,
        dark_mode,
        folders,
        payment_history,
        email_preferences,
      } of allUsers) {
        const creationDateOfUser = new Date(created_at);
        const fullName = first_name + " " + last_name;

        userCount += 1;

        logger([LogCategory.INFO], "another suser");
        idsToUsers[user_id] = idsToUsers[user_id] ?? {};

        idsToUsers[user_id].firstName = first_name;
        idsToUsers[user_id].lastName = last_name;
        idsToUsers[user_id].isPaidUser = paid_user;
        idsToUsers[user_id].paymentHistory = payment_history;

        // this is the day we started tracking transcript expenses.
        // if user was created after this date but has no entries in the transcript expenses table,
        // we need to initialize their lifetimeGPTCost and lifetimeTranscriptionAmount to 0
        if (creationDateOfUser > new Date("2024-06-12")) {
          if (idsToUsers[user_id].lifetimeGPTCost === undefined) {
            idsToUsers[user_id].lifetimeGPTCost = 0;
          }
          if (idsToUsers[user_id].lifetimeTranscriptionAmount === undefined) {
            idsToUsers[user_id].lifetimeTranscriptionAmount = 0;
          }
        }
        if (dark_mode === true) {
          numDarkMode += 1;
        }

        // user falls in the week range
        if (
          weekStartDate <= creationDateOfUser &&
          creationDateOfUser < weekEndDate
        ) {
          // move to the next week
        } else if (creationDateOfUser > weekEndDate) {
          weeks.push(
            `${
              months[weekStartDate.getMonth()]
            } ${weekStartDate.getDate()}-${weekEndDate.getDate()}`
          );
          retentionRates.push(
            ((weekActiveUserCount / weekNewUserCount) * 100).toFixed(2)
          );

          weekNewUserCounts.push(weekNewUserCount);
          weekActiveUserCounts.push(weekActiveUserCount);

          weekStartDate = new Date(
            weekStartDate.getTime() + 24 * 60 * 60000 * 7
          );
          weekEndDate = new Date(weekEndDate.getTime() + 24 * 60 * 60000 * 7);
          weekActiveUserCount = 0;
          weekNewUserCount = 0;
        }

        weekNewUserCount += 1;
        // the user is still active
        if (activeUsersIds.includes(user_id)) {
          weekActiveUserCount += 1;
        }

        // update number of active users that are not new
        if (
          activeNotNewUserIds.includes(user_id) &&
          creationDateOfUser > twoWeeksAgo
        ) {
          activeNotNewUserIds = activeNotNewUserIds.filter(
            (userId) => userId !== user_id
          );
        } else if (activeNotNewUserIds.includes(user_id)) {
          activeNotNewUsers.push(fullName);
        }

        if (currentlyActiveUserIds.includes(user_id)) {
          currentlyActiveUsers.push(fullName);
        }

        if (dailyActiveUsersIds.includes(user_id)) {
          dailyActiveUsers.push(fullName);
        }

        // this date is greater than the end date,
        while (creationDateOfUser > endDate) {
          // add the current value to the lists
          usersCumulative.push((usersCumulative.at(-1) ?? 0) + userDayCount);
          numUsers.push(userDayCount);
          dates.push(dateString);
          datesCumulative.push(dateString);

          userDayCount = 0;
          dateString = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        }

        userDayCount += 1;
      }

      let avgLifetimeCostFreeUser = 0;
      let freeUserCountExpenses = 0;
      let paidUserCountExpenses = 0;
      let avgLifetimeCostPaidUser = 0;
      let paidUserCount = 0;

      let paymentHistoryUserCount = 0;
      let paymentCount = 0;
      let avgLifetimeTranscriptionDuration = 0;

      const topUsers = [];
      for (let id in idsToUsers) {
        logger([LogCategory.INFO], "in id to num transcripts loop");
        const user = { ...initialState.user };
        user.userId = id;
        user.numTranscriptsCreated = idsToUsers[id].numTranscripts;
        user.firstName = idsToUsers[id].firstName;
        user.lastName = idsToUsers[id].lastName;
        user.lifetimeTranscriptionAmount =
          idsToUsers[id].lifetimeTranscriptionAmount;
        user.lifetimeGPTCost = idsToUsers[id].lifetimeGPTCost;
        topUsers.push(user);

        if (idsToUsers[id].isPaidUser) {
          paidUserCount += 1;
        }

        if (!OSSY_TEAM_USER_IDS.includes(id)) {
          avgLifetimeTranscriptionDuration +=
            idsToUsers[id].lifetimeTranscriptionAmount ?? 0;
        }
        console.log(idsToUsers[id].lifetimeGPTCost);
        console.log(idsToUsers[id].lifetimeTranscriptionAmount);

        const notWhatWeWant = (x) => {
          return [undefined, null, NaN].includes(x);
        };

        if (
          !notWhatWeWant(idsToUsers[id].lifetimeGPTCost) &&
          !notWhatWeWant(idsToUsers[id].lifetimeTranscriptionAmount) &&
          !OSSY_TEAM_USER_IDS.includes(id)
        ) {
          console.log("made it in!!!");
          console.log(idsToUsers[id].lifetimeGPTCost);
          console.log(idsToUsers[id].lifetimeTranscriptionAmount);

          if (idsToUsers[id].isPaidUser) {
            avgLifetimeCostPaidUser -= idsToUsers[id].lifetimeGPTCost;
            avgLifetimeCostPaidUser -=
              idsToUsers[id].lifetimeTranscriptionAmount *
              TRANSCRIPTION_COST_PER_SECOND_REV_AI_CENTS;

            avgLifetimeCostPaidUser +=
              idsToUsers[id].paymentHistory.length * 10.25;
            paidUserCountExpenses += 1;
          } else {
            avgLifetimeCostFreeUser -= idsToUsers[id].lifetimeGPTCost;
            avgLifetimeCostFreeUser -=
              idsToUsers[id].lifetimeTranscriptionAmount *
              TRANSCRIPTION_COST_PER_SECOND_REV_AI_CENTS;
            freeUserCountExpenses += 1;
            avgLifetimeCostFreeUser +=
              idsToUsers[id].paymentHistory.length * 10.25;
          }
        }

        if (
          idsToUsers[id].paymentHistory.length > 0 &&
          !OSSY_TEAM_USER_IDS.includes(id)
        ) {
          paymentHistoryUserCount += 1;
          paymentCount += idsToUsers[id].paymentHistory.length;
        }
      }
      // -$2.57, $0.81, -$2.49
      // -$2.57, -$9.44, -$2.73

      const formatCost = (cost) => {
        const costTwoDecimals = cost.toFixed(2);
        const costWithCurrencySign = `$${Math.abs(costTwoDecimals)}`;

        let costFormatted = costWithCurrencySign;
        if (cost < 0) {
          costFormatted = "-" + costFormatted;
        }
        return costFormatted;
      };

      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "avgLifetimeTranscriptionDuration",
              value: avgLifetimeTranscriptionDuration / allUsers.length,
            },
            {
              data: "avgLifetimeCostFreeUser",
              value: formatCost(
                avgLifetimeCostFreeUser / freeUserCountExpenses
              ),
            },
            {
              data: "avgLifetimeCostPaidUser",
              value: formatCost(
                avgLifetimeCostPaidUser / paidUserCountExpenses
              ),
            },
            {
              data: "avgLifetimeCostOfUser",
              value: formatCost(
                (avgLifetimeCostPaidUser + avgLifetimeCostFreeUser) /
                  (paidUserCountExpenses + freeUserCountExpenses)
              ),
            },
            {
              data: "paidUserToFreeUserRatio",
              value: `${((paidUserCount / allUsers.length) * 100).toFixed(4)}%`,
            },
            {
              data: "avgNumMonthsPaidUser",
              value: (paymentCount / paymentHistoryUserCount).toFixed(4),
            },
          ],
        })
      );

      logger([LogCategory.INFO], "sorting top users");
      topUsers.sort((user1, user2) => {
        return user2.numTranscriptsCreated - user1.numTranscriptsCreated;
      });

      dispatch(
        updateAnalytics({ stuff: [{ data: "usersRanked", value: topUsers }] })
      );

      weeks.push(
        `${
          months[weekStartDate.getMonth()]
        } ${weekStartDate.getDate()}-${weekEndDate.getDate()}`
      );
      retentionRates.push(
        ((weekActiveUserCount / weekNewUserCount) * 100).toFixed(2)
      );

      // weekNewUserCounts,
      // weekActiveUserCounts,
      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "weeklyRetention",
              value: {
                weeks,
                retentionRates,
              },
            },
            {
              data: "percentDarkMode",
              value: `${((numDarkMode / userCount) * 100).toFixed(2)}%`,
            },
            {
              data: "percentCreatedFolder",
              value: `${(
                (userIdsThatMadeFolders.length / userCount) *
                100
              ).toFixed(2)}%`,
            },
            { data: "dailyActiveUsers", value: dailyActiveUsers },
          ],
        })
      );

      usersCumulative.push((usersCumulative.at(-1) ?? 0) + userDayCount);
      numUsers.push(userDayCount);
      dates.push(dateString);
      datesCumulative.push(dateString);

      dispatch(
        updateAnalytics({
          stuff: [
            {
              data: "usersCumulative",
              value: {
                numUsers: usersCumulative,
                dates: datesCumulative,
              },
            },
            {
              data: "usersByDay",
              value: { numUsers: numUsers, dates: dates },
            },
            {
              data: "activeNotNew",
              value: activeNotNewUserIds.length,
            },
            { data: "currentlyActiveUsers", value: currentlyActiveUsers },
            { data: "activeNotNewUsers", value: activeNotNewUsers },
          ],
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const logDeviceType = createAsyncThunk(
  "users/logDeviceType",
  async (supabase, { getState }) => {
    const { userId, debugInfo, transcriptIndex, emailPreferences } =
      getState().routes;

    const deviceType = getDeviceType();

    const _debugInfo = JSON.parse(JSON.stringify(debugInfo));
    _debugInfo[transcriptIndex].deviceType = deviceType;

    // if the user doesn't exist, initialize this stuff to an empty list
    return supabase
      .from("user data")
      .update({
        email_preferences: emailPreferences,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        logger(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const logAutoPause = createAsyncThunk(
  "users/logAutoPause",
  async ({ supabase }, { getState }) => {
    logger([LogCategory.INFO], `logging the autopause`);

    const newDebugJson = { ...getState().routes.transcript.debugJson };
    newDebugJson.numAutoPauses += 1;

    // log the auto pause on the backend
    const response = await supabase
      .from("transcripts")
      .update({
        debug_json: newDebugJson,
      })
      .eq("transcript_id", getState().routes.transcript.id);

    return { debugJson: newDebugJson };
  }
);

export const getLatestTranscriptSession = createAsyncThunk(
  "sessions/getLatestTranscriptSession",
  async ({ supabase }, { getState }) => {
    const transcriptId = getState().routes.transcript.id;
    const userId = getState().routes.userId;

    // if user id is equal to default state then user has not logged in
    if (!getState().routes.isLoggedIn) {
      logger([LogCategory.INFO], "logged in");
      return { latestSession: { ...initialState.session } };
    } else {
      // Get the last transcript session that matches
      // the current transcript id and user id
      const { data, error } = await supabase
        .from("sessions")
        .select()
        .eq("user_id", userId)
        .eq("transcript_id", transcriptId)
        .order("start_date", { ascending: false })
        .limit(1);

      // previous session doesn't exist
      if (data.length === 0) {
        return { latestSession: { ...initialState.session } };
      }

      const previousSession = { ...initialState.session };
      previousSession.startDate = data[0].start_date;
      previousSession.endDate = data[0].end_date;
      previousSession.sessionId = data[0].session_id;
      previousSession.userId = data[0].user_id;
      previousSession.transcriptId = data[0].transcript_id;
      previousSession.newUser = data[0].new_user;

      return { latestSession: previousSession };
    }
  }
);

export const createSession = createAsyncThunk(
  "sessions/createSession",
  async ({ supabase }, { getState, dispatch }) => {
    logger([LogCategory.INFO], "creating session");
    const transcriptId = getState().routes.transcript.id;
    const userId = getState().routes.userId;
    const createdDate = new Date();
    const endDate = new Date();
    const sessionId = (transcriptId + createdDate.getTime()).toString();
    const isNewUser = getState().routes.isNewUser;

    // add a new session to the session table
    const { data, error } = await supabase
      .from("sessions")
      .insert({
        session_id: sessionId,
        start_date: createdDate,
        end_date: endDate,
        user_id: userId,
        transcript_id: transcriptId,
        new_user: isNewUser,
      })
      .select();

    const session = { ...initialState.session };
    session.sessionId = sessionId;
    session.userId = userId;
    session.transcriptId = transcriptId;
    session.newUser = isNewUser;
    session.createdDate = createdDate.toString();
    session.endDate = endDate.toString();

    // save the transcript session event in amplitude
    const eventName = "Transcript Session";
    const eventProperties = {
      transcriptId: transcriptId,
      startTime: createdDate.getTime(),
      endTime: endDate.getTime(),
    };
    dispatch(logAmplitudeEvent({ eventName, eventProperties }));

    return { session };
  }
);

export const updateSessionEndDate = createAsyncThunk(
  "sessions/updateSessionEndDate",
  async ({ supabase }, { dispatch, getState }) => {
    const session = getState().routes.session;
    let newSession = { ...session };

    // session does not exist locally...
    if (session.sessionId === null) {
      // check if there is a recent session in the backend
      let { latestSession } = await dispatch(
        getLatestTranscriptSession({ supabase })
      ).unwrap();

      // no recent sessions so create one
      if (latestSession.sessionId === null) {
        return await dispatch(createSession({ supabase })).unwrap();
      }

      // recent session exists, check how long ago it ended in minutes
      const currentTime = new Date();
      const timeSinceLastSessionEnded =
        (currentTime - new Date(latestSession.endDate)) / (1000 * 60);

      const SESSION_THRESHOLD = 5;

      // session ended too long ago so create a new one
      if (timeSinceLastSessionEnded >= SESSION_THRESHOLD) {
        return await dispatch(createSession({ supabase })).unwrap();
      }

      // session was recent enough so continue it
      newSession = latestSession;
    }

    // update the end_date
    const currentTime = new Date();
    const { error } = await supabase
      .from("sessions")
      .update({ end_date: currentTime })
      // .select()
      .eq("session_id", newSession.sessionId);

    newSession.endDate = currentTime.toString();

    // save the transcript session event in amplitude
    const eventName = "Transcript Session";
    // COME BACK AND FIX
    const transcriptId = getState().routes.transcriptId;
    const eventProperties = {
      transcriptId: transcriptId,
      startTime: new Date(newSession.startDate).getTime(),
      endTime: currentTime.getTime(),
    };
    dispatch(logAmplitudeEvent({ eventName, eventProperties }));

    return { session: newSession };
  }
);

export const logAmplitudeEvent = createAsyncThunk(
  "sessions/logAmplitudeEvent",
  async ({ eventName, eventProperties = {} }, { getState, dispatch }) => {
    try {
      const fullName = getState().routes.fullName;
      const userId = getState().routes.userId;
      const email = getState().routes.email;

      const amplitudeAPIKey =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_AMPLITUDE_KEY
          : process.env.REACT_APP_TEST_AMPLITUDE_KEY;
      amplitude.init(amplitudeAPIKey, {
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
        },
      });
      amplitude.setUserId(userId);

      // set the user information to amplitude
      const identifyEvent = new amplitude.Identify();
      identifyEvent.setOnce("fullName", fullName);
      identifyEvent.setOnce("email", email);
      amplitude.identify(identifyEvent);

      // log the event to amplitude
      amplitude.track(eventName, eventProperties);
      logger(
        [LogCategory.AMPLITUDE],
        `logged event: ${eventName} to amplitude.`
      );
    } catch (error) {
      logger(
        [LogCategory.DEBUG],
        `error logging the event: ${eventName} to amplitude.`
      );
    }
  }
);

export const checkIfNewUser = createAsyncThunk(
  "sessions/checkIfNewUser",
  async ({ supabase }, { getState, dispatch }) => {
    const userId = getState().routes.userId;
    // determine if user id already exists in database
    const { data, error } = await supabase
      .from("user data")
      .select()
      .eq("user_id", userId)
      .limit(1);
    console.log(error);

    const isNewUser = data.length === 0;

    if (isNewUser) {
      // get the total number of users in supabase
      const { count, error: countError } = await supabase
        .from("user data")
        .select("*", { count: "exact", head: true });

      // save the new user event in amplitude
      const eventName = "New User";
      const eventProperties = {
        userCount: count + 1,
      };
      dispatch(logAmplitudeEvent({ eventName, eventProperties }));
    }
    return { isNewUser };
  }
);

export const logTranscriptSettings = createAsyncThunk(
  "users/logTranscriptSettings",
  async (
    { supabase, customVocab, filterProfanity, filterDisfluencies, saveAudio },
    { getState }
  ) => {
    const newDebugJson = { ...initialState.transcript.debugJson };
    newDebugJson.settings.customVocab = customVocab;
    newDebugJson.settings.filterProfanity = filterProfanity;
    newDebugJson.filterDisfluencies = filterDisfluencies;
    newDebugJson.saveAudio = saveAudio;

    const deviceType = getDeviceType();
    newDebugJson.deviceType = deviceType;

    const transcriptId = getState().routes.transcript.id;
    const response = await supabase
      .from("transcripts")
      .update({
        debug_json: newDebugJson,
      })
      .eq("transcript_id", transcriptId);

    return { debugJson: newDebugJson };
  }
);

export const logTranscriptFeedbackForm = createAsyncThunk(
  "users/logTranscriptFeedbackForm",
  async ({ supabase, ratings }, { getState }) => {
    // save post transcript feedback form to backend
    const transcriptId = getState().routes.transcript.id;
    supabase
      .from("transcripts")
      .update({
        transcript_feedback_form: ratings,
      })
      .eq("transcript_id", transcriptId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

// when a user finally cancels their account, log the reason they cancelled
export const logCancelReason = createAsyncThunk(
  "users/logCancelReason",
  async ({ supabase, cancelReason }, { getState }) => {
    const userId = getState().routes.userId;
    await supabase
      .from("cancel_subscription")
      .insert({
        user_id: userId,
        cancel_reason: cancelReason,
      })
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

// returns the last date the user was asked to fill out the post transcript feedback form
export const getLastPostTranscriptFeedbackFormDate = createAsyncThunk(
  "users/getLastPostTranscriptFeedbackFormDate",
  async ({ supabase }, { getState }) => {
    const userId = getState().routes.userId;
    // selects all rows where transcript_feedback_form is not NULL and sorts those rows by the created date
    const { data, error } = await supabase
      .from("transcripts")
      .select("created_at, transcript_feedback_form")
      .eq("user_id", userId)
      .neq("transcript_feedback_form", null)
      .order("created_at", { ascending: false })
      .limit(1)
      .maybeSingle();

    // default case if data is null, set the last transcript feedback form date to 7 days prior to today,
    // so that the post transcript panel is displayed
    let date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    // if data is not null, extract the last transcript feedback form date (created date)
    if (data !== null) {
      date = new Date(data["created_at"]);
    }
    return date.toString();
  }
);

export const shouldDisplayPostTranscriptFeedbackPanel = createAsyncThunk(
  "users/displayPostTranscriptFeedbackForm",
  async ({ supabase }, { getState, dispatch }) => {
    dispatch(
      setDisplayPostTranscriptFeedbackPanel({
        displayPostTranscriptFeedbackPanel: true,
      })
    );

    // Displays the post transcript panel once every week
    // logger([LogCategory.INFO], "entering feedback panel");
    // // get the last date the user was asked to fill out the post transcript feedback form
    // let lastFeedbackFormDate = await dispatch(
    //   getLastPostTranscriptFeedbackFormDate({
    //     supabase,
    //   })
    // ).unwrap();
    // lastFeedbackFormDate = new Date(lastFeedbackFormDate);
    // const currentDate = new Date();
    // const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    // const timeDifferrenceInDays =
    //   (currentDate - lastFeedbackFormDate) / millisecondsInOneDay;

    // // conditions to show a new post transcript feedback form
    // if (timeDifferrenceInDays >= 7) {
    //   dispatch(
    //     setDisplayPostTranscriptFeedbackPanel({
    //       displayPostTranscriptFeedbackPanel: true,
    //     })
    // );
    // }
  }
);

export const setDarkMode = createAsyncThunk(
  "users/setDarkMode",
  async ({ supabase, darkMode }, { getState }) => {
    const { userId } = getState().routes;

    // if the user doesn't exist, initialize this stuff to an empty list
    const { data: upsertData, error: upsertError } = await supabase
      .from("user data")
      .upsert(
        {
          user_id: userId,
          dark_mode: darkMode,
        },
        { onConflict: "user_id", ignoreDuplicates: false }
      )
      .select();

    if (upsertError) {
      logger(
        [LogCategory.ERROR],
        "upsert error: " + JSON.stringify(upsertError)
      );
    }

    logger([LogCategory.INFO], `dark mode: ${darkMode}`);
    return { darkMode: darkMode };
  }
);

export const saveTranscriptAudio = createAsyncThunk(
  "users/saveTranscriptAudio",
  async (
    { supabase, audioBlobs, fileType = "audio/mp4" },
    { getState, dispatch }
  ) => {
    dispatch(setAudioUploading({ uploadingState: true }));

    // create the audio file
    const audioBlob = new Blob(audioBlobs, {
      type: fileType,
    });
    logger([LogCategory.DEBUG], `uploading the audio with type: ${fileType}`);

    const fileExtension = fileType.split("/")[1];

    // this is the code to upload audio
    const tus = require("tus-js-client");
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return new Promise(async (resolve, reject) => {
      const upload = new tus.Upload(audioBlob, {
        endpoint: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/upload/resumable`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        headers: {
          authorization: `Bearer ${session.access_token}`,
          "x-upsert": "true",
        },
        uploadDataDuringCreation: true,
        removeFingerprintOnSuccess: true,
        metadata: {
          bucketName: "recordings",
          objectName: `${getState().routes.userId}/${
            getState().routes.transcript.id
          }.${fileExtension}`,
          contentType: fileType,
          cacheControl: 3600,
        },
        // NOTE: it must be set to 6MB (for now) do not change it
        chunkSize: 6 * 1024 * 1024,
        onError: (error) => {
          logger([LogCategory.ERROR], "Failed because: " + error);
          reject(error);
          dispatch(setAudioUploading({ uploadingState: false }));
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          logger(
            [LogCategory.DEBUG],
            bytesUploaded,
            bytesTotal,
            percentage + "%"
          );
          dispatch(setAudioUploadProgress({ percentage: percentage }));
        },

        // successfully uploaded audio
        onSuccess: () => {
          logger(
            [LogCategory.DEBUG],
            `Download ${upload.file.name} from ${upload.url}`
          );

          const { data: audioUrlJson } = supabase.storage
            .from("recordings")
            .getPublicUrl(
              `${getState().routes.userId}/${
                getState().routes.transcript.id
              }.${fileExtension}`
            );
          const audioUrl = audioUrlJson.publicUrl;
          logger([LogCategory.DEBUG], `here is the audio url: ${audioUrl}`);

          supabase
            .from("transcripts")
            .update({
              audio_url: audioUrl,
            })
            .eq("transcript_id", getState().routes.transcript.id)
            .then((response) => {
              logger([LogCategory.DEBUG], "added to databse");
              dispatch(
                addAudioUrlLocal({
                  audioUrl: audioUrl,
                })
              );
            });

          dispatch(setAudioUploading({ uploadingState: false }));
          dispatch(shouldDisplayPostTranscriptFeedbackPanel({ supabase }));
          resolve();
        },
      });

      // Check if there are any previous uploads to continue.
      return upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    });
  }
);

export const updateFolderTitle = createAsyncThunk(
  "users/updateFolderTitle",
  async ({ supabase, folderIndex, newFolderTitle }, { getState, dispatch }) => {
    logger("updating folder title...");
    const { id: folderId } = getState().routes.folders[folderIndex];

    dispatch(updateFolderTitleLocal({ newFolderTitle, folderIndex }));

    const updateFolderQuery = supabase.from("folders");
    let operation;

    // this folder hasn't been actually created yet, make it now with the new transcript title
    if (folderId === initialState.folder.id) {
      operation = updateFolderQuery.insert({
        folder_id: uuidv4(),
        folder_url: await createFolderUrl(supabase),
        user_id: getState().routes.userId,
        folder_name: newFolderTitle,
        folder_privacy: initialState.folder.isPrivate,
      });
    }

    // the folder exists, just update the title
    else {
      operation = updateFolderQuery
        .update({ folder_name: newFolderTitle })
        .eq("folder_id", folderId);
    }

    const {
      data: [newFolder],
      error: updateFolderError,
    } = await operation.select();

    return { folderIndex, ...newFolder };
  }
);

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const createTranscriptUrl = async (supabase) => {
  const letters = "qwertyuiopasdfghjklzxcvbnm";

  let transcriptUrl = "";
  let duplicateUrl = true;

  // keep creating urls until we found one that hasn't been used
  // 5 lowercase letters: 26^5 = 11,881,376
  do {
    transcriptUrl = "";
    for (let i = 0; i < 5; i++) {
      transcriptUrl += letters[getRandomInt(0, 25)];
    }
    const { data: urlData, error: selectUrlDataError } = await supabase
      .from("transcripts")
      .select("")
      .eq("transcript_url", transcriptUrl);
    duplicateUrl = selectUrlDataError ? true : false;
  } while (duplicateUrl === true);

  return transcriptUrl;
};

const createDocumentUrl = async (supabase) => {
  const letters = "qwertyuiopasdfghjklzxcvbnm";

  let documentUrl = "";
  let duplicateUrl = true;

  // keep creating urls until we found one that hasn't been used
  // 5 lowercase letters: 26^5 = 11,881,376
  do {
    documentUrl = "";
    for (let i = 0; i < 6; i++) {
      documentUrl += letters[getRandomInt(0, 25)];
    }
    const { data: urlData, error: selectUrlDataError } = await supabase
      .from("documents")
      .select("")
      .eq("document_url", documentUrl);
    duplicateUrl = selectUrlDataError ? true : false;
  } while (duplicateUrl === true);

  return documentUrl;
};

const createFolderUrl = async (supabase) => {
  const letters = "qwertyuiopasdfghjklzxcvbnm";

  let folderUrl = "";
  let duplicateUrl = true;

  // keep creating urls until we found one that hasn't been used
  // 5 lowercase letters: 26^5 = 11,881,376
  do {
    folderUrl = "";
    for (let i = 0; i < 7; i++) {
      folderUrl += letters[getRandomInt(0, 25)];
    }
    const { data: urlData, error: selectUrlDataError } = await supabase
      .from("folders")
      .select("")
      .eq("folder_url", folderUrl);
    duplicateUrl = selectUrlDataError ? true : false;
  } while (duplicateUrl === true);

  return folderUrl;
};

export const goToContent = createAsyncThunk(
  "users/goToContent",
  async ({ contentPathName, newTab = false, contentType }, { dispatch }) => {
    let letter;
    if (contentType === contentTypes.TRANSCRIPT) {
      letter = "t";
    } else if (contentType === contentTypes.DOCUMENT) {
      letter = "d";
    }
    if (newTab) {
      window
        .open(
          `${window.location.origin}/${letter}/${contentPathName}`,
          "_blank"
        )
        .focus();
    } else {
      window.location.pathname = `${letter}/${contentPathName}`;
    }
  }
);

// returns the total number of audio seconds transcibed since Sunday
export const getWeekAudioDuration = createAsyncThunk(
  "users/getWeekAudioDuration",
  async ({ supabase, includeCurrent = true }, { getState, dispatch }) => {
    const userId = getState().routes.userId;
    const currentDate = new Date();
    const startOfWeekDate = startOfWeek(currentDate);
    const startOfWeekDateString = startOfWeekDate.toISOString();

    //  get all the transcript after Sunday (the start of the week)
    const { data: transcriptsFromThisWeek, error } = await supabase
      .from("transcripts")
      .select("audio_duration, transcript_id")
      .eq("user_id", userId)
      .gte("created_at", startOfWeekDateString);

    const transcriptId = getState().routes.transcript.id;

    // Sum up the values in the "audio_duration" column
    let weekAudioDuration = 0;
    for (const { audio_duration, transcript_id } of transcriptsFromThisWeek) {
      if (!includeCurrent && transcript_id === transcriptId) {
        continue;
      }
      weekAudioDuration += audio_duration;
    }

    logger(`total week usage: ${weekAudioDuration}`);
    return { weekAudioDuration, includeCurrent };
  }
);

// determine if the weekly quota limit has been reached
export const checkReachedWeeklyQuota = createAsyncThunk(
  "users/checkReachedWeeklyQuota",
  async ({ supabase }, { getState, dispatch }) => {
    const weekTranscriptionLimitSeconds =
      getState().routes.weekTranscriptionLimitSeconds;
    const { weekAudioDuration } = await dispatch(
      getWeekAudioDuration({ supabase })
    ).unwrap();

    logger(`week total audio duration: ${weekAudioDuration}`);
    return weekAudioDuration >= weekTranscriptionLimitSeconds;
  }
);

export const invitationWasClicked = createAsyncThunk(
  "users/invitationWasClicked",
  async ({ supabase, invitedEmail }) => {
    const { error } = await supabase
      .from("invitations")
      .update({ invitation_click_date: new Date().toISOString() })
      .eq("invitee_email", invitedEmail)
      .is("invitation_click_date", null);
  }
);

export const fetchWeekTranscriptionLimit = createAsyncThunk(
  "users/fetchWeekTranscriptionLimit",
  async ({ supabase }, { getState }) => {
    const currentDate = new Date();
    const startOfWeekDate = startOfWeek(currentDate);
    const startOfWeekDateString = startOfWeekDate.toISOString();
    const { data: invitations, error } = await supabase
      .from("invitations")
      .select()
      .eq("inviter_user_id", getState().routes.userId)
      .gte("invitation_sent_date", startOfWeekDateString);

    let claimedStatus = claimFreeHourStatus.unclaimed;
    if (invitations.length > 0) {
      claimedStatus = claimFreeHourStatus.claimed;
    }

    return { claimedStatus };
  }
);

export const setTranscribingFile = createAsyncThunk(
  "users/setTranscribingFile",
  async ({ supabase, transcribingFile }, { getState }) => {
    // push to backend
    supabase
      .from("transcripts")
      .update({
        transcribing_file: transcribingFile,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then((response) => {
        logger(
          [LogCategory.DEBUG],
          `RESPONSE to toggle transcribing file: ${JSON.stringify(response)}`
        );
        logger(
          [LogCategory.DEBUG],
          `new value of transcribing file: ${transcribingFile}`
        );
      });

    return { transcribingFile };
  }
);

export const updateDebugJson = createAsyncThunk(
  "users/updateDebugJson",
  async ({ supabase, key, newValue }, { getState }) => {
    const debugJson = { ...getState().routes.transcript.debugJson };
    debugJson[key] = newValue;

    // push to backend
    supabase
      .from("transcripts")
      .update({
        debug_json: debugJson,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then((response) => {
        logger(
          [LogCategory.DEBUG],
          `RESPONSE to toggle transcribing file: ${JSON.stringify(response)}`
        );
      });
    return { newDebugJson: debugJson };
  }
);

// export const fetchLiveTranscriptUsage = createAsyncThunk(
//   "users/fetchLiveTranscriptUsage",
//   async ({ supabase }, {dispatch, getState}) => {
//         // update transcript when supabase information is changed
//         const handleTranscriptUpdated = (payload) => {
//           const {
//             transcript_array,
//             gpt_json,
//             bookmarks,
//             transcript_title,
//             summary,
//             summary_bullet_points,
//             audio_url,
//             timestamps,
//           } = payload.new;

//           const currentTranscriptObj = getState().routes.transcript;
//           dispatch(
//             setLiveTranscriptData({
//               gptJson: gpt_json || currentTranscriptObj.gptJson,
//               transcriptArray:
//                 transcript_array || currentTranscriptObj.transcriptArray,
//               timestamps: timestamps || currentTranscriptObj.timestamps,
//               bookmarks: bookmarks || currentTranscriptObj.bookmarks,
//               transcriptTitle:
//                 transcript_title || currentTranscriptObj.transcriptTitle,
//               summarySentences: summary || currentTranscriptObj.summarySentences,
//               summaryBulletPoints:
//                 summary_bullet_points || currentTranscriptObj.summaryBulletPoints,
//               audioUrl: audio_url || currentTranscriptObj.audioUrl,
//             })
//           );
//         };

//         // subscribes to the transcript table for the specific row
//         supabase
//           .channel("live transcripts")
//           .on(
//             "postgres_changes",
//             {
//               event: "UPDATE",
//               schema: "public",
//               table: "transcripts",
//               filter: `transcript_id=eq.${transcriptId}`,
//             },
//             handleTranscriptUpdated
//           )
//           .subscribe();
//       }

// );

export const checkIfUserUpdates = createAsyncThunk(
  "users/checkIfUserUpdates",
  async ({ supabase }, { dispatch, getState }) => {
    const handleTranscriptUpdated = (payload) => {
      logger("transcript updated");
      const { paid_blurb_index: newPaidBlurbIndex } = payload["new"];

      if (newPaidBlurbIndex !== getState().routes.transcript.paidBlurbIndex) {
        dispatch(
          setPaidBlurbIndex({
            paidBlurbIndex: newPaidBlurbIndex,
          })
        );
      }
    };
    // subscribes to the transcript table for the specific row
    supabase
      .channel("paid user")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "transcripts",
          filter: `transcript_id=eq.${getState().routes.transcript.id}`,
        },
        handleTranscriptUpdated
      )
      .subscribe();
  }
);

export const checkIfUserChangesPlan = createAsyncThunk(
  "users/checkIfUserChangesPlan",
  async ({ supabase }, { dispatch, getState }) => {
    logger("check if user changes plan...");
    logger(getState().routes.userId);
    // update transcript when supabase information is changed
    const handleUserChangesPlan = (payload) => {
      logger("handle transcript updated entered");
      logger(payload);
      const { paid_user, paused_subscription } = payload["new"];

      if (paid_user) {
        dispatch(
          updateUserPlan({
            isPaidUser: paid_user,
          })
        );
      }
      if (paused_subscription) {
        dispatch(
          updatePausedSubscriptionStatus({
            isSubscriptionPaused: paused_subscription,
          })
        );
      }
    };

    // subscribes to the transcript table for the specific row
    supabase
      .channel("change plan")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "user data",
          filter: `user_id=eq.${getState().routes.userId}`,
        },
        handleUserChangesPlan
      )
      .subscribe();

    logger("finished subscribing to plan changes");
  }
);

export const fetchLiveTranscript = createAsyncThunk(
  "users/fetchLiveTranscript",
  async ({ supabase, transcriptId }, { dispatch, getState }) => {
    // update transcript when supabase information is changed
    const handleTranscriptUpdated = (payload) => {
      const {
        transcript_array,
        gpt_json_array,
        bookmarks,
        transcript_title,
        summary,
        summary_bullet_points,
        audio_url,
        timestamps,
      } = payload["new"];

      const currentTranscriptObj = getState().routes.transcript;
      dispatch(
        setLiveTranscriptData({
          gptJsonArray: gpt_json_array || currentTranscriptObj.gptJsonArray,
          contentArray: transcript_array || currentTranscriptObj.contentArray,
          timestamps: timestamps || currentTranscriptObj.timestamps,
          bookmarks: bookmarks || currentTranscriptObj.bookmarks,
          transcriptTitle: transcript_title || currentTranscriptObj.title,
          summarySentences: summary || currentTranscriptObj.summarySentences,
          summaryBulletPoints:
            summary_bullet_points || currentTranscriptObj.summaryBulletPoints,
          audioUrl: audio_url || currentTranscriptObj.audioUrl,
        })
      );
    };

    // subscribes to the transcript table for the specific row
    supabase
      .channel("live transcripts")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "transcripts",
          filter: `transcript_id=eq.${transcriptId}`,
        },
        handleTranscriptUpdated
      )
      .subscribe();
  }
);

export const disconnectLiveTranscript = createAsyncThunk(
  "users/disconnectLiveTranscript",
  async ({ supabase }, { dispatch, getState }) => {
    // unsubscribe to the transcript
    supabase.channel("live transcripts").unsubscribe();
  }
);

export const getUploadAudioProgressData = createAsyncThunk(
  "users/getUploadAudioProgressData",
  async ({ supabase, transcriptId }, { dispatch, getState }) => {
    const handleUploadAudioProgressUpdated = (payload) => {
      const currentTranscriptObj = getState().routes.transcript;
      const upload_audio_progress = payload.new.upload_audio_progress;
      // set transcribing file equal to false once the upload audio progress reaches 100
      const transcribing_file =
        upload_audio_progress === 100 ? false : payload.new.transcribing_file;
      const transcriptArray = payload.new.transcript_array;
      const bookmarks = payload.new.bookmarks;
      const timestamps = payload.new.timestamps;
      const audioUrl = payload.new.audio_url;
      const paidBlurbIndex = payload.new.paid_blurb_index;

      dispatch(
        updateUploadAudioProgress({
          uploadAudioProgress:
            upload_audio_progress || currentTranscriptObj.uploadAudioProgress,
          transcribingFile:
            transcribing_file || currentTranscriptObj.transcribingFile,
        })
      );
      // update the transcript once the upload audio progress reaches 100
      dispatch(
        setTranscriptResults({
          contentArray: transcriptArray || currentTranscriptObj.contentArray,
          bookmarks: bookmarks || currentTranscriptObj.bookmarks,
          timestamps: timestamps || currentTranscriptObj.timestamps,
          audioUrl: audioUrl || currentTranscriptObj.audioUrl,
          paidBlurbIndex: paidBlurbIndex || currentTranscriptObj.paidBlurbIndex,
        })
      );
    };

    supabase
      .channel("upload audio progress")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "transcripts",
          filter: `transcript_id=eq.${transcriptId}`,
        },
        handleUploadAudioProgressUpdated
      )
      .subscribe();
  }
);

export const disconnectGetUploadAudioProgress = createAsyncThunk(
  "users/disconnectGetUploadAudioProgress",
  async ({ supabase }, { dispatch, getState }) => {
    // unsubscribe to the transcript
    supabase.channel("upload audio progress").unsubscribe();
  }
);

export const fetchFolderChats = createAsyncThunk(
  "users/fetchFolderChats",
  async ({ supabase, folderId }, { getState }) => {
    let folderChats;
    const { data: folderChatsAttempt1, error: selectTranscriptDataError } =
      await supabase
        .from("folder_chats")
        .select(`chat_messages`)
        .eq("folder_id", folderId)
        .eq("user_id", getState().routes.userId);

    if (folderChatsAttempt1.length === 0) {
      const { data: folderChatsAttempt2, error: selectTranscriptDataError } =
        await supabase
          .from("folder_chats")
          .insert({
            chat_messages: [],
            folder_id: folderId,
            user_id: getState().routes.userId,
          })
          .select();
      folderChats = folderChatsAttempt2;
    } else {
      folderChats = folderChatsAttempt1;
    }
    return folderChats;
  }
);

export const fetchDocument = createAsyncThunk(
  "users/fetchDocument",
  async ({ supabase, documentUrl }, { dispatch, getState }) => {
    // // this is a new document
    // if (documentUrl === "new") {

    //   return {
    //     transcript: { ...initialState.transcript },
    //     ownerOfTranscript: true,
    //   };
    // }

    // get the document
    const { data: documentData, error: selectTranscriptDataError } =
      await supabase
        .from("documents")
        .select(
          `deleted, document_url, document_folder_url, document_id, document_title, file_url, 
          document_privacy, user_id, document_text_array, document_summary_sentences, document_bullet_points,
          document_messages, document_embeddings`
        )
        .eq("document_url", documentUrl);

    if (selectTranscriptDataError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching transcript: " +
          JSON.stringify(selectTranscriptDataError)
      );
    }

    // couldn't find any matches; this transcript doesn't exist
    if (documentData.length === 0) {
      logger([LogCategory.DEBUG], "couldn't find the requested transcript");
      return initialState.FILE_DOES_NOT_EXIST;
    }

    const {
      document_url,
      document_folder_url,
      document_id,
      document_title,
      file_url,
      document_privacy,
      user_id,
      deleted,
      document_text_array,
      document_summary_sentences,
      document_bullet_points,
      document_embeddings,
    } = documentData[0];
    let isDocumentSaved = false;

    const { data: folderData, error } = await supabase
      .from("folders")
      .select("folder_privacy")
      .eq("folder_url", document_folder_url)
      .limit(1);

    // user is accessing a transcript they don't own
    if (user_id !== getState().routes.userId) {
      // document is private, can't access
      if (document_privacy === true) {
        logger([LogCategory.DEBUG], "tried to access a private folder");
        return initialState.FILE_IS_PRIVATE;
      }
      // document is public if either document is public or document is within a folder that is public
      else {
        // get the ids of the saved document
        const {
          data: [{ saved_transcript_ids: savedContentIds }],
        } = await supabase
          .from("user data")
          .select("saved_transcript_ids")
          .eq("user_id", getState().routes.userId);

        // this transcript is saved by the user
        if (savedContentIds.includes(document_id)) {
          isDocumentSaved = true;
        }
      }
    }

    // document has been deleted
    if (deleted) {
      return initialState.FILE_HAS_BEEN_DELETED;
    }

    let chat_messages = [];
    const userId = getState().routes.userId;
    const documentId = documentData[0].document_id;
    // fetch the chat messages corresponding to this document id and user id
    const {
      data: [chat],
      error: selectDocumentMessagesError,
    } = await supabase
      .from("document_chats")
      .select(`chat_messages`)
      .eq("document_id", documentId)
      .eq("user_id", userId)
      .limit(1);

    if (chat === undefined) {
      // create the chat messages entry in the table transcript_chats
      await dispatch(
        createContentMessages({
          supabase,
          contentId: documentId,
          contentType: contentTypes.DOCUMENT,
        })
      ).unwrap();
    } else {
      chat_messages = chat.chat_messages;
    }

    // update time of session end
    dispatch(updateSessionEndDate({ supabase }));
    logger("embeddings");
    // logger(embeddings);
    return {
      document: {
        pageUrl: documentUrl,
        folderUrl: document_folder_url,
        id: document_id,
        title: document_title,
        fileUrl: file_url,
        isSaved: isDocumentSaved,
        isPrivate: document_privacy,
        contentArray: document_text_array,
        summarySentences: document_summary_sentences,
        summaryBulletPoints: document_bullet_points,
        messages: chat_messages,
        embeddings: document_embeddings,
        contentType: contentTypes.DOCUMENT,
      },
      ownerOfTranscript: user_id === getState().routes.userId,
    };
  }
);

export const fetchTranscript = createAsyncThunk(
  "users/fetchTranscript",
  async ({ supabase, transcriptUrl }, { dispatch, getState }) => {
    // this is a new transcript
    if (
      transcriptUrl === "new" ||
      transcriptUrl === transcriptModeTypes.LIVE_TRANSCRIPTION.url ||
      transcriptUrl === transcriptModeTypes.UPLOAD_AUDIO.url ||
      transcriptUrl === transcriptModeTypes.VIDEO_LINK.url
    ) {
      return {
        transcript: { ...initialState.transcript },
        ownerOfTranscript: true,
      };
    }

    // get the transcript
    const { data: transcriptData, error: selectTranscriptDataError } =
      await supabase
        .from("transcripts")
        .select(
          `audio_url, creation_date, transcript_array, transcript_title, summary, summary_bullet_points,
          timestamps, transcript_id, gpt_json_array, bookmarks, user_id, deleted, transcript_privacy, debug_json, 
          transcript_folder_url, transcribing_file, audio_duration, paid_blurb_index, embeddings, messages, slides_url, video_url, upload_audio_progress`
        )
        .eq("transcript_url", transcriptUrl)
        .limit(1);

    if (selectTranscriptDataError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching transcript: ",
        selectTranscriptDataError
      );
    }

    // couldn't find any matches; this transcript doesn't exist
    if (transcriptData.length === 0) {
      logger([LogCategory.DEBUG], "couldn't find the requested transcript");
      return initialState.FILE_DOES_NOT_EXIST;
    }

    const {
      audio_url,
      bookmarks,
      creation_date,
      debug_json,
      deleted,
      embeddings,
      gpt_json_array,
      summary,
      summary_bullet_points,
      transcript_array,
      transcript_title,
      timestamps,
      transcript_id,
      transcript_privacy,
      transcript_folder_url,
      transcribing_file,
      upload_audio_file_length,
      user_id,
      audio_duration,
      paid_blurb_index,
      slides_url,
      video_url,
      upload_audio_progress,
    } = transcriptData[0];
    let isTranscriptSaved = false;

    logger(
      [LogCategory.DEBUG],
      `value of transcribing file: ${transcribing_file}`
    );

    const { data: folderData, error } = await supabase
      .from("folders")
      .select("folder_privacy")
      .eq("folder_url", transcript_folder_url)
      .limit(1);

    // user is accessing a transcript they don't own
    if (user_id !== getState().routes.userId) {
      // transcript is private, can't acess
      if (transcript_privacy === true) {
        logger([LogCategory.DEBUG], "tried to access a private transcript");
        return initialState.FILE_IS_PRIVATE;
      }
      // transcript is public if either the transcript is public or in a folder that is public
      else {
        // get the ids of the saved transcripts
        const {
          data: [{ saved_transcript_ids: savedContentIds }],
        } = await supabase
          .from("user data")
          .select("saved_transcript_ids")
          .eq("user_id", getState().routes.userId);

        // this transcript is saved by the user
        if (savedContentIds.includes(transcript_id)) {
          isTranscriptSaved = true;
        }
      }
    }

    // transcript has been deleted
    if (deleted) {
      return initialState.FILE_HAS_BEEN_DELETED;
    }

    let chat_messages = [];
    const userId = getState().routes.userId;
    const transcriptId = transcriptData[0].transcript_id;
    // fetch the chat messages corresponding to this transcript id and user id
    const {
      data: [chat],
      error: selectTranscriptMessagesError,
    } = await supabase
      .from("transcript_chats")
      .select(`chat_messages`)
      .eq("transcript_id", transcriptId)
      .eq("user_id", userId)
      .limit(1);

    if (chat === undefined) {
      // create the chat messages entry in the table transcript_chats
      await dispatch(
        createContentMessages({
          supabase,
          contentId: transcriptId,
          contentType: contentTypes.TRANSCRIPT,
        })
      ).unwrap();
    } else {
      chat_messages = chat.chat_messages;
    }

    // update time of session end
    dispatch(updateSessionEndDate({ supabase }));
    logger("embeddings");
    logger(embeddings);
    return {
      transcript: {
        audioUrl: audio_url,
        contentArray: transcript_array,
        title: transcript_title,
        summarySentences: summary,
        summaryBulletPoints: summary_bullet_points,
        creationDate: creation_date,
        id: transcript_id,
        folderUrl: transcript_folder_url,
        timestamps: timestamps,
        bookmarks: bookmarks,
        gptJsonArray: gpt_json_array,
        messages: chat_messages,
        isPrivate: transcript_privacy,
        pageUrl: transcriptUrl,
        debugJson: debug_json,
        embeddings: embeddings,
        displayPostTranscriptFeedbackPanel: false,
        isSaved: isTranscriptSaved,
        uploadAudioFileLength: upload_audio_file_length,
        audioDuration: audio_duration,
        paidBlurbIndex: paid_blurb_index,
        slidesUrl: slides_url,
        videoUrl: video_url,
        contentType: contentTypes.TRANSCRIPT,
        uploadAudioProgress: upload_audio_progress,
      },
      transcribingFile: transcribing_file,
      ownerOfTranscript: user_id === getState().routes.userId,
    };
  }
);

const dot = (a, b) => a.map((_, i) => a[i] * b[i]).reduce((m, n) => m + n);

export const getQueryContext = createAsyncThunk(
  "users/getQueryContext",
  async (
    {
      userQuery,
      count = 20,
      mostRecentBlurbsEmbeddingObj,
      contentType,
      folderUrl,
    },
    { getState }
  ) => {
    logger("getting query embedding...");

    const queryEmbeddingResponse = await fetch(
      `${apiEndpoint}/generate_embedding`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: userQuery,
          embeddingType: "query_embedding",
          transcriptId: getState().routes.transcript.id,
        }),
      }
    );
    logger(`got it...`);

    const { embedding: queryEmbedding } = await queryEmbeddingResponse.json();
    logger(`converted query embedding json`);

    let contextContents = [];

    // asking question on a folder level
    if (contentType === contentTypes.FOLDER) {
      contextContents = getState().routes.contentsArray;
    } else {
      const content = { ...getState().routes[contentType.name] };

      // mid-transcript; add the most recent embedding
      if (mostRecentBlurbsEmbeddingObj !== undefined) {
        content.embeddings = [
          ...content.embeddings,
          mostRecentBlurbsEmbeddingObj,
        ];
        logger("adding most recent embedding...");
      }
      contextContents.push(content);
    }

    logger(`total context...`);
    logger(contextContents);

    // all the blurbs with their relevant contexts
    let contentSections = [];

    try {
      contextContents.forEach((content, index) => {
        let embeddings = content.embeddings ?? [];
        let contentType = content.contentType;
        embeddings.forEach(({ embedding, startIndex, endIndex }) => {
          contentSections.push({
            embedding,
            startIndex,
            endIndex,
            contextContentsIndex: index,
            similarity: dot(embedding, queryEmbedding),
            sectionType: contentType,
          });
        });
      });
    } catch (e) {
      console.error(e);
    }

    logger(`here 2`);
    logger(contextContents);
    logger(contentSections);

    contentSections = contentSections.filter(
      ({ similarity }) => similarity >= 0
    );

    logger(`here 3`);

    // sort blurbs from highest similariy to lowest
    contentSections.sort(
      ({ similarity: similarityA }, { similarity: similarityB }) =>
        similarityB - similarityA
    );

    logger(`here 4`);

    // take the top n most similar blurbs
    const topClosestBlurbs = contentSections.slice(0, count);

    logger(`here 5`);

    // group the blurbs by content and sort by order in the content
    topClosestBlurbs.sort(
      (
        { contextContentsIndex: contentsA, startIndex: indexA },
        { contextContentsIndex: contentsB, startIndex: indexB }
      ) => {
        if (contentsA > contentsB) {
          return 1;
        } else if (contentsA < contentsB) {
          return -1;
        } else {
          return indexA - indexB;
        }
      }
    );

    logger(`here 6`);
    logger(contextContents);
    logger(topClosestBlurbs);

    // get the relevant lecture context text
    let lectureText = "";
    let prevContentIndex = -1;
    try {
      for (const {
        startIndex,
        endIndex,
        contextContentsIndex,
        sectionType,
      } of topClosestBlurbs) {
        const content = contextContents[contextContentsIndex];

        logger("content:");
        logger(content);

        // const sectionType = contentTypes[sectionTypeText];

        // give professor ossy the transcript title
        if (contextContentsIndex !== prevContentIndex) {
          lectureText += `\n${sectionType.name} title: ${content.title}\n`;
        }
        prevContentIndex = contextContentsIndex;

        if (sectionType === contentTypes.TRANSCRIPT) {
          const { contentArray, timestamps } = content;
          const filteredTranscriptArray = filterTranscriptArray(contentArray);

          // add all the text that corresponds to this embedding
          for (let i = startIndex; i < endIndex; i++) {
            let embeddingText = `${formatTimestamp(timestamps[i])} - ${
              filteredTranscriptArray[i]
            }\n`;

            lectureText += embeddingText;
          }
        } else if (sectionType === contentTypes.DOCUMENT) {
          const { contentArray } = content;

          //  indices starts at 0, page numbers start at 1. so we need to add 1
          lectureText += `Page ${startIndex + 1}\n`;
          lectureText += `${contentArray.slice(startIndex, endIndex)}\n`;
        }
      }

      logger(`here 7`);
    } catch (e) {
      console.error(e);
    }

    return lectureText;
  }
);

export const generateAITranscriptTitle = createAsyncThunk(
  "users/generateAITranscriptTitle",
  async (
    { supabase, transcriptTitleRef, setDisplayedTranscriptTitle },
    { getState, dispatch }
  ) => {
    const newDebugJson = { ...getState().routes.transcript.debugJson };
    const { contentArray, id: transcriptId } = getState().routes.transcript;
    const unlatexedIndex = getState().routes.unlatexedIndex;
    const mergedTranscript = contentArray
      .slice(0, unlatexedIndex)
      .map((blurb) => removeBlurbState(blurb))
      .join(" ");

    const input = { transcript: mergedTranscript };
    const body = JSON.stringify({
      input: input,
    });
    // generate an AI transcript title
    fetch(`${apiEndpoint}/generate_AI_transcript_title`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        let newTranscriptTitle = "";
        const reader = response.body.getReader();
        const currentTranscriptTitle = getState().routes.transcript.title;
        // ensure the transcript title has not been changed from the default
        // while fetching the AI transcript title
        if (currentTranscriptTitle === initialState.transcript.title) {
          return new ReadableStream({
            start(controller) {
              return pump();
              async function pump() {
                return reader.read().then(async ({ done, value }) => {
                  newTranscriptTitle += new TextDecoder().decode(value);
                  setDisplayedTranscriptTitle(newTranscriptTitle);
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    dispatch(
                      setTranscriptTitle({
                        newTranscriptTitle: newTranscriptTitle,
                      })
                    );

                    // save the new title to supabase
                    newDebugJson.generatedAITitle = newTranscriptTitle;
                    supabase
                      .from("transcripts")
                      .update({
                        transcript_title: newTranscriptTitle,
                        debug_json: newDebugJson,
                      })
                      .eq("transcript_id", transcriptId)
                      .then((response) => {
                        logger(
                          [LogCategory.DEBUG],
                          `RESPONSE: ${JSON.stringify(response)}`
                        );
                      });

                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error generating a title: ", error);
      });
  }
);

export const deleteFolder = createAsyncThunk(
  "users/deleteFolder",
  async (
    { supabase, folderId, indexToRemove, deleteContents = false },
    { getState, dispatch }
  ) => {
    logger(getState().routes.folders);
    const folderToDelete = { ...getState().routes.folders[indexToRemove] };

    if (folderToDelete.id) {
      supabase
        .from("folders")
        .update({
          deleted: true,
        })
        .eq("folder_id", folderToDelete.id)
        .select()
        .then((response) => {
          logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
        });
    }

    // remove the folder locally after deleting the one we want to
    const folders = getState().routes.folders.filter(
      (folder, index) => index !== indexToRemove
    );

    if (deleteContents) {
      supabase
        .from("transcripts")
        .update({
          deleted: true,
        })
        .eq("transcript_folder_url", folderToDelete.folderUrl)
        .then((response) => {});
    }

    return { folders, folderUrl: folderToDelete.folderUrl, deleteContents };
  }
);

export const fetchFolderContentAndEmbeddings = createAsyncThunk(
  "users/fetchFolderContentAndEmbeddings",
  async ({ supabase, folderUrl }, { getState }) => {
    // fetch_folder_content_and_embeddings
    const { data: folderContent, error } = await supabase.rpc(
      `${sqlQueries.fetchFolderContentAndEmbeddings}`,
      { folder_url_input: folderUrl }
    );

    logger(folderContent);
    logger(error);
    return { folderContent };
  }
);

export const toggleBookmark = createAsyncThunk(
  "users/toggleBookmark",
  async ({ supabase, index: indexToChange }, { getState, dispatch }) => {
    // toggle the bookmark
    const bookmarks = [...getState().routes.transcript.bookmarks];
    bookmarks[indexToChange] = !bookmarks[indexToChange];

    // toggle the bookmark locally to
    dispatch(updateBookmarks({ bookmarks: bookmarks }));

    supabase
      .from("transcripts")
      .update({
        bookmarks: bookmarks,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const togglePrivacy = createAsyncThunk(
  "users/togglePrivacy",
  async (
    { supabase, contentType, makeContentPublic = false, index = undefined },
    { getState, dispatch }
  ) => {
    const contentPrivacy =
      index >= 0
        ? // if toggling privacy from the homepage
          contentType.id === contentTypes.FOLDER.id
          ? getState().routes.folders[index].isPrivate
          : getState().routes.contentsArray[index].isPrivate
        : // within folder, document, or transcript
          getState().routes[contentType.name].isPrivate;

    const newContentPrivacy = makeContentPublic ? false : !contentPrivacy;

    const contentId =
      index >= 0
        ? // if toggling privacy from the homepage
          contentType.id === contentTypes.FOLDER.id
          ? getState().routes.folders[index].id
          : getState().routes.contentsArray[index].id
        : // within folder, document, or transcript
          getState().routes[contentType.name].id;

    await supabase
      .from(databaseInfo[contentType.databaseObjKey].tableName)
      .update({
        [databaseInfo[contentType.databaseObjKey].tablePrivacy]:
          newContentPrivacy,
      })
      .eq(databaseInfo[contentType.databaseObjKey].tableId, contentId)
      .then((response) => logger([LogCategory.DEBUG], "Response: ", response));

    if (contentType.id === contentTypes.FOLDER.id) {
      dispatch(
        toggleContentPrivacyWithinFOlder({
          supabase: supabase,
          folderId: contentId,
          newPrivacy: newContentPrivacy,
        })
      );
    }

    return { privacy: newContentPrivacy, contentType, index };
  }
);

export const toggleContentPrivacyWithinFOlder = createAsyncThunk(
  "users/toggleContentPrivacyWithinFOlder",
  async ({ supabase, folderId, newPrivacy }, { getState }) => {
    const folderUrl = getState().routes.folders.reduce((folderUrl, folder) => {
      if (folderId === folder.id) {
        folderUrl = folder.folderUrl;
      }
      return folderUrl;
    }, "");

    // get the transcript ids within this folder
    const {
      error: errorFetchingTranscriptsInFolder,
      data: transcriptIdsInFolder,
    } = await supabase
      .from("transcripts")
      .select("transcript_id")
      .eq("transcript_folder_url", folderUrl);

    // get the document ids within this folder
    const { error: errorFetchingDocumentsInFolder, data: documentIdsInFolder } =
      await supabase
        .from("documents")
        .select("document_id")
        .eq("document_folder_url", folderUrl);

    if (errorFetchingTranscriptsInFolder) {
      logger(
        [LogCategory.ERROR],
        "Error fetching the transcript ids in the folder: ",
        errorFetchingTranscriptsInFolder
      );
    }

    if (errorFetchingDocumentsInFolder) {
      logger(
        [LogCategory.ERROR],
        "Error fetching the document ids in the folder: ",
        errorFetchingDocumentsInFolder
      );
    }

    // toggle the privacy for every transcript within the folder
    for (const { transcript_id } of transcriptIdsInFolder) {
      await supabase
        .from("transcripts")
        .update({ transcript_privacy: newPrivacy })
        .eq("transcript_id", transcript_id)
        .then((response) => {
          logger(
            [LogCategory.DEBUG],
            `Updated privacy for transcriptId: ${transcript_id}:`,
            response
          );
        });
    }

    // toggle the privacy for every document within the folder
    for (const { document_id } of documentIdsInFolder) {
      await supabase
        .from("documents")
        .update({ document_privacy: newPrivacy })
        .eq("document_id", document_id)
        .then((response) => {
          logger(
            [LogCategory.DEBUG],
            `Updated privacy for documentId: ${document_id}:`,
            response
          );
        });
    }
  }
);

export const addToFolder = createAsyncThunk(
  "users/addToFolder",
  async ({ supabase, contentIndex, folderUrl }, { dispatch, getState }) => {
    const isTranscript =
      getState().routes.contentsArray[contentIndex].contentType ===
      contentTypes.TRANSCRIPT;
    const contentId = getState().routes.contentsArray[contentIndex].id;

    dispatch(
      updateContentFolderUrlLocal({
        contentFolderUrl: folderUrl,
        contentIndex: contentIndex,
        isTranscript,
      })
    );
    await supabase
      .from(isTranscript ? "transcripts" : "documents")
      .update(
        isTranscript
          ? {
              transcript_folder_url: folderUrl,
            }
          : { document_folder_url: folderUrl }
      )
      .eq(isTranscript ? "transcript_id" : "document_id", contentId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const removeFromFolder = createAsyncThunk(
  "users/removeFromFolder",
  async (
    { supabase, contentIndex, isFolderSelected, selected, contentType },
    { dispatch, getState }
  ) => {
    const isTranscript =
      getState().routes.contentsArray[contentIndex].contentType ===
      contentTypes.TRANSCRIPT;
    const contentId = getState().routes.contentsArray[contentIndex].id;
    const folderUrl = getState().routes.contentsArray[contentIndex].folderUrl;
    const folderName = getState().routes.folderUrlToName[folderUrl];

    // on the folder page
    if (isFolderSelected && selected === folderName) {
      dispatch(deleteContentLocal({ index: contentIndex }));
    } else {
      // on the main page
      dispatch(
        updateContentFolderUrlLocal({
          contentFolderUrl: "",
          contentIndex: contentIndex,
          contentType,
        })
      );
    }

    supabase
      .from(isTranscript ? "transcripts" : "documents")
      .update(
        isTranscript
          ? {
              transcript_folder_url: "",
            }
          : { document_folder_url: "" }
      )
      .eq(isTranscript ? "transcript_id" : "document_id", contentId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const deleteTranscript = createAsyncThunk(
  "users/deleteTranscript",
  async ({ supabase, index }, { getState, dispatch }) => {
    // get the id of the transcript we're deleting BEFORE modifying transcripts locally
    const deletedTranscriptId = getState().routes.contentsArray[index].id;

    // delete the transcription from the front end
    dispatch(deleteContentLocal({ index }));

    // delete the transcript from the backend
    supabase
      .from("transcripts")
      .update({
        deleted: true,
      })
      .eq("transcript_id", deletedTranscriptId)
      .then((response) => {
        logger(
          [LogCategory.DEBUG],
          `deleted transcript: ${JSON.stringify(response)}`
        );
      });

    // delete the transcript audio if it exists
    for (const mimeType of ["mp4", "ogg"]) {
      const { data, error } = await supabase.storage
        .from("recordings")
        .remove([
          `${getState().routes.userId}/${deletedTranscriptId}.${mimeType}`,
        ]);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "users/deleteDocument",
  async ({ supabase, index }, { getState, dispatch }) => {
    const deletedDocumentId = getState().routes.contentsArray[index].id;

    // delete the transcription from the front end
    dispatch(deleteContentLocal({ index }));

    // delete the transcript from the backend
    supabase
      .from("documents")
      .update({
        deleted: true,
      })
      .eq("document_id", deletedDocumentId)
      .then((response) => {
        logger(
          [LogCategory.DEBUG],
          `deleted transcript: ${JSON.stringify(response)}`
        );
      });

    // delete the transcript audio if it exists
    const { data, error } = await supabase.storage
      .from("slides")
      .remove([`${getState().routes.userId}/${deletedDocumentId}.pdf`]);
  }
);

// export const saveContent = createAsyncThunk(
//   "users/saveContent",
//   async ({ supabase, contentId, contentType }, { getState }) => {
//     logger([LogCategory.DEBUG], "saved");

//     // get all the saved content
//     const {
//       data: [{ saved_content_ids: savedContentIds }],
//     } = await supabase
//       .from("user data")
//       .select("saved_content_ids")
//       .eq("user_id", getState().routes.userId);

//     // check if it's already saved
//     const newSavedContentIds = [...savedContentIds];
//     let alreadySaved = false;
//     for (let i = 0; i < newSavedContentIds; i++) {
//       if (newSavedContentIds[i].id === contentId) {
//         alreadySaved = true;
//         break;
//       }
//     }

//     if (alreadySaved) {
//       return { contentType };
//     }

//     // if it's not saved then add this to the saved stuff
//     newSavedContentIds.push({ type: contentType.name, id: contentId });

//     const { error } = await supabase
//       .from("user data")
//       .update({
//         saved_content_ids: newSavedContentIds,
//       })
//       .eq("user_id", getState().routes.userId);

//     return {
//       contentType,
//     };
//   }
// );

// export const unsaveContent = createAsyncThunk(
//   "users/unsaveContent",
//   async ({ supabase, contentId, contentType }, { getState }) => {
//     logger([LogCategory.DEBUG], "unsaving transcript");
//     const {
//       data: [{ saved_content_ids: savedContentIds }],
//     } = await supabase
//       .from("user data")
//       .select("saved_content_ids")
//       .eq("user_id", getState().routes.userId);

//     const { error } = await supabase
//       .from("user data")
//       .update({
//         saved_content_ids: [...savedContentIds].filter(
//           (id) => id !== contentId
//         ),
//       })
//       .eq("user_id", getState().routes.userId);

//     return { contentId: contentId, contentType };
//   }
// );

export const saveContent = createAsyncThunk(
  "users/saveContent",
  async ({ supabase, contentId, contentType }, { getState }) => {
    logger([LogCategory.DEBUG], "saved");

    const {
      data: [
        { [databaseInfo[contentType.databaseObjKey].tableSavedIds]: savedIds },
      ],
    } = await supabase
      .from("user data")
      .select(databaseInfo[contentType.databaseObjKey].tableSavedIds)
      .eq("user_id", getState().routes.userId);

    const newSavedIds = [...savedIds];
    if (!newSavedIds.includes(contentId)) {
      newSavedIds.push(contentId);
    }

    const { error } = await supabase
      .from("user data")
      .update({
        [databaseInfo[contentType.databaseObjKey].tableSavedIds]: newSavedIds,
      })
      .eq("user_id", getState().routes.userId);

    return {
      contentType,
    };
  }
);

export const unsaveContent = createAsyncThunk(
  "users/unsaveContent",
  async ({ supabase, contentId, contentType }, { getState, dispatch }) => {
    logger([LogCategory.DEBUG], "unsaving transcript");
    const userId = getState().routes.userId;

    const {
      data: [
        { [databaseInfo[contentType.databaseObjKey].tableSavedIds]: savedIds },
      ],
    } = await supabase
      .from("user data")
      .select(databaseInfo[contentType.databaseObjKey].tableSavedIds)
      .eq("user_id", userId);

    const { error } = await supabase
      .from("user data")
      .update({
        [databaseInfo[contentType.databaseObjKey].tableSavedIds]: [
          ...savedIds,
        ].filter((id) => id !== contentId),
      })
      .eq("user_id", userId);

    if (contentType === contentTypes.FOLDER) {
      dispatch(
        unsaveContentWhenUnsaveFolder({ supabase, folderId: contentId })
      );
    }

    return { contentId: contentId, contentType };
  }
);

export const unsaveContentWhenUnsaveFolder = createAsyncThunk(
  "users/unsaveContentWhenUnsaveFolder",
  async ({ supabase, folderId }, { getState }) => {
    const userId = getState().routes.userId;

    // get the folder url of the folder id
    const {
      data: [folderUrlObj],
      error,
    } = await supabase
      .from("folders")
      .select("folder_url")
      .eq("folder_id", folderId)
      .limit(1);

    if (folderUrlObj !== undefined) {
      const folderUrl = folderUrlObj.folder_url;
      // get all the transcript ids and document ids within the folder
      const { data: transcriptIdsInFolder, error: transcriptIdsInFolderError } =
        await supabase
          .from("transcripts")
          .select("transcript_id")
          .eq("transcript_folder_url", folderUrl);
      const { data: documentsIdsInFolder, error: documentsIdsInFolderError } =
        await supabase
          .from("documents")
          .select("document_id")
          .eq("document_folder_url", folderUrl);

      const IdsToRemove = new Set();
      transcriptIdsInFolder.map(({ transcript_id }) =>
        IdsToRemove.add(transcript_id)
      );
      documentsIdsInFolder.map(({ document_id }) =>
        IdsToRemove.add(document_id)
      );

      // get the saved ids that the user has
      const {
        data: [{ saved_transcript_ids: savedIds }],
        error: savedIdsError,
      } = await supabase
        .from("user data")
        .select("saved_transcript_ids")
        .eq("user_id", userId);

      // remove ids from saved ids that appear in the folder
      await supabase
        .from("user data")
        .update({
          saved_transcript_ids: savedIds.filter((id) => !IdsToRemove.has(id)),
        })
        .eq("user_id", getState().routes.userId)
        .then((response) =>
          logger(
            LogCategory.DEBUG,
            "done removing ids that where in folder an in saved transcript id: ",
            response
          )
        );
    } else {
      logger([LogCategory.ERROR], "folderUrlObj is undefined");
    }
  }
);

export const editDocumentTitle = createAsyncThunk(
  "users/editDocumentTitle",
  async ({ supabase, newTitle, index }, { getState, dispatch }) => {
    let { id: documentId } = getState().routes.document;

    // update the transcript title locally (from home page)
    if (index !== undefined) {
      // update transcript title locally
      dispatch(
        updateContentTitleLocal({
          newContentTitle: newTitle,
          index: index,
        })
      );
      documentId = getState().routes.contentsArray[index].id;
    }

    // update transcript title on backend
    supabase
      .from("documents")
      .update({
        document_title: newTitle,
      })
      .eq("document_id", documentId)
      .then((response) => {
        if (response.error) {
          logger(
            [LogCategory.ERROR],
            "edit transcript title error: " + response.error
          );
          return;
        }
      });

    return { documentTitle: newTitle };
  }
);

export const editTranscriptTitle = createAsyncThunk(
  "users/editTranscriptTitle",
  async ({ supabase, newTitle, index }, { getState, dispatch }) => {
    logger("editing title:");
    logger(newTitle);
    logger(index);
    // new transcript and haven't created transcript
    if (getIsNewTranscriptUrl(window.location.pathname) && !fetchedUrl) {
      fetchedUrl = true;
      logger([LogCategory.DEBUG], "actually creating transcript");
      await dispatch(
        actuallyCreateTranscript({
          supabase,
          transcriptTitle: newTitle,
        })
      ).unwrap();
    }
    let { id: transcriptId } = getState().routes.transcript;

    // update the transcript title locally (from home page)
    if (index !== undefined) {
      // update transcript title locally
      dispatch(
        updateContentTitleLocal({
          newContentTitle: newTitle,
          index: index,
        })
      );
      transcriptId = getState().routes.contentsArray[index].id;
    }

    // update transcript title on backend
    supabase
      .from("transcripts")
      .update({
        transcript_title: newTitle,
      })
      .eq("transcript_id", transcriptId)
      .then((response) => {
        if (response.error) {
          logger(
            [LogCategory.ERROR],
            "edit transcript title error: " + response.error
          );
          return;
        }
      });

    return { transcriptTitle: newTitle };
  }
);

// when the user manually edits the transcript, save it on backend
export const saveTranscriptEdits = createAsyncThunk(
  "users/editTranscript",
  async (supabase, { getState }) => {
    supabase
      .from("transcripts")
      .update({
        transcript_array: getState().routes.transcript.contentArray,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then((response) => {
        logger([LogCategory.DEBUG], `response: ${JSON.stringify(response)}`);
      });
  }
);

export const createEmbeddingObjLastFewBlurbs = createAsyncThunk(
  "users/createEmbeddingObjLastFewBlurbs",
  async ({ supabase }, { dispatch, getState }) => {
    const { contentArray } = getState().routes.transcript;
    // we're already going to generate an embedding after latex is parsed
    if (contentArray.length % 10 === 0) return undefined;
    const unembeddedIndex = contentArray.length - (contentArray.length % 10);
    return await dispatch(
      getTranscriptEmbeddingObj({
        supabase,
        startIndex: unembeddedIndex,
        endIndex: contentArray.length,
      })
    ).unwrap();
  }
);

const getTranscriptEmbeddingObj = createAsyncThunk(
  "getTranscriptEmbeddingObj",
  async ({ startIndex, endIndex }, { getState }) => {
    logger(
      `creating transcript embedding. blurbs ${startIndex} to ${endIndex}`
    );
    const { contentArray } = getState().routes.transcript;
    const embeddingResponseObj = await fetch(
      `${apiEndpoint}/generate_embedding`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: contentArray.slice(startIndex, endIndex).join(" "),
          embeddingType: "transcript_embedding",
          transcriptId: getState().routes.transcript.id,
        }),
      }
    );
    const { embedding } = await embeddingResponseObj.json();

    return { embedding, startIndex, endIndex };
  }
);

const createContentMessages = createAsyncThunk(
  "users/createContentMessages",
  async ({ supabase, contentId, contentType }, { getState, dispatch }) => {
    // create the chat messages entry in the table corresponding to the content type
    const { data: messages, error: messagesError } = await supabase
      .from(databaseInfo[contentType.databaseObjKey].messagesTable)
      .insert({
        [databaseInfo[contentType.databaseObjKey].tableId]: contentId,
        chat_messages: [],
        user_id: getState().routes.userId,
      });

    if (messagesError) {
      logger(
        [LogCategory.DEBUG],
        "Error creating the messages entry for the new content: ",
        messagesError
      );
    }
  }
);

// if this is a brand new transcript, go ahead and create it now
export const actuallyCreateTranscript = createAsyncThunk(
  "users/actuallyCreateTranscript",
  async (
    {
      supabase,
      transcriptTitle = undefined,
      slidesUrl = undefined,
      videoUrl = undefined,
    },
    { getState, dispatch }
  ) => {
    try {
      // get the current transcript with any text that's already been transcribed
      const newTranscript = { ...getState().routes.transcript };

      logger([LogCategory.INFO], `transcript title: ${transcriptTitle}`);

      // update the creation date and set transcript id
      if (transcriptTitle !== undefined) {
        newTranscript.title = transcriptTitle;
      }
      if (slidesUrl !== undefined) {
        newTranscript.slidesUrl = slidesUrl;
      }
      if (videoUrl !== undefined) {
        newTranscript.videoUrl = videoUrl;
      }

      await dispatch(fetchFolders({ supabase })).unwrap();
      const folderUrlToName = getState().routes.folderUrlToName;
      const searchParams = new URLSearchParams(window.location.search);
      const tempFolderUrl = searchParams.get("f") || "";
      const folderUrl =
        folderUrlToName[tempFolderUrl] !== undefined ? tempFolderUrl : "";

      const isFolderPrivate = getState().routes.folders.reduce(
        (isFolderPrivate, folder) => {
          if (folder.folderUrl === folderUrl) {
            isFolderPrivate = false;
          }
          return isFolderPrivate;
        },
        true
      );

      newTranscript.creationDate = getDate();
      newTranscript.id = uuidv4();
      newTranscript.folderUrl = folderUrl;
      newTranscript.pageUrl = await createTranscriptUrl(supabase);
      newTranscript.isPrivate =
        isFolderPrivate === false ? false : newTranscript.isPrivate;
      logger([LogCategory.INFO], `got the url: ${newTranscript.pageUrl}`);

      // push the new transcript to the backend
      // CHNAGE FROM summary TO summary_sentences
      const { data, error } = await supabase
        .from("transcripts")
        .insert({
          transcript_id: newTranscript.id,
          transcript_title: newTranscript.title,
          creation_date: newTranscript.creationDate,
          transcript_array: newTranscript.contentArray,
          timestamps: newTranscript.timestamps,
          bookmarks: newTranscript.bookmarks,
          audio_url: newTranscript.audioUrl,
          gpt_json: [], // DELETE LATER
          gpt_json_array: newTranscript.gptJsonArray,
          summary: newTranscript.summarySentences,
          summary_bullet_points: newTranscript.summaryBulletPoints,
          transcript_url: newTranscript.pageUrl,
          debug_json: newTranscript.debugJson,
          transcript_folder_url: newTranscript.folderUrl,
          deleted: false,
          transcript_privacy: newTranscript.isPrivate,
          user_id: getState().routes.userId,
          audio_duration: newTranscript.audioDuration,
          paid_blurb_index: newTranscript.paidBlurbIndex,
          slides_url: newTranscript.slidesUrl,
          video_url: newTranscript.videoUrl,
        })
        .select();

      const { data: transcriptExpenses, error: logTranscriptExpensesFail } =
        await supabase
          .from("transcript_expenses")
          .insert({ transcript_id: newTranscript.id })
          .select();

      // create the chat messages entry in the table transcript_chats
      await dispatch(
        createContentMessages({
          supabase,
          contentId: newTranscript.id,
          contentType: contentTypes.TRANSCRIPT,
        })
      ).unwrap();

      // replace the current url with this new one
      window.history.replaceState(
        null,
        getState().routes.transcript.title,
        `/t/${newTranscript.pageUrl}`
      );

      if (error) {
        console.error(`error creating transcript: `, error);
      }

      return {
        creationDate: newTranscript.creationDate,
        transcriptId: newTranscript.id,
        folderUrl: newTranscript.folderUrl,
        transcriptUrl: newTranscript.pageUrl,
        title: newTranscript.title,
        isPrivate: newTranscript.isPrivate,
      };
    } catch (e) {
      console.error(e);
    }
  }
);

const checkAndImposeLimit = createAsyncThunk(
  "users/checkAndImposeLimit",
  async ({ supabase }, { getState, dispatch }) => {
    logger("checking if hit free limit...");
    const { contentArray } = getState().routes.transcript;
    const reachedWeeklyQuota = await dispatch(
      checkReachedWeeklyQuota({ supabase })
    ).unwrap();

    logger(`reached the quota: ${JSON.stringify(reachedWeeklyQuota)}`);

    if (reachedWeeklyQuota) {
      logger(`updating paid blurb index: ${contentArray.length}`);
      logger(`updating paid blurb index: ${contentArray.length}`);
      supabase
        .from("transcripts")
        .update({
          paid_blurb_index: contentArray.length,
        })
        .eq("transcript_id", getState().routes.transcript.id)
        .then((response) => {
          logger(`updating paid blurb index response:`);
          logger(response);
        });
    }

    return { paidBlurbIndex: reachedWeeklyQuota ? contentArray.length : -1 };
  }
);

let fetchedUrl = false;

// just received more text from transcription api
export const addToTranscriptArray = createAsyncThunk(
  "users/addToTranscriptArray",
  async ({ supabase, results }, { getState, dispatch }) => {
    const { timestamps, bookmarks, contentArray, paidBlurbIndex } =
      getState().routes.transcript;

    const newTranscriptArray = [...contentArray];
    const newTimestamps = [...timestamps];
    const newBookmarks = [...bookmarks];

    // timestamp of the last result
    let prevEndTimestamp = getState().routes.prevEndTimestamp;

    // keeping track of if the last result was partial or final
    let prevTranscriptType = getState().routes.prevTranscriptType;
    let unfinishedText = getState().routes.unfinishedText;
    unfinishedText = displayBlurbState(
      unfinishedText,
      blurbState.PARTIAL_REV_AI
    );

    // we want to know if this result is a fragment or a complete sentence
    let endsSentence = false;

    // if the user paused at some point, add this timestamp onto the previous
    const timestamp = Number(
      (results.ts + getState().routes.pausedTimestamp).toFixed(2)
    );

    // maintain the previous timestamp
    prevEndTimestamp = results.end_ts + getState().routes.pausedTimestamp;
    logger(`new prev end timestamp: ${prevEndTimestamp}`);

    let lastFinalBlurbIndex = getState().routes.lastFinalBlurbIndex;

    // partial transcripts need to have spaces inserted between; final do not
    let delimiter = "";

    // new result; create new entry in transcript array
    if (prevTranscriptType === initialState.FINAL) {
      newTranscriptArray.push("");
      newTimestamps.push(0);
      newBookmarks.push(false);
      prevTranscriptType = initialState.PARTIAL;
    }

    // update the transcript
    if (results.type === "partial") {
      delimiter = " ";
    } else if (results.type === "final") {
      // we don't want blurbs that are fragments; only make a new blurb if the text
      // ends the sentence with proper punctuation.
      endsSentence =
        results.elements.at(-1).type === "punct" &&
        [".", "!", "?"].includes(results.elements.at(-1).value);

      if (endsSentence) {
        prevTranscriptType = getState().routes.FINAL;
        lastFinalBlurbIndex += 1;

        logger([LogCategory.DEBUG], "new final blurb that ends a sentence");
      }
    }

    // put the result together in a sentence
    const newText = results.elements
      .map((result) => result.value)
      .join(delimiter);

    // populate last entry in transcript array with new information
    newTranscriptArray[newTranscriptArray.length - 1] =
      unfinishedText + newText;
    newTimestamps[newTimestamps.length - 1] = timestamp;

    // store the final result if we are not making a new blurb for it
    if (results.type === "final" && !endsSentence) {
      unfinishedText += newText + " ";

      // reset unfinishedText if we finally ended the sentence
    } else if (results.type === "final" && endsSentence) {
      unfinishedText = "";
      // denote the blurb as being final when not in production
      let lastBlurb = newTranscriptArray[newTranscriptArray.length - 1];
      lastBlurb = displayBlurbState(lastBlurb, blurbState.FINAL_REV_AI);
      newTranscriptArray[newTranscriptArray.length - 1] = lastBlurb;
    }

    if (results.type === "final" && endsSentence) {
      // free user; check if they just hit the limit
      if (!getState().routes.isPaidUser && paidBlurbIndex === -1) {
        dispatch(checkAndImposeLimit({ supabase }));
      }
      // save the newTransriptArray, newTimestamps, and newBookmarks to supabase
      dispatch(
        saveFinalRevAI({
          supabase,
          newTranscriptArray: newTranscriptArray,
          newTimestamps: newTimestamps,
          newBookmarks: newBookmarks,
          prevEndTimestamp: prevEndTimestamp,
        })
      );
    }

    return {
      prevTranscriptType,
      prevEndTimestamp,
      newTranscriptArray,
      newTimestamps,
      newBookmarks,
      unfinishedText,
      lastFinalBlurbIndex,
      paidBlurbIndex,
    };
  }
);

export const saveFinalRevAI = createAsyncThunk(
  "users/saveFinalRevAI",
  async (
    {
      supabase,
      newTranscriptArray,
      newTimestamps,
      newBookmarks,
      prevEndTimestamp,
    },
    { getState, dispatch }
  ) => {
    // save the newTransriptArray, newTimestamps, and newBookmarks to supabase
    supabase
      .from("transcripts")
      .update({
        transcript_array: newTranscriptArray,
        timestamps: newTimestamps,
        bookmarks: newBookmarks,
        audio_duration: prevEndTimestamp,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then(async (response) => {
        // actually create the transcript now that we've added results to the backend
        if (getIsNewTranscriptUrl(window.location.pathname) && !fetchedUrl) {
          fetchedUrl = true;
          await dispatch(actuallyCreateTranscript({ supabase })).unwrap();
        }

        // saw raw rev ai output to supabase
        const rawRevAIOutput =
          newTranscriptArray[newTranscriptArray.length - 1];
        dispatch(saveRawRevAIOutput({ supabase, rawRevAIOutput }));
      });
  }
);

export const startPipeline = createAsyncThunk(
  "users/startPipeline",
  async ({ supabase }, { getState, dispatch }) => {
    // if the result is final, reached the end of a sentence, and is not currrent merging, then merge
    try {
      logger([LogCategory.PIPELINE]);
      logger([LogCategory.PIPELINE], "STARTING PIPELINE");
      // create a lock so merge blurbs can not happen at the same time
      dispatch(setCurrentlyMerging({ currentlyMerging: true })); // may be an issue because not synchronous
      const { startIndex, unmergedIndex } = await dispatch(
        mergeBlurbs({
          supabase,
        })
      ).unwrap();
      dispatch(setCurrentlyMerging({ currentlyMerging: false }));
      logger([LogCategory.PIPELINE], "done merging blurbs");

      logger([LogCategory.PIPELINE], "new unmerged index: ", unmergedIndex);
      // startIndex must be less than unmergedIndex to ensure that a
      // blurb is only autocorrected and parsed for latex once
      const endIndex = unmergedIndex - 1;
      if (startIndex < unmergedIndex) {
        logger(
          [LogCategory.PIPELINE],
          "update blurbs start index: ",
          startIndex,
          "endIndex: ",
          endIndex
        );
        // autoCorrect and parseLatex from [startIndex, endIndex (inclusive)]
        dispatch(updateBlurbs({ supabase, startIndex, endIndex }));
      }
    } catch (error) {
      dispatch(setCurrentlyMerging({ currentlyMerging: false }));
    }
  }
);

export const mergeBlurbs = createAsyncThunk(
  "users/mergeBlurbs",
  async ({ supabase }, { getState, dispatch, rejectWithValue }) => {
    const startIndex = getState().routes.unmergedIndex;
    let endIndex = getState().routes.lastFinalBlurbIndex + 1;

    const isUploadVideo = getState().routes.transcript.debugJson.uploadVideo;
    if (isUploadVideo) {
      const transcriptLength = getState().routes.transcript.contentArray;
      endIndex = transcriptLength;
    }

    const { contentArray, bookmarks, timestamps } =
      getState().routes.transcript;

    const transcriptJSONList = [];
    for (let i = startIndex; i < endIndex; i++) {
      transcriptJSONList.push({
        text: removeBlurbState(contentArray[i]),
        timestamp: timestamps[i],
        bookmark: bookmarks[i],
      });
    }

    const input = {
      transcriptJSONList: transcriptJSONList,
      transcriptId: getState().routes.transcript.id,
    };

    const body = JSON.stringify({
      input: input,
    });
    const response = await fetch(`${apiEndpoint}/merge_sentences`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        logger([LogCategory.PIPELINE], "Done merging blurbs");
        const mergedBlurbsList = await response.json();
        const newTranscriptArray = mergedBlurbsList.map((object) =>
          displayBlurbState(object["text"], blurbState.MERGE)
        );
        const newBookmarks = mergedBlurbsList.map(
          (object) => object["bookmark"]
        );
        const newTimestamps = mergedBlurbsList.map(
          (object) => object["timestamp"]
        );
        logger(
          [LogCategory.PIPELINE],
          "newTranscriptArray: ",
          newTranscriptArray
        );
        logger([LogCategory.PIPELINE], "newBookmarks: ", newBookmarks);
        logger([LogCategory.PIPELINE], "newTimestamps: ", newTimestamps);
        const newUnmergedIndex = startIndex + newTranscriptArray.length - 1;
        // successfully merged all blurbs
        return {
          newTranscriptArray,
          newBookmarks,
          newTimestamps,
          startIndex: startIndex,
          endIndex: endIndex,
          unmergedIndex: newUnmergedIndex,
        };
        // COME BACK: MAY NEED TO RECURSIVELY CALL MERGE BLURBS
      })
      .catch((reason) => {
        console.error(
          `couldn't merge sentences for whatever reason: ${reason}, new unmergedIndex: ${
            startIndex + 1
          }`
        );
        const oldText = displayBlurbState(
          contentArray[startIndex],
          blurbState.FAILED_MERGE
        );
        return rejectWithValue({
          oldText: oldText,
          index: startIndex,
          unmergedIndex: startIndex + 1,
        });
      });
    return response;
  }
);

export const updateBlurbs = createAsyncThunk(
  "users/updateBlurbs",
  async ({ supabase, startIndex, endIndex }, { getState, dispatch }) => {
    for (let i = startIndex; i < endIndex + 1; i++) {
      await dispatch(autoCorrect({ supabase, index: i })).unwrap();
      await dispatch(parseLatex({ supabase, index: i })).unwrap();
    }
  }
);

export const autoCorrect = createAsyncThunk(
  "users/autoCorrect",
  async ({ supabase, index }, { getState, dispatch, rejectWithValue }) => {
    logger([LogCategory.DEBUG], `entered auto-correct`);

    const transcript = getState().routes.transcript;
    let text = transcript.contentArray[index];
    text = removeBlurbState(text);
    // last three sentences
    const contextArray = transcript.contentArray
      .slice(Math.max(0, index - 3), index)
      .map((text) => removeBlurbState(text));
    const context = contextArray.join(" ");
    const customVocab = transcript.debugJson.settings.customVocab;

    const input = {
      text: text,
      customVocab: customVocab,
      context: context,
      transcriptId: transcript.id,
    };

    const body = JSON.stringify({ input: input });
    const response = await fetch(`${apiEndpoint}/auto_correct`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        logger([LogCategory.DEBUG], "Entered .then of autocorrect function");
        const textWithAutoCorrect = await response.json();
        logger(
          [LogCategory.DEBUG, LogCategory.PIPELINE],
          `finished receiving autocorrect text: `
        );
        logger([LogCategory.DEBUG, LogCategory.PIPELINE], `original: ${text}`);
        logger(
          [LogCategory.DEBUG, LogCategory.PIPELINE],
          `new text: ${textWithAutoCorrect}`
        );
        const newText = displayBlurbState(
          textWithAutoCorrect,
          blurbState.AUTO_CORRECT
        );
        return {
          index,
          textWithAutoCorrect: newText,
          unautoCorrectedIndex: index + 1,
        };
      })
      .catch((reason) => {
        console.error(`couldn't auto correct for whatever reason: ${reason}`);
        const oldText = displayBlurbState(text, blurbState.FAILED_AUTO_CORRECT);
        return rejectWithValue({
          oldText: oldText,
          index: index,
          unautoCorrectedIndex: index + 1,
        });
      });
    return response;
  }
);

export const parseLatex = createAsyncThunk(
  "users/parseLatex",
  async ({ supabase, index }, { getState, dispatch, rejectWithValue }) => {
    logger([LogCategory.DEBUG], `entered parse latex`);

    const transcript = getState().routes.transcript;
    let text = transcript.contentArray[index];
    text = removeBlurbState(text);
    // don't try to parse latex if there's only one word
    if (text.split(" ").length === 1) {
      return {
        index: index,
        textWithLatex: text,
        unlatexedIndex: index + 1,
      };
    }
    // last three sentences
    const contextArray = transcript.contentArray
      .slice(Math.max(0, index - 3), index)
      .map((text) => removeBlurbState(text));
    const context = contextArray.join(" ");

    logger([LogCategory.DEBUG], `here is the context: ${context}`);

    const input = { text: text, context: context, transcriptId: transcript.id };
    const body = JSON.stringify({ input: input });
    const response = await fetch(`${apiEndpoint}/parse_latex`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        logger([LogCategory.DEBUG], "Entered .then of parse latex function");
        const textWithLatex = await response.json();
        logger(
          [LogCategory.DEBUG, LogCategory.PIPELINE],
          `finished receiving parse latex text.`
        );
        logger([LogCategory.DEBUG, LogCategory.PIPELINE], `original: ${text}`);
        logger(
          [LogCategory.DEBUG, LogCategory.PIPELINE],
          `new text: ${textWithLatex}`
        );

        // generate another embedding every ten blurbs
        if (index % 10 === 0 && index !== 0) {
          logger("10 blurbs passed, creating another embedding...");
          dispatch(
            addTranscriptEmbedding({
              supabase: supabase,
              startIndex: index - 10,
              endIndex: index,
            })
          );
        }

        // if the auto correct fails, just return the original text
        const newText = displayBlurbState(textWithLatex, blurbState.LATEX);
        return {
          index,
          textWithLatex: newText,
          unlatexedIndex: index + 1,
        };
      })
      .catch((reason) => {
        console.error(
          `couldn't parse the latex for whatever reason: ${reason}`
        );
        const oldText = displayBlurbState(text, blurbState.FAILED_LATEX);
        return rejectWithValue({
          oldText: oldText,
          index: index,
          unlatexedIndex: index + 1,
        });
      });
    return response;
  }
);

// save the raw rev ai output to  supabase
const saveRawRevAIOutput = createAsyncThunk(
  "users/saveRawRevAIOutput",
  async ({ supabase, rawRevAIOutput }, { getState, dispatch }) => {
    const transcriptId = getState().routes.transcript.id;
    // fetch current raw rev AI
    const { data, error } = await supabase
      .from("transcripts")
      .select("raw_rev_ai")
      .eq("transcript_id", transcriptId)
      .limit(1);

    const revAIOutput = data[0].raw_rev_ai;
    revAIOutput.push(rawRevAIOutput);
    // saves the joined raw rev ai output to  supabase
    supabase
      .from("transcripts")
      .update({
        raw_rev_ai: revAIOutput,
      })
      .eq("transcript_id", transcriptId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const addTranscriptEmbedding = createAsyncThunk(
  "users/addTranscriptEmbedding",
  async ({ supabase, startIndex, endIndex }, { dispatch, getState }) => {
    logger("adding embedding to end of transcript...");
    const { embeddings, id: transcriptId } = getState().routes.transcript;
    const embeddingObj = await dispatch(
      getTranscriptEmbeddingObj({ startIndex, endIndex })
    ).unwrap();
    logger(
      `embeddingObj: type: ${typeof embeddingObj}, embeddingObj: ${JSON.stringify(
        embeddingObj
      )}`
    );
    const newEmbeddings = [...embeddings];
    newEmbeddings.push(embeddingObj);

    logger(`new embeddings...`);
    logger(newEmbeddings);
    // storing embedding in the backend
    supabase
      .from("transcripts")
      .update({
        embeddings: newEmbeddings,
      })
      .eq("transcript_id", transcriptId)
      .then((response) => {
        logger(`RESPONSE: ${JSON.stringify(response)}`);
      });

    return { newEmbeddings };
  }
);

// generate the emebeddings for the entire transcript all at once, used for upload audio and video
export const addEntireTranscriptEmbeddings = createAsyncThunk(
  "users/addEntireTranscriptEmbeddings",
  async ({ supabase }, { dispatch, getState }) => {
    const { contentArray, id: transcriptId } = getState().routes.transcript;
    const promiseNewEmbeddings = [];

    const stepSize = 10;
    for (let i = 0; i < contentArray.length; i += stepSize) {
      const startIndex = i;
      const endIndex = Math.min(i + stepSize, contentArray.length);
      const embeddingObj = dispatch(
        getTranscriptEmbeddingObj({ startIndex, endIndex })
      ).unwrap();
      promiseNewEmbeddings.push(embeddingObj);
    }

    const newEmbeddings = await Promise.all(promiseNewEmbeddings);
    // storing embeddings in the backend
    supabase
      .from("transcripts")
      .update({
        embeddings: newEmbeddings,
      })
      .eq("transcript_id", transcriptId)
      .then((response) => {
        logger(`RESPONSE: ${JSON.stringify(response)}`);
      });
    return { newEmbeddings };
  }
);

export const saveSummarySentencesBackend = createAsyncThunk(
  "users/saveSummarySentencesBackend",
  async (
    { supabase, newSummarySentences, index, contentType },
    { getState, dispatch }
  ) => {
    // update summary sentences locally
    dispatch(
      updateSummarySentencesLocal({
        newSummarySentences,
        index,
      })
    );

    const contentId =
      index >= 0
        ? getState().routes.contentsArray[index].id
        : getState().routes[contentType.name].id;
    const { data, error } = supabase
      .from(databaseInfo[contentType.databaseObjKey].tableName)
      .update({
        [databaseInfo[contentType.databaseObjKey].tableSummarySentences]:
          newSummarySentences,
      })
      .eq(databaseInfo[contentType.databaseObjKey].tableId, contentId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });

    if (error) {
      logger(
        [LogCategory.ERROR],
        "Error saving the summary sentences to the backend: ",
        error
      );
    }
  }
);

export const saveSummaryBulletPointsBackend = createAsyncThunk(
  "users/updateSummaryBulletPoints",
  async (
    { supabase, newSummaryBulletPoints, index = undefined, contentType },
    { getState, dispatch }
  ) => {
    // update summary bullet points locally
    dispatch(
      updateSummaryBulletPointsLocal({
        newSummaryBulletPoints,
        index,
      })
    );

    const contentId =
      index >= 0
        ? getState().routes.contentsArray[index].id
        : getState().routes[contentType.name].id;
    // update summary bullet points on backend
    supabase
      .from(databaseInfo[contentType.databaseObjKey].tableName)
      .update({
        [databaseInfo[contentType.databaseObjKey].tableSummaryBulletPoints]:
          newSummaryBulletPoints,
      })
      .eq(databaseInfo[contentType.databaseObjKey].tableId, contentId)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const claimProfessorOssy = createAsyncThunk(
  "users/claimProfessorOssy",
  async ({ supabase }, { getState }) => {
    supabase
      .from("user data")
      .update({
        claimed_professor_ossy: true,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        logger(`RESPONSE: ${JSON.stringify(response)}`);
      });

    return { claimedProfessorOssy: true };
  }
);

export const getTranscriptsMatchingUserQuery = createAsyncThunk(
  "users/getTranscriptTitlesMatchingUserQuery",
  async ({ supabase, query }, { getState }) => {
    const userId = getState().routes.userId;
    const { data, error } = await supabase.rpc(
      "get_transcripts_matching_query",
      { query_input: query, user_id_input: userId }
    );
    if (error) {
      console.error(
        `Error searching for matching query, \nquery: ${query}, \nerror: `,
        error
      );
    }
    return error === null ? data : [];
  }
);

export const fetchUserData = createAsyncThunk(
  "users/fetchUserData",
  async ({ supabase }, { getState }) => {
    const { userId } = getState().routes;

    const { data: userData, error: selectDataError } = await supabase
      .from("user data")
      .select(
        "folders, dark_mode, email, email_preferences, professor_ossy_access, claimed_professor_ossy, paid_user, paused_subscription"
      )
      .eq("user_id", userId);

    if (selectDataError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching user data: " + JSON.stringify(selectDataError)
      );
    }

    return userData;
  }
);
export const fetchContentsArray = createAsyncThunk(
  "users/fetchContentsArray",
  async (
    {
      supabase,
      startFetchTranscriptIndex,
      folderUrl,
      selectedTranscriptsType,
      isInitialFetch = false,
    },
    { getState, dispatch }
  ) => {
    logger(
      [LogCategory.DEBUG],
      `Fetching ${JSON.stringify(
        startFetchTranscriptIndex.current
      )} transcripts, if folder, folderUrl: ${folderUrl}`
    );
    logger(folderUrl);
    logger(folderUrl.length);

    const userId = getState().routes.userId;
    let savedContentIds = undefined;
    let savedFolderIds = undefined;
    let _folderUrl = folderUrl;

    if (selectedTranscriptsType === transcriptsType.SHARED_TRANSCRIPTS) {
      const {
        data: [{ saved_transcript_ids, saved_folder_ids }],
      } = await supabase
        .from("user data")
        .select("saved_transcript_ids, saved_folder_ids")
        .eq("user_id", userId);

      savedContentIds = saved_transcript_ids;
      savedFolderIds = saved_folder_ids;
      _folderUrl = undefined;
    }

    logger("saved ids...");

    // fetch transcripts from [startIndex, endIndex) (endIndex not inclusive) in increments of incrementSize
    const incrementSize = 30;
    const startIndex = startFetchTranscriptIndex.current;
    const endIndex = startIndex + incrementSize - 1;

    const { data: contentsArray, error } = await supabase.rpc(
      `${sqlQueries.fetchCourseContent}`,
      {
        user_id_input: userId,
        start_index: startIndex,
        end_index: endIndex,
        folder_url_input: _folderUrl,
        saved_content_ids: savedContentIds,
        saved_folder_ids: savedFolderIds,
      }
    );
    if (error) {
      console.error(`Error retrieving course content`, error);
      return { fetchedTranscripts: [], isInitialFetch: true };
    }
    startFetchTranscriptIndex.current += contentsArray.length;
    logger("here's the content we got...");
    logger(contentsArray);
    return { contentsArray: contentsArray, isInitialFetch } ?? [];
  }
);

export const fetchDefaultCourseContent = createAsyncThunk(
  "users/fetchDefaultCourseContent",
  async ({ supabase, startIndex, endIndex, folderUrl }, { getState }) => {
    const userId = getState().routes.userId;
    logger("FETCHING DEFAULT");
    const { data: contentsArray, error } = await supabase.rpc(
      `${sqlQueries.fetchCourseContent}`,
      {
        user_id_input: userId,
        start_index: startIndex,
        end_index: endIndex,
        folder_url_input: folderUrl,
      }
    );
    if (error) {
      console.error(`Error retrieving course content`, error);
    }

    logger(contentsArray);
    return contentsArray ?? [];
  }
);

export const fetchFolders = createAsyncThunk(
  "users/fetchFolders",
  async ({ supabase, selectedTranscriptsType }, { getState }) => {
    const userId = getState().routes.userId;

    let savedFolderIds = undefined;
    if (selectedTranscriptsType === transcriptsType.SHARED_TRANSCRIPTS) {
      const {
        data: [{ saved_folder_ids }],
      } = await supabase
        .from("user data")
        .select("saved_folder_ids")
        .eq("user_id", userId);

      savedFolderIds = saved_folder_ids;
    }

    const { data: allFolders, error } = await supabase.rpc(
      `${sqlQueries.fetchFolders}`,
      {
        user_id_input: userId,
        saved_folder_ids: savedFolderIds,
      }
    );

    if (error) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching folders: " + JSON.stringify(error)
      );
    }
    return { allFolders, savedFolderIds };
  }
);

export const fetchContentEmbeddings = createAsyncThunk(
  "users/fetchContentEmbeddings",
  async ({ supabase, folderUrl }, { getState }) => {
    const userId = getState().routes.userId;
    const transcriptsQuery = supabase
      .from("transcripts")
      .select(`embeddings`)
      .eq("user_id", userId)
      .eq("deleted", false)
      .order("created_at", { ascending: false });

    if (folderUrl) {
      transcriptsQuery.eq("transcript_folder_url", folderUrl);
    }

    const { data: transcriptsEmbeddings, error: selectDataError } =
      await transcriptsQuery;

    logger("MADE IT OUT");
    logger(transcriptsEmbeddings);
    if (selectDataError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching all transcripts: " +
          JSON.stringify(selectDataError)
      );
    }

    logger("leaving fetch all transcripts");
    return transcriptsEmbeddings;
  }
);

export const moveToFolderIdInsteadOfName = createAsyncThunk(
  "users/moveToFolderIdInsteadOfName",
  async ({ supabase }, { getState }) => {
    const userId = getState().routes.userId;
    logger(
      `updating all folder names to folder ids in transcripts database...`
    );

    logger(userId);

    const { data: allFolders, error: selectFoldersError } = await supabase
      .from("folders")
      .select("folder_name, folder_url, folder_id")
      .eq("user_id", userId)
      .eq("deleted", false)
      .order("created_at", { ascending: false });

    if (selectFoldersError) {
      logger(
        [LogCategory.ERROR],
        "selection error fetching folders: " +
          JSON.stringify(selectFoldersError)
      );
    }

    logger(`got all the folders. there are ${allFolders.length} folders`);
    logger(`looping through...`);

    for (const { folder_name, folder_url, folder_id } of allFolders) {
      logger(`folder "${folder_name}", getting all transcripts...`);

      const response = await supabase
        .from("transcripts")
        .update({ transcript_folder_url: folder_id })
        .eq("transcript_id", getState().routes.transcript.id);

      logger(
        `updated transcript folder from folder_name to folder_id: ${JSON.stringify(
          response
        )}`
      );
    }
  }
);

// export const fetchSavedContent = createAsyncThunk(
//   "users/fetchSavedContent",
//   async ({ supabase, startIndex, endIndex }, { getState }) => {
//     logger([LogCategory.DEBUG], "fetching saved transcripts...");
//     const { userId } = getState().routes;

//     // get ids of all saved content
//     const {
//       data: [{ saved_content_ids: savedContentIds }],
//     } = await supabase
//       .from("user data")
//       .select("saved_content_ids")
//       .eq("user_id", userId);

//     const savedTranscriptPromises = savedContentIds
//       .slice(startIndex, endIndex)
//       .map(({type, id}) => {
//         const contentType = contentTypes[type]
//         return new Promise(
//           async (resolveFetchSavedContent, rejectFetchSavedContent) => {
//             const {
//               data: [savedTranscriptData],
//               error,
//             } = await supabase
//               .from("transcripts")
//               .select(
//                 `audio_url, creation_date, transcript_array, transcript_privacy, deleted, transcript_title, summary,
//               summary_bullet_points, transcript_url, timestamps, transcript_id, gpt_json_array, transcript_folder_url,
//               bookmarks, debug_json, paid_blurb_index`
//               )
//               .eq("deleted", false)
//               .eq("transcript_id", transcriptId);

//             if (error) {
//               rejectFetchSavedContent(error);
//             } else {
//               resolveFetchSavedContent(savedTranscriptData);
//             }
//           }
//         );
//       });

//     const savedTranscripts = await Promise.all(savedTranscriptPromises);
//     return savedTranscripts;
//   }
// );

export const updateGptJsonArray = createAsyncThunk(
  "users/updateGptJsonArray",
  async ({ newGptJsons, supabase }, { getState, dispatch }) => {
    const uploadVideo = getState().routes.transcript.debugJson.uploadVideo;
    const uploadAudio = getState().routes.transcript.debugJson.uploadAudio;
    let newGPTJsonArray = getState().routes.transcript.gptJsonArray;
    if (uploadVideo === true || uploadAudio === true) {
      newGPTJsonArray = newGPTJsonArray.concat(newGptJsons);
    } else {
      newGPTJsonArray = newGPTJsonArray.slice(0, -1).concat(newGptJsons);
    }

    // set the gpt json locally
    dispatch(setGptJsonArrayLocal({ newGPTJsonArray: newGPTJsonArray }));

    // set the gpt json backend
    supabase
      .from("transcripts")
      .update({
        gpt_json_array: newGPTJsonArray,
      })
      .eq("transcript_id", getState().routes.transcript.id)
      .then((response) => {
        logger([LogCategory.DEBUG], `RESPONSE: ${JSON.stringify(response)}`);
      });

    return newGPTJsonArray;
  }
);

export const handlePauseTranscript = createAsyncThunk(
  "handlePauseTranscript",
  async ({ supabase }, { getState, dispatch }) => {
    const { contentArray } = getState().routes.transcript;

    // we're already going to generate an embedding after latex is parsed
    if (contentArray.length % 10 === 0) return;
    const unembeddedIndex = contentArray.length - (contentArray.length % 10);
    dispatch(
      addTranscriptEmbedding({
        supabase,
        startIndex: unembeddedIndex,
        endIndex: contentArray.length,
      })
    );
  }
);

export const handleStopTranscript = createAsyncThunk(
  "handleStopTranscript",
  async ({ supabase }, { getState, dispatch }) => {
    const { contentArray } = getState().routes.transcript;
    // we're already going to generate an embedding after latex is parsed
    if (contentArray.length % 10 === 0) return;
    const unembeddedIndex = contentArray.length - (contentArray.length % 10);
    dispatch(
      addTranscriptEmbedding({
        supabase,
        startIndex: unembeddedIndex,
        endIndex: contentArray.length,
      })
    );
  }
);

export const saveVideoUrl = createAsyncThunk(
  "saveSlides",
  async ({ supabase, videoUrl }, { getState, dispatch }) => {
    dispatch(updateVideoUrlLocal({ newVideoUrl: videoUrl }));
    if (getIsNewTranscriptUrl(window.location.pathname) && !fetchedUrl) {
      // await dispatch(
      //   actuallyCreateTranscript({ supabase, videoUrl: videoUrl })
      // ).unwrap();
    }
  }
);

export const saveSlidesToStorage = createAsyncThunk(
  "saveSlides",
  async (
    {
      supabase,
      slidesArrayBuffer,
      setDisplaySlides,
      setDisplayLoadingSymbol,
      setDisplayErrorMessage,
      setErrorMsg,
    },
    { getState, dispatch }
  ) => {
    const userId = getState().routes.userId;
    const transcriptId = getState().routes.transcript.id;
    const fileType = "pdf";
    const path = `${userId}/${transcriptId}.${fileType}`;
    const bucket = "slides";

    // save the slides data to supabase
    const { data: uploadedFile, error } = await supabase.storage
      .from(bucket)
      .upload(path, slidesArrayBuffer, {
        upsert: true,
        contentType: "application/pdf",
      });

    if (error === null) {
      logger([LogCategory.DEBUG], "uploaded file to supabase: ", uploadedFile);

      const { data } = supabase.storage.from(bucket).getPublicUrl(path);
      const newSlidesUrl = data.publicUrl;
      if (getIsNewTranscriptUrl(window.location.pathname) && !fetchedUrl) {
        await dispatch(
          actuallyCreateTranscript({ supabase, slidesUrl: newSlidesUrl })
        ).unwrap();
      }
      dispatch(
        saveSlidesUrl({
          supabase,
          newSlidesUrl,
          setDisplaySlides,
          setDisplayLoadingSymbol,
          setDisplayErrorMessage,
          setErrorMsg,
        })
      );
    } else {
      console.error("Error uploading file to supabase: ", error);
      setErrorMsg("Error uploading the slides. Please try again.");
      setDisplayLoadingSymbol(false);
      setDisplayErrorMessage(true);
    }
  }
);

export const uploadPdfDocument = createAsyncThunk(
  "uploadPdfDocument",
  async (
    {
      supabase,
      slidesArrayBuffer,
      fileUploadErrorMsg,
      setUploadingFile,
      folderUrl,
      fileName,
    },
    { getState, dispatch }
  ) => {
    logger(`uploading pdf document: ${fileName}`);

    const userId = getState().routes.userId;
    const documentId = uuidv4();
    const fileType = "pdf";
    const path = `${userId}/${documentId}.${fileType}`;
    const bucket = "slides";

    // save the slides data to supabase
    const { data: uploadedFile, error } = await supabase.storage
      .from(bucket)
      .upload(path, slidesArrayBuffer, {
        upsert: true,
        contentType: "application/pdf",
      });

    if (error !== null) {
      console.error("Error uploading file to supabase: ", error);
      fileUploadErrorMsg(
        "It seems we're having trouble uploading this document. Please try again."
      );
      setUploadingFile(false);
      return;
    }

    logger([LogCategory.DEBUG], "uploaded file to supabase: ", uploadedFile);
    const { data } = supabase.storage.from(bucket).getPublicUrl(path);
    const newSlidesUrl = data.publicUrl;
    let documentTextArray = [];
    let documentEmbeddings = [];

    try {
      const response = await fetch(`${apiEndpoint}/get_pdf_text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pdf_url: newSlidesUrl }),
      });

      logger(`got something...`);
      documentTextArray = await response.json();
      logger(documentTextArray);

      documentEmbeddings = await Promise.all(
        documentTextArray.map(async (page, index) => {
          return (
            await (
              await fetch(`${apiEndpoint}/generate_embedding`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  text: page,
                  embeddingType: "document_page_embedding",
                  documentId: documentId,
                }),
              })
            ).json()
          )["embedding"];
        })
      );

      logger("document embeddings...");
      logger(documentEmbeddings);
      documentEmbeddings = documentEmbeddings.map((embedding, index) => {
        return {
          startIndex: index,
          endIndex: index + 1,
          embedding: embedding,
        };
      });
    } catch (fetchError) {
      console.error("unable to get pdf text");
    }

    const isFolderPrivate = getState().routes.folders.reduce(
      (isFolderPrivate, folder) => {
        if (folder.folderUrl === folderUrl) {
          isFolderPrivate = false;
        }
        return isFolderPrivate;
      },
      true
    );

    // save the document to supabase
    const {
      data: [newDocument],
      error: insertPdfError,
    } = await supabase
      .from("documents")
      .insert({
        document_id: documentId,
        file_url: newSlidesUrl,
        user_id: userId,
        document_folder_url: folderUrl,
        document_text_array: documentTextArray,
        document_embeddings: documentEmbeddings,
        document_title: fileName,
        document_url: await createDocumentUrl(supabase),
        document_privacy:
          isFolderPrivate === false ? false : initialState.document.isPrivate,
      })
      .select();
    // here
    // create the chat messages entry in the table document_chats
    await dispatch(
      createContentMessages({
        supabase,
        contentId: documentId,
        contentType: contentTypes.DOCUMENT,
      })
    ).unwrap();

    logger(`inserted pdf...`);
    logger(newDocument);
    logger(insertPdfError);
    if (error) {
      // set the error message
    }
    setUploadingFile(false);

    return { newDocument };
  }
);

export const saveSlidesUrl = createAsyncThunk(
  "saveSlides",
  async (
    {
      supabase,
      newSlidesUrl,
      setDisplaySlides,
      setDisplayLoadingSymbol,
      setDisplayErrorMessage,
      setErrorMsg,
    },
    { getState, dispatch }
  ) => {
    const transcriptId = getState().routes.transcript.id;

    // save the slides url to supabase
    const { data, error } = await supabase
      .from("transcripts")
      .update({
        slides_url: newSlidesUrl,
      })
      .eq("transcript_id", transcriptId);

    if (error === null) {
      logger([LogCategory.DEBUG], `RESPONSE: `, data);

      dispatch(updateSlidesUrlLocal({ newSlidesUrl: newSlidesUrl }));
      setDisplayLoadingSymbol(false);
      setDisplaySlides(true);
    } else {
      console.error("error saving the slides url: ", error);
      setErrorMsg("Error uploading the slides. Please try again.");
      setDisplayLoadingSymbol(false);
      setDisplayErrorMessage(true);
    }
  }
);

const getUserIdsThatMadeFolders = async ({ supabase, OSSY_TEAM_USER_IDS }) => {
  const userIdsThatMadeFolders = [];
  let { data: folders, error: fetchFoldersError } = await supabase
    .from("folders")
    .select("user_id");

  for (const { user_id } of folders) {
    // we need to filter ourselves out because we have created so many transcripts
    if (OSSY_TEAM_USER_IDS.includes(user_id)) {
      continue;
    }

    if (!userIdsThatMadeFolders.includes(user_id)) {
      userIdsThatMadeFolders.push(user_id);
    }
  }
  return userIdsThatMadeFolders;
};

const getActiveUsers = async ({
  supabase,
  twoWeeksAgoISO,
  oneDayAgo,
  fiveMinutesAgo,
  OSSY_TEAM_USER_IDS,
}) => {
  const dailyActiveUsersIds = [];
  const activeUsersIds = [];

  // get num active users (users that have viewed a transcript in the past 2 weeks)
  const { data: allActiveUserSessions, error: error2 } = await supabase
    .from("sessions")
    .select()
    .gte(`start_date`, twoWeeksAgoISO);
  for (const {
    user_id,
    start_date,
    end_date,
    new_user,
    transcript_id,
  } of allActiveUserSessions) {
    // maintain list of all active user ids
    if (!activeUsersIds.includes(user_id)) {
      activeUsersIds.push(user_id);
    }

    if (
      new Date(start_date) >= oneDayAgo &&
      !dailyActiveUsersIds.includes(user_id)
    ) {
      dailyActiveUsersIds.push(user_id);
    }
  }
  // currently active users (viewed a transcript in the past 5 minutes)
  let { data: currentlyActiveUserIds, error: error3 } = await supabase
    .from("sessions")
    .select("user_id")
    .gte(`end_date`, fiveMinutesAgo.toISOString());

  currentlyActiveUserIds = currentlyActiveUserIds.map(({ user_id }) => user_id);
  logger(
    [LogCategory.INFO],
    `currently active user ids: ${JSON.stringify(currentlyActiveUserIds)}`
  );

  let { data: allSessions, error: fetchAllSessionsError } = await supabase
    .from("sessions")
    .select("user_id, transcript_id");

  const transcriptsVisitations = {
    numVisits: [],
    percentTranscriptsForNumVisits: [],
  };

  const transcriptIdToNumVisits = {};
  for (const { user_id, transcript_id } of allSessions) {
    if (OSSY_TEAM_USER_IDS.includes(user_id)) {
      continue;
    }
    transcriptIdToNumVisits[transcript_id] =
      transcriptIdToNumVisits[transcript_id] ?? 0;
    transcriptIdToNumVisits[transcript_id] += 1;
  }

  const numVisitsToNumTranscripts = {};
  for (const transcriptId in transcriptIdToNumVisits) {
    const numVisits = transcriptIdToNumVisits[transcriptId];
    numVisitsToNumTranscripts[numVisits] =
      numVisitsToNumTranscripts[numVisits] ?? 0;
    numVisitsToNumTranscripts[numVisits] += 1;
  }

  const numVisitsToNumTranscriptsList = [];
  for (const numVisits in numVisitsToNumTranscripts) {
    numVisitsToNumTranscriptsList.push({
      numVisits,
      numTranscripts: numVisitsToNumTranscripts[numVisits],
    });
  }

  numVisitsToNumTranscriptsList.sort((a, b) => a.numVisits - b.numVisits);
  transcriptsVisitations.numVisits = numVisitsToNumTranscriptsList.map(
    ({ numVisits }) => numVisits
  );
  transcriptsVisitations.percentTranscriptsForNumVisits =
    numVisitsToNumTranscriptsList.map(({ numTranscripts }) => numTranscripts);

  return {
    dailyActiveUsersIds,
    activeUsersIds,
    currentlyActiveUserIds,
    transcriptsVisitations,
  };
};

export const routesSlice = createSlice({
  name: "transcription",
  initialState,
  reducers: {
    resetStartIndex: (state, { payload, type }) => {
      logger([LogCategory.DEBUG], "resetting start index");
      state.startIndex = 0;
      state.transcript.contentArray = [];
    },

    // index for when the user pauses transcribing
    handleResumeTranscript: (state, { payload, type }) => {
      state.startIndex = state.transcript.contentArray.length;

      // when we resume, continue the timestamp from
      // where it left off instead of from 0 again
      state.pausedTimestamp = state.prevEndTimestamp;

      // the previous transcript type needs to be final
      // or it will get overwritten by new results
      state.prevTranscriptType = state.FINAL;
    },

    setAudioURL: (state, { payload: { url }, type }) => {
      state.transcript.audioUrl = url;
    },

    getGoogleInfo: (
      state,
      {
        payload: { pfpUrl, fullName, userId, firstName, lastName, email },
        type,
      }
    ) => {
      // updating state
      state.pfpUrl = pfpUrl;
      state.fullName = fullName;
      state.userId = userId;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
    },

    logOut: (state, { payload: { response }, type }) => {
      state.isLoggedIn = false;
      state.justLoggedOut = true;
      window.localStorage.removeItem("app_state");
      logger("logging user out...");
      window.localStorage.setItem(
        "ossy-logout-event",
        `logout${Math.random()}`
      );

      // even though we trigger a log out event,
      // this tab won't detect it so we have to manually redirect to the login page
      window.location.pathname = "/login";
    },

    createNewFolderLocal: (state, { payload, type }) => {
      const foldersWithoutEmptyString = state.folders.filter(
        (folder, index) => folder !== ""
      );
      state.folders = [
        { ...initialState.folder },
        ...foldersWithoutEmptyString,
      ];
    },

    setFoldersLocal: (state, { payload: { newFolders } }) => {
      state.folders = newFolders;
    },

    atLoginPage: (state, { payload }) => {
      state.justLoggedOut = false;
    },

    updateFolders: (state, { payload: { folders }, type }) => {
      state.folders = folders;
    },

    deleteContentLocal: (state, { payload: { index }, type }) => {
      state.contentsArray.splice(index, 1);
    },

    updateContentTitleLocal: (
      state,
      { payload: { newContentTitle, index }, type }
    ) => {
      state.contentsArray[index].title = newContentTitle;
      logger([LogCategory.DEBUG], "renamed transcription title");
    },

    updateAnalytics: (state, { payload: { stuff } }) => {
      for (const { data, value } of stuff) {
        state.analytics[data] = value;
        logger([LogCategory.INFO], `${data}: ${value}`);
      }
    },

    setTranscriptTitle: (state, { payload: { newTranscriptTitle }, type }) => {
      state.transcript.title = newTranscriptTitle;
      logger([LogCategory.DEBUG], "renamed transcription title");
    },

    addAudioUrlLocal: (state, { payload: { audioUrl }, type }) => {
      state.transcript.audioUrl = audioUrl;
    },
    setAudioUploading: (state, { payload: { uploadingState }, type }) => {
      state.audioUploading = uploadingState;

      // reset audio upload state
      if (uploadingState === false) {
        state.audioUploadProgress = 0;
      }
    },

    setTranscriptResults: (
      state,
      {
        payload: {
          contentArray,
          bookmarks,
          timestamps,
          audioUrl = initialState.transcript.audioUrl,
          paidBlurbIndex,
        },
        type,
      }
    ) => {
      logger([LogCategory.DEBUG], `am i setting transcript results properly?`);
      logger(
        [LogCategory.DEBUG],
        `contentArray: ${JSON.stringify(contentArray)}`
      );
      logger([LogCategory.DEBUG], `timestamps: ${JSON.stringify(timestamps)}`);
      state.transcript.contentArray = contentArray;
      state.transcript.bookmarks = bookmarks;
      state.transcript.timestamps = timestamps;
      state.transcript.audioUrl = audioUrl;
      state.transcript.paidBlurbIndex = paidBlurbIndex;
    },

    updateUserPlan: (state, { payload: { isPaidUser } }) => {
      logger("updating user plan...");
      state.isPaidUser = isPaidUser;
    },

    cancelPauseSubscription: (state, { payload: {} }) => {
      logger("canceling paused subscription...");
      state.isPaidUser = false;
      state.isSubscriptionPaused = false;
    },

    updatePausedSubscriptionStatus: (
      state,
      { payload: { isSubscriptionPaused } }
    ) => {
      logger("updating paused subscription status to: ", isSubscriptionPaused);
      state.isSubscriptionPaused = isSubscriptionPaused;
    },

    setLiveTranscriptData: (
      state,
      {
        payload: {
          gptJsonArray,
          contentArray,
          timestamps,
          bookmarks,
          transcriptTitle,
          summarySentences,
          summaryBulletPoints,
          audioUrl,
        },
        type,
      }
    ) => {
      state.transcript.gptJsonArray = gptJsonArray;
      state.transcript.contentArray = contentArray;
      state.transcript.timestamps = timestamps;
      state.transcript.bookmarks = bookmarks;
      state.transcript.title = transcriptTitle;
      state.transcript.summarySentences = summarySentences;
      state.transcript.summaryBulletPoints = summaryBulletPoints;
      state.transcript.audioUrl = audioUrl;
    },

    setPaidBlurbIndex: (state, { payload: { paidBlurbIndex } }, type) => {
      state.transcript.paidBlurbIndex = -1;
    },
    setAudioUploadProgress: (state, { payload: { percentage }, type }) => {
      state.audioUploadProgress = percentage;
    },

    updateContentFolderUrlLocal: (
      state,
      { payload: { contentFolderUrl, contentIndex, isTranscript }, type }
    ) => {
      state.contentsArray.splice(contentIndex, 1);
    },

    updateTranscriptArray: (state, { payload: { index, newValue }, type }) => {
      state.transcript.contentArray[index] = newValue;
    },

    setDisplayPostTranscriptFeedbackPanel: (
      state,
      { payload: { displayPostTranscriptFeedbackPanel }, type }
    ) => {
      state.transcript.displayPostTranscriptFeedbackPanel =
        displayPostTranscriptFeedbackPanel;
    },

    updateUnsummarizedIndex: (state, { payload: { newIndex }, type }) => {
      state.unsummarizedIndex = newIndex;
      logger([LogCategory.DEBUG], `updating unsummarizedIndex: ${newIndex}`);
    },

    updateSummarySentencesLocal: (
      state,
      { payload: { newSummarySentences, index }, type }
    ) => {
      if (index >= 0) {
        state.contentsArray[index].summarySentences = newSummarySentences;
      } else {
        state.transcript.summarySentences = newSummarySentences;
      }
    },

    updateSlidesUrlLocal: (state, { payload: { newSlidesUrl }, type }) => {
      state.transcript.slidesUrl = newSlidesUrl;
    },

    updateVideoUrlLocal: (state, { payload: { newVideoUrl }, type }) => {
      state.transcript.videoUrl = newVideoUrl;
    },

    updateSummaryBulletPointsLocal: (
      state,
      { payload: { newSummaryBulletPoints, index }, type }
    ) => {
      // if an index was passed, set the summary bullet points of that index
      if (index >= 0) {
        state.contentsArray[index].summaryBulletPoints = [
          ...newSummaryBulletPoints,
        ];
      } else {
        state.transcript.summaryBulletPoints = [...newSummaryBulletPoints];
      }
    },

    updateUploadAudioProgress: (
      state,
      { payload: { uploadAudioProgress, transcribingFile } }
    ) => {
      state.transcript.uploadAudioProgress = uploadAudioProgress;
      state.transcript.transcribingFile = transcribingFile;
    },

    resetPrevTranscriptType: (state, { payload, type }) => {
      state.prevTranscriptType = state.FINAL;
    },

    updateBookmarks: (state, { payload: { bookmarks } }) => {
      state.transcript.bookmarks = bookmarks;
    },

    setTranscriptsLocal: (state, { payload: { transcripts } }) => {
      state.contentsArray = transcripts;
    },

    updateEmailPreferencesLocal: (state, { payload: { emailPreferences } }) => {
      state.emailPreferences = emailPreferences;
      logger(
        [LogCategory.DEBUG],
        `new prefs: ${JSON.stringify(state.emailPreferences)}`
      );
    },

    updateFolderTitleLocal: (
      state,
      { payload: { folderIndex, newFolderTitle } }
    ) => {
      state.folders[folderIndex].folderName = newFolderTitle;
    },

    setGptJsonArrayLocal: (state, { payload: { newGPTJsonArray } }) => {
      state.transcript.gptJsonArray = newGPTJsonArray;
      logger(
        [LogCategory.DEBUG],
        `setting gpt json: ${JSON.stringify(state.transcript.gptJsonArray)}`
      );
    },

    scrollToBookmark(
      state,
      {
        payload: {
          blurbIndex,
          setHighlightedSummaryBlurbIndex,
          setHighlightedTranscriptBlurbIndex,
          highlightSummaryBlurbTimeout,
          highlightTranscriptBlurbTimeout,
        },
      }
    ) {
      // scrolls to the given bookmark

      const bookmarkedTranscriptBlurb = document.getElementById(
        `transcriptBlurb ${blurbIndex}`
      );
      const bookmarkedTranscriptBlurbText = document.getElementById(
        `transcriptBlurbText ${blurbIndex}`
      );

      logger(
        [LogCategory.DEBUG],
        `blurb text object: ${bookmarkedTranscriptBlurb}`
      );
      logger([LogCategory.DEBUG], `scrolling to transcript blurb`);
      bookmarkedTranscriptBlurb.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
      setHighlightedTranscriptBlurbIndex(`transcriptBlurbText ${blurbIndex}`);
      clearTimeout(highlightTranscriptBlurbTimeout.current);
      highlightTranscriptBlurbTimeout.current = setTimeout(() => {
        logger([LogCategory.DEBUG], "highlight blurb timeout");
        setHighlightedTranscriptBlurbIndex(-1);
      }, 1500);

      const summaryBlurbId = bookmarkedTranscriptBlurbText.className;
      const summaryBlurb = document.getElementById(summaryBlurbId);
      if (summaryBlurb) {
        logger([LogCategory.DEBUG], `scrolling to summary blurb`);
        summaryBlurb.scrollIntoView({ behavior: "instant", block: "center" });
        setHighlightedSummaryBlurbIndex(summaryBlurbId);
        clearTimeout(highlightSummaryBlurbTimeout.current);
        highlightSummaryBlurbTimeout.current = setTimeout(() => {
          logger([LogCategory.DEBUG], "highlight blurb timeout");
          setHighlightedSummaryBlurbIndex(-1);
        }, 1500);
        logger([LogCategory.DEBUG], `set highlight blurb index:`);
      } else {
        logger([LogCategory.DEBUG], "coulnd't find it");
      }
    },

    resetUnfinishedText: (state, { payload }) => {
      state.unfinishedText = "";
    },

    setCurrentlyMerging: (state, { payload: { currentlyMerging } }) => {
      state.currentlyMerging = currentlyMerging;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, { payload: userData }) => {
        const {
          dark_mode,
          email_preferences,
          professor_ossy_access,
          claimed_professor_ossy,
          paid_user,
          email,
          paused_subscription,
        } = userData[0];
        state.isLoggedIn = true;
        state.darkMode = dark_mode;
        state.isPaidUser = paid_user;
        state.isSubscriptionPaused = paused_subscription;
        logger(`just set email: ${email}`);
        state.email = email;
        state.emailPreferences =
          email_preferences || initialState.emailPreferences;
        state.professorOssyAccess =
          professor_ossy_access ?? initialState.professorOssyAccess;
        state.claimedProfessorOssy =
          claimed_professor_ossy || initialState.claimedProfessorOssy;
      })
      .addCase(
        fetchFolders.fulfilled,
        (state, { payload: { allFolders, savedFolderIds } }) => {
          const folders = [];

          const folderUrlToName = {};
          for (const {
            folder_name,
            folder_url,
            folder_id,
            folder_privacy,
          } of allFolders) {
            // we don't need to store all the values for these transcripts;
            // just the values shown on the home page
            const folder = {
              folderName: folder_name,
              folderUrl: folder_url,
              id: folder_id,
              isPrivate: folder_privacy,
              isSaved:
                savedFolderIds !== undefined
                  ? savedFolderIds.includes(folder_id)
                  : false,
            };
            folders.push(folder);
            folderUrlToName[folder_url] = folder_name;
          }
          logger(folders);
          logger(folderUrlToName);
          state.folderUrlToName = folderUrlToName;
          state.folders = folders;
        }
      )
      .addCase(dropOssyPlus.fulfilled, (state, { payload: { isPaidUser } }) => {
        state.isPaidUser = false;
      })
      .addCase(
        fetchContentEmbeddings.fulfilled,
        (state, { payload: transcriptsEmbeddings }) => {
          const contentsArray = state.contentsArray;
          const newTranscripts = [];
          for (let i = 0; i < contentsArray.length; i++) {
            const newTranscript = { ...contentsArray[i] };
            newTranscript.embeddings = transcriptsEmbeddings[i].embeddings;
            newTranscripts.push(newTranscript);
          }
          state.contentsArray = newTranscripts;
        }
      )
      .addCase(
        fetchContentsArray.fulfilled,
        (
          state,
          { payload: { contentsArray, isInitialFetch, setIsLoading } }
        ) => {
          const newContentsArray = [];
          for (const {
            video_url,
            audio_url,
            transcript_array,
            transcript_title,
            summary,
            summary_bullet_points,
            creation_date,
            transcript_id,
            transcript_folder_url,
            document_folder_url,
            debug_json,
            transcript_privacy,
            transcript_url,
            audio_duration,
            paid_blurb_index,
            timestamps,
            file_url,
            document_title,
            document_id,
            created_at,
            document_url,
            document_privacy,
            document_text_array,
            document_bullet_points,
            upload_audio_file_length,
            document_summary_sentences,
          } of contentsArray) {
            const newContent = {
              id: transcript_id ?? document_id,
              title: transcript_title ?? document_title,
              contentArray: transcript_array ?? document_text_array,
              folderUrl: transcript_folder_url ?? document_folder_url,
              pageUrl: transcript_url ?? document_url,
              summarySentences: summary ?? document_summary_sentences,
              summaryBulletPoints:
                summary_bullet_points ?? document_bullet_points,
              isPrivate: transcript_privacy ?? document_privacy,
            };
            if (transcript_id) {
              // create transcript object
              newContent["videoUrl"] = video_url;
              newContent["audioUrl"] = audio_url;
              newContent["creationDate"] = creation_date;
              newContent["debugJson"] = debug_json;
              newContent["audioDuration"] = audio_duration;
              newContent["paidBlurbIndex"] = paid_blurb_index;
              newContent["contentType"] = contentTypes.TRANSCRIPT;
              newContent["timestamps"] = timestamps;
            } else {
              // create document object
              newContent["createdAt"] = created_at;
              newContent["contentType"] = contentTypes.DOCUMENT;
              newContent["fileUrl"] = file_url;
            }
            newContentsArray.push(newContent);
          }
          logger(`set courseContents: ${newContentsArray.length}`);
          if (isInitialFetch) {
            // if the user just switched the side bar from all transcript, a folder name, etc.
            state.contentsArray = newContentsArray;
          } else {
            // for infinite scroll
            state.contentsArray = state.contentsArray.concat(newContentsArray);
          }
        }
      )
      .addCase(
        claimProfessorOssy.fulfilled,
        (state, { payload: { claimedProfessorOssy } }) => {
          state.claimedProfessorOssy = true;
        }
      )
      .addCase(
        updateFolderTitle.fulfilled,
        (
          state,
          {
            payload: {
              folderIndex,
              folder_name: folderName,
              folder_url: folderUrl,
              folder_id: folderId,
            },
          }
        ) => {
          state.folders[folderIndex].folderName = folderName;
          state.folders[folderIndex].folderUrl = folderUrl;
          state.folders[folderIndex].id = folderId;
          state.folderUrlToName[folderUrl] = folderName;
        }
      )
      .addCase(
        togglePrivacy.fulfilled,
        (state, { payload: { privacy, contentType, index } }) => {
          if (index >= 0) {
            // toggling privacy from the home page
            if (contentType.id === contentTypes.FOLDER.id) {
              state.folders[index].isPrivate = privacy;
            } else {
              state.contentsArray[index].isPrivate = privacy;
            }
          } else {
            // within a transcript, document or folder
            state[contentType.name].isPrivate = privacy;
          }
        }
      )
      .addCase(
        actuallyCreateTranscript.fulfilled,
        (
          state,
          {
            payload: {
              creationDate,
              transcriptId,
              folderUrl,
              transcriptUrl,
              title,
              isPrivate,
            },
          }
        ) => {
          state.transcript.creationDate = creationDate;
          state.transcript.id = transcriptId;
          state.transcript.folderUrl = folderUrl;
          state.transcript.pageUrl = transcriptUrl;
          state.transcript.title = title;
          state.transcript.isPrivate = isPrivate;
          document.getElementById("title_box").value = title;
          state.contentsArray.splice(0, 0, state.transcript);
        }
      )
      .addCase(
        fetchDocument.fulfilled,
        (state, { payload: { document, ownerOfDocument } }) => {
          state.document = document;
          state.ownerOfDocument = ownerOfDocument;
        }
      )
      .addCase(
        fetchTranscript.fulfilled,
        (
          state,
          { payload: { transcript, ownerOfTranscript, transcribingFile } }
        ) => {
          logger("saving transcript...");
          logger(transcript);
          state.transcript = transcript;
          state.ownerOfTranscript = ownerOfTranscript;
          state.transcribingFile = transcribingFile;
        }
      )
      .addCase(
        updateMessagesBackend.fulfilled,
        (state, { payload: { messages } }) => {
          logger("updating messages");
          state.transcript.messages = messages;
        }
      )
      .addCase(updateGoogleData.fulfilled, (state, { payload: userData }) => {
        logger([LogCategory.DEBUG], "updated user data:");
      })

      .addCase(
        addTranscriptEmbedding.fulfilled,
        (state, { payload: { newEmbeddings } }) => {
          logger(`about to save embeddings...`);
          logger(newEmbeddings);
          state.transcript.embeddings = newEmbeddings;
        }
      )
      .addCase(inviteEmailsAndAddFreeHour.fulfilled, (state, { payload }) => {
        state.claimedStatus = claimFreeHourStatus.claimed;
        state.weekTranscriptionLimitSeconds =
          state.freeWeeklyTranscriptionLimitSeconds + 1 * 60 * 60;
        state.transcript.paidBlurbIndex = -1;
      })
      .addCase(
        addEntireTranscriptEmbeddings.fulfilled,
        (state, { payload: { newEmbeddings } }) => {
          logger(`about to save embeddings...`);
          logger(newEmbeddings);
          state.transcript.embeddings = newEmbeddings;
        }
      )
      .addCase(
        addToTranscriptArray.fulfilled,
        (
          state,
          {
            payload: {
              prevTranscriptType,
              prevEndTimestamp,
              newTranscriptArray,
              newTimestamps,
              newBookmarks,
              unfinishedText,
              lastFinalBlurbIndex,
            },
          }
        ) => {
          state.prevTranscriptType = prevTranscriptType;
          state.prevEndTimestamp = prevEndTimestamp;
          state.transcript.contentArray = newTranscriptArray;
          state.transcript.timestamps = newTimestamps;
          state.transcript.bookmarks = newBookmarks;
          state.unfinishedText = unfinishedText;
          state.lastFinalBlurbIndex = lastFinalBlurbIndex;
        }
      )
      .addCase(setDarkMode.fulfilled, (state, { payload: _darkMode }) => {
        const { darkMode } = _darkMode;
        state.darkMode = darkMode;
      })
      .addCase(
        logTranscriptSettings.fulfilled,
        (state, { payload: { debugJson } }) => {
          state.transcript.debugJson = debugJson;
        }
      )
      .addCase(
        mergeBlurbs.fulfilled,
        (
          state,
          {
            payload: {
              newTranscriptArray,
              newBookmarks,
              newTimestamps,
              startIndex,
              endIndex,
              unmergedIndex,
            },
          }
        ) => {
          logger([LogCategory.DEBUG], `done merging blurbs.`);
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `old transcript array: ${JSON.stringify(
              state.transcript.contentArray
            )}`
          );
          logger(
            [LogCategory.DEBUG],
            `old timestamps: ${JSON.stringify(state.transcript.timestamps)}`
          );
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `start index: ${startIndex}`
          );
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `end index: ${endIndex}`
          );
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `start part: ${JSON.stringify(
              state.transcript.contentArray.slice(0, startIndex)
            )}`
          );
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `end part: ${JSON.stringify(
              state.transcript.contentArray.slice(endIndex)
            )}`
          );

          const initialLength = state.transcript.contentArray.length;

          // we subtract 1 because we want to include the last sentence we just
          // merged since it could be part of the sentence currently being transcribed
          state.unmergedIndex = unmergedIndex;
          state.transcript.contentArray = [
            ...state.transcript.contentArray.slice(0, startIndex),
            ...newTranscriptArray,
            ...state.transcript.contentArray.slice(endIndex),
          ];
          state.transcript.bookmarks = [
            ...state.transcript.bookmarks.slice(0, startIndex),
            ...newBookmarks,
            ...state.transcript.bookmarks.slice(endIndex),
          ];
          state.transcript.timestamps = [
            ...state.transcript.timestamps.slice(0, startIndex),
            ...newTimestamps,
            ...state.transcript.timestamps.slice(endIndex),
          ];

          const finalLength = state.transcript.contentArray.length;

          // we need to update the last final blurb index since we just changed the entire length of the array
          const deltaLength = finalLength - initialLength;
          state.lastFinalBlurbIndex += deltaLength;

          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `new transcript array: ${JSON.stringify(
              state.transcript.contentArray
            )}`
          );
          logger(
            [LogCategory.DEBUG, LogCategory.PIPELINE],
            `new timestamps: ${JSON.stringify(state.transcript.timestamps)}`
          );
        }
      )

      .addCase(
        mergeBlurbs.rejected,
        (state, { payload: { oldText, index, unmergedIndex } }) => {
          state.transcript.contentArray[index] = oldText;
          state.unmergedIndex = Math.max(unmergedIndex, state.unmergedIndex);
        }
      )

      .addCase(createSession.fulfilled, (state, { payload: { session } }) => {
        state.session = session;
      })
      .addCase(
        fetchFolder.fulfilled,
        (state, { payload: { folder, owneroffolder } }) => {
          state.folder = folder;
        }
      )
      .addCase(
        updateSessionEndDate.fulfilled,
        (state, { payload: { session } }) => {
          state.session = session;
        }
      )
      .addCase(
        checkIfNewUser.fulfilled,
        (state, { payload: { isNewUser } }) => {
          state.isNewUser = isNewUser;
        }
      )
      .addCase(
        setTranscribingFile.fulfilled,
        (state, { payload: { transcribingFile } }) => {
          state.transcribingFile = transcribingFile;
        }
      )
      .addCase(
        updateDebugJson.fulfilled,
        (state, { payload: { newDebugJson } }) => {
          state.transcript.debugJson = newDebugJson;
        }
      )
      .addCase(
        checkAndImposeLimit.fulfilled,
        (state, { payload: { paidBlurbIndex } }) => {
          state.transcript.paidBlurbIndex = paidBlurbIndex;
        }
      )
      .addCase(
        fetchFolderContentAndEmbeddings.fulfilled,
        (state, { payload: { folderContent } }) => {
          const newContentsArray = [];
          for (const {
            audio_url,
            transcript_array,
            transcript_title,
            summary,
            summary_bullet_points,
            creation_date,
            transcript_id,
            transcript_folder_url,
            document_folder_url,
            debug_json,
            transcript_privacy,
            transcript_url,
            audio_duration,
            paid_blurb_index,
            timestamps,
            file_url,
            document_title,
            document_id,
            created_at,
            document_url,
            document_privacy,
            document_text_array,
            document_bullet_points,
            document_embeddings,
            embeddings,
            upload_audio_file_length,
            document_summary_sentences,
          } of folderContent) {
            const newContent = {
              id: transcript_id ?? document_id,
              title: transcript_title ?? document_title,
              contentArray: transcript_array ?? document_text_array,
              folderUrl: transcript_folder_url ?? document_folder_url,
              pageUrl: transcript_url ?? document_url,
              summarySentences: summary ?? document_summary_sentences,
              summaryBulletPoints:
                summary_bullet_points ?? document_bullet_points,
              isPrivate: transcript_privacy ?? document_privacy,
              embeddings: embeddings ?? document_embeddings,
            };
            if (transcript_id) {
              // create transcript object
              newContent["timestamps"] = timestamps;
              newContent["audioUrl"] = audio_url;
              newContent["creationDate"] = creation_date;
              newContent["debugJson"] = debug_json;
              newContent["audioDuration"] = audio_duration;
              newContent["paidBlurbIndex"] = paid_blurb_index;
              newContent["contentType"] = contentTypes.TRANSCRIPT;
            } else {
              // create document object
              newContent["createdAt"] = created_at;
              newContent["contentType"] = contentTypes.DOCUMENT;
              newContent["fileUrl"] = file_url;
            }
            newContentsArray.push(newContent);
          }
          state.contentsArray = newContentsArray;
        }
      )
      .addCase(
        uploadPdfDocument.fulfilled,
        (
          state,
          {
            payload: {
              newDocument: {
                document_folder_url,
                document_id,
                file_url,
                document_title,
                created_at,
                user_id,
                document_privacy,
                document_url,
                document_text_array,
                document_summary_sentences,
                document_bullet_points,
              },
            },
          }
        ) => {
          const newDocument = {
            folderUrl: document_folder_url,
            id: document_id,
            fileUrl: file_url,
            title: document_title,
            createdAt: created_at,
            // Not sure why this is needed
            // userId: user_id,
            isPrivate: document_privacy,
            pageUrl: document_url,
            contentArray: document_text_array,
            // documentTextArray: document_text_array,
            summarySentences: document_summary_sentences,
            summaryBulletPoints: document_bullet_points,
            // isTranscript: false,
          };
          state.contentsArray.splice(0, 0, newDocument);
        }
      )
      .addCase(
        parseLatex.fulfilled,
        (state, { payload: { index, textWithLatex, unlatexedIndex } }) => {
          logger(
            [LogCategory.DEBUG],
            `updated transcript with latex @ index ${index}`
          );
          logger(
            [LogCategory.DEBUG],
            `old: ${state.transcript.contentArray[index]}`
          );
          logger([LogCategory.DEBUG], `new: ${textWithLatex}`);
          logger([LogCategory.DEBUG], `new unlatexed index: ${unlatexedIndex}`);
          state.transcript.contentArray[index] = textWithLatex;
          state.unlatexedIndex = Math.max(state.unlatexedIndex, unlatexedIndex);
        }
      )
      .addCase(
        parseLatex.rejected,
        (state, { payload: { oldText, index, unlatexedIndex } }) => {
          state.transcript.contentArray[index] = oldText;
          state.unlatexedIndex = Math.max(state.unlatexedIndex, unlatexedIndex);
        }
      )
      .addCase(
        fetchFolderChats.fulfilled,
        (state, { payload: folderChats }) => {
          state.folder.messages = folderChats[0].chat_messages;
        }
      )
      .addCase(
        fetchWeekTranscriptionLimit.fulfilled,
        (state, { payload: { claimedStatus } }) => {
          state.claimedStatus = claimedStatus;
          if (claimedStatus === claimFreeHourStatus.claimed) {
            state.weekTranscriptionLimitSeconds =
              state.freeWeeklyTranscriptionLimitSeconds + 1 * 60 * 60;
          } else {
            state.weekTranscriptionLimitSeconds =
              state.freeWeeklyTranscriptionLimitSeconds;
          }
        }
      )
      .addCase(
        autoCorrect.fulfilled,
        (
          state,
          { payload: { index, textWithAutoCorrect, unautoCorrectedIndex } }
        ) => {
          logger(
            [LogCategory.DEBUG],
            `updated transcript with autoCorrect @ index ${index}`
          );
          logger(
            [LogCategory.DEBUG],
            `old: ${state.transcript.contentArray[index]}`
          );
          logger([LogCategory.DEBUG], `new: ${textWithAutoCorrect}`);
          logger(
            [LogCategory.DEBUG],
            `new unautoCorrected index: ${unautoCorrectedIndex}`
          );
          state.transcript.contentArray[index] = textWithAutoCorrect;
          state.unautoCorrectedIndex = Math.max(
            state.unautoCorrectedIndex,
            unautoCorrectedIndex
          );
        }
      )
      .addCase(
        autoCorrect.rejected,
        (state, { payload: { oldText, index, unautoCorrectedIndex } }) => {
          state.transcript.contentArray[index] = oldText;
          state.unautoCorrectedIndex = Math.max(
            state.unautoCorrectedIndex,
            unautoCorrectedIndex
          );
        }
      )
      .addCase(
        editTranscriptTitle.fulfilled,
        (state, { payload: { transcriptTitle } }) => {
          state.transcript.title = transcriptTitle;
        }
      )
      .addCase(
        editDocumentTitle.fulfilled,
        (state, { payload: { documentTitle } }) => {
          state.document.title = documentTitle;
        }
      )
      .addCase(
        deleteFolder.fulfilled,
        (state, { payload: { folders, folderUrl, deleteContents } }) => {
          state.folders = folders;

          if (deleteContents) {
            state.contentsArray = state.contentsArray.filter(
              ({ contentFolderUrl }) => contentFolderUrl !== folderUrl
            );
          }
        }
      )
      .addCase(handlePauseTranscript.pending, (state) => {
        state.unmergedIndex = state.transcript.contentArray.length;
        state.unlatexedIndex = state.transcript.contentArray.length;
        state.unautoCorrectedIndex = state.transcript.contentArray.length;
      })
      .addCase(handleStopTranscript.pending, (state) => {
        state.prevTranscriptType = state.FINAL;
        state.unmergedIndex = state.transcript.contentArray.length;
        state.unlatexedIndex = state.transcript.contentArray.length;
        state.unautoCorrectedIndex = state.transcript.contentArray.length;
      })

      .addCase(saveContent.fulfilled, (state, { payload: { contentType } }) => {
        logger([LogCategory.DEBUG], "saved transcript");
        state[contentType.name].isSaved = true;
      })
      .addCase(
        unsaveContent.fulfilled,
        (state, { payload: { contentType, contentId: unsavedContentId } }) => {
          state[contentType.name].isSaved = false;

          if (contentType.id === contentTypes.FOLDER.id) {
            state.folders = state.folders.filter(
              ({ id }) => id !== unsavedContentId
            );
          } else {
            state.contentsArray = state.contentsArray.filter(
              ({ id }) => id !== unsavedContentId
            );
          }
        }
      )

      .addCase(
        getWeekAudioDuration.fulfilled,
        (state, { payload: { weekAudioDuration, includeCurrent } }) => {
          /*
          we only want to save the value everywhere if we are including all transcripts.
          including the one currently opened.
          */
          if (includeCurrent) {
            state.weekAudioDuration = weekAudioDuration;
          }
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  resetStartIndex,
  handleResumeTranscript,
  addAudio,
  setAudioURL,
  getGoogleInfo,
  logOut,
  createNewFolderLocal,
  deleteContentLocal,
  changeTranscriptionTitleLocal,
  addAudioUrlLocal,
  setAudioUploading,
  setAudioUploadProgress,
  updateTranscriptArray,
  updateSummarySentencesLocal,
  updateSummaryBulletPointsLocal,
  resetPrevTranscriptType,
  updateFolders,
  updateContentFolderUrlLocal,
  updateBookmarks,
  scrollToBookmark,
  updateEmailPreferencesLocal,
  setGptJsonArrayLocal,
  updateContentTitleLocal,
  atLoginPage,
  resetUnfinishedText,
  setCurrentlyMerging,
  updateAnalytics,
  setTranscriptResults,
  setParseLatex,
  setDisplayPostTranscriptFeedbackPanel,
  updateUnsummarizedIndex,
  setLiveTranscriptData,
  setFoldersLocal,
  setPaidBlurbIndex,
  updateUserPlan,
  updateFolderTitleLocal,
  setTranscriptTitle,
  updateSlidesUrlLocal,
  updateVideoUrlLocal,
  updateEntireTranscriptArray,
  updatePausedSubscriptionStatus,
  cancelPauseSubscription,
  updateUploadAudioProgress,
} = routesSlice.actions;

export default routesSlice.reducer;
