import { LogLevel } from "microsoft-cognitiveservices-speech-sdk";
import {
  updateGoogleData,
  fetchUserData,
  getGoogleInfo,
  isNewUser,
  checkIfNewUser,
} from "../../../transcriptSlice";
import { Resend } from "resend";
import { apiEndpoint } from "../../../utility/utility.mjs";

const decodeJwtResponseFromGoogleAPI = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const onSignInGSI = async (credentialResponse, dispatch, supabase) => {
  const responsePayload = decodeJwtResponseFromGoogleAPI(
    credentialResponse.credential
  );

  const {
    given_name: firstName,
    family_name: lastName,
    sub: userId,
    email,
    name: fullName,
    picture: pfpUrl,
  } = responsePayload;

  // get pfp, name, userId, & email
  dispatch(
    getGoogleInfo({ pfpUrl, fullName, userId, firstName, lastName, email })
  );

  // determine if user is new
  const { isNewUser } = await dispatch(checkIfNewUser({ supabase })).unwrap();

  // push the google info to database
  await dispatch(updateGoogleData(supabase)).unwrap();

  // fetch all the user's data
  await dispatch(fetchUserData({ supabase })).unwrap();

  if (isNewUser) {
    await fetch(`${apiEndpoint}/send_welcome_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
      }),
    });
  }

  localStorage.setItem("ossy-login-event", "login" + Math.random());

  return {
    firstName,
    lastName,
    userId,
    email,
    fullName,
    pfpUrl,
  };
};
