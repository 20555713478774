import { useSelector } from "react-redux";
import { colors } from "../../../utility/utility";
import { initialState } from "../../../data/initialState";
import { formatDuration } from "../../../utility/utility.mjs";

const OssyPlusTable = () => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <table
      style={{
        marginTop: 32,
        tableLayout: "fixed",
        width: 700,
        maxWidth: "95%",
        borderCollapse: "collapse",
        backgroundColor: darkMode ? "transparent" : colors.gray,
        color: darkMode ? "white" : "black",
      }}
    >
      <thead>
        <tr style={{ borderBottom: "1px solid white" }}>
          <th scope="col" style={{ padding: 20, fontWeight: 600 }}>
            What you'll get
          </th>
          <th
            scope="col"
            style={{
              padding: 20,
              backgroundColor: darkMode ? "white" : colors.gray,
              color: "black",
              fontWeight: 600,
            }}
          >
            Ossy AI's Free plan
          </th>
          <th
            scope="col"
            style={{
              padding: 20,
              backgroundColor: colors.ossyBlue1,
              color: "white",
              fontWeight: 600,
            }}
          >
            Ossy Plus
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ borderBottom: "1px solid white" }}>
          <th
            scope="row"
            style={{ padding: 20, fontWeight: 400, textAlign: "left" }}
          >
            Transcription
          </th>
          <td style={{ textAlign: "center" }}>
            {formatDuration({
              duration: initialState.freeWeeklyTranscriptionLimitSeconds,
            })}{" "}
            a week
          </td>
          <td
            style={{
              textAlign: "center",
              backgroundColor: colors.ossyBlue1,
              fontWeight: 700,
              color: "white",
            }}
          >
            Unlimited
          </td>
        </tr>
        <tr style={{ borderBottom: "1px solid white" }}>
          <th
            scope="row"
            style={{ padding: 20, fontWeight: 400, textAlign: "left" }}
          >
            AI explanations
          </th>
          <td style={{ textAlign: "center" }}>
            {" "}
            {formatDuration({
              duration: initialState.freeWeeklyTranscriptionLimitSeconds,
            })}{" "}
            a week
          </td>
          <td
            style={{
              textAlign: "center",
              backgroundColor: colors.ossyBlue1,
              fontWeight: 700,
              color: "white",
            }}
          >
            Unlimited
          </td>
        </tr>

        <tr style={{ borderBottom: "1px solid white" }}>
          <th
            scope="row"
            style={{ padding: 20, fontWeight: 400, textAlign: "left" }}
          >
            Questions to Professor Ossy
          </th>
          <td style={{ textAlign: "center" }}>10 per transcript</td>
          <td
            style={{
              textAlign: "center",
              backgroundColor: colors.ossyBlue1,
              fontWeight: 700,
              color: "white",
            }}
          >
            Unlimited
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OssyPlusTable;
