import { initialState } from "../data/initialState.js";

export const GOOGLE_CLIENT_ID =
  "1020047349054-oa3b87eo87rf6qhu392o2oc6afqmk4cb.apps.googleusercontent.com";

export const transcriptsType = {
  FOLDER_TRANSCRIPTS: "",
  HOME: "Home",
  SHARED_TRANSCRIPTS: "Shared with me",
};

// different type of contents that are possible
export const contentTypes = {
  // NAME MUST BE THE SAME AS THE KEY WITHIN INITIALSTATE
  NONE: { name: "", id: 999 },
  TRANSCRIPT: {
    name: "transcript",
    id: 1000,
    pathLetter: "t",
    databaseObjKey: "TRANSCRIPT",
  },
  DOCUMENT: {
    name: "document",
    id: 1001,
    pathLetter: "d",
    databaseObjKey: "DOCUMENT",
  },
  FOLDER: {
    name: "folder",
    id: 1002,
    pathLetter: "f",
    databaseObjKey: "FOLDER",
  },
};

export const rightClickTypes = {
  NONE: contentTypes.NONE.id,
  TRANSCRIPT: contentTypes.TRANSCRIPT.id,
  DOCUMENT: contentTypes.DOCUMENT.id,
  FOLDER: contentTypes.FOLDER.id,
};

export const databaseInfo = {
  TRANSCRIPT: {
    tableName: "transcripts",
    tableId: "transcript_id",
    tableSummarySentences: "summary",
    tableSummaryBulletPoints: "summary_bullet_points",
    tablePrivacy: "transcript_privacy",
    tableSavedIds: "saved_transcript_ids",
    // the table that messages is in
    messagesTable: "transcript_chats",
    // the name of the column in the table with the messages
    tableMessages: "chat_messages",
  },

  DOCUMENT: {
    tableName: "documents",
    tablePrivacy: "document_privacy",
    tableId: "document_id",
    tableSummarySentences: "document_summary_sentences",
    tableSavedIds: "saved_transcript_ids",
    tableSummaryBulletPoints: "document_bullet_points",
    // the table that messages is in
    messagesTable: "document_chats",
    // the name of the column in the table with the messages
    tableMessages: "chat_messages",
  },

  FOLDER: {
    tableName: "folders",
    tablePrivacy: "folder_privacy",
    tableId: "folder_id",
    tableSavedIds: "saved_folder_ids",
    // the table that messages is in
    messagesTable: "folder_chats",
    // the name of the column in the table with the messages
    tableMessages: "chat_messages",
  },
};

// different type of transcripts you can create: live, audio file, or youtube link
export const transcriptModeTypes = {
  LIVE_TRANSCRIPTION: {
    id: 100,
    url: "new/live",
    name: "live",
  },
  UPLOAD_AUDIO: {
    id: 101,
    url: "new/upload",
    name: "upload",
  },
  VIDEO_LINK: {
    id: 102,
    url: "new/link",
    name: "link",
  },
};

export const documentModeTypes = {
  UPLOAD: {
    id: 103,
    url: "",
    name: "uploadDocument",
  },
};

// determine if the input url has the correct syntax for new transcript
export const getIsNewTranscriptUrl = (url) => {
  // matches only the following expressions
  // /t/new,        /t/new/
  // /t/new/upload, /t/new/upload/
  // /t/new/live,   /t/new/live/
  // /t/new/link,   /t/new/link
  const regexPattern = /^\/t\/new(?:\/(?:live|upload|link))?\/?$/;
  const isNewTranscript = regexPattern.test(url);
  return isNewTranscript;
};

export const LogCategory = {
  DEBUG: "DEBUG",
  INFO: "INFO",
  ERROR: "ERROR",
  AMPLITUDE: "AMPLITUDE",
  PIPELINE: "PIPELINE",
  NONE: "NONE", // no logger should ever have the category NONE, used when you don't want to display any logs
};

export const logger = (...args) => {
  try {
    if (process.env.NODE_ENV !== "production") {
      const defaultAllowedLogs = new Set(Object.values(LogCategory));
      // define your personal allowed logs here, should always reset to empty set
      const personalAllowedLogs = new Set([LogCategory.NONE]);
      // const personalAllowedLogs = new Set([LogCategory.NONE]);
      const allowedLogs =
        personalAllowedLogs.size === 0
          ? defaultAllowedLogs
          : personalAllowedLogs;
      const displayIncorrrectFormattedLoggers = false;
      const logLevels = args[0];
      //  first element in the logger is an Array
      if (Array.isArray(logLevels)) {
        if (logLevels.some((item) => defaultAllowedLogs.has(item))) {
          if (logLevels.some((item) => allowedLogs.has(item))) {
            // logger with a [LogCategory...]
            console.log(...args.slice(1));
          }
        } else if (displayIncorrrectFormattedLoggers) {
          console.log(...args);
        }
      } else if (displayIncorrrectFormattedLoggers) {
        // not a logger that start with a list
        console.log(...args);
      }
    }
  } catch {
    console.error("error with logger");
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getIsMac = () => {
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  return isMac;
};

export const blurbState = {
  PARTIAL_REV_AI: "PARTIAL REV AI",
  FINAL_REV_AI: "FINAL REV AI",
  MERGE: "DONE MERGE",
  LATEX: "DONE LATEX",
  AUTO_CORRECT: "DONE AUTO CORRECT",
  FAILED_MERGE: "FAILED MERGE",
  FAILED_AUTO_CORRECT: "FAILED AUTO CORRECT",
  FAILED_LATEX: "FAILED LATEX",
};

const isBlurbStateDisplayed = true;

export const displayBlurbState = (text, state) => {
  let modifiedText = text;
  if (isBlurbStateDisplayed && process.env.NODE_ENV !== "production") {
    modifiedText = removeBlurbState(modifiedText);
    // display a blub text with the prefix [blurbState]:
    modifiedText = `[${state}]: ${modifiedText}`;
  }
  // IMPORTANT: Make sure the default is to return input text,
  // all modifications to default return will be shown in production deployment
  return modifiedText;
};

export const removeBlurbState = (text) => {
  let modifiedText = text;
  if (isBlurbStateDisplayed && process.env.NODE_ENV !== "production") {
    // find a match for [blurbState]: text, and get the text after ]:
    const pattern = /\]:\s*(.*)/;
    const match = text.match(pattern);
    if (match != null) {
      modifiedText = match[1];
    }
  }
  // IMPORTANT: Make sure the default is to return input text,
  // all modifications to default return will be shown in production deployment
  return modifiedText;
};

export const addScrollBarUI = (event) => {
  // displays the scrollbar only while scrolling
  if (
    event.target.classList.contains("visible-scrollbar-thumb") === false &&
    event.target.classList.contains("visible-scrollbar") === false
  ) {
    // adds the class to make scroll bar thumb visible and scrollbar visible on hover
    event.target.classList.add("visible-scrollbar-thumb");
    event.target.classList.add("visible-scrollbar");

    // removes the scrollbar after a certain number of seconds
    const scrollbarDisplayDurationInMilliSeconds = 2500;
    setTimeout(() => {
      event.target.classList.remove("visible-scrollbar-thumb");
      event.target.classList.remove("visible-scrollbar");
    }, scrollbarDisplayDurationInMilliSeconds);
  }
};

export const setScrollbarLightOrDarkMode = (darkMode) => {
  document.body.classList.add(
    `${darkMode ? "darkMode-scrollbar-thumb" : "lightMode-scrollbar-thumb"}`
  );
  document.body.classList.add(
    `${darkMode ? "darkMode-scrollbar" : "lightMode-scrollbar"}`
  );
};

export const extractYoutubeVideoId = (youtubeLink) => {
  const queryParams = youtubeLink.split(
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi
  );
  if (queryParams.length > 2) {
    const videoId = queryParams[1];
    return videoId;
  } else {
    return null;
  }
};

// returns the transcript array with only blurbs that have characters less than maxNumCharacters
export const filterTranscriptArray = (
  contentArray,
  maxNumCharacters = 1000
) => {
  const filteredTranscriptArray = contentArray.filter((blurbText) => {
    const isBlurbShort = blurbText.length <= maxNumCharacters;
    return isBlurbShort;
  });
  return filteredTranscriptArray;
};

export const apiEndpoint =
  process.env.NODE_ENV === "production"
    ? "https://api.ossy.ai"
    : "http://localhost:8080";

const sqlQueriesFunctionNames = {
  production: {
    fetchCourseContent: "fetch_course_content_2",
    fetchFolders: "fetch_folders_2",
    fetchFolderContentAndEmbeddings: "fetch_folder_content_and_embeddings_2",
  },
  testing: {
    fetchCourseContent: "test_fetch_course_content",
    fetchFolders: "test_fetch_folders",
    fetchFolderContentAndEmbeddings: "test_fetch_folder_content_and_embeddings",
  },
};

export const sqlQueries =
  process.env.NODE_ENV === "production"
    ? sqlQueriesFunctionNames.production
    : sqlQueriesFunctionNames.testing;

export const ossyDiscordLink = "https://discord.gg/yGVWtesdj8";

export const colors = {
  // color blender tool:
  // https://meyerweb.com/eric/tools/color-blend/#2B9CFF:FFFFFF:10:rgbd

  gray: "#e8e8e8",
  graySlightlyDarker: "rgb(225, 225, 225)",
  grayNumber: (opacity) => {
    return `rgba(232, 232, 232, ${opacity})`;
  },
  gray1Number: (opacity) => {
    return `rgba(61, 61, 61, ${opacity})`;
  },
  green: "mediumseagreen",
  ossyBlue1: "dodgerblue",
  darkBlue: "#0d6eff",
  lightGray: "#f5f5f5",
  lighterGray: "#fafafa",

  ossyBlue2: "rgba(43, 156, 255, 0.5)",

  ossyBlue3: "rgba(43, 156, 255, 0.3)", // ossy blue but translucent

  ossyBlue4: "rgba(43, 156, 255, 0.15)", // ossy blue but translucent

  ossyDarkBlue1: "rgb(42,145,235)",
  ossyDarkBlue2: "rgb(41,133,214)",
  ossyDarkBlue3: "rgb(40,122,194)",
  ossyDarkBlue4: "rgb(39,111,174)",
  ossyDarkBlue5: "rgb(38,100,154)",
  ossyDarkBlue6: "rgb(37,88,133)",
  ossyDarkBlue7: "rgb(36,77,113)",
  ossyDarkBlue8: "rgb(35,66,93)",
  ossyDarkBlue9: "rgb(34,55,73)",
  ossyDarkBlue10: "rgb(33,43,52)",

  ossyBlue2Full: "rgb(62,165,255)",
  ossyBlue3Full: "rgb(82,174,255)",
  ossyBlue4Full: "rgb(101,183,255)",
  ossyBlue5Full: "rgb(120,192,255)",
  ossyBlue6Full: "rgb(139,201,255)",
  ossyBlue7Full: "rgb(159,210,255)",
  ossyBlue8Full: "rgb(178,219,255)",
  ossyBlue9Full: "rgb(197,228,255)",
  ossyBlue10Full: "rgb(216,237,255)",
  ossyBlue11Full: "rgb(236,246,255)",

  gray1: "#3d3d3d",
  gray15: "#333333",
  gray2: "#2b2b2b",
  gray3: "#1f1f1f",
  gray4: "#1b1c1e",
  black: "#202020", // our eyes don't really like pure black
  gold: "#d6b600",
};

export const fontSizes = {
  regular: 16,
  big: 18,
};

export const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const formatDate = (dateString) => {
  if ([undefined, ""].includes(dateString)) {
    return "";
  }
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const [year, monthNum, day] = dateString.split("-");

  return `${months[monthNum]} ${day}, ${year}`;
};

export const coolBoxShadow = "2px 2px 10px rgba(0, 0, 0, 0.4)";
export const boxShadow = "2px 2px 10px rgba(0, 0, 0, 0.4)";
export const boxShadowHalf = "2px 2px 10px rgba(0, 0, 0, 0.2)";
export const borderRadius = "8px";

export const emToPixels = 18;
export const lorenIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget duis at tellus at urna condimentum mattis pellentesque id. Ultricies integer quis auctor elit sed vulputate mi sit. Mauris nunc congue nisi vitae suscipit. Non nisi est sit amet facilisis magna etiam. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Id porta nibh venenatis cras sed felis eget. Pellentesque pulvinar pellentesque habitant morbi tristique. Proin fermentum leo vel orci porta non pulvinar neque. Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut. Nunc faucibus a pellentesque sit amet porttitor. Dictum sit amet justo donec enim diam vulputate ut. Enim blandit volutpat maecenas volutpat blandit aliquam. Netus et malesuada fames ac. Molestie at elementum eu facilisis sed.";

export const WORDS_TO_TOKENS = 1500 / 2048;

export const getDate = () => {
  const date = new Date();
  const formattedDate =
    date.getFullYear() +
    "-" +
    // the month is offset by 1
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0");
  return formattedDate;
};

export const formatTimestamp = (timestamp) => {
  if (timestamp === undefined) {
    return "00:00";
  }
  const timestampArray = `${timestamp}`.split(".");
  const secondsFraction = `${Number(timestampArray[1])}`;
  const hours = `${Math.floor(Number(timestampArray[0]) / 3600)}`;
  const minutes = `${Math.floor((Number(timestampArray[0]) % 3600) / 60)}`;
  const seconds = `${Number(timestampArray[0]) % 60}`;

  let formattedTimestamp = "";
  if (hours !== "0") {
    formattedTimestamp += `${hours.padStart(2, "0")}:`;
  }
  formattedTimestamp += `${minutes.padStart(2, "0")}:${seconds.padStart(
    2,
    "0"
  )}`;
  return formattedTimestamp;
};

export const unformatTimestamp = (textTimestamp) => {
  const timestampArray = textTimestamp.split(":");

  // add the hours if it doesn't exist
  if (timestampArray.length === 2) timestampArray.splice(0, 0, "00");

  const [hoursString, minutesString, secondsString] = timestampArray;
  return (
    Number(hoursString) * 3600 +
    Number(minutesString) * 60 +
    Number(secondsString)
  );
};

export const SECONDS_IN_A_MINUTE = 60;
export const MINUTES_AN_HOUR = 60;
export const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * MINUTES_AN_HOUR;

export const formatDuration = ({
  duration,
  abbreviated = false,
  maxDurationHours = initialState.freeWeeklyTranscriptionLimitSeconds,
}) => {
  const hours = Math.min(maxDurationHours, Math.floor(duration / 3600));
  const minutes = Math.floor((duration % 3600) / 60);

  let durationText = "";
  if (hours >= 1) {
    if (abbreviated) {
      durationText += `${hours}h`;
    } else {
      durationText += hours > 1 ? `${hours} hours` : `${hours} hour`;
    }
  }

  if (minutes >= 1 && hours < maxDurationHours) {
    if (abbreviated) {
      durationText += hours === 0 ? `${minutes}m` : ` ${minutes}m`;
    } else {
      durationText += hours === 0 ? `${minutes}` : ` and ${minutes}`;
      durationText += minutes === 1 ? " minute" : " minutes";
    }
  }

  if (hours === 0 && minutes === 0) {
    if (abbreviated) {
      durationText = "0h";
    } else {
      durationText = "0 hours";
    }
  }

  return durationText;
};

// export const emailRegex = "asdf";
// export const emailRegex = new RegExp(
//   "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
// );

export const emailRegex =
  "/[a-zA-z0-9._:$!%\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}/";
// export const emailRegex =
// "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

export const claimFreeHourStatus = {
  unclaimed: 1000,
  claimed: 1002,
};
