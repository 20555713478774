import { colors, borderRadius } from "../utility/utility";
import { deleteFolder } from "../transcriptSlice";
import { useDispatch, useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";
import Button, { buttonStyles } from "./Button";

const DeleteFolderWarningPanel = ({
  supabase,
  setDeleteFolderWarningPanel,
  rightClickObject: {
    type: rightClickedType,
    index: rightClickedIndex,
    thing: { folderName },
  },
  selected,
  folderId,
}) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <>
      <DarkOverlay zIndex={1} />

      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          padding: 16,
          textAlign: "center",
          maxWidth: "50%",
          maxHeight: "80%",
          overflowY: "auto",
          color: darkMode ? "white" : "black",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        <p style={{ fontWeight: 500, fontSize: 16, marginBottom: 16 }}>
          Delete Folder?
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 300,
            marginBottom: 12,
            overflowWrap: "break-word",
          }}
        >
          Deleting{" "}
          <span style={{ color: "inherit", fontWeight: 500 }}>
            {folderName}
          </span>{" "}
          will delete everything inside of it.
        </p>

        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <Button
            content={"Delete Folder"}
            onClick={async () => {
              await dispatch(
                deleteFolder({
                  indexToRemove: rightClickedIndex,
                  supabase: supabase,
                  folderId,
                  deleteContents: true,
                })
              ).unwrap();

              if (selected === folderName) {
                window.location.pathname = "/";
              }

              setDeleteFolderWarningPanel(false);
            }}
            buttonStyle={buttonStyles.tomato}
          />

          <Button
            content={"Cancel"}
            onClick={() => setDeleteFolderWarningPanel(false)}
            buttonStyle={buttonStyles.inverted}
          />
        </div>
      </div>
    </>
  );
};

export default DeleteFolderWarningPanel;
