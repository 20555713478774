import {
  colors,
  borderRadius,
  formatTimestamp,
  logger,
  LogCategory,
} from "../../../utility/utility";
import { useSelector } from "react-redux";

const BookmarkBar = ({
  setTranscriptAutoscroll,
  setHighlightedSummaryBlurbIndex,
  setHighlightedTranscriptBlurbIndex,
  highlightSummaryBlurbTimeout,
}) => {
  const { timestamps, bookmarks } = useSelector(
    (state) => state.routes.transcript
  );
  const darkMode = useSelector((state) => state.routes.darkMode);

  const scrollToBookmark = (blurbIndex) => {
    // scrolls to the given bookmark

    const bookmarkedTranscriptBlurb = document.getElementById(
      `transcriptBlurb ${blurbIndex}`
    );
    const bookmarkedTranscriptBlurbText = document.getElementById(
      `transcriptBlurbText ${blurbIndex}`
    );

    logger([LogCategory.DEBUG], `scrolling to transcript blurb`);
    bookmarkedTranscriptBlurb.scrollIntoView({
      behavior: "instant",
      block: "center",
    });

    const summaryBlurbId = bookmarkedTranscriptBlurbText.className;
    const summaryBlurb = document.getElementById(summaryBlurbId);
    if (summaryBlurb) {
      logger([LogCategory.DEBUG], `scrolling to summary blurb`);
      summaryBlurb.scrollIntoView({ behavior: "instant", block: "center" });
      setHighlightedSummaryBlurbIndex(summaryBlurbId);
      setHighlightedTranscriptBlurbIndex(-1);
      clearTimeout(highlightSummaryBlurbTimeout.current);
      highlightSummaryBlurbTimeout.current = setTimeout(() => {
        logger([LogCategory.DEBUG], "highlight blurb timeout");
        setHighlightedSummaryBlurbIndex(-1);
      }, 1500);
      logger([LogCategory.DEBUG], `set highlight blurb index:`);
    } else {
      logger([LogCategory.DEBUG], "coulnd't find it");
    }
  };

  return (
    <div
      style={{
        backgroundColor: darkMode ? colors.gray1 : "lightgray",
        borderRadius: 4,
        position: "relative",
        // flexGrow: 1,
        width: 6,
        // flex: 1,
        height: "100%",
        minWidth: 6,
        maxWidth: 6,
        marginTop: 0,
        display: "flex",
        zIndex: 0,
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 0,
        alignItems: "center",
      }}
      className="bookmark_scrollbar"
    >
      {/* render the points on the bookmark scrollbar */}
      {timestamps.map((_, index) => {
        return (
          <div
            style={{
              position: "relative",
              width: 0,
              height: 0,
              borderRadius: borderRadius,
              cursor: bookmarks[index] ? "pointer" : "auto",
            }}
            key={index}
            className={`bookmark_dot ${formatTimestamp(timestamps[index])}`}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: bookmarks[index]
                  ? colors.ossyBlue1
                  : "transparent",
                borderRadius: 1000,
                position: "absolute",
                display: bookmarks[index] ? "flex" : "none",
                top: -6,
                left: -6,
                transform: "scale(1)",
                cursor: bookmarks[index] ? "pointer" : "auto",

                // smooth animate scaling and shrinking
                transition: "transform 0.2s",
              }}
              onMouseEnter={(event) => {
                event.currentTarget.style.transform = "scale(2)";
              }}
              onMouseLeave={(event) => {
                event.currentTarget.style.transform = "scale(1)";
              }}
              onClick={() => {
                // toggles the timestamp bookmark and updates the UI
                logger([LogCategory.DEBUG], "just set autoscroll to false");
                setTranscriptAutoscroll(false);

                scrollToBookmark(index);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BookmarkBar;
