import { HomeHeader } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { apiEndpoint, colors } from "../utility/utility";
import { useEffect, useState, useRef } from "react";
import { fetchAnalytics } from "../transcriptSlice";
import { Analytics } from "../features/analytics/components/Analytics";
import { NotFoundPage } from ".";
import { logger, LogCategory } from "../utility/utility";

const AdminPage = ({ supabase }) => {
  const [page, setPage] = useState(<NotFoundPage />);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.routes.userId);

  const alreadyFetched = useRef(false);

  // power users

  useEffect(() => {
    // we already fetched analytics
    if (alreadyFetched.current === true) {
      return;
    }

    alreadyFetched.current = true;

    const formData = new FormData();
    formData.append("userId", userId);

    fetch(`${apiEndpoint}/admin_verification`, {
      method: "POST",
      body: formData,
    }).then(async (response) => {
      logger([LogCategory.INFO], "got response");
      if (response.ok) {
        const responseJson = await response.json();
        if (responseJson.verified === true) {
          dispatch(fetchAnalytics({ supabase }));
          logger([LogCategory.INFO], `fetching analytics and setting page`);
          setPage(<Analytics />);
        }
      }
    });
  }, [dispatch, supabase, userId]);

  return page;
};

export default AdminPage;
