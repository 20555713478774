import { useDispatch, useSelector } from "react-redux";
import { goToContent, editDocumentTitle } from "../transcriptSlice";
import { borderRadius, colors, months } from "../utility/utility";
import speaker from "../assets/speaker.png";

import React, { useEffect, useRef, useState } from "react";
import {
  LogCategory,
  apiEndpoint,
  logger,
  contentTypes,
  getIsMac,
} from "../utility/utility.mjs";
import { ellipsis } from "../assets/ellipsis2";
import { BsFileEarmarkPdf } from "react-icons/bs";

const Document = ({
  index,
  transcriptTitleEditIndex,
  setTranscriptTitleEditIndex,
  editingTranscriptTitle,
  setPoints,
  setRightClickObject,
  rightClickObject: { type: righClickType, index: rightClickIndex },
  setEditingTranscriptTitle,
  isFolderSelected,
  supabase,
  smallWindow,
  content: { folderUrl, createdAt, title, fileUrl, pageUrl },
  condensed,
}) => {
  const rightClickedTranscript = righClickType === contentTypes.TRANSCRIPT;

  const contentsArray = useSelector((state) => state.routes.contentsArray);
  const folderUrlToName = useSelector((state) => state.routes.folderUrlToName);

  const ellipsisRef = useRef(null);

  const hoveringEllipsis = useRef(false);

  const date = new Date(createdAt);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }

    return () => window.MathJax.typesetClear();
  }, []);

  const darkMode = useSelector((state) => state.routes.darkMode);
  const editingThisTitle = JSON.parse(transcriptTitleEditIndex) === index;
  const TextType = editingTranscriptTitle && editingThisTitle ? "input" : "div";
  const dispatch = useDispatch();
  const dragImageRef = useRef(null);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text", index);
    // Set the drag image content
    const dragImage = dragImageRef.current;
    dragImage.textContent = title;
    dragImage.style.display = "block";

    // Adjust styles if needed (e.g., font size, padding)
    dragImage.style.backgroundColor = darkMode ? "gray" : "lightgray"; // Set a solid background color
    // dragImage.style.fontSize = "16px";
    dragImage.style.padding = "8px";
    // dragImage.style.borderRadius = 6;

    // Use the custom element as the drag image
    event.dataTransfer.setDragImage(dragImage, 0, 0);

    // Optionally hide the drag image element again after a short delay
    setTimeout(() => {
      dragImage.style.display = "none";
    }, 0);
  };

  const defaultBgColor = darkMode ? "#2f2f2f" : "white";
  const hoverBgColor = darkMode ? "#454545" : "white";
  const hoverShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.2)";
  const defaultShadow = darkMode ? "none" : "2px 2px 10px rgba(0, 0, 0, 0.13)";
  const [contextMenu, setContextMenu] = useState(
    rightClickedTranscript && rightClickIndex === index
  );

  useEffect(() => {
    setContextMenu(rightClickedTranscript && rightClickIndex === index);
  }, [rightClickedTranscript, rightClickIndex, index]);

  return (
    <div
      style={{
        height: condensed ? undefined : smallWindow ? 200 : 240,
        width: "100%",
        borderRadius: 6,
        cursor: "pointer",
        float: "left",
        display: "flex",
        flexDirection: "column",
        backgroundColor: contextMenu ? hoverBgColor : defaultBgColor,
        boxShadow: contextMenu ? hoverShadow : defaultShadow,
      }}
      // users have reported that it is frustrating not being able to select the
      // title and rename it, so disable dragging when title is being edited
      draggable={!editingThisTitle ? "true" : undefined}
      onDragStart={!editingThisTitle ? handleDragStart : undefined}
      onDragEnd={(event) => event.currentTarget.classList.remove("highlight")}
      onMouseEnter={(event) => {
        event.currentTarget.style.backgroundColor = hoverBgColor;
        event.currentTarget.style.boxShadow = hoverShadow;
        ellipsisRef.current.style.display = "flex";
        ellipsisRef.current.style.backgroundColor = hoverBgColor;
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.backgroundColor = contextMenu
          ? hoverBgColor
          : defaultBgColor;
        event.currentTarget.style.boxShadow = contextMenu
          ? hoverShadow
          : defaultShadow;
        ellipsisRef.current.style.display =
          rightClickedTranscript && rightClickIndex === index ? "flex" : "none";
        ellipsisRef.current.style.backgroundColor =
          rightClickedTranscript && rightClickIndex === index
            ? hoverBgColor
            : defaultBgColor;
      }}
      onClick={() => {
        if (hoveringEllipsis.current) {
          return;
        }

        dispatch(
          goToContent({
            contentPathName: pageUrl,
            newTab: true,
            contentType: contentTypes.DOCUMENT,
          })
        );
      }}
      id={`document ${index}`}
      onContextMenu={(event) => {
        event.preventDefault();
        setRightClickObject({
          type: contentTypes.DOCUMENT,
          index,
          thing: contentsArray[index],
          active: true,
        });
        setPoints({ x: event.pageX, y: event.pageY });
      }}
    >
      <div
        ref={dragImageRef}
        style={{
          display: "none",
          position: "absolute",
          zIndex: "-1",
          borderRadius: borderRadius,
          fontSize: 14,
        }}
      >
        {/* This element will be used as the drag image */}
      </div>
      {/* show some of the text of the transcript */}

      {!condensed && (
        <>
          <div
            style={{
              overflow: "hidden",
              flex: 4,
              lineHeight: 0.7,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
            }}
          >
            <embed
              src={fileUrl}
              height="100%"
              style={{
                pointerEvents: "none",
                transform: "scale(1.02)",
                width: getIsMac() ? "100%" : "calc(100% + 17px)",
              }}
            />
          </div>

          <div
            style={{
              backgroundColor: darkMode ? "gray" : colors.lightGray,
              height: 0.5,
            }}
          />
        </>
      )}

      <div
        style={{
          flex: 1,
          // width: "100%",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          height: "100%",
          fontSize: 14,
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          <TextType
            style={{
              fontWeight: 500,
              // height: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              border: "none",
              fontSize: 14,
              fontFamily: "poppins",
              padding: 0,
              margin: 0,
              textAlign: "start",
              display: "flex",
              alignItems: "flex-start",
              lineHeight: "1.5em",
              maxHeight: "5em",
              whiteSpace: "nowrap",
              background: "transparent",
              color: darkMode ? "white" : "black",
            }}
            autoFocus={true}
            defaultValue={title}
            onKeyDown={(event) => {
              if (["Enter", "Escape"].includes(event.key)) {
                event.currentTarget.blur();
              }
            }}
            onBlur={(event) => {
              setEditingTranscriptTitle(false);
              setTranscriptTitleEditIndex(-1);
              if (
                event.currentTarget.value !== "" &&
                event.currentTarget.value !== title
              ) {
                dispatch(
                  editDocumentTitle({
                    supabase: supabase,
                    newTitle: event.currentTarget.value,
                    index: index,
                  })
                );
              }
            }}
          >
            {TextType === "input" ? null : (
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <BsFileEarmarkPdf
                  color={"red"}
                  style={{ minWidth: 14, maxWidth: 14 }}
                />
                {title}
              </div>
            )}
          </TextType>

          {/* Triple dots for more: ... */}
          <div
            ref={ellipsisRef}
            style={{
              display:
                rightClickedTranscript && rightClickIndex === index
                  ? "flex"
                  : "none",
              alignItems: "center",
              color: darkMode ? "white" : "gray",
              backgroundColor:
                rightClickedTranscript && rightClickIndex === index
                  ? hoverBgColor
                  : defaultBgColor,
              height: "100%",
              paddingLeft: 8,
              position: "absolute",
              right: 0,
            }}
            onClick={(event) => {
              event.preventDefault();
              logger(
                [LogCategory.DEBUG],
                `YOU JUST CLICKED ON A transcript: ${event.pageX}, ${event.pageY}`
              );
              setPoints({ x: event.pageX, y: event.pageY + 8 });
              setRightClickObject({
                type: contentTypes.DOCUMENT,
                index,
                thing: contentsArray[index],
                active: true,
              });
            }}
            onMouseLeave={(event) => {
              hoveringEllipsis.current = false;
            }}
            onMouseEnter={(event) => {
              hoveringEllipsis.current = true;
            }}
          >
            {ellipsis({
              color: darkMode ? "white" : "gray",
              size: 20,
            })}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: 400,
            fontSize: 12,
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontWeight: 300,
              fontSize: 12,
              whiteSpace: "nowrap",
              marginRight: 10,
            }}
          >
            {/* October 20, 2023 */}
            {/* {formatDate(createdAt)} */}
            {`${
              months[date.getMonth()]
            } ${date.getDate()}, ${date.getFullYear()}`}
          </p>
          {!isFolderSelected && (
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {folderUrlToName[folderUrl]}
            </p>
          )}
        </div>
      </div>
    </div>
    // </a>
  );
};
export default Document;
