import { FiXCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Button, DarkOverlay } from "../../../components";
import {
  LogCategory,
  apiEndpoint,
  boxShadow,
  colors,
  logger,
} from "../../../utility/utility.mjs";
import { useEffect, useState } from "react";

const ConfirmPausePanel = ({
  setDisplayConfirmPausePanel,
  pauseUntilDate,
  nextBillDate,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const userId = useSelector((state) => state.routes.userId);
  const unableToPauseSubscriptionErrorMessage =
    "Sorry we were unable to pause your subscription.";
  const resumeDateAfterNextBillDateErrorMessage =
    "The resume date must be after the next bill date";
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (
      nextBillDate === undefined ||
      new Date(nextBillDate) > new Date(pauseUntilDate["$d"])
    ) {
      setErrorMsg(resumeDateAfterNextBillDateErrorMessage);
      setDisplayErrorMessage(true);
    }
  }, []);

  const handleConfirmPauseSubscription = () => {
    const date = new Date(pauseUntilDate["$d"]);
    // Stripe requires resume date to be a Unix timestamp
    const numMilliSecondsInSeconds = 1000;
    const unixTimestamp = Math.floor(date.getTime() / numMilliSecondsInSeconds);

    const body = JSON.stringify({
      userId: userId,
      resumeDate: unixTimestamp,
    });
    fetch(`${apiEndpoint}/pause_subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    }).then(async (response) => {
      const data = await response.json();
      if (data.isPaused === true) {
        logger([LogCategory.DEBUG], "Paused Subscription");
        window.location.pathname = "settings";
      } else {
        setErrorMsg(unableToPauseSubscriptionErrorMessage);
        setDisplayErrorMessage(true);
      }
    });
  };

  return (
    <>
      <DarkOverlay
        zIndex={3}
        onClick={() => {
          setDisplayConfirmPausePanel(false);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1
            style={{
              fontSize: 22,
              marginTop: 0,
              width: "100%",
              fontWeight: 700,
            }}
          >
            Confirm Subscription Pause
          </h1>
        </div>
        <div style={{ display: "flex", flexDirection: "column", fontSize: 16 }}>
          <p>
            Your Ossy Plus subscription will end with your current billing cycle
            on{" "}
            <span
              style={{
                fontWeight: 700,
                color: darkMode ? "white" : "black",
              }}
            >
              {nextBillDate !== undefined
                ? new Intl.DateTimeFormat("en-US", dateOptions).format(
                    nextBillDate
                  )
                : null}
              .
            </span>
          </p>

          <p style={{ marginTop: 12 }}>
            Your subscription will resume on{" "}
            <span
              style={{
                fontWeight: 700,
                color: darkMode ? "white" : "black",
              }}
            >
              {nextBillDate !== undefined
                ? new Intl.DateTimeFormat("en-US", dateOptions).format(
                    pauseUntilDate
                  )
                : null}
              .
            </span>
          </p>

          {displayErrorMessage && (
            <div
              style={{
                whiteSpace: "pre-line",
                fontSize: 12,
                color: "red",
                marginTop: 8,
              }}
            >
              {errorMsg}
            </div>
          )}
        </div>
        <Button
          // disabled if nextBillDate is not defined or the nextBillDate is before pauseUntilDate
          disabled={
            nextBillDate === undefined ||
            new Date(nextBillDate) > new Date(pauseUntilDate["$d"])
          }
          style={{ marginTop: 32, fontSize: 16, alignSelf: "flex-end" }}
          content={"Confirm Pause"}
          onClick={() => handleConfirmPauseSubscription()}
        />
      </div>
    </>
  );
};

export default ConfirmPausePanel;
