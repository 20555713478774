import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";

const CollectCardInfoForm = () => {
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [expirationDateMessage, setExpirationDateMessage] = useState("");
  const [cvcErrorMessage, setCvcErrorMessage] = useState("");
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <>
      <p style={{ fontSize: 14, marginBottom: 6 }}>Card number</p>
      <div
        style={{
          // width: "100%",
          width: "100%",
          marginBottom: 24,
        }}
        // className="card-entry"
      >
        <CardNumberElement
          className={`card-info${darkMode ? " dark-mode" : ""}`}
          options={{
            style: {
              base: {
                color: darkMode ? "white" : "black",
                fontSize: "18px",
              },
              invalid: {
                color: darkMode ? "white" : "black",
              },
            },
            disableLink: true,
            showIcon: true,
          }}
          onChange={(e) => {
            if (e.error) {
              setCardErrorMessage(e.error.message);
            } else {
              setCardErrorMessage("");
            }
          }}
        />
        <p
          style={{
            fontWeight: 500,
            color: "tomato",
            fontSize: 14,
            marginTop: 4,
          }}
        >
          {cardErrorMessage}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: 14,
        }}
      >
        <div style={{ flex: 1 }} className="card-entry">
          <p style={{ fontSize: 14, marginBottom: 6 }}>Expiry date</p>
          <CardExpiryElement
            className={`card-info${darkMode ? " dark-mode" : ""}`}
            options={{
              style: {
                base: {
                  color: darkMode ? "white" : "black",
                  fontSize: "18px",
                },
                invalid: {
                  color: darkMode ? "white" : "black",
                },
              },
            }}
            onChange={(e) => {
              if (e.error) {
                setExpirationDateMessage(e.error.message);
              } else {
                setExpirationDateMessage("");
              }
            }}
          />
          <p
            style={{
              fontWeight: 500,
              color: "tomato",
              fontSize: 14,
              marginTop: 4,
            }}
          >
            {expirationDateMessage}
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ fontSize: 14, marginBottom: 6 }}>Security code</p>
          <CardCvcElement
            className={`card-info${darkMode ? " dark-mode" : ""}`}
            options={{
              style: {
                base: {
                  color: darkMode ? "white" : "black",
                  fontSize: "18px",
                },
                invalid: {
                  color: darkMode ? "white" : "black",
                },
              },
            }}
            onChange={(e) => {
              if (e.error) {
                setCvcErrorMessage(e.error.message);
              } else {
                setCvcErrorMessage("");
              }
            }}
          />
          <p
            style={{
              fontWeight: 500,
              color: "tomato",
              fontSize: 14,
              marginTop: 4,
            }}
          >
            {cvcErrorMessage}
          </p>
        </div>
      </div>
    </>
  );
};

export default CollectCardInfoForm;
