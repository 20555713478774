const DarkOverlay = ({ opacity, onClick, zIndex, blur }) => {
  return (
    <div
      style={{
        flex: 1,
        position: "fixed",
        backgroundColor: "black",
        opacity: opacity !== undefined ? opacity : 0.5,
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: zIndex !== undefined ? zIndex : 0,
      }}
      onClick={onClick !== undefined ? onClick : () => {}}
      onContextMenu={(event) => {
        event.preventDefault();
      }}
    />
  );
};

export default DarkOverlay;
