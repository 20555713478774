import { FiX, FiXCircle } from "react-icons/fi";
import { useState, useEffect } from "react";
import {
  borderRadius,
  boxShadow,
  colors,
  logger,
  LogCategory,
} from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import StarRating from "../../../components/StarRating";
import {
  logTranscriptFeedbackForm,
  setDisplayPostTranscriptFeedbackPanel,
} from "../../../transcriptSlice";

const PostTranscriptFeedbackPanel = ({ supabase }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const dispatch = useDispatch();
  const [displayMoreQuestions, setDisplayMoreQuestions] = useState(false);
  const [findOutOption, setFindOutOption] = useState("");
  const [hoverNotNow, setHoverNotNow] = useState(false);
  const feedbackQuestions = [
    "Rate you experience?",
    "What issues did you encounter with this transcript?",
    "What new features would you like to see?",
    "How did you find out about ossy.ai?",
  ];
  const feedbackQuestionsElements = feedbackQuestions.map((question) => {
    return (
      <p
        style={{
          lineHeight: 2,
          fontFamily: "poppins",
          fontSize: 16,
          fontWeight: 400,
          marginBottom: 4,
        }}
      >
        {question}
      </p>
    );
  });
  const [ratings, setRatings] = useState(
    feedbackQuestions.reduce((obj, key) => {
      // default value for: Rate you experience is 0 else ""
      obj[key] = key === feedbackQuestions[0] ? 0 : "";
      return obj;
    }, {})
  );

  // sets how you heard about ossy ai
  const handleRadioChange = (option) => {
    setFindOutOption(option);
    const question = feedbackQuestions[3];
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: option,
    }));
  };

  // updates ratings object when a text box changes
  const handleTextInputChange = (questionIndex, event) => {
    const question = feedbackQuestions[questionIndex];
    const text = event.target.value;
    let response = text;
    // for the question How did you find out about ossy.ai?, input Other before the response
    if (questionIndex === 3) {
      response = `${findOutOption}: ${text}`;
    }
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: response,
    }));
  };

  // When ratings change, saving the object of ratings to the backend
  useEffect(() => {
    logger([LogCategory.INFO], "Ratings: ", ratings);
    dispatch(
      logTranscriptFeedbackForm({ supabase: supabase, ratings: ratings })
    );
  }, [ratings]);

  return (
    <>
      <DarkOverlay />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: "max-content",
          maxWidth: "85%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          maxHeight: "80%",
        }}
      >
        <div
          style={{
            backgroundColor: darkMode ? colors.gray1 : "white",
            borderRadius: 6,
            padding: 16,
            color: darkMode ? "white" : "black",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Title */}
          {!displayMoreQuestions && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                lignItems: "center",
                marginBottom: 12,
              }}
            >
              <h1 style={{ fontSize: 20, margin: 0 }}>Rate your experience?</h1>
            </div>
          )}

          {/* Initial displayed question: How would you rate your overall experience */}
          <div
            style={{
              display: displayMoreQuestions ? "none" : "block",
            }}
          >
            <p
              style={{
                lineHeight: 2,
                fontFamily: "poppins",
                fontSize: 16,
                fontWeight: 400,
                marginBottom: 4,
              }}
            >
              We would love to hear from you!
            </p>
          </div>

          {/* More questions if the user clicks next */}
          <div
            style={{
              display: !displayMoreQuestions ? "none" : "block",
              textAlign: "left",
            }}
          >
            {/* What bugs did you have with the transcript? */}
            <div
              style={{
                marginBottom: 8,
              }}
            >
              {feedbackQuestionsElements[1]}
              <textarea
                style={{
                  width: "100%",
                  height: 50,
                  borderRadius: 4,
                  padding: "6px",
                  border: 0,
                  resize: "none",
                  fontFamily: "Poppins",
                  boxSizing: "border-box",
                  backgroundColor: darkMode ? "" : colors.gray,
                }}
                rows={2}
                cols={0}
                placeholder="Type here..."
                onBlur={(event) => handleTextInputChange(1, event)}
              />
            </div>

            {/* What new features would you like to see? */}
            <div
              style={{
                marginBottom: 8,
                width: "100%",
              }}
            >
              {feedbackQuestionsElements[2]}
              <textarea
                style={{
                  width: "100%",
                  height: 50,
                  borderRadius: 4,
                  padding: "6px",
                  border: 0,
                  resize: "none",
                  fontFamily: "Poppins",
                  boxSizing: "border-box",
                  backgroundColor: darkMode ? "" : colors.gray,
                }}
                rows={2}
                cols={0}
                placeholder="Type here..."
                onBlur={(event) => handleTextInputChange(2, event)}
              />
            </div>

            {/* How did you find out about ossy.ai? */}
            <div
              style={{
                marginBottom: 8,
              }}
            >
              {feedbackQuestionsElements[3]}
              {
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "max-content",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    <label style={{ marginBottom: "4px", cursor: "pointer" }}>
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        checked={findOutOption === "Google"}
                        onChange={() => handleRadioChange("Google")}
                      />
                      Google
                    </label>

                    <label style={{ marginBottom: "4px", cursor: "pointer" }}>
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        checked={findOutOption === "Friend"}
                        onChange={() => handleRadioChange("Friend")}
                      />
                      Friend
                    </label>

                    <label style={{ marginBottom: "4px", cursor: "pointer" }}>
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        checked={findOutOption === "Social Media"}
                        onChange={() => handleRadioChange("Social Media")}
                      />
                      Social Media
                    </label>

                    <label
                      style={{
                        marginBottom: "4px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginRight: 8 }}>
                        <input
                          style={{ cursor: "pointer" }}
                          type="radio"
                          checked={findOutOption === "Other"}
                          onChange={() => handleRadioChange("Other")}
                        />
                        Other:
                      </div>
                      <div>
                        <input
                          type="text"
                          onBlur={(event) => handleTextInputChange(3, event)}
                          onClick={() => handleRadioChange("Other")}
                          placeholder="Type here..."
                          style={{
                            borderRadius: 4,
                            padding: "6px",
                            border: 0,
                            width: "100%",
                            backgroundColor: darkMode ? "" : colors.gray,
                          }}
                        />
                      </div>
                    </label>
                  </form>
                </div>
              }
            </div>
          </div>
        </div>

        {/* contains divider and the star */}
        <div
          style={{
            display: displayMoreQuestions ? "none" : "block",
          }}
        >
          <hr
            style={{
              width: "100%",
              boxSizing: "border-box",
              borderWidth: 1.5,
              color: colors.ossyBlue1,
              borderStyle: "solid",
              margin: 0,
            }}
          />

          <div style={{ padding: "9px 16px" }}>
            <StarRating
              setRatings={setRatings}
              question={feedbackQuestions[0]}
              displayMoreQuestions={displayMoreQuestions}
              setDisplayMoreQuestions={setDisplayMoreQuestions}
            />
          </div>
        </div>
        <hr
          style={{
            width: "100%",
            boxSizing: "border-box",
            borderWidth: 1.5,
            color: colors.ossyBlue1,
            borderStyle: "solid",
            margin: 0,
          }}
        />

        {/* Not Now button */}
        <div
          style={{
            width: "100%",
            fontSize: 20,
            padding: 16,
            boxSizing: "border-box",
            cursor: "pointer",
            backgroundColor: darkMode
              ? hoverNotNow
                ? colors.gray2
                : colors.gray1
              : hoverNotNow
              ? colors.lightGray
              : "white",
          }}
          onClick={() => {
            dispatch(
              setDisplayPostTranscriptFeedbackPanel({
                displayPostTranscriptFeedbackPanel: false,
              })
            );
          }}
          onMouseEnter={(event) => {
            setHoverNotNow(true);
          }}
          onMouseLeave={(event) => {
            setHoverNotNow(false);
          }}
        >
          {!displayMoreQuestions ? "Not Now" : "Submit"}
        </div>
      </div>
    </>
  );
};

export default PostTranscriptFeedbackPanel;
