import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  borderRadius,
  colors,
  fontSizes,
  logger,
  LogCategory,
} from "../../../utility/utility";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { saveSlidesToStorage, saveSlidesUrl } from "../../../transcriptSlice";
import { initialState } from "../../../data/initialState";
import { BsTrash3 } from "react-icons/bs";
import { loadingSymbol } from "../../../assets";
import actions from "../../login/components/actions.json";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Button } from "../../../components";
import { contentTypes } from "../../../utility/utility.mjs";

const SlidesPanel = ({
  slides,
  supabase,
  setGuestUserAction,
  setLogInPanel,
  setFunctionAfterLogIn,
  courseMaterialType,
  ownerOfTranscript,
  setNotOwnerPoints,
  setDisplayNotOwnerTooltip,
  setNotOwnerAction,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const slidesUrlDocument = useSelector(
    (state) => state.routes.document.fileUrl
  );
  const slidesUrlTranscript = useSelector(
    (state) => state.routes.transcript.slidesUrl
  );
  const slidesUrl =
    courseMaterialType === contentTypes.TRANSCRIPT
      ? slidesUrlTranscript
      : slidesUrlDocument;
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [numPages, setNumPages] = useState(null);
  const [slidesContainerRef, setSlidesContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  const [displaySlides, setDisplaySlides] = useState(
    slidesUrl !== initialState.transcript.slidesUrl
  );
  const [displayLoadingSymbol, setDisplayLoadingSymbol] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Error uploading the slides. Please try again."
  );
  const file = useRef();
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const onResize = useCallback((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);
  const resizeObserverOptions = {};
  useResizeObserver(slidesContainerRef, resizeObserverOptions, onResize);

  // triggered after drag and drop or click
  const handleSubmit = () => {
    logger([LogCategory.DEBUG], "file: ", file.current);

    const isFileValid = getIsFileValid();
    if (isFileValid) {
      // make user log in before uploading a file
      if (!isLoggedIn) {
        setDisplayLoadingSymbol(true);
        setGuestUserAction(actions.UPLOAD_SLIDES_ATTEMPT);
        setLogInPanel(true);
        setFunctionAfterLogIn(() => () => saveFile());
      } else {
        saveFile();
      }
    }
  };

  // determine if the input file is valid
  const getIsFileValid = () => {
    const MAX_FILE_SIZE_IN_MB = 15;

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / 1024 ** 2;
    // if file type is not PDF
    if (file.current.type !== "application/pdf") {
      setErrorMsg("Incorrect file type. File must be a PDF.");
      setDisplayErrorMessage(true);
      return false;
    }
    // if file size exceeds the limit
    if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
      setErrorMsg(
        `File Size: ${fileSizeInMB.toFixed(
          2
        )} MB. Max file size allowed is ${MAX_FILE_SIZE_IN_MB} MB.`
      );
      setDisplayErrorMessage(true);
      return false;
    }
    return true;
  };

  // save the pdf file to supabase and then displays it
  const saveFile = () => {
    setDisplayLoadingSymbol(true);
    // read the files content
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.current);
    reader.onloadend = (e) => {
      const slidesArrayBuffer = e.target.result;
      //  save the pdf file to supabase
      dispatch(
        saveSlidesToStorage({
          supabase,
          slidesArrayBuffer,
          setDisplaySlides,
          setDisplayLoadingSymbol,
          setDisplayErrorMessage,
          setErrorMsg,
        })
      );
    };
  };

  // stops the slides display and sets the slides url to be ""
  const removeSlides = () => {
    setDisplaySlides(false);
    dispatch(saveSlidesUrl({ supabase, newSlidesUrl: "" }));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      style={{
        flex: 1,
        flexShrink: 1,
        color: "gray",
        flexDirection: "column",
        alignItems: "center",
        display: slides ? "flex" : "none",
        fontSize: fontSizes.big,
        fontWeight: 400,
        lineHeight: 2.4,
        justifyContent: "center",
        marginTop: 0,
        boxSizing: "border-box",
        resize: "none",
        overflow: "hidden",
        backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
        borderRadius: borderRadius,
        overflowY: "auto",
        height: "100%",
      }}
    >
      {/* click to upload a file */}
      {!displaySlides && !displayLoadingSymbol && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
            onDrop={(e) => {
              // stops the file from opening right away in a new tab
              e.preventDefault();
              if (ownerOfTranscript) {
                const firstItem = e.dataTransfer.items[0];
                if (firstItem && firstItem.kind === "file") {
                  file.current = firstItem.getAsFile();
                  handleSubmit();
                }
              } else {
                setNotOwnerPoints({ x: e.pageX, y: e.pageY });
                setNotOwnerAction("upload slides");
                setDisplayNotOwnerTooltip(true);
              }
            }}
            onDragOver={(e) => {
              // stops the file from opening right away in a new tab
              e.preventDefault();
            }}
          >
            <FaCloudUploadAlt
              style={{
                height: 100,
                width: 100,
                color: colors.ossyBlue1,
                marginBottom: 8,
              }}
            />
            <div style={{ lineHeight: 1.6 }}>Drag a PDF file to upload</div>
            <div style={{ lineHeight: 1.6 }}>or</div>
            <Button
              onClick={(event) => {
                if (ownerOfTranscript) {
                  fileInputRef.current.click();
                } else {
                  setNotOwnerPoints({ x: event.pageX - 40, y: event.pageY });
                  setNotOwnerAction("upload slides");
                  setDisplayNotOwnerTooltip(true);
                }
              }}
              style={{ marginTop: 8 }}
              content={
                <div>
                  <p>Browse files</p>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".pdf"
                    onChange={(event) => {
                      event.preventDefault();
                      file.current = event.target.files[0];
                      handleSubmit();
                    }}
                    style={{ display: "none" }}
                  />
                </div>
              }
            ></Button>
          </div>

          {/* error uploading file */}
          {displayErrorMessage && (
            <div
              style={{
                whiteSpace: "pre-line",
                fontSize: 12,
                color: "red",
                marginTop: 8,
              }}
            >
              {errorMsg}
            </div>
          )}
        </>
      )}

      {/* loading file */}
      {displayLoadingSymbol && (
        <img
          src={loadingSymbol}
          style={{ width: 20, height: 20 }}
          alt="Loading..."
        />
      )}

      {/* pdf slides */}
      {displaySlides && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
          ref={setSlidesContainerRef}
        >
          {courseMaterialType === contentTypes.TRANSCRIPT && (
            <div
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                cursor: "pointer",
                display: "flex",
                zIndex: 3,
              }}
              onClick={(event) => {
                if (ownerOfTranscript) {
                  removeSlides();
                } else {
                  setNotOwnerPoints({ x: event.pageX - 205, y: event.pageY });
                  setNotOwnerAction("delete the slides");
                  setDisplayNotOwnerTooltip(true);
                }
              }}
            >
              <BsTrash3 color="red" style={{ height: 20, width: 20 }} />
            </div>
          )}
          <div>
            <Document
              file={slidesUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={""}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={containerWidth}
                />
              ))}
            </Document>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlidesPanel;
