import amex from "../assets/cards/amex.svg";
import discover from "../assets/cards/discover.svg";
import mastercard from "../assets/cards/mastercard.svg";
import visa from "../assets/cards/visa.svg";

export const cardIcons = {
  amex: amex,
  discover: discover,
  mastercard: mastercard,
  visa: visa,
};
