import { FiXCircle } from "react-icons/fi";
import customVocabs from "../../../data/customVocabs.json";
import { useState, useRef, useEffect, useCallback } from "react";
import {
  apiEndpoint,
  borderRadius,
  boxShadow,
  colors,
  logger,
  LogCategory,
} from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { useDispatch, useSelector } from "react-redux";
import Button, { buttonStyles } from "../../../components/Button";
import { Switch } from "../../../components";
import fileIcon from "../../../assets/file.png";
import {
  actuallyCreateTranscript,
  setTranscribingFile,
  setTranscriptResults,
  shouldDisplayPostTranscriptFeedbackPanel,
  getWeekAudioDuration,
  checkReachedWeeklyQuota,
  updateVideoUrlLocal,
  updateDebugJson,
} from "../../../transcriptSlice";
import actions from "../../login/components/actions.json";
import {
  extractYoutubeVideoId,
  transcriptModeTypes,
} from "../../../utility/utility.mjs";
import { initialState } from "../../../data/initialState";
import Dropdown from "./Dropdown";

const TranscriptSettingsPanel = ({
  startTranscribing,
  setTranscriptionSettings,
  setFilterProfanity,
  setFilterDisfluencies,
  setCustomVocab,
  setLogInPanel,
  setSaveAudio,
  setLoading,
  setGuestUserAction,
  supabase,
  setShowUpgradePanel,
  setFunctionAfterLogIn,
  transcriptMode,
}) => {
  const [toolTip, setToolTip] = useState({ display: "none" });
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [audioFile, setAudioFile] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [videoLink, setVideoLink] = useState();
  const [isValidVideoLink, setIsValidVideoLink] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const userId = useSelector((state) => state.routes.userId);
  const weekTranscriptionLimitSeconds = useSelector(
    (state) => state.routes.weekTranscriptionLimitSeconds
  );
  const videoLinkInputRef = useRef();
  const dispatch = useDispatch();
  const isPaidUser = useSelector((state) => state.routes.isPaidUser);

  const acceptedAudioFormats = [
    "mp3",
    "mp4",
    "mpeg",
    "mpga",
    "m4a",
    "wav",
    "webm",
    "x-m4a",
    "flac",
    "mpga",
    "ogg",
  ];

  // gets the length of audio file in seconds
  const getUploadAudioLengthInSeconds = async (file) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    const metadataLoaded = new Promise((resolve) => {
      audio.addEventListener("loadedmetadata", () => {
        resolve();
      });
    });
    // Wait for the file to be loaded
    await metadataLoaded;
    const audioFileLengthInSeconds = audio.duration;
    return audioFileLengthInSeconds;
  };

  // gets the length of video link in seconds
  const getVideoLinkLengthInSeconds = async (videoLink) => {
    const videoId = extractYoutubeVideoId(videoLink);
    const input = { videoId: videoId };
    const body = JSON.stringify({ input: input });

    const videoDuration = await fetch(
      `${apiEndpoint}/get_youtube_link_duration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      }
    );
    const videolengthInSeconds = (await videoDuration.json()).duration;
    return videolengthInSeconds;
  };

  // Determine if user has reached weekly quota of minutes uploaded
  const isWeeklyUploadQuotaLimitReached = async (uploadLength) => {
    // returns the total number of audio seconds transcibed since Sunday
    const { weekAudioDuration } = await dispatch(
      getWeekAudioDuration({ supabase })
    ).unwrap();
    if (weekAudioDuration + uploadLength < weekTranscriptionLimitSeconds) {
      return false;
    } else return true;
  };

  // Determine if the selected audio file is valid
  const checkIfValidFile = (file) => {
    if (file) {
      // determine file size
      const fileType = file.name.split(".").pop().toLowerCase();
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / 1024 ** 2;
      const MAX_FILE_SIZE_IN_MB = 25;
      logger("fileType: ", fileType);
      // check if file size is under MAX_FILE_SIZE_IN_MB
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        setErrorMsg(
          `File Size: ${fileSizeInMB.toFixed(
            2
          )} MB. Max file size allowed is 25 MB.`
        );
        setDisplayErrorMessage(true);
        return false;
      }
      // check if file is in an accepted format
      else if (!acceptedAudioFormats.includes(fileType)) {
        setErrorMsg("Invalid file format.");
        setDisplayErrorMessage(true);
        return false;
      }

      // file is valid
      else {
        setIsValidFile(true);
        return true;
      }
    }
  };

  // Calls the backend server to transcribe audio file to text
  const transcribeAudio = async (userId_ = userId) => {
    if (audioFile === null) {
      return;
    }
    const { transcriptId } = await dispatch(
      actuallyCreateTranscript({ supabase, transcriptTitle: audioFile.name })
    ).unwrap();

    const formData = new FormData();
    formData.append("audio", audioFile);
    formData.append(
      "audioDuration",
      await getUploadAudioLengthInSeconds(audioFile)
    );
    formData.append("userId", userId_);
    formData.append("transcriptId", transcriptId);

    logger([LogCategory.INFO], "fetching audio transcription");

    dispatch(
      updateDebugJson({ supabase, key: "uploadAudio", newValue: true })
    ).unwrap();
    dispatch(
      setTranscribingFile({ supabase, transcribingFile: true })
    ).unwrap();

    fetch(`${apiEndpoint}/transcribe_audio`, {
      method: "POST",
      body: formData,
    })
      .then(async (response) => {
        if (response.ok) {
          const responseJson = await response.json();
          logger(
            [LogCategory.DEBUG],
            "Created uploaded transcript in supabase. response json: ",
            JSON.stringify(responseJson)
          );

          const { data, error } = responseJson;

          if (error) {
            logger(
              [LogCategory.ERROR],
              `couldn't transcribe file for whatever reason: ${error}`
            );
          }

          dispatch(shouldDisplayPostTranscriptFeedbackPanel({ supabase }));
        } else {
          logger(
            [LogCategory.ERROR],
            `Error transcribing the audio file: ${response.statusText}`
          );
          setErrorMsg("Could not upload audio");
          setDisplayErrorMessage(true);
          throw new Error(
            `Error transcribing the audio file: ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        dispatch(setTranscribingFile({ transcribingFile: false }));
        setErrorMsg("Could not upload audio");
        setDisplayErrorMessage(true);
        console.error(error.message);
      });
  };

  // automatically focus on the youtube link input when the tab is opened
  useEffect(() => {
    if (
      transcriptMode === transcriptModeTypes.VIDEO_LINK.id &&
      videoLinkInputRef.current
    ) {
      videoLinkInputRef.current.focus();
    }
  }, [transcriptMode]);

  const getIsValidYoutubeLink = (link) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
    const isValidYoutubeLink = youtubeRegex.test(link);
    return isValidYoutubeLink;
  };

  // determine if youtube video has captions/transcript
  const getDoesYoutubeLinkHaveCaptions = async (link) => {
    const videoId = extractYoutubeVideoId(link);
    const input = { videoId: videoId };
    const body = JSON.stringify({ input: input });
    const response = await fetch(
      `${apiEndpoint}/does_youtube_link_have_captions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      }
    );
    const doesYoutubeLinkHaveCaptions = (await response.json())["haveCaptions"];
    return doesYoutubeLinkHaveCaptions;
  };

  // when the video link changes see if the video link meets conditions
  useEffect(() => {
    const validateLink = async () => {
      if (
        videoLinkInputRef.current !== null &&
        videoLinkInputRef.current !== undefined &&
        videoLink !== ""
      ) {
        // determine if video link meets conditions
        const link = videoLinkInputRef.current.value;
        const doesYoutubeLinkHaveCaptions =
          await getDoesYoutubeLinkHaveCaptions(link);
        checkIfValidVideoLink(link, doesYoutubeLinkHaveCaptions);
      }
    };
    validateLink();
  }, [videoLink]);

  // determine if a video link is valid and actually has captions/transcript
  const checkIfValidVideoLink = (link, doesYoutubeLinkHaveCaptions) => {
    if (link === "") {
      setDisplayErrorMessage(false);
      setIsValidVideoLink(false);
      return false;
    } else if (getIsValidYoutubeLink(link) === true) {
      if (doesYoutubeLinkHaveCaptions === true) {
        setDisplayErrorMessage(false);
        setIsValidVideoLink(true);
        return true;
      } else {
        setIsValidVideoLink(false);
        setErrorMsg("Subtitles are not available for this video");
        setDisplayErrorMessage(true);
        return false;
      }
    } else {
      setIsValidVideoLink(false);
      setErrorMsg("Invalid YouTube video link");
      setDisplayErrorMessage(true);
      return false;
    }
  };

  // gets video title
  const getVideoTitle = async (videoLink) => {
    const videoId = extractYoutubeVideoId(videoLink);
    const input = { videoId: videoId };
    const body = JSON.stringify({ input: input });

    try {
      const response = await fetch(`${apiEndpoint}/get_youtube_title`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });
      const videoTitle = await response.text();
      return videoTitle;
    } catch {
      return initialState.transcript.title;
    }
  };

  // Calls the backend server to transcribe video link to text
  const transcribeVideo = async (userId_ = userId) => {
    // get the video title
    const videoTitle = await getVideoTitle(videoLink);
    // create the new transcript
    const { transcriptId } = await dispatch(
      actuallyCreateTranscript({
        supabase,
        transcriptTitle: videoTitle,
        videoUrl: videoLink,
      })
    ).unwrap();

    // set the video Url, transcribing tile, and uploadVideo
    dispatch(updateVideoUrlLocal({ newVideoUrl: videoLink }));
    dispatch(
      updateDebugJson({ supabase, key: "uploadVideo", newValue: true })
    ).unwrap();
    dispatch(
      setTranscribingFile({ supabase, transcribingFile: true })
    ).unwrap();

    const videoId = extractYoutubeVideoId(videoLink);
    const input = {
      videoId: videoId,
      transcriptId: transcriptId,
      userId: userId_,
    };
    const body = JSON.stringify({ input: input });

    // transcribe the video link
    fetch(`${apiEndpoint}/transcribe_video`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(async (response) => {
        dispatch(
          setTranscribingFile({ supabase, transcribingFile: false })
        ).unwrap();
        const responseJson = await response.json();
        logger(
          [LogCategory.DEBUG],
          "Created transcript from youtube video link in supabase. response json: ",
          JSON.stringify(responseJson)
        );

        const { data, error } = responseJson;

        if (error) {
          logger(
            [LogCategory.ERROR],
            `couldn't transcibe video link for whatever reason: ${error}`
          );
        }
        const {
          transcriptArray: contentArray,
          bookmarks,
          timestamps,
          paidBlurbIndex,
        } = data;
        dispatch(
          setTranscriptResults({
            contentArray,
            bookmarks,
            timestamps,
            paidBlurbIndex,
          })
        );
        dispatch(getWeekAudioDuration({ supabase, includeCurrent: true }));
      })
      .catch((error) => {
        dispatch(setTranscribingFile({ transcribingFile: false }));
        setErrorMsg("Could not upload video");
        setDisplayErrorMessage(true);
        console.error(error.message);
      });
  };

  return (
    <>
      <DarkOverlay
        zIndex={3}
        onClick={() => {
          setTranscriptionSettings(false);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
        data-testid="transcript-settings-panel"
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              top: 20,
              left: 20,
              cursor: "pointer",
              display: "flex",
            }}
            data-testid="close-settings-panel"
            onClick={() => setTranscriptionSettings(false)}
          >
            <FiXCircle
              color={darkMode ? "white" : "gray"}
              style={{
                minWidth: 20,
                maxWidth: 20,
                minHeight: 20,
                maxHeight: 20,
              }}
            />
          </div>
          <h1
            style={{
              fontSize: 20,
              marginTop: 0,
              width: "100%",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {(() => {
              switch (transcriptMode) {
                case transcriptModeTypes.LIVE_TRANSCRIPTION.id:
                  return "Transcription Settings";
                case transcriptModeTypes.UPLOAD_AUDIO.id:
                  return "Select Audio File";
                case transcriptModeTypes.VIDEO_LINK.id:
                  return "Paste Youtube Link";
                default:
                  return "Transcription Settings";
              }
            })()}
          </h1>
        </div>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            let isWeeklyAudioLimitReached = false;
            if (!isPaidUser) {
              isWeeklyAudioLimitReached = await dispatch(
                checkReachedWeeklyQuota({
                  supabase,
                })
              ).unwrap();
            }

            // display paywall
            if (!isPaidUser && isWeeklyAudioLimitReached) {
              setShowUpgradePanel(true);
              if (
                transcriptMode === transcriptModeTypes.LIVE_TRANSCRIPTION.id
              ) {
                setLoading(true);
              }

              // start transcribing
            } else {
              if (
                transcriptMode === transcriptModeTypes.LIVE_TRANSCRIPTION.id
              ) {
                startTranscribing();
              } else if (
                transcriptMode === transcriptModeTypes.UPLOAD_AUDIO.id
              ) {
                transcribeAudio();
              } else if (transcriptMode === transcriptModeTypes.VIDEO_LINK.id) {
                transcribeVideo();
              }
            }
            setTranscriptionSettings(false);
          }}
        >
          <div
            style={{
              backgroundColor: darkMode ? colors.gray15 : colors.lightGray,
              borderRadius: borderRadius,
              padding: 10,
              // border: !isValidVideoLink ? "2px solid red" : "",
            }}
          >
            {/* Live trancsription */}
            {transcriptMode === transcriptModeTypes.LIVE_TRANSCRIPTION.id && (
              <div>
                {/* subject selection */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Dropdown
                    updateValue={setCustomVocab}
                    options={Object.keys(customVocabs)}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 1000,
                        backgroundColor: darkMode ? colors.gray2 : colors.gray,
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                        fontSize: 12,
                        marginRight: 8,
                        color: darkMode ? "white" : "black",
                      }}
                      onMouseEnter={(e) => {
                        setToolTip({ display: "block" });
                      }}
                      onMouseLeave={(e) => {
                        setToolTip({ display: "none" });
                      }}
                    >
                      i
                    </div>
                    <div style={toolTip}>
                      <p
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        Selecting a subject can increase transcript accuracy.
                      </p>
                    </div>
                  </div>
                </div>

                {/* profanity filter */}
                <div
                  style={{
                    display: "flex",
                    fontSize: 12,
                    width: 200,
                    height: 30,
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  <p stye={{ margin: 0, padding: 0 }}>Filter Profanity</p>

                  <Switch
                    style={{
                      marginLeft: 5,
                    }}
                    updateState={setFilterProfanity}
                  />
                </div>

                {/* filter disfluencies */}
                <div
                  style={{
                    display: "flex",
                    fontSize: 12,
                    // width: 200,
                    height: 30,
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  <p stye={{ margin: 0, padding: 0 }}>
                    Filter Disfluencies (umm, hmm, etc)
                  </p>
                  <Switch
                    style={{
                      marginLeft: 5,
                    }}
                    updateState={setFilterDisfluencies}
                  />
                </div>

                {/* save audio */}
                <div
                  style={{
                    display: "flex",
                    fontSize: 12,
                    width: 200,
                    height: 30,
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  <p stye={{ margin: 0, padding: 0 }}>Save Audio</p>
                  <Switch
                    style={{
                      marginLeft: 5,
                    }}
                    updateState={setSaveAudio}
                  />
                </div>
              </div>
            )}

            {/* Upload audio*/}
            {transcriptMode === transcriptModeTypes.UPLOAD_AUDIO.id && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                {!audioFile && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{
                        // padding: 6px 12px;
                        cursor: "pointer",
                        backgroundColor: darkMode ? "gray" : "lightgray",
                        borderRadius: borderRadius,
                        width: 100,
                        height: 100,
                        fontSize: 12,
                        padding: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      onDrop={(e) => {
                        // stops the file from opening right away in a new tab
                        e.preventDefault();

                        // reset error message
                        setDisplayErrorMessage(false);
                        setIsValidFile(false);

                        [...e.dataTransfer.items].forEach(async (item, i) => {
                          if (item.kind === "file") {
                            const file = item.getAsFile();
                            if (checkIfValidFile(file)) {
                              setAudioFile(file);
                            }
                            return;
                          }
                        });
                      }}
                      onDragOver={(e) => {
                        // stops the file from opening right away in a new tab
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: 0,
                            height: 0,
                            borderLeft: "20px solid transparent",
                            borderRight: "20px solid transparent",

                            borderBottom: `20px solid ${
                              darkMode ? "lightgray" : "white"
                            }`,
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: darkMode ? "lightgray" : "white",
                            width: 16,
                            height: 14,
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: darkMode ? "lightgray" : "white",
                            width: 40,
                            height: 6,
                            marginTop: 8,
                          }}
                        />
                      </div>
                      <input
                        style={{
                          marginBottom: 20,
                          fontSize: 16,
                          display: "none",
                        }}
                        type="file"
                        name="audio"
                        // accept="audio/*"
                        accept={acceptedAudioFormats
                          .map((format) => `audio/${format}`)
                          .join(", ")}
                        id="audio_input"
                        onChange={async (event) => {
                          // reset error message
                          setDisplayErrorMessage(false);
                          setIsValidFile(false);

                          // determine if audio file meets conditions
                          if (checkIfValidFile(event.target.files[0])) {
                            setAudioFile(event.target.files[0]);
                          }
                        }}
                      />
                    </label>
                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 8,
                        color: darkMode ? "lightgray" : "gray",
                        fontWeight: 600,
                      }}
                    >
                      Drag + Drop or Click to Choose File
                    </p>
                  </div>
                )}

                {audioFile && !displayErrorMessage && (
                  <div
                    style={{
                      backgroundColor: "gray",
                      borderRadius: borderRadius,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 14,
                      alignItems: "flex-start",
                      padding: 8,
                      lineHeight: 1.3,
                      alignSelf: "center",
                      width: "fit-content",
                    }}
                  >
                    <FiXCircle
                      style={{
                        // position: "absolute",
                        left: 8,
                        top: 8,
                        width: 18,
                        height: 18,
                        cursor: "pointer",
                        marginBottom: 8,
                      }}
                      onClick={(e) => setAudioFile(null)}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={fileIcon} alt="file" style={{ width: 60 }} />
                      <div
                        style={{
                          marginLeft: 16,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          // justifyContent: "center",
                        }}
                      >
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          {audioFile.name}
                        </p>
                        <p>{(audioFile.size / 1024 ** 2).toFixed(2)} mb</p>
                        <p>{audioFile.type}</p>
                      </div>
                    </div>
                  </div>
                )}

                {displayErrorMessage && (
                  <p
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: 12,
                      color: "red",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}
              </div>
            )}

            {/* Video Link*/}
            {transcriptMode === transcriptModeTypes.VIDEO_LINK.id && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  <input
                    ref={videoLinkInputRef}
                    type="text"
                    placeholder="Paste YouTube link here"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      border: "none",
                      borderWidth: 0,
                      outline: "none",
                      color: darkMode ? "white" : "black",
                      padding: 4,
                      boxSizing: "border-box",
                      lineHeight: 24,
                      height: 24,
                    }}
                    onChange={(event) => {
                      setDisplayErrorMessage(false);
                      setIsValidVideoLink(false);
                      setVideoLink(event.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {transcriptMode === transcriptModeTypes.VIDEO_LINK.id &&
            displayErrorMessage && (
              <p
                style={{
                  paddingTop: 4,
                  whiteSpace: "pre-line",
                  fontSize: 12,
                  color: "red",
                }}
              >
                {errorMsg}
              </p>
            )}
          <Button
            style={{
              alignSelf: "flex-end",
              borderRadius: 6,
              fontSize: 16,
              marginTop: 10,
              // justifySelf: "flex-end",
            }}
            loading={
              videoLinkInputRef.current instanceof HTMLInputElement &&
              videoLinkInputRef.current.value !== "" &&
              transcriptMode === transcriptModeTypes.VIDEO_LINK.id &&
              isValidVideoLink === false &&
              displayErrorMessage === false
            }
            content={(() => {
              switch (transcriptMode) {
                case transcriptModeTypes.LIVE_TRANSCRIPTION.id:
                  return "Start Transcribing";
                case transcriptModeTypes.UPLOAD_AUDIO.id:
                  return "Upload Audio";
                case transcriptModeTypes.VIDEO_LINK.id:
                  return "Create Transcript";
                default:
                  console.error(
                    "error setting property 'content' in transcript settings button"
                  );
                  return "Start Transcribing";
              }
            })()}
            isClickable={(() => {
              switch (transcriptMode) {
                case transcriptModeTypes.LIVE_TRANSCRIPTION.id:
                  return true;
                case transcriptModeTypes.UPLOAD_AUDIO.id:
                  return isValidFile;
                case transcriptModeTypes.VIDEO_LINK.id:
                  return isValidVideoLink;
                default:
                  console.error(
                    "error setting property'isClickable' in transcript settings button"
                  );
                  return true;
              }
            })()}
            onClick={(event) => {
              if (!isLoggedIn) {
                event.preventDefault();

                // make the mic start loading to indicate that transcription is about to start
                setLoading(true);

                // close this settings panel
                setTranscriptionSettings(false);

                // make user log in before transcribing
                setLogInPanel(true);

                // determine which function is triggered after the user logs in
                switch (transcriptMode) {
                  case transcriptModeTypes.LIVE_TRANSCRIPTION.id:
                    setGuestUserAction(actions.START_LIVE_TRANSCRIPT);
                    setFunctionAfterLogIn(
                      (userData) => () => startTranscribing()
                    );
                    break;
                  case transcriptModeTypes.UPLOAD_AUDIO.id:
                    setGuestUserAction(actions.START_UPLOAD_AUDIO);
                    setFunctionAfterLogIn(
                      () =>
                        ({ userId }) =>
                          transcribeAudio(userId)
                    );
                    break;
                  case transcriptModeTypes.VIDEO_LINK.id:
                    setGuestUserAction(actions.START_TRANSCRIBE_VIDEO);
                    setFunctionAfterLogIn(
                      () =>
                        ({ userId }) =>
                          transcribeVideo(userId)
                    );
                    break;
                  default:
                    console.error("should never reach here");
                    break;
                }
              }
            }}
          />
        </form>
      </div>
    </>
  );
};

export default TranscriptSettingsPanel;
