import { useSelector } from "react-redux";
import { borderRadius } from "../../../utility/utility";

const Dropdown = ({ updateValue = () => {}, options = [] }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <select
      name="subject"
      id="subject_selector"
      style={{
        backgroundColor: darkMode ? "gray" : "lightgray",
        // height: 20,
        marginRight: 6,
        appearance: "none",
        padding: 8,
        color: darkMode ? "white" : "black",
        borderRadius: borderRadius,
        border: "transparent",
        WebkitTapHighlightColor: "transparent",
        msScrollbarHighlighxtColor: "transparent",
        fontSize: 12,
        fontFamily: "poppins",
      }}
      onChange={(event) => updateValue(event.currentTarget.value)}
    >
      {options.map((subject) => (
        <option key={subject} value={subject}>
          {subject}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
