import { useDispatch, useSelector } from "react-redux";
import { boxShadow, borderRadius, colors } from "../utility/utility";
import { FiXCircle } from "react-icons/fi";
import DarkOverlay from "./DarkOverlay";
import Button, { buttonStyles } from "./Button";
import FriendInviteInput from "./FriendInviteInput";
import { useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import {
  checkReachedWeeklyQuota,
  inviteEmailsAndAddFreeHour,
} from "../transcriptSlice";
import { claimFreeHourStatus } from "../utility/utility.mjs";
import LimitBarAndDescription from "./LimitBarAndDescription";

const InviteThreeFriendsPanel = ({
  showInviteFriendsPanel,
  setShowInviteFriendsPanel,
  supabase,
  midTranscript,
  setShowCheckout,
  setShowTranscriptUpgradeButton,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const { unclaimed, claimed } = claimFreeHourStatus;
  const claimedStatus = useSelector((state) => state.routes.claimedStatus);

  const [loading, setLoading] = useState(false);

  const [inviteeEmail1, setInviteeEmail1] = useState("");
  const [inviteeEmail2, setInviteeEmail2] = useState("");
  const [inviteeEmail3, setInviteeEmail3] = useState("");

  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");

  const dispatch = useDispatch();

  const validatedEmails = useRef([]);

  // check that the email address is a valid email address (has an @ sign, etc)
  const checkIfValidEmailFormat = (emailAddress) => {
    let errorMessage = "";
    if (
      !new RegExp(/[a-zA-z0-9._:$!%\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}/).test(
        emailAddress
      )
    ) {
      errorMessage = "Please enter a valid email address.";
    }

    return { errorMessage };
  };

  const checkIfUserAlreadyExists = async (emailAddress) => {
    let errorMessage = "";

    const { data: existingUsers, error: fetchExistingUsersError } =
      await supabase
        .from("user data")
        .select("email")
        .eq("email", emailAddress);

    if (existingUsers.length > 0) {
      errorMessage = "This user already created an account.";
    }
    return { errorMessage };
  };

  const checkIfUserHasBeenInvited = async (emailAddress) => {
    let errorMessage = "";

    const { data: invitedUsers, error: fetchInvitedUsersError } = await supabase
      .from("invitations")
      .select("invitee_email")
      .eq("invitee_email", emailAddress);

    if (invitedUsers.length > 0) {
      errorMessage = "This user has already been invited to Ossy AI.";
    }

    return { errorMessage };
  };

  const checkIfUserEnteredTheSameEmail = (emailAddress) => {
    let errorMessage = "";
    if (validatedEmails.current.includes(emailAddress)) {
      errorMessage = "You've already entered this email";
    }
    validatedEmails.current = [...validatedEmails.current, emailAddress];

    return { errorMessage };
  };

  /**
   *
   * @param {boolean} inviteeEmail [the email of the person being invited]
   * @returns {object} [an object with the property errorMessage,
   * a boolean indicating if the email can be invited to the platform]
   */
  const checkEmailValidity = async (inviteeEmail) => {
    let _errorMessage = "";

    for (const emailCheck of [
      checkIfValidEmailFormat,
      checkIfUserAlreadyExists,
      checkIfUserHasBeenInvited,
      checkIfUserEnteredTheSameEmail,
    ]) {
      const { errorMessage } = await emailCheck(inviteeEmail);
      _errorMessage = errorMessage;
      if (errorMessage) {
        break;
      }
    }

    return { errorMessage: _errorMessage };
  };

  return (
    <>
      <DarkOverlay />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 450,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          opacity: showInviteFriendsPanel ? 1 : 0,
          transition: "opacity 1s",
        }}
      >
        <div
          style={{
            position: "fixed",
            margin: 0,
            padding: 0,
            top: 12,
            left: 12,
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => {
            setShowInviteFriendsPanel(false);
            setShowTranscriptUpgradeButton(true);
          }}
        >
          <FiXCircle
            color={darkMode ? "white" : "gray"}
            style={{
              width: 20,
              margin: 0,
              padding: 0,
              height: 20,
            }}
          />
        </div>
        <div
          style={{
            borderRadius: borderRadius,
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            backgroundColor: darkMode ? colors.gray1 : "white",
          }}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: 26,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              color: colors.ossyBlue1,
            }}
          >
            <span style={{ color: "inherit" }}>
              {(() => {
                switch (claimedStatus) {
                  case unclaimed:
                    return (
                      <>
                        Claim 1{" "}
                        <span style={{ fontWeight: 900, color: "inherit" }}>
                          FREE
                        </span>{" "}
                        Hour of Ossy AI
                      </>
                    );

                  case claimed:
                    return "Thanks for your support!";
                  default:
                    return "asdf";
                }
              })()}
            </span>
          </p>

          <p style={{ display: "flex", fontSize: 18, fontWeight: 500 }}>
            {(() => {
              switch (claimedStatus) {
                case claimed:
                  return "One hour of transcription has been added to your account.";
                default:
                  return "Invite 3 people to Ossy AI to receive a free hour.";
              }
            })()}
          </p>

          {claimedStatus === claimed && <LimitBarAndDescription right />}

          <div style={{ marginTop: 20 }} />
          <form
            onSubmit={async (e) => {
              setLoading(true);
              e.preventDefault();

              validatedEmails.current = [];

              const { errorMessage: _errorMessage1 } = await checkEmailValidity(
                inviteeEmail1
              );

              const { errorMessage: _errorMessage2 } = await checkEmailValidity(
                inviteeEmail2
              );

              const { errorMessage: _errorMessage3 } = await checkEmailValidity(
                inviteeEmail3
              );

              setErrorMessage1(_errorMessage1);
              setErrorMessage2(_errorMessage2);
              setErrorMessage3(_errorMessage3);

              if (
                _errorMessage1 === "" &&
                _errorMessage2 === "" &&
                _errorMessage3 === ""
              ) {
                await dispatch(
                  inviteEmailsAndAddFreeHour({
                    supabase,
                    emails: [inviteeEmail1, inviteeEmail2, inviteeEmail3],
                  })
                ).unwrap();
              }

              setLoading(false);

              const reachedTranscriptionLimit = await dispatch(
                checkReachedWeeklyQuota({ supabase })
              ).unwrap();

              if (!reachedTranscriptionLimit) {
                setShowTranscriptUpgradeButton(false);
              }
            }}
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display:
                  claimedStatus === claimFreeHourStatus.unclaimed
                    ? "flex"
                    : "none",
                flexDirection: "column",
                gap: 12,
                marginBottom: 24,
                width: "100%",
              }}
            >
              <FriendInviteInput
                count={1}
                supabase={supabase}
                inviteeEmail={inviteeEmail1}
                setInviteeEmail={setInviteeEmail1}
                errorMessage={errorMessage1}
                setErrorMessage={setErrorMessage1}
              />
              <FriendInviteInput
                count={2}
                supabase={supabase}
                inviteeEmail={inviteeEmail2}
                setInviteeEmail={setInviteeEmail2}
                errorMessage={errorMessage2}
                setErrorMessage={setErrorMessage2}
              />
              <FriendInviteInput
                count={3}
                supabase={supabase}
                inviteeEmail={inviteeEmail3}
                setInviteeEmail={setInviteeEmail3}
                errorMessage={errorMessage3}
                setErrorMessage={setErrorMessage3}
              />
              <p style={{ textAlign: "right", fontSize: 12, color: "gray" }}>
                *The hour expires Sunday at midnight
              </p>
            </div>

            <Button
              content={(() => {
                switch (claimedStatus) {
                  case unclaimed:
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>Send Invites</p>
                        <BsSend style={{ width: 16, height: 16 }} />
                      </div>
                    );
                  default:
                    return "Continue";
                }
              })()}
              style={{
                fontSize: 20,
                marginTop: claimedStatus === claimed ? 16 : 0,
                padding: 12,
                fontWeight: 700,
                minWidth: "100%",
                justifyContent: "center",
              }}
              loading={loading}
              onClick={(e) => {
                if (claimedStatus === unclaimed) {
                  // proceed to submit the form
                } else {
                  // don't submit the form
                  e.preventDefault();
                  setShowInviteFriendsPanel(false);
                }
              }}
            />
            {claimedStatus === unclaimed && (
              <Button
                content="or upgrade to Ossy Plus"
                buttonStyle={buttonStyles.text}
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  marginTop: 6,
                  fontSize: 14,
                }}
                onClick={() => {
                  if (midTranscript) {
                    setShowCheckout(true);
                    setShowInviteFriendsPanel(false);
                  } else {
                    window.location.pathname = "/checkout";
                  }
                }}
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default InviteThreeFriendsPanel;
