import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { apiEndpoint, colors } from "../../../utility/utility";

export default function CheckoutForm({
  style = {},
  redirect = true,
  setCheckoutCompleted = () => {},
}) {
  const elements = useElements();
  const stripe = useStripe();

  const [message, setMessage] = useState(null);
  const [returnUrl, setReturnUrl] = useState("/");
  const [isLoading, setIsLoading] = useState(false);
  const email = useSelector((state) => state.routes.email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: redirect ? "always" : "if_required",
      // redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.origin}/WelcomeToOssyPlus`,
        receipt_email: email,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setCheckoutCompleted(true);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    wallets: { applePay: "never", googlePay: "never" },
    paymentMethodOrder: ["card", "apple_pay", "google_pay"],
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="payment-form"
      style={{ color: "white", ...style }}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        style={{ backgroundColor: colors.ossyBlue1 }}
      >
        <span style={{ color: "white" }}>
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" style={{ color: "red" }}>
          {message}
        </div>
      )}
    </form>
  );
}
