import { useDispatch, useSelector } from "react-redux";
import logoHuge from "../../../assets/logo_huge.png";
import {
  borderRadius,
  colors,
  fontSizes,
  formatTimestamp,
  logger,
  unformatTimestamp,
} from "../../../utility/utility";
import senders from "./senders.json";
import TranscriptBlurbPreview from "./BlurbPreview";
import MessageBlurbText from "./MessageBlurbText";
import MessageBlurbTextReference from "./MessageBlurbTextReference";
import MessageBlurbTextBold from "./MessageBlurbTextBold";
import { contentTypes } from "../../../utility/utility.mjs";

const MessageBlurb = ({
  index,
  content,
  sender,
  panelCutoff,
  thinking,
  error,
  queryTranscriptBlurbs = [],
  setHighlightedTranscriptBlurbIndex,
  setHighlightedSummaryBlurbIndex,
  highlightTranscriptBlurbTimeout,
  folderLevel,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const pfpUrl = useSelector((state) => state.routes.pfpUrl);
  const { timestamps } = useSelector((state) => state.routes.transcript);

  const contentsArray = useSelector((state) => state.routes.contentsArray);

  let referenceRegex;

  const lectureWithTimestampRegexes = contentsArray.map(
    ({ title }) =>
      new RegExp(`${title}${", ?[0-5][0-9]:[0-5][0-9](:[0-5][0-9])?"}`, "i")
  );

  const lecturesWithTimestampsRegex = new RegExp(
    lectureWithTimestampRegexes.map((regex) => `(${regex.source})`).join("|"),
    "i"
  );

  const lecturesRegex = new RegExp(
    contentsArray.map(({ title }) => `(${title})`).join("|"),
    "i"
  );

  const boldRegex = new RegExp("([*][*].+[*][*])", "g");

  const timestampRegex = new RegExp(
    "([0-5][0-9]:[0-5][0-9](:[0-5][0-9])?)",
    "g"
  );

  if (folderLevel) {
    referenceRegex = contentsArray.length
      ? new RegExp(
          `${lecturesWithTimestampsRegex.source}|${lecturesRegex.source}`,
          "i"
        )
      : timestampRegex;
  } else {
    referenceRegex = timestampRegex;
  }

  // Initialize the result array
  // const contentSplitByReferences = content.split(referenceRegex);
  const contentSplitByReferences = content.split(
    new RegExp(`${referenceRegex.source}|${boldRegex.source}`, "g")
  );

  return (
    <div
      style={{
        marginBottom: 40,
        color: "black",
        marginRight: panelCutoff ? 8 : 16,
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 4,
        borderRadius: borderRadius,
        marginLeft: 4,
        // maxWidth: ""
      }}
      key={`messageBlurb ${index}`}
      id={`messageBlurb ${index}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        {/* sender of message */}
        <img
          src={sender === senders.user ? pfpUrl : logoHuge}
          alt="you"
          style={{
            borderRadius: 1000,
            width: 30,
            height: 30,
            margin: 0,
            padding: 0,
          }}
        />

        {/* right */}
        <div
          style={{
            marginLeft: 8,
            /* critical line of code, prevents div from overflowing when a blurb was selected
             */
            overflow: "clip",
          }}
        >
          <p
            style={{
              fontWeight: 700,
              color: darkMode ? "white" : "gray",
              margin: 0,
              padding: 0,
              lineHeight: 1,
              fontSize: fontSizes.regular,
            }}
            id={`message ${index} sender`}
          >
            {sender}
          </p>

          {/* blurbs that were associated with the question */}
          <div style={{ marginTop: 8 }}>
            {queryTranscriptBlurbs.map(({ timestampText, transcriptText }) => (
              <TranscriptBlurbPreview
                timestampText={timestampText}
                transcriptText={transcriptText}
              />
            ))}
          </div>

          {/* message */}
          <p
            style={{
              margin: 0,
              fontSize: thinking ? 40 : fontSizes.regular,

              // qudus prefers lineHeight of 2
              lineHeight: thinking ? 1 : 2, // needs to be reduced
              borderRadius: borderRadius,
              color: darkMode ? "white" : "black",
              opacity: 1,
              overflowWrap: "anywhere",

              // this keeps the newline characters that the model might put in the message.
              whiteSpace: "pre-wrap",
            }}
            id={`messageBlurbText ${index}`}
          >
            {thinking
              ? "•"
              : contentSplitByReferences.map((text, index) => {
                  const isReference = referenceRegex.test(text);
                  const isBolded = boldRegex.test(text);

                  let MessageBlurbType;
                  if (isReference) {
                    MessageBlurbType = MessageBlurbTextReference;
                  } else if (isBolded) {
                    MessageBlurbType = MessageBlurbTextBold;
                  } else {
                    MessageBlurbType = MessageBlurbText;
                  }

                  // remove the double asteriks
                  text = isBolded ? text.slice(2, -2) : text;
                  return (
                    <MessageBlurbType
                      index={index}
                      thinking={thinking}
                      setHighlightedSummaryBlurbIndex={
                        setHighlightedSummaryBlurbIndex
                      }
                      setHighlightedTranscriptBlurbIndex={
                        setHighlightedTranscriptBlurbIndex
                      }
                      text={text}
                      lecturesRegex={lecturesRegex}
                      lecturesWithTimestampsRegex={lecturesWithTimestampsRegex}
                      folderLevel={folderLevel}
                      highlightTranscriptBlurbTimeout={
                        highlightTranscriptBlurbTimeout
                      }
                      contentType={contentTypes.TRANSCRIPT}
                    />
                  );
                })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageBlurb;
