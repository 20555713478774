import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Menu, OssyLogo, OssyText } from "../components";
import { useEffect, useState } from "react";
import {
  addScrollBarUI,
  boxShadow,
  getIsMac,
  setScrollbarLightOrDarkMode,
  colors,
} from "../utility/utility.mjs";
import WelcomePageFooter from "../components/WelcomePageFooter";
import { initialState } from "../data/initialState";
import CarrotIcon from "../components/CarrotIcon";

const AffilaiteProgramPage = ({ supabase }) => {
  const dispatch = useDispatch();
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${770}px)`).matches
  );
  const ossyAffiliateLink = "https://ossyai.promotekit.com";
  const payoutPercentage = 0.5;
  const questions = [
    {
      question: "Is there a limit on how much I can earn?",
      answer:
        "No, there is no cap on your earnings. The more subscribers you bring in, the more you earn.",
    },
    {
      question: "How and when do I get paid?",
      answer: `Payments are made at the start of each month using PayPal or Wise.`,
    },
    {
      question: "Can I promote my affiliate link on social media?",
      answer:
        "Absolutely! You have the freedom to share your affiliate link through social media, blog posts, emails, or any platform you prefer.",
    },
    {
      question: "Do you have any content examples?",
      answer: (
        <>
          Here are some accounts to look at: <br></br>
          <a
            href={`https://www.instagram.com/ossy.ai/`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.ossyBlue1, fontWeight: "bold" }}
          >
            Instagram
          </a>
          <br></br>
          <a
            href={`https://www.tiktok.com/@ossy.ai`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.ossyBlue1, fontWeight: "bold" }}
          >
            TikTok
          </a>
        </>
      ),
    },
    {
      question: "Who can I contact if I have more questions?",
      answer: (
        <>
          Send us an{" "}
          <a
            href={`mailto:developers@ossy.ai`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.ossyBlue1, fontWeight: "bold" }}
          >
            email
          </a>{" "}
          or dm on{" "}
          <a
            href={`https://www.instagram.com/ossy.ai/`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.ossyBlue1, fontWeight: "bold" }}
          >
            Instagram
          </a>
          .
        </>
      ),
    },
  ];
  const [openedQuestions, setOpenedQuestions] = useState(
    questions.map((_) => false)
  );

  //  changes the styling of the scrollbar and thumb when in darkMode or lightMode
  useEffect(() => {
    const isMac = getIsMac();
    const isDarkMode = false;
    if (!isMac) {
      setScrollbarLightOrDarkMode(isDarkMode);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(window.matchMedia(`(max-width: ${770}px)`).matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
        onScroll={(event) => {
          const isMac = getIsMac();
          if (!isMac) {
            addScrollBarUI(event);
          }
        }}
      >
        <Header
          color={"white"}
          height={80}
          marginInside={smallWindow ? 16 : 28}
          line
          ignoreDarkMode
          fixed
          left={
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <OssyLogo width={25} />
              <OssyText big color={colors.ossyBlue1} />
            </div>
          }
          right={
            <Menu
              breakpoint={1250}
              noMenuContent={
                <>
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      color: colors.black,
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      window.location.href = "/affiliate";
                    }}
                  >
                    Affiliate
                  </p>
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      color: colors.black,
                      whiteSpace: "nowrap",
                      marginLeft: 20,
                    }}
                    onClick={() => {
                      window.location.href = "/releases";
                    }}
                  >
                    What's New
                  </p>
                  <Button
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    content={"Login"}
                    style={{
                      fontSize: 18,
                      marginLeft: 20,
                    }}
                  />
                </>
              }
              menuContents={[
                {
                  text: "What's New",
                  onClick: () => {
                    window.location.href = "/releases";
                  },
                },
                {
                  text: "Affiliate",
                  onClick: () => {
                    window.location.href = "/affiliate";
                  },
                },
                {
                  text: "Login",
                  onClick: () => {
                    window.location.href = "/login";
                  },
                },
              ]}
            />
          }
        />
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: colors.black,
          }}
        >
          {/* large call to action */}
          <div
            style={{
              marginTop: 160,
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 54,
              marginRight: 54,
              maxWidth: 700,
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: smallWindow ? 48 : 72,
                marginBottom: 12,
              }}
            >
              Earn{" "}
              <span style={{ color: colors.ossyBlue1 }}>
                {payoutPercentage * 100}%
              </span>{" "}
              on all referrals
            </p>
            <p
              style={{
                fontSize: 20,
                color: "gray",
                // fontWeight: 500,
              }}
            >
              Join our affiliate program and receive a $
              {(payoutPercentage * initialState.ossyPriceNum).toFixed(2)}{" "}
              commission for every customer you refer to us!
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                marginTop: smallWindow ? 0 : 40,
                alignItems: "center",
              }}
            >
              <Button
                content={
                  <div>
                    <a
                      href={`${ossyAffiliateLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Join Us Now
                    </a>
                  </div>
                }
                onClick={() => {}}
                padding={20}
                style={{
                  alignItems: "center",
                  marginTop: smallWindow ? 30 : 0,
                  fontSize: 24,
                  justifyContent: "center",
                  marginRight: smallWindow ? 0 : 20,
                }}
              />
            </div>
          </div>

          {/* How it works */}
          <div
            style={{
              marginTop: 150,
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 54,
              marginRight: 54,
              width: "100%",
            }}
          >
            <div
              style={{
                fontSize: smallWindow ? 40 : 56,
                fontWeight: 700,
                marginBottom: 32,
              }}
            >
              How it works
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                justifyContent: "space-between",
                width: "100%",
                boxSizing: "border-box",
                gap: smallWindow ? 32 : 48,
                paddingRight: smallWindow ? 32 : 48,
                paddingLeft: smallWindow ? 32 : 48,
              }}
            >
              {[
                {
                  stepNumber: 1,
                  headerText: "Sign up",
                  subHeadingText: (
                    <>
                      Click{" "}
                      <a
                        href={`${ossyAffiliateLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.ossyBlue1, fontWeight: "bold" }}
                      >
                        here
                      </a>{" "}
                      to sign up for our affiliate program and set up your own
                      personal referral link.
                    </>
                  ),
                },
                {
                  stepNumber: 2,
                  headerText: "Share your referral link",
                  subHeadingText:
                    "Share your referral link with your friends, family, and followers.",
                },
                {
                  stepNumber: 3,
                  headerText: "Get paid",
                  subHeadingText:
                    "We'll send your commission via PayPal or Wise at the beginning of every month.",
                },
              ].map(({ stepNumber, headerText, subHeadingText }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    textAlign: "left",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      fontSize: 18,
                      color: colors.ossyBlue1,
                      fontWeight: 500,
                    }}
                  >
                    Step {stepNumber}
                  </div>
                  <div style={{ marginTop: 8, fontWeight: 600, fontSize: 24 }}>
                    {headerText}
                  </div>
                  <div style={{ marginTop: 16, fontSize: 18 }}>
                    {subHeadingText}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Questions */}
          <div
            style={{
              marginTop: 150,
              textAlign: "center",
              // color: "white",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: smallWindow ? 40 : 56,
                fontWeight: 700,
              }}
            >
              Questions?
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 500,
                marginTop: 8,
                marginBottom: 16,
              }}
            >
              We've got answers.
            </p>

            <div style={{ width: 1160, maxWidth: "95%" }}>
              {questions.map(({ question, answer }, index) => {
                const isSelected = openedQuestions[index] ?? false;
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        fontWeight: 600,
                        fontSize: 18,
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        textDecoration: isSelected ? "underline" : undefined,
                        padding: 16,
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          colors.ossyBlue10Full;
                      }}
                      onMouseLeave={(e) => {
                        if (!isSelected) {
                          e.currentTarget.style.backgroundColor = "transparent";
                        }
                      }}
                      onClick={(e) => {
                        const newOpenedQuestions = [
                          ...openedQuestions.slice(0, index),
                          !isSelected,
                          ...openedQuestions.slice(index + 1),
                        ];
                        setOpenedQuestions(newOpenedQuestions);
                      }}
                    >
                      {question}

                      <CarrotIcon
                        color={"black"}
                        style={{
                          maxWidth: 24,
                          minWidth: 24,
                          transform: isSelected ? undefined : "scaleY(-1)",
                        }}
                      />
                    </div>

                    {isSelected && (
                      <div
                        style={{
                          backgroundColor: colors.ossyBlue10Full,
                          textAlign: "left",
                          paddingBottom: 24,
                          paddingLeft: 32,
                          paddingRight: 32,
                          fontSize: 15,
                          whiteSpace: "pre-wrap",
                          fontWeight: 500,
                        }}
                      >
                        {answer}
                      </div>
                    )}

                    <div
                      style={{
                        backgroundColor: colors.ossyBlue10Full,
                        height: 1,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {/* Partner with us */}
          <div
            style={{
              marginTop: 150,
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 54,
              marginRight: 54,
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: smallWindow ? 40 : 56,
              }}
            >
              Partner with us
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: smallWindow ? "column" : "row",
                marginTop: smallWindow ? 16 : 56,
              }}
            >
              <Button
                content={
                  <div>
                    <a
                      href={`${ossyAffiliateLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Join Us Now
                    </a>
                  </div>
                }
                onClick={() => {}}
                padding={16}
                style={{
                  alignItems: "center",
                  marginTop: smallWindow ? 30 : 0,
                  fontSize: 18,
                  justifyContent: "center",
                  marginRight: smallWindow ? 0 : 20,
                }}
              />
            </div>
          </div>

          {/* footer */}
          <WelcomePageFooter />
        </div>
      </div>
    </>
  );
};

export default AffilaiteProgramPage;
