import DropDown from "./DropDown";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFolder,
  fetchCourseContent,
  fetchDefaultCourseContent,
  saveContent,
  unsaveContent,
} from "../transcriptSlice";
import { logger, LogCategory } from "../utility/utility";
import { contentTypes } from "../utility/utility.mjs";

const RightClickFolderDropdown = ({
  points,
  rightClickObject,
  setRightClickObject,
  setFolderTitleEditIndex,
  setDeleteFolderWarningPanel,
  supabase,
  selected,
  isFolderSelected,
  isOwner,
  setDisplaySharePanel,
  isWithinFolder = false,
}) => {
  const dispatch = useDispatch();
  const folderObj = useSelector((state) => state.routes.folder);

  const { isSaved, id } =
    rightClickObject.index > -1 ? rightClickObject.thing : folderObj;

  const {
    index: rightClickIndex,
    thing: { folderUrl, folderName },
  } = rightClickObject;

  let links = [];
  if (isOwner && !isWithinFolder) {
    links.push(
      {
        name: "Rename Folder",
        onClick: () => {
          setFolderTitleEditIndex(rightClickIndex);
          logger(
            [LogCategory.DEBUG],
            `setting folder title edit index: ${rightClickIndex} `
          );
        },
      },
      {
        name: "Delete Folder",
        onClick: async () => {
          const folderContents = await dispatch(
            fetchDefaultCourseContent({ supabase, folderUrl })
          ).unwrap();
          if (folderContents.length > 0) {
            setDeleteFolderWarningPanel(true);
          } else {
            await dispatch(
              deleteFolder({
                supabase: supabase,
                indexToRemove: rightClickIndex,
              })
            ).unwrap();
            if (isFolderSelected && selected === folderName) {
              window.location.href = "/";
            }
          }
          setRightClickObject({ ...rightClickObject, active: false });
        },
      }
    );
  }

  if (isOwner) {
    links.push({
      name: "Share",
      onClick: () => {
        setDisplaySharePanel(true);
      },
    });
  }

  links.push({
    name: "Copy Folder Link",
    onClick: async () => {
      await navigator.clipboard.writeText(`${origin}/f/${folderUrl}`);
    },
  });

  if (!isOwner) {
    links.push({
      name: `${isSaved ? "Unsave" : "Save"} Folder`,
      onClick: () => {
        isSaved
          ? dispatch(
              unsaveContent({
                supabase,
                contentId: id,
                contentType: contentTypes.FOLDER,
              })
            )
          : dispatch(
              saveContent({
                supabase,
                contentId: id,
                contentType: contentTypes.FOLDER,
              })
            );
      },
    });
  }

  return (
    <DropDown
      overlay={true}
      top={points.y}
      left={points.x}
      func={() => {
        setRightClickObject({ ...rightClickObject, active: false });
      }}
      links={links}
    />
  );
};

export default RightClickFolderDropdown;
