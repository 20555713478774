import { useDispatch, useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";
import { boxShadow, colors, contentTypes } from "../utility/utility.mjs";
import Button, { buttonStyles } from "./Button";
import { addToFolder, goToContent, togglePrivacy } from "../transcriptSlice";

const AddToSharedFolderPanel = ({
  setDisplayAddToSharedFolder,
  supabase,
  addToSharedFolderIndex,
  addToSharedFolderContentIndex,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const dispatch = useDispatch();
  const { folderUrl, folderName } = useSelector(
    (state) => state.routes.folders[addToSharedFolderIndex]
  );
  const isTranscript =
    useSelector(
      (state) =>
        state.routes.contentsArray[addToSharedFolderContentIndex]?.contentType
    ) === contentTypes.TRANSCRIPT;

  return (
    <>
      <DarkOverlay
        zIndex={3}
        onClick={() => {
          setDisplayAddToSharedFolder(false);
        }}
      />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          zIndex: 3,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1
            style={{
              fontSize: 20,
              marginTop: 0,
              width: "100%",
              textAlign: "left",
              fontWeight: 500,
            }}
          >
            Change who has access?
          </h1>
        </div>
        <div
          style={{
            displaye: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 8,
          }}
        >
          <p style={{ fontSize: 12, textAlign: "left" }}>
            {`This item will be visible to everyone who can see "${folderName}"`}
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              content={"Cancel"}
              buttonStyle={{
                ...buttonStyles.white,
                style: { ...buttonStyles.white.style, color: "gray" },
              }}
              style={{ marginRight: 12 }}
              onClick={() => setDisplayAddToSharedFolder(false)}
            />
            <Button
              content={"Move"}
              onClick={async () => {
                await dispatch(
                  togglePrivacy({
                    supabase: supabase,
                    contentType: isTranscript
                      ? contentTypes.TRANSCRIPT
                      : contentTypes.DOCUMENT,
                    makeContentPublic: true,
                    index: addToSharedFolderContentIndex,
                  })
                ).unwrap();

                dispatch(
                  addToFolder({
                    supabase: supabase,
                    contentIndex: addToSharedFolderContentIndex,
                    folderUrl: folderUrl,
                  })
                );

                setDisplayAddToSharedFolder(false);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddToSharedFolderPanel;
