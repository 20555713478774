import { useSelector } from "react-redux";
import { initialState } from "../data/initialState";
import { shareIcon } from "../assets";
import ToolTip from "./ToolTip";
import { colors, contentTypes } from "../utility/utility.mjs";

const ShareButton = ({ setDisplaySharePanel, shareButtonRef, contentType }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const contentArray = useSelector(
    (state) => state.routes[contentType.name].contentArray
  );

  const contentTitle = useSelector(
    (state) => state.routes[contentType.name].title
  );

  let isFreshContent = false;
  let freshContentMessage = "";

  if (contentType === contentTypes.TRANSCRIPT) {
    freshContentMessage =
      "title this transcript or start transcribing to share";
    if (
      contentArray.length === 0 &&
      contentTitle === initialState.transcript.title
    ) {
      isFreshContent = true;
    }
  } else if (contentType === contentTypes.DOCUMENT) {
    freshContentMessage = "";
  }

  return (
    <div
      style={{
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        margin: 0,
        backgroundColor: darkMode ? "gray" : colors.gray,
        maxHeight: 35,
        minHeight: 35,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      }}
      ref={shareButtonRef}
      onMouseEnter={(event) => {
        if (!isFreshContent) {
          event.currentTarget.firstChild.style.transform = "scale(1.2)";
        }
      }}
      onMouseLeave={(event) => {
        event.currentTarget.firstChild.style.transform = "scale(1)";
      }}
      onClick={(event) => {
        if (isFreshContent) {
          return;
        }
        setDisplaySharePanel(true);
      }}
    >
      <img
        src={shareIcon}
        id="img"
        className="shareButton"
        alt="privacy controls"
        style={{ width: 20, transition: ".1s transform" }}
      />
      <ToolTip
        text={
          isFreshContent ? freshContentMessage : `share ${contentType.name}`
        }
        top={false}
        removeOnClick={!isFreshContent}
      />
    </div>
  );
};

export default ShareButton;
