import { useEffect, useRef, useState } from "react";
import {
  colors,
  borderRadius,
  fontSizes,
  logger,
  LogCategory,
  getIsMac,
  addScrollBarUI,
} from "../../../utility/utility";
import {
  TranscriptBlurb,
  BookmarkBar,
  JumpToBottomButton,
  Button,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import MidTranscriptPaywall from "./MidTranscriptPaywall";
import TranscriptUpgradeButton from "./TranscriptUpgradeButton";
import {
  addEntireTranscriptEmbeddings,
  startPipeline,
} from "../../../transcriptSlice";

const TranscriptPanel = ({
  transcriptAutoscroll,
  editMode,
  GPT,
  chat,
  transcript,
  setTranscriptAutoscroll,
  started,
  supabase,
  trptRef,
  setEditMode,
  ownerOfTranscript,
  setHighlightedSummaryBlurbIndex,
  highlightedTranscriptBlurbIndex,
  setHighlightedTranscriptBlurbIndex,
  highlightSummaryBlurbTimeout,
  setShowCheckout,
  setShowTranscriptUpgradeButton,
  showTranscriptUpgradeButton,
  selectedTranscriptBlurbIndices,
  setSelectedTranscriptBlurbIndices,
  setNotOwnerPoints,
  setDisplayNotOwnerTooltip,
  setNotOwnerAction,
  setShowInviteFriendsPanel,
}) => {
  const transcriptPanelRef = useRef(null);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const transcribingFile = useSelector(
    (state) => state.routes.transcribingFile
  );
  const { contentArray } = useSelector((state) => state.routes.transcript);
  const jumpToBottomButtonVisibilityTimeout = useRef(null);
  const [jumpToBottomVisibility, setJumpToBottomVisibility] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);
  const clickedJumpToBottomButton = useRef(false);
  const alreadyShowedPaywall = useRef(false);
  const lastFinalBlurbIndex = useSelector(
    (state) => state.routes.lastFinalBlurbIndex
  );
  const unmergedIndex = useSelector((state) => state.routes.unmergedIndex);
  const currentlyMerging = useSelector(
    (state) => state.routes.currentlyMerging
  );
  const uploadVideo = useSelector(
    (state) => state.routes.transcript.debugJson.uploadVideo
  );
  const uploadAudio = useSelector(
    (state) => state.routes.transcript.debugJson.uploadAudio
  );
  const { uploadAudioProgress } = useSelector(
    (state) => state.routes.transcript
  );

  const dispatch = useDispatch();

  // We want to trigger the pipeline if:
  // 1. We get new results from rev.ai: lastFinalBlurbIndex
  // 2. We just finished merging and may need to merge again: currentlyMerging
  useEffect(() => {
    if (unmergedIndex < lastFinalBlurbIndex && !currentlyMerging) {
      dispatch(startPipeline({ supabase }));
    }
  }, [lastFinalBlurbIndex, currentlyMerging]);

  const embeddings = useSelector((state) => state.routes.transcript.embeddings);
  // create all transcript embeddings for upload audio and video
  useEffect(() => {
    if (
      (uploadVideo === true || uploadAudio === true) &&
      embeddings.length === 0 &&
      contentArray.length > 0
    ) {
      dispatch(addEntireTranscriptEmbeddings({ supabase }));
    }
  }, [contentArray]);

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * detect click outside of transcript panel to leave edit mode,
       * unless you just clicked on the edit mode pencil again
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          document.activeElement.className !== "editModeButton"
        ) {
          setEditMode(false);
        }
      }
      // Bind the event listener. we want to know when they are done clicking on the item, so on mouse up
      document.addEventListener("mouseup", handleClickOutside, {
        passive: true,
      });
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mouseup", handleClickOutside, {
          passive: true,
        });
      };
    }, [ref]);
  };
  useDetectClickOutside(transcriptPanelRef);

  // smooth autoscrolling if autoscroll is true
  useEffect(() => {
    // don't autoscroll in edit mode
    if (editMode) {
      return;
    }

    logger([LogCategory.DEBUG], "autoscrolling right now");
    const textbox = document.getElementById("transcribed_textbox");
    if (textbox !== null && !editMode && transcriptAutoscroll) {
      textbox.scrollTop = textbox.scrollHeight;
      textbox.scrollTo({
        top: textbox.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [contentArray, transcriptAutoscroll, editMode]);

  useEffect(() => {
    if (!darkMode) {
      return;
    }

    let styleElement = document.createElement("style");
    styleElement.innerHTML = `mjx-container {color: white;}`;
    document.head.appendChild(styleElement);
  }, [darkMode]);

  return (
    <div
      style={{
        display: transcript ? "flex" : "none",
        flexDirection: "row",
        flex: 1,
        overflowY: "auto",
      }}
    >
      {/* bookmark scrollbar body element */}
      <BookmarkBar
        setTranscriptAutoscroll={setTranscriptAutoscroll}
        setHighlightedSummaryBlurbIndex={setHighlightedSummaryBlurbIndex}
        setHighlightedTranscriptBlurbIndex={setHighlightedTranscriptBlurbIndex}
        highlightSummaryBlurbTimeout={highlightSummaryBlurbTimeout}
      />

      <div
        ref={transcriptPanelRef}
        style={{
          flex: 1,
          flexDirection: "column",
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
          borderRadius: borderRadius,

          marginLeft: 12,
          // if gpt panel is open, 50%. else, 1000 pixels
        }}
      >
        <div
          style={{
            margin: 20,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 20,
            paddingBottom: 0,
            flex: 1,
            width: "100%",
            maxWidth: 1000,
            // overflow: "scroll",
            overflowY: "auto",
            // overflowX: "hidden",
            textAlign: "center",
            fontSize: 20,
            fontFamily: "poppins",
            border: 0,
            resize: "none",
            fontWeight: 700,
            lineHeight: 2.4,
            padding: 0,
            boxSizing: "border-box",
            position: "relative",
          }}
          ref={trptRef}
          className="transcribed_textbox"
          id="transcribed_textbox"
          onScroll={(event) => {
            const isMac = getIsMac();
            if (!isMac) {
              addScrollBarUI(event);
            }

            const transcript = event.currentTarget;

            const distanceFromBottom = Math.abs(
              transcript.scrollTop +
                transcript.offsetHeight -
                transcript.scrollHeight
            );

            // determine whether or not to continue autoscrolling.
            // added buffer because sometimes latex parsing throws off the autoscrolling
            setTranscriptAutoscroll(distanceFromBottom <= 20);

            // if autoscroll is on, don't show the jump to bottom button
            if (transcriptAutoscroll) {
              return;
            }

            // user clicked jump to button bottom, don't display the button again
            if (clickedJumpToBottomButton.current) {
              return;
            }

            // if you're not that far from the bottom, and the user didn't click on the button,
            if (distanceFromBottom < 500) {
              clearTimeout(jumpToBottomButtonVisibilityTimeout.current);
              setJumpToBottomVisibility(false);
              return;
            }

            clearTimeout(jumpToBottomButtonVisibilityTimeout.current);

            setJumpToBottomVisibility(true);
            jumpToBottomButtonVisibilityTimeout.current = setTimeout(() => {
              setJumpToBottomVisibility(false);
            }, 2200);
          }}
        >
          {/* only render children if we are not in edit mode */}

          {/* display transcription blurb if it exists or if recording started, 
            otherwise 'Transcribed text will appear here.' */}
          {contentArray.length > 0 || started || transcribingFile ? (
            <div
              style={{
                // overflow: "scroll",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                color: "white",
                justifyContent: "center",
                alignText: "center",

                boxSizing: "border-box",
                // padding: 1,
              }}
            >
              {contentArray.length === 0 && (
                <TranscriptBlurb
                  editMode={editMode}
                  GPT={GPT}
                  index={0}
                  started={started}
                  supabase={supabase}
                  ownerOfTranscript={ownerOfTranscript}
                  text={(() => {
                    if (transcribingFile) {
                      switch (true) {
                        case uploadAudio:
                          return `Transcribing your audio file... ${Math.round(
                            uploadAudioProgress > 5 ? uploadAudioProgress : 5
                          )}%`;
                        case uploadVideo:
                          return "Transcribing YouTube video...";
                        default:
                          return "Listening for speech...";
                      }
                    } else {
                      return "Listening for speech...";
                    }
                  })()}
                  chat={chat}
                  key={1000}
                  opacity={0.5}
                  setHighlightedSummaryBlurbIndex={
                    setHighlightedSummaryBlurbIndex
                  }
                  listeningForSpeechIndicator={true}
                  highlightBlurb={false}
                  setHighlightedTranscriptBlurbIndex={
                    setHighlightedTranscriptBlurbIndex
                  }
                />
              )}

              {contentArray.map((text, index) => {
                return (
                  <div key={index}>
                    <TranscriptBlurb
                      editMode={editMode}
                      GPT={GPT}
                      index={index}
                      started={started}
                      supabase={supabase}
                      text={text}
                      chat={chat}
                      setHighlightedSummaryBlurbIndex={
                        setHighlightedSummaryBlurbIndex
                      }
                      ownerOfTranscript={ownerOfTranscript}
                      highlightSummaryBlurbTimeout={
                        highlightSummaryBlurbTimeout
                      }
                      setHighlightedTranscriptBlurbIndex={
                        setHighlightedTranscriptBlurbIndex
                      }
                      highlightBlurb={
                        highlightedTranscriptBlurbIndex ===
                        `transcriptBlurbText ${index}`
                      }
                      alreadyShowedPaywall={alreadyShowedPaywall}
                      setShowPaywall={setShowPaywall}
                      setSelectedTranscriptBlurbIndices={
                        setSelectedTranscriptBlurbIndices
                      }
                      selectedTranscriptBlurbIndices={
                        selectedTranscriptBlurbIndices
                      }
                      setNotOwnerPoints={setNotOwnerPoints}
                      setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
                      setNotOwnerAction={setNotOwnerAction}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span
              style={{
                color: "gray",
                fontWeight: 600,
                margin: 20,
                opacity: 0.5,
                fontSize: fontSizes.big,
              }}
            >
              Transcribed text will appear here.
            </span>
          )}
        </div>

        <MidTranscriptPaywall
          showPaywall={showPaywall}
          setShowCheckout={setShowCheckout}
          midTranscript={started}
          setShowPaywall={setShowPaywall}
          setShowInviteFriendsPanel={setShowInviteFriendsPanel}
          setShowTranscriptUpgradeButton={setShowTranscriptUpgradeButton}
        />

        <TranscriptUpgradeButton
          showTranscriptUpgradeButton={showTranscriptUpgradeButton}
          setShowTranscriptUpgradeButton={setShowTranscriptUpgradeButton}
          setShowCheckout={setShowCheckout}
          setShowInviteFriendsPanel={setShowInviteFriendsPanel}
        />

        <JumpToBottomButton
          jumpToBottomVisibility={jumpToBottomVisibility}
          jumpToBottomButtonVisibilityTimeout={
            jumpToBottomButtonVisibilityTimeout
          }
          clickedJumpToBottomButton={clickedJumpToBottomButton}
          setJumpToBottomVisibility={setJumpToBottomVisibility}
        />
      </div>
    </div>
  );
};
export default TranscriptPanel;
