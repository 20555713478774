import { colors, borderRadius, fontSizes } from "../../../utility/utility";
import { Timestamp, TranscriptText } from "../../../components";
import { useSelector } from "react-redux";

const TranscriptBlurb = ({
  index,
  text,
  editMode,
  started,
  GPT,
  chat,
  supabase,
  opacity = 1,
  ownerOfTranscript,
  setHighlightedSummaryBlurbIndex,
  highlightSummaryBlurbTimeout,
  highlightBlurb,
  setShowPaywall,
  setHighlightedTranscriptBlurbIndex,
  alreadyShowedPaywall,
  listeningForSpeechIndicator = false,
  setSelectedTranscriptBlurbIndices,
  selectedTranscriptBlurbIndices,
  setNotOwnerPoints,
  setDisplayNotOwnerTooltip,
  setNotOwnerAction,
}) => {
  const { bookmarks, paidBlurbIndex } = useSelector(
    (state) => state.routes.transcript
  );

  const bookmarked = bookmarks[index];
  const paidBlurb = index >= paidBlurbIndex && paidBlurbIndex !== -1;

  return (
    <div
      style={{
        display: "flex",
        color: "black",
        //marginRight: 20,
        flexDirection: "row",
        marginBottom: 4,
        marginLeft: 4,
        paddingTop: 4,
        marginTop: index !== 0 ? 0 : 16,
        paddingBottom: 4,
        marginRight: 20,
        opacity: opacity,
        // padding: 6,
        //border: bookmarks[index] ? "2px solid " + colors.ossyBlue1 : "2px solid transparent",
        backgroundColor:
          bookmarked || highlightBlurb ? colors.ossyBlue2 : "transparent",
        borderRadius: borderRadius,
        transition: "background-color 0.5s",
      }}
    >
      <div
        style={{
          color: "gray", // TODO: fix bug where after page reloads, bookmarked timestamp is gray
          fontSize: fontSizes.regular,
          fontWeight: 800,
          // paddingTop: 7,
          // paddingBottom: 7,
          marginTop: 0,
          marginBottom: 0,
          display: "flex",
          alignItems: "flex-start",
          marginRight: 4,
          marginLeft: 4,
          lineHeight: 2.5,
          fontFamily: "poppins",
          width: "100%",
        }}
        id={`transcriptBlurb ${index}`}
      >
        {/* timestamp */}
        <Timestamp
          index={index}
          supabase={supabase}
          listeningForSpeechIndicator={listeningForSpeechIndicator}
          ownerOfTranscript={ownerOfTranscript}
          highlightBlurb={highlightBlurb}
          paidBlurb={paidBlurb}
          setNotOwnerPoints={setNotOwnerPoints}
          setDisplayNotOwnerTooltip={setDisplayNotOwnerTooltip}
          setNotOwnerAction={setNotOwnerAction}
        />

        {/* transcript text */}
        <TranscriptText
          editMode={editMode}
          GPT={GPT}
          chat={chat}
          index={index}
          started={started}
          supabase={supabase}
          text={text}
          highlightBlurb={highlightBlurb}
          setHighlightedSummaryBlurbIndex={setHighlightedSummaryBlurbIndex}
          listeningForSpeechIndicator={listeningForSpeechIndicator}
          highlightSummaryBlurbTimeout={highlightSummaryBlurbTimeout}
          setHighlightedTranscriptBlurbIndex={
            setHighlightedTranscriptBlurbIndex
          }
          setShowPaywall={setShowPaywall}
          alreadyShowedPaywall={alreadyShowedPaywall}
          paidBlurb={paidBlurb}
          setSelectedTranscriptBlurbIndices={setSelectedTranscriptBlurbIndices}
          selectedTranscriptBlurbIndices={selectedTranscriptBlurbIndices}
        />
      </div>
    </div>
  );
};

export default TranscriptBlurb;
