import { useState, useRef, useEffect } from "react";
import { colors, boxShadow } from "../utility/utility";
import DarkOverlay from "./DarkOverlay";
import { useSelector } from "react-redux";

const DropDown = ({
  links,
  func,
  top,
  left,
  right,
  bottom,
  overlay,
  excludeRef,
  zIndex = 2,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  const panelRef = useRef(null);
  const [dropDownHover, setDropDownHover] = useState(false);

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          // the drop down panel is visible
          ref.current &&
          // the panel itself wasn't clicked
          !ref.current.contains(event.target)
          // the profile button wasn't clicked again (this already closes it)
          // !excludeRef.current.contains(event.target)
        ) {
          // then close it
          func();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside, {
        passive: true,
      });
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside, {
          passive: true,
        });
      };
    }, [ref]);
  };

  useDetectClickOutside(panelRef);

  const position = {};
  if (right !== undefined) {
    position.right = right;
  }
  if (left !== undefined) {
    position.left = left;
  }
  if (top !== undefined) {
    position.top = top;
  }
  if (bottom !== undefined) {
    position.bottom = bottom;
  }

  return (
    <>

      {/* make background unclickable */}
      {overlay && <DarkOverlay opacity={0} />}

      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          position: "absolute",
          boxShadow: boxShadow,
          borderRadius: 8,
          mihWidth: "fit-content",
          zIndex: zIndex,
          ...position,
        }}
        ref={panelRef}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        {links.map(
          (
            { name, href, downloadName, onClick, disabled, dropdown, newTab },
            index
          ) => {
            return (
              <div style={{}} key={index}>
                <div
                  style={{
                    borderRadius: 8,
                    opacity: disabled ? 0.5 : 1,
                    borderTopRightRadius: index === 0 ? 8 : 0,
                    borderTopLeftRadius: index === 0 ? 8 : 0,
                    borderBottomRightRadius: index === links.length - 1 ? 8 : 0,
                    borderBottomLeftRadius: index === links.length - 1 ? 8 : 0,
                    cursor: disabled ? "default" : "pointer",
                    width: "100%",
                  }}
                  onClick={
                    disabled
                      ? (event) => {}
                      : (event) => {
                          if (onClick) {
                            onClick();
                          }

                          if (!href) {
                            func();
                          }
                        }
                  }
                  onContextMenu={() => {}}
                  onMouseEnter={(event) => {
                    if (disabled) {
                      return;
                    }
                    event.currentTarget.style.backgroundColor = darkMode
                      ? colors.gray15
                      : colors.lightGray;
                    if (dropdown) {
                      setDropDownHover(true);
                    }
                  }} // saveAudio &&
                  onMouseLeave={(event) => {
                    event.currentTarget.style.backgroundColor = "transparent";
                    if (dropdown) {
                      setDropDownHover(false);
                    }
                  }}
                >
                  {href ? (
                    <a
                      style={{
                        margin: 0,
                        padding: 0,
                        textDecoration: "none",

                        color: darkMode ? "white" : "gray",
                        borderRadius: 8,
                        borderTopRightRadius: index === 0 ? 8 : 0,
                        borderTopLeftRadius: index === 0 ? 8 : 0,
                        borderBottomRightRadius:
                          index === links.length - 1 ? 8 : 0,
                        borderBottomLeftRadius:
                          index === links.length - 1 ? 8 : 0,
                        display: "flex",
                        pointerEvents: disabled ? "none" : "auto",
                      }}
                      href={href}
                      target={newTab ? "_blank" : "_self"}
                      onClick={() => {
                        func();
                      }}
                      rel='noreferrer'
                      download={downloadName}
                      onContextMenu={() => {}}
                    >
                      <div
                        style={{
                          margin: 0,
                          padding: 8,
                          fontSize: 12,
                          borderTopRightRadius: index === 0 ? 8 : 0,
                          borderTopLeftRadius: index === 0 ? 8 : 0,
                          borderBottomRightRadius:
                            index === links.length - 1 ? 8 : 0,
                          borderBottomLeftRadius:
                            index === links.length - 1 ? 8 : 0,
                          opacity: 1,
                          whiteSpace: "nowrap",
                          color: darkMode ? "white" : "gray",
                        }}
                      >
                        {name}
                      </div>

                      {dropdown && (
                        <div
                          style={{
                            color: darkMode ? "white" : "gray",
                          }}
                        ></div>
                      )}
                    </a>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: 0,
                          padding: 8,
                          fontSize: 12,
                          color: darkMode ? "white" : "gray",
                          minWidth: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {name}
                      </div>

                      {dropdown !== undefined && (
                        <div
                          style={{
                            paddingRight: 8,
                            display: "flex",
                            fontSize: 12,
                            color: darkMode ? "white" : "gray",
                            alignItems: "center",
                          }}
                          onMouseEnter={(event) => {}}
                          onMouseLeave={(event) => {}}
                        >
                          {">"}
                        </div>
                      )}
                    </div>
                  )}

                  {dropDownHover && dropdown && (
                    <DropDown
                      top={0}
                      left={0}
                      links={[
                        dropdown.map((dropdownName) => {
                          return { name: dropdownName };
                        }),
                      ]}
                    />
                  )}
                </div>
                {/* separating line between menu elements  */}
                {index !== links.length - 1 && (
                  <div
                    style={{
                      backgroundColor: darkMode ? "gray" : "lightgray",
                      height: 0.5,
                    }}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default DropDown;
