import {
  micInactive,
  download,
  pencil,
  loadingSymbol,
  pencilWhite,
  downloadWhite,
} from "../assets/index";
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import DropDown from "./DropDown";
import {
  colors,
  formatTimestamp,
  boxShadow,
  logger,
  LogCategory,
} from "../utility/utility.mjs";
import { ToolTip } from "./";

const DocumentControlPanel = ({
  loading,
  editMode,
  setEditMode,
  stopTranscribing,
  setTranscriptionSettings,
  setShowSummaryPanel,
  ownerOfTranscript,
  setDownloadOptionsVisible,
  downloadOptionsVisible,
}) => {
  const { fileUrl, documentTitle } = useSelector(
    (state) => state.routes.document
  );
  const darkMode = useSelector((state) => state.routes.darkMode);
  const backgroundColor = darkMode ? colors.gray1 : "white";

  const selectedColor = darkMode ? colors.black : "gray";
  const unselectedColor = darkMode ? "gray" : colors.gray;
  const hoverColor = darkMode ? colors.gray15 : "lightgray";
  const textColor = darkMode ? "white" : "black";
  const newBoxShadow = darkMode ? "none" : boxShadow;

  const completedTranscriptionStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    bottom: 50, // 30,/
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 1000,
    boxShadow: newBoxShadow,
    paddingRight: 6,
    paddingLeft: 6,
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: "column",
    height: "fit-content",
  };

  const downloadOptionsRef = useRef(null);

  const useDetectClickOutside = (ref, func) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside, {
        passive: true,
      });
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside, {
          passive: true,
        });
      };
    }, [ref, func]);
  };

  useDetectClickOutside(downloadOptionsRef, () =>
    setDownloadOptionsVisible(false)
  );

  const UNSTARTED = 100;
  const COMPLETED_OR_UPLOADING_AUDIO = 101;
  const COMPLETED_OR_UPLOADING_NO_AUDIO_URL = 102;
  const CURRENT_LIVE_TRANSCRIPT = 103;
  let state = COMPLETED_OR_UPLOADING_AUDIO;
  let styles = completedTranscriptionStyle;

  return (
    <div
      style={{
        ...styles,
        maxWidth: "80dvw",
        minWidth: "fit-content",
      }}
    >
      {/* the bottom half of the bottom bar */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* download button */}
        {[CURRENT_LIVE_TRANSCRIPT, COMPLETED_OR_UPLOADING_AUDIO].includes(
          state
        ) && (
          <button
            style={{
              border: 0,
              backgroundColor: downloadOptionsVisible
                ? selectedColor
                : unselectedColor,
              borderRadius: 60,
              height: 40,
              width: 40,
              cursor: "pointer",
              // necessary so the dropdown shows up when clicked
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(event) => {
              if (!downloadOptionsVisible) {
                setDownloadOptionsVisible(true);
              } else {
                setDownloadOptionsVisible(false);
              }
            }}
            onMouseEnter={(event) => {
              if (downloadOptionsVisible) {
                return;
              }
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              if (downloadOptionsVisible) {
                return;
              }
              event.currentTarget.style.backgroundColor = unselectedColor;
            }}
          >
            <ToolTip text={"Download"} />
            {downloadOptionsVisible && (
              <DropDown
                links={[
                  {
                    name: "Document",
                    href: fileUrl,
                    downloadName: documentTitle,
                    newTab: true,
                  },
                ]}
                func={() => setDownloadOptionsVisible(false)}
                top={-38}
                left={-12}
              />
            )}
            <img
              style={{ width: 14, height: 14 }}
              src={
                darkMode || downloadOptionsVisible ? downloadWhite : download
              }
              alt="download button"
            />
          </button>
        )}

        <button
          onClick={() => {
            setShowSummaryPanel(true);
          }}
          style={{
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            flexDirection: "row",
            marginLeft: 10,
            border: "none",
            cursor: "pointer",
            justifySelf: "flex-end",
            alignSelf: "flex-end",
            borderRadius: 60,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: unselectedColor,
            color: darkMode ? "white" : "gray",
            fontWeight: "600",
            fontSize: 12,
            position: "relative",
          }}
          onMouseEnter={(event) => {
            event.currentTarget.style.backgroundColor = hoverColor;
          }}
          onMouseLeave={(event) => {
            event.currentTarget.style.backgroundColor = unselectedColor;
          }}
        >
          <ToolTip text={"Get Summary"} />
          <p>SUMMARIZE</p>
        </button>
      </div>
    </div>
  );
};

export default DocumentControlPanel;
