import { useSelector } from "react-redux";
import { colors } from "../../../utility/utility";
import { HomeHeader } from "../../../components";
import { useState } from "react";
import AnalyticPanel from "./AnalyticPanel";

export const Analytics = () => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const [selected, setSelected] = useState();
  const {
    totalNumUsers,
    totalNumActiveUsers,
    usersByDay: { dates, numUsers },
    usersCumulative: { dates: datesCumulative, numUsers: numUsersCumulative },
    currentlyUsing,
    activeNotNew,
    totalTranscriptCount,
    activeNotNewUsers,
    currentlyActiveUsers,
    percentDarkMode,
    dailyActiveUsers,
    percentSaveAudio,
    avgTranscriptLength,
    dailyNewTranscripts,
    transcriptsByDay: { dates: transcriptDates, numTranscripts },
    autoPausesByDay,
    percentOnPhone,
    uploadAudio,
    youTube,
    percentCreatedFolder,
    percentPublicTranscripts,
    weeklyRetention: { weeks, retentionRates },
    usersRanked,
    weeklyTranscriptionTime: {
      freeUsers: freeUsersWeeklyTranscriptionTime,
      paidUsers: paidUsersWeeklyTranscriptionTime,
    },
    professorOssyQuestions,
    professorOssyQuestionsPercent: {
      numQuestions,
      percentTranscriptsEqualOrGreater,
    },
    percentProfessorOssy,
    transcriptsVisitations: { numVisits, percentTranscriptsForNumVisits },
    numUsersToNumTranscripts: {
      numUsersThatMadeTranscripts,
      numTranscriptsForNumUsers,
    },
    dailyExpenditure: { daysExpenditure, expenditureByDay },
    numTranscriptsPerType: { transcriptionTypes, numberOfTranscriptsForType },
    avgLifetimeCostFreeUser,
    avgLifetimeCostPaidUser,
    paidUserToFreeUserRatio,
    avgLifetimeCostOfUser,
    avgNumMonthsPaidUser,
    avgLifetimeTranscriptionDuration,
    paidUsers,
  } = useSelector((state) => state.routes.analytics);

  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: darkMode ? colors.black : "white",
        color: darkMode ? "white" : "gray",
      }}
    >
      {/* header */}
      <HomeHeader line />

      {/* page content */}
      <div
        style={{
          overflow: "auto",
          //   width: "100%",
          //   alignItems: "center",
          paddingBottom: 70,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        <p
          style={{
            color: darkMode ? "white" : "gray",
            marginTop: 60,
            fontWeight: 600,
            fontSize: 32,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Analytics
        </p>

        {/* user analytics */}
        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 28,
              fontWeight: 600,
              textAlign: "start",
            }}
          >
            Users
          </p>

          <div
            style={{
              display: "inline-block",
              // backgroundColor: "red",
              float: "left",
            }}
          >
            {[
              { text: "Total user count:", data: totalNumUsers },
              {
                text: "Active users:",
                data: totalNumActiveUsers,
              },
              {
                text: "Percent of user base that's active:",
                data: `${((totalNumActiveUsers / totalNumUsers) * 100).toFixed(
                  2
                )}%`,
              },
              {
                text: "Currently using:",
                data: currentlyActiveUsers.length,
                moreData: currentlyActiveUsers,
              },
              {
                text: "Active users that aren't new:",
                data: activeNotNew,
                moreData: activeNotNewUsers,
              },

              {
                text: "Daily Active Users",
                data: dailyActiveUsers.length,
                moreData: dailyActiveUsers,
              },
              {
                text: "Total number of paid users",
                data: paidUsers.length,
                moreData: paidUsers,
              },
            ].map(({ text, data, moreData }, index) => {
              return (
                <AnalyticPanel
                  text={text}
                  data={data}
                  moreData={moreData}
                  selected={selected}
                  index={index}
                  setSelected={setSelected}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        {/* feature analytics */}
        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 28,
              fontWeight: 600,
              textAlign: "start",
            }}
          >
            Features
          </p>

          <div
            style={{
              display: "inline-block",
              // backgroundColor: "red",
              float: "left",
            }}
          >
            {[
              {
                text: "percent of users that created a folder",
                data: percentCreatedFolder,
              },
              {
                text: "percent of users on dark mode",
                data: percentDarkMode,
              },
              {
                text: "percent of transcripts that ask a question to professor ossy",
                data: percentProfessorOssy,
              },
            ].map(({ text, data, moreData }, index) => {
              return (
                <AnalyticPanel
                  text={text}
                  data={data}
                  moreData={moreData}
                  selected={selected}
                  index={index}
                  setSelected={setSelected}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        {/* transcripts analytics */}
        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 8,
              textAlign: "start",
            }}
          >
            Transcripts
          </p>

          <div
            style={{
              display: "inline-block",
              float: "left",
            }}
          >
            {[
              {
                text: "Daily New Transcripts",
                data: dailyNewTranscripts,
              },
              {
                text: "average transcript length (mins):",
                data: avgTranscriptLength,
                unit: "minutes",
              },
              {
                text: "percent of realtime transcripts where users clicked 'save audio'",
                data: percentSaveAudio,
              },
              {
                text: "percent of transcripts made on a mobile device",
                data: percentOnPhone,
              },
              {
                text: "percent of transcripts created by uploading audio",
                data: uploadAudio,
              },
              {
                text: "percent of transcripts created by youtube links",
                data: youTube,
              },
              {
                text: "percent of transcripts that are public",
                data: percentPublicTranscripts,
              },
              { text: "total transcript count", data: totalTranscriptCount },
            ].map(({ text, data, moreData, unit }, index) => {
              return (
                <AnalyticPanel
                  text={text}
                  data={data}
                  moreData={moreData}
                  selected={selected}
                  index={index}
                  unit={unit}
                  key={index}
                  setSelected={setSelected}
                />
              );
            })}
          </div>
        </div>

        {/* expenses */}
        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 28,
              fontWeight: 600,
              textAlign: "start",
            }}
          >
            Expenses
          </p>

          <div
            style={{
              display: "inline-block",
              // backgroundColor: "red",
              float: "left",
            }}
          >
            {[
              {
                text: "Average Lifetime Value of a Free User:",
                data: avgLifetimeCostFreeUser,
              },
              {
                text: "Average Lifetime Value of a Paid User:",
                data: avgLifetimeCostPaidUser,
              },
              {
                text: "Average Lifetime Value of a User:",
                data: avgLifetimeCostOfUser,
              },
              {
                text: "Avg Lifetime User Transcription Duration (minutes):",
                data: (avgLifetimeTranscriptionDuration / 60).toFixed(2),
              },
              {
                text: "Avg # Months of a Paid User:",
                data: avgNumMonthsPaidUser,
              },
              {
                text: "Percent of Users That Convert After Reaching Free Limit:",
                data: 0,
              },
              {
                text: "Ratio of paid to free users:",
                data: paidUserToFreeUserRatio,
              },
            ].map(({ text, data, moreData }, index) => {
              return (
                <AnalyticPanel
                  text={text}
                  data={data}
                  moreData={moreData}
                  selected={selected}
                  index={index}
                  setSelected={setSelected}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 8,
              textAlign: "start",
            }}
          >
            Graphs
          </p>
          <div>
            {/* https://quickchart.io/chart?c={ type: 'line', data: { labels: [1,2,3,4,5], datasets: [{ label: 'Rainfall', data: [  */}
            <img
              //   src={`https://quickchart.io/chart?c={type:'line',data:{labels:[${dates}],datasets:[{label:'new users',data:[${numUsers}]}]}`}
              src={`https://quickchart.io/chart?c={ type: %27line%27, data: { labels: ${JSON.stringify(
                datesCumulative.slice(-250)
              )}, datasets: [{ label: %27User Growth%27, data: ${JSON.stringify(
                numUsersCumulative.slice(-250)
              )}}]}}`}
              alt="users per day"
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* https://quickchart.io/chart?c={ type: 'line', data: { labels: [1,2,3,4,5], datasets: [{ label: 'Rainfall', data: [  */}
            <img
              //   src={`https://quickchart.io/chart?c={type:'line',data:{labels:[${dates}],datasets:[{label:'new users',data:[${numUsers}]}]}`}
              src={`https://quickchart.io/chart?c={ type: %27line%27, data: { labels: ${JSON.stringify(
                dates.slice(-250)
              )}, datasets: [{ label: %27New Users Gained%27, data: ${JSON.stringify(
                numUsers.slice(-250)
              )}}]}}`}
              alt="users per day"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* https://quickchart.io/chart?c={ type: 'line', data: { labels: [1,2,3,4,5], datasets: [{ label: 'Rainfall', data: [  */}
            <img
              //   src={`https://quickchart.io/chart?c={type:'line',data:{labels:[${dates}],datasets:[{label:'new users',data:[${numUsers}]}]}`}
              src={`https://quickchart.io/chart?c={ type: %27line%27, data: { labels: ${JSON.stringify(
                transcriptDates.slice(-250)
              )}, datasets: [{ label: %27Transcripts Created%27, data: ${JSON.stringify(
                numTranscripts.slice(-250)
              )}}]}}`}
              alt="users per day"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* https://quickchart.io/chart?c={ type: 'line', data: { labels: [1,2,3,4,5], datasets: [{ label: 'Rainfall', data: [  */}
            <img
              //   src={`https://quickchart.io/chart?c={type:'line',data:{labels:[${dates}],datasets:[{label:'new users',data:[${numUsers}]}]}`}
              src={`https://quickchart.io/chart?c={ type: %27line%27, data: { labels: ${JSON.stringify(
                transcriptDates.slice(-250)
              )}, datasets: [{ label: %27Auto-Pause Count%27, data: ${JSON.stringify(
                autoPausesByDay.slice(-250)
              )}}]}}`}
              alt="users per day"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* https://quickchart.io/chart?c={ type: 'line', data: { labels: [1,2,3,4,5], datasets: [{ label: 'Rainfall', data: [  */}
            <img
              //   src={`https://quickchart.io/chart?c={type:'line',data:{labels:[${dates}],datasets:[{label:'new users',data:[${numUsers}]}]}`}
              src={`https://quickchart.io/chart?c={ type: %27bar%27, data: { labels: ${JSON.stringify(
                weeks
              )}, datasets: [{ label: %27Weekly Retention%27, data: ${JSON.stringify(
                retentionRates
              )}}]}}`}
              alt="users per day"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* professor ossy distribution */}
            <img
              src={`https://quickchart.io/chart?h=400&c={ type: %27bar%27, data: { labels: ${JSON.stringify(
                Object.keys(professorOssyQuestions).slice(1)
              )}, datasets: [{ label: %27Questions to Professor Ossy%27, data: ${JSON.stringify(
                Object.values(professorOssyQuestions).slice(1)
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:5},scaleLabel:{labelString:'Number of Transcripts',display:true}}],xAxes:[{scaleLabel:{labelString:'Number of Questions',display:true}}]}}}`}
              alt="professor ossy questions"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* professor ossy distribution 2 */}
            <img
              src={`https://quickchart.io/chart?h=400&c={ type: %27line%27, data: { labels: ${JSON.stringify(
                numQuestions
              )}, datasets: [{ label: %27Percent of Transcripts that ask X or More Questions (excluding 0)%27, data: ${JSON.stringify(
                percentTranscriptsEqualOrGreater
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:5},scaleLabel:{labelString:'Percent of Transcripts',display:true}}],xAxes:[{scaleLabel:{labelString:'Number of Questions',display:true}}]}}}`}
              alt="professor ossy questions"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
            {/* # of times transcripts are visited */}
            <img
              src={`https://quickchart.io/chart?h=400&c={ type: %27bar%27, data: { labels: ${JSON.stringify(
                numVisits
              )}, datasets: [{ label: %27Number of Transcripts That Are Visited X Times%27, data: ${JSON.stringify(
                percentTranscriptsForNumVisits
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:5},scaleLabel:{labelString:'Number of Transcripts',display:true}}],xAxes:[{scaleLabel:{labelString:'Number of Visits',display:true}}]}}}`}
              alt="# of times transcripts are visited"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />

            {/* # of Users that made X transcripts */}
            <img
              src={`https://quickchart.io/chart?c={ type: %27bar%27, data: { labels: ${JSON.stringify(
                numTranscriptsForNumUsers
              )}, datasets: [{ label: %27Number of Users That Made X Transcripts%27, data: ${JSON.stringify(
                numUsersThatMadeTranscripts
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:10},scaleLabel:{labelString:'Number of Users',display:true}}],xAxes:[{ticks:{stepSize:1},scaleLabel:{labelString:'Number of Transcripts',display:true}}]}}}`}
              alt="# of users that made X transcripts"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />

            {/* Daily Expenditure */}
            <img
              src={`https://quickchart.io/chart?h=500&c={ type: %27line%27, data: { labels: ${JSON.stringify(
                daysExpenditure
              )}, datasets: [{ label: %27Daily Expenditure%27, data: ${JSON.stringify(
                expenditureByDay
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:10},scaleLabel:{labelString:'Costs (cents)',display:true}}],xAxes:[{ticks:{stepSize:1},scaleLabel:{labelString:'Date',display:true}}]}}}`}
              alt="# of users that made X transcripts"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />

            {/* Transcription Type Pie Chart */}
            <img
              src={`https://quickchart.io/chart?c={ type: %27pie%27, data: { labels: ${JSON.stringify(
                transcriptionTypes
              )}, datasets: [{ label: %27Daily Expenditure%27, data: ${JSON.stringify(
                numberOfTranscriptsForType
              )}}]}}`}
              alt="# of users that made X transcripts"
              width={700}
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 8,
              textAlign: "start",
            }}
          >
            Weekly Usage Scatter Plots
          </p>
          <div>
            <img
              src={`https://quickchart.io/chart?h=550&c={type:'scatter',data:{datasets:[{label:'Free User',data:${JSON.stringify(
                freeUsersWeeklyTranscriptionTime.slice(-750)
              )}},{label:'Paid User',data:${JSON.stringify(
                paidUsersWeeklyTranscriptionTime
              )}}]},options:{scales:{yAxes:[{ticks:{stepSize:60},scaleLabel:{labelString:'Minutes Per Week',display:true}}],xAxes:[{scaleLabel:{labelString:'Week Number',display:true}}]}}}`}
              alt="users per day"
              style={{ marginTop: 40, width: 700, maxWidth: "80%" }}
            />
          </div>
        </div>

        {/* top users */}
        <div
          style={{
            color: darkMode ? "white" : "gray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 8,
              textAlign: "start",
            }}
          >
            Top Users
          </p>

          {/* table */}
          <div>
            <div
              style={{
                display: "flex",
                gap: 10,
                fontSize: 20,
                marginBottom: 20,
              }}
            >
              <div style={{ maxWidth: 150, minWidth: 150 }}>First Name</div>
              <div style={{ maxWidth: 150, minWidth: 150 }}>Last Name</div>
              <div style={{ maxWidth: 150, minWidth: 150 }}>
                Num Transcripts
              </div>
              <div style={{ maxWidth: 150, minWidth: 150 }}>
                Total Transcription time (hours)
              </div>
              <div style={{ maxWidth: 150, minWidth: 150 }}>Counter</div>
            </div>
            {usersRanked.map(
              (
                {
                  firstName,
                  lastName,
                  numTranscriptsCreated,
                  lifetimeTranscriptionAmount,
                },
                index
              ) => {
                return (
                  <div style={{ display: "flex", gap: 10, fontSize: 20 }}>
                    <div style={{ maxWidth: 150, minWidth: 150 }}>
                      {firstName}
                    </div>
                    <div style={{ maxWidth: 150, minWidth: 150 }}>
                      {lastName}
                    </div>
                    <div style={{ maxWidth: 150, minWidth: 150 }}>
                      {numTranscriptsCreated}
                    </div>
                    <div style={{ maxWidth: 150, minWidth: 150 }}>
                      {(lifetimeTranscriptionAmount / 60 / 60).toFixed(2)} hours
                    </div>
                    <div style={{ maxWidth: 150, minWidth: 150 }}>{index}</div>
                  </div>
                );
              }
            )}
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
