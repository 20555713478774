import DropDown from "./DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTranscript,
  removeFromFolder,
  unsaveContent,
} from "../transcriptSlice";
import { logger } from "../utility/utility";
import { contentTypes } from "../utility/utility.mjs";
import { initialState } from "../data/initialState";

const RightClickTranscriptDropdown = ({
  points,
  setTranscriptTitleEditIndex,
  setEditingTranscriptTitle,
  rightClickObject,
  setRightClickObject,
  setShowSummaryPanel,
  setSelectFolderPanel,
  supabase,
  isOwner,
  selected,
  isFolderSelected,
  isWithinFolder = false,
  setDisplaySharePanel,
}) => {
  const dispatch = useDispatch();
  const folderUrlToName = useSelector((state) => state.routes.folderUrlToName);

  const {
    type: rightClickType,
    index: rightClickIndex,
    thing: {
      folderUrl,
      contentArray,
      pageUrl,
      id,
      summarySentences,
      summaryBulletPoints,
    },
  } = rightClickObject;

  logger("contentArray: ", contentArray);
  let links = [];
  if (isOwner) {
    links.push({
      name: "Rename Transcript",
      onClick: () => {
        setRightClickObject({ ...rightClickObject, active: false });
        setTranscriptTitleEditIndex(rightClickIndex);
        setEditingTranscriptTitle(true);
      },
    });
  }

  links.push({
    name: "Summarize",
    onClick: () => {
      setShowSummaryPanel(true);
      logger("JUST CLICKED SUMMARIZE:");
      logger({ contentArray, summarySentences, summaryBulletPoints });
    },
    disabled: contentArray.length === 0,
  });

  if (isOwner) {
    links.push({
      name: "Add to Folder",
      onClick: () => {
        setRightClickObject({ ...rightClickObject, active: false });
        setSelectFolderPanel(true);
      },
      disabled: folderUrl !== "",
    });

    links.push({
      name: (
        <span style={{ color: "inherit" }}>
          Remove from{" "}
          {folderUrl ? (
            <span style={{ fontWeight: 600, color: "inherit" }}>
              {(folderUrlToName[folderUrl] ?? "").substring(0, 12)}
            </span>
          ) : (
            "Folder"
          )}
        </span>
      ),

      onClick: () => {
        dispatch(
          removeFromFolder({
            supabase: supabase,
            contentIndex: rightClickIndex,
            isFolderSelected: isFolderSelected,
            selected: selected,
            contentType: contentTypes.TRANSCRIPT,
          })
        );
        setRightClickObject({ ...rightClickObject, active: false });
      },
      disabled: folderUrl === "",
    });

    links.push({
      name: "Share",
      onClick: () => {
        setDisplaySharePanel(true);
      },
    });
  }

  links.push({
    name: "Copy Transcript Link",
    onClick: async () => {
      await navigator.clipboard.writeText(
        `${window.location.origin}/t/${pageUrl}`
      );
    },
  });

  if (isOwner) {
    links.push({
      name: "Delete Transcript",
      onClick: () => {
        dispatch(
          deleteTranscript({
            supabase: supabase,
            index: rightClickIndex,
          })
        );
        setRightClickObject({ ...rightClickObject, active: false });
      },
    });
  }

  if (!isOwner && !isWithinFolder) {
    links.push({
      name: "Unsave Transcript",
      onClick: () => {
        dispatch(
          unsaveContent({
            supabase: supabase,
            contentId: id,
            contentType: contentTypes.TRANSCRIPT,
          })
        );
        setRightClickObject({ ...rightClickObject, active: false });
      },
    });
  }

  return (
    <DropDown
      overlay={true}
      top={points.y + 1}
      left={points.x + 1}
      func={() => {
        setRightClickObject({ ...rightClickObject, active: false });
      }}
      links={links}
    />
  );
};

export default RightClickTranscriptDropdown;
