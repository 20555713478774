import "../App.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchDocument } from "../transcriptSlice";
import { useLoaderData } from "react-router-dom";
import { initialState } from "../data/initialState";
import TranscriptDoesNotExistPage from "./TranscriptDoesNotExistPage";
import TranscriptHasBeenDeletedPage from "./TranscriptHasBeenDeletedPage";
import TranscriptIsPrivatePage from "./TranscriptIsPrivatePage";
import { logger, LogCategory } from "../utility/utility";
import DocumentPage from "./DocumentPage";
import { contentTypes } from "../utility/utility.mjs";

const DocumentRouter = ({ supabase }) => {
  const documentUrl = useLoaderData();

  const dispatch = useDispatch();

  const [page, setPage] = useState();
  // const [page, setPage] = useState(<TranscriptPage dummy />);

  // get the transcript data
  useEffect(() => {
    logger([LogCategory.DEBUG], "fetching document...");

    // https://ossy.ai/d/ -> https://ossy.ai/
    if (documentUrl === "") {
      window.location.pathname = "";
    }

    const fetchDocument_ = async () => {
      // fetch the transcript data from the database
      const documentData = await dispatch(
        fetchDocument({ supabase: supabase, documentUrl: documentUrl })
      ).unwrap();

      // invalid url, transcript doesn't exist
      if (documentData === initialState.FILE_DOES_NOT_EXIST) {
        setPage(
          <TranscriptDoesNotExistPage contentType={contentTypes.DOCUMENT} />
        );
        return;

        // the transcript used to exist but doesn't anymore
      } else if (documentData === initialState.FILE_HAS_BEEN_DELETED) {
        setPage(
          <TranscriptHasBeenDeletedPage contentType={contentTypes.DOCUMENT} />
        );
        return;

        // the transcript is private and the user is not the owner
      } else if (documentData === initialState.FILE_IS_PRIVATE) {
        setPage(
          <TranscriptIsPrivatePage contentType={contentTypes.DOCUMENT} />
        );
        logger([LogCategory.DEBUG], "detecting the right thing");
        return;

        // user can access the transcript with no issues
      } else {
        setPage(
          <DocumentPage
            supabase={supabase}
            ownerOfDocument={documentData.ownerOfTranscript}
          />
        );
        return;
      }
    };

    fetchDocument_();
  }, [dispatch, documentUrl, supabase]);

  return page;
};

export default DocumentRouter;
