import { useSelector } from "react-redux";
import { colors } from "../../../utility/utility";

const AnalyticPanel = ({
  text,
  data,
  moreData,
  selected,
  index,
  setSelected,
  unit,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
        float: "left",
        marginRight: 20,
        marginBottom: 50,
        height: 150,
        maxHeight: 150,
        width: 300,
        maxWidth: 300,
        minWidth: 300,
        borderRadius: 8,
        cursor: "pointer",
        backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
        color: darkMode ? "white" : "gray",
        paddingTop: 20,
        paddingLeft: 16,
        paddingRight: 16,
      }}
      onClick={(e) => {
        if (moreData === undefined) {
          return;
        }
        if (selected !== index) {
          setSelected(index);
        } else {
          setSelected(-1);
        }
      }}
    >
      <p style={{ fontWeight: 600 }}>{text}</p>
      <div
        style={{
          overflow: "auto",
          paddingBottom: 20,
        }}
      >
        {(selected !== index || (selected === index && !moreData)) && (
          <>
            <p
              style={{
                fontSize: 60,
                fontWeight: 600,
              }}
            >
              {data}
            </p>
          </>
        )}
        {selected === index &&
          moreData !== undefined &&
          moreData.map((element, index) => <p key={index}>{element}</p>)}
      </div>
    </div>
  );
};

export default AnalyticPanel;
