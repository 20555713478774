import { Button, HomeHeader } from "../components";
import { initialState } from "../data/initialState";
import { colors, boxShadow, borderRadius } from "../utility/utility";
import { useSelector } from "react-redux";
import OssyPlusTable from "./../features/payment/components/OssyPlusTable";
import carrot from "../assets/cards/carrot.svg";
import { useState } from "react";
import { formatDuration } from "../utility/utility.mjs";

const UpgradePage = () => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const questions = [
    {
      question: "How do I cancel my Ossy Plus plan?",
      answer:
        "You can easily cancel your Ossy Plus plan any time online on the settings page. Your Plus features stays until your next billing date, then your account switches over to our free plan.\n\nYou'll continue to have complete access to all your current transcripts, explanations, and questions.",
    },
    {
      question: "How does transcription time work for the Ossy Free Plan?",
      answer: `With the Ossy Free plan, you have ${formatDuration({
        duration: initialState.freeWeeklyTranscriptionLimitSeconds,
      })} of free transcription per week. This refreshes every week at 12 AM midnight. Once this limit is reached, any new text in currently in-session transcripts will be unviewable. You can upgrade to Ossy Plus to unlock them and continue transcribing.`,
    },
    {
      question:
        "How are questions to Professor Ossy limited in the Ossy Free Plan?",
      answer:
        "With the Ossy Free plan, you can ask a maximum of 10 questions to each lecture. Upgrade to Ossy Plus for unlimited questions to each transcript.",
    },
    {
      question: "How much is Ossy Plus in USA?",
      answer: `Ossy Plus costs ${initialState.ossyPrice} per month. However, we're students building for students so if you need to, shoot an email to developers@ossy.ai and we'll hear you out.`,
    },
    {
      question: "What if I have more questions?",
      answer:
        "We have more answers! If you have more questions or need further clarification, don't hesitate to reach out to our us at developers@ossy.ai. We're committed to providing you with the assistance you need and ensuring that your experience with us is smooth and enjoyable. Your feedback and questions are very important to us!",
    },
  ];

  const [openedQuestions, setOpenedQuestions] = useState(
    questions.map((_) => false)
  );

  return (
    <div
      style={{
        display: "flex",
        height: "100dvh",
        flexDirection: "column",
        color: darkMode ? "white" : "black",
        backgroundColor: darkMode ? colors.black : colors.ossyDarkBlue4,
      }}
    >
      <HomeHeader search={false} />
      <div
        style={{
          flex: 1,
          // height: "100dvh",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: 48,
            color: "wHite",
            marginTop: 80,
          }}
        >
          Ossy Plus
        </p>

        <p
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 32,
          }}
        >
          Using AI to help you thrive in your classes.
        </p>

        <div
          style={{
            boxShadow: darkMode ? "none" : boxShadow,
            padding: 24,
            borderRadius: borderRadius,
            backgroundColor: darkMode ? colors.gray1 : "white",
            width: 350,
            marginTop: 32,
            marginBottom: 64,
            maxWidth: "70%",
          }}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: 26,
              marginBottom: 8,
              color: colors.ossyBlue1,
            }}
          >
            Ossy Plus
          </p>

          <div>
            <span
              style={{
                fontWeight: 800,
                fontSize: 48,
                color: darkMode ? "white" : "black",
              }}
            >
              {initialState.ossyPrice}
            </span>
            <span
              style={{
                fontWeight: 800,
                fontSize: 18,
                color: darkMode ? "white" : "black",
              }}
            >
              {" "}
              / month
            </span>
          </div>

          <Button
            content="Get Ossy Plus"
            style={{
              fontSize: 16,
              marginTop: 16,
              padding: 12,
              minWidth: "100%",
              justifyContent: "center",
            }}
            onClick={(event) => {
              window.location.pathname = "/checkout";
            }}
          />

          <div
            style={{
              backgroundColor: "lightgray",
              color: "black",
              height: 1,
              marginTop: 20,
              marginBottom: 20,
              width: "100%",
            }}
          />
          {[
            "Unlimited Transcriptions",
            "Unlimited Summaries",
            "Unlimited AI Explanations",
          ].map((text, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: colors.ossyBlue1,
                  width: 8,
                  height: 8,
                  marginRight: 16,
                  borderRadius: 1000,
                }}
              />
              <p style={{ fontSize: 14, lineHeight: 2 }}>{text}</p>
            </div>
          ))}
        </div>

        <div
          style={{
            textAlign: "center",
            color: "white",
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          <p style={{ fontWeight: 700, fontSize: 40, marginBottom: 8 }}>
            Experience the difference.
          </p>
          <p>Get unlimited access to Ossy with Ossy Plus.</p>
          <p>Cancel anytime.</p>
        </div>
        <OssyPlusTable />

        <div
          style={{
            marginTop: 120,
            textAlign: "center",
            color: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ color: "white", fontSize: 32, fontWeight: 700 }}>
            Questions?
          </p>
          <p
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: 500,
              marginTop: 8,
              marginBottom: 16,
            }}
          >
            We've got answers.
          </p>

          <div style={{ width: 1160, maxWidth: "95%", marginBottom: 120 }}>
            {questions.map(({ question, answer }, index) => {
              const isSelected = openedQuestions[index] ?? false;
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: 600,
                      fontSize: 18,
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      textDecoration: isSelected ? "underline" : undefined,
                      padding: 16,
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        colors.ossyDarkBlue5;
                    }}
                    onMouseLeave={(e) => {
                      if (!isSelected) {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }
                    }}
                    onClick={(e) => {
                      const newOpenedQuestions = [
                        ...openedQuestions.slice(0, index),
                        !isSelected,
                        ...openedQuestions.slice(index + 1),
                      ];
                      setOpenedQuestions(newOpenedQuestions);
                    }}
                  >
                    {question}

                    <img
                      style={{
                        color: "white",
                        width: 24,
                        transform: isSelected ? undefined : "scaleY(-1)",
                      }}
                      src={carrot}
                      alt="carrot"
                    />
                  </div>
                  {isSelected && (
                    <div
                      style={{
                        backgroundColor: colors.ossyDarkBlue5,
                        textAlign: "left",
                        paddingBottom: 24,
                        paddingLeft: 32,
                        paddingRight: 32,
                        fontSize: 15,
                        whiteSpace: "pre-wrap",
                        fontWeight: 500,
                      }}
                    >
                      {answer}
                    </div>
                  )}

                  <div
                    style={{
                      backgroundColor: colors.ossyDarkBlue2,
                      height: 1,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;
//
