import MessageBlurbText from "./MessageBlurbText";
import { useSelector } from "react-redux";
import {
  colors,
  unformatTimestamp,
  logger,
  contentTypes,
} from "../../../utility/utility.mjs";
import { initialState } from "../../../data/initialState";

const MessageBlurbTextReference = (props) => {
  const {
    folderLevel,
    lecturesWithTimestampsRegex,
    setHighlightedSummaryBlurbIndex,
    setHighlightedTranscriptBlurbIndex,
    highlightTranscriptBlurbTimeout,
    lecturesRegex,
    text,
    contentType,
  } = props;

  const contentsArray = useSelector((state) => state.routes.contentsArray);
  const { timestamps } = useSelector((state) => state.routes.transcript);

  return (
    <MessageBlurbText
      {...props}
      cursor="pointer"
      underline
      bold
      color={colors.ossyBlue1}
      onMouseEnter={(e) => {
        e.currentTarget.style.color = colors.ossyBlue2;
        e.currentTarget.style.textDecorationColor = colors.ossyBlue2;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.color = colors.ossyBlue1;
        e.currentTarget.style.textDecorationColor = colors.ossyBlue1;
      }}
      onClick={(e) => {
        if (folderLevel) {
          let url = "";

          // this is a transcript and a timestamp, go to it
          if (lecturesWithTimestampsRegex.test(text)) {
            const [lectureTitle, formattedTimestamp] = text.split(
              new RegExp("[ ]?,[ ]?")
            );

            for (let i = 0; i < contentsArray.length; i++) {
              if (
                contentsArray[i].contentType === contentTypes.TRANSCRIPT &&
                (contentsArray[i].title ?? "").toLowerCase() ===
                  lectureTitle.toLowerCase()
              ) {
                const pageUrl = contentsArray[i].pageUrl;
                const pathLetter = contentType.pathLetter;
                url = `${window.location.origin}/${pathLetter}/${pageUrl}?t=${formattedTimestamp}`;
                break;
              }
            }
          }

          // this is a lecture, go to it
          else if (lecturesRegex.test(text)) {
            for (let i = 0; i < contentsArray.length; i++) {
              const pathLetter = contentType.pathLetter;
              if (
                contentsArray[i].contentType === contentTypes.TRANSCRIPT &&
                contentsArray[i].title.toLowerCase() === text.toLowerCase()
              ) {
                const { pageUrl } = contentsArray[i];
                url = `${window.location.origin}/${pathLetter}/${pageUrl}`;
                break;
              }
            }
          }

          // open the reference in a new tab
          if (url !== "") {
            window.open(url, "_blank").focus();
          }
        } else {
          const timestamp = unformatTimestamp(text);
          let blurbIndex = 0;
          // we want to find the blurb that is closest to this index
          // Ex: 06:19 should match to 06:12 if 06:19 doesn't exist.
          for (let i = 0; i < timestamps.length; i++) {
            if (timestamp >= Math.floor(timestamps[i])) {
              blurbIndex = i;
            } else {
              break;
            }
          }
          logger(`blurbIndex: ${blurbIndex}`);
          const transcriptTextBlurbId = `transcriptBlurbText ${blurbIndex}`;
          const transcriptTextBlurb = document.getElementById(
            transcriptTextBlurbId
          );

          if (transcriptTextBlurb) {
            transcriptTextBlurb.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });

            setHighlightedTranscriptBlurbIndex(transcriptTextBlurbId);
            setHighlightedSummaryBlurbIndex(-1);
            clearTimeout(highlightTranscriptBlurbTimeout.current);
            highlightTranscriptBlurbTimeout.current = setTimeout(() => {
              logger("highlight blurb timeout");
              setHighlightedTranscriptBlurbIndex(-1);
            }, 1500);
          }
        }
      }}
    />
  );
};

export default MessageBlurbTextReference;
