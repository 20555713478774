import { useSelector, useDispatch } from "react-redux";
import {
  colors,
  borderRadius,
  formatTimestamp,
  fontSizes,
} from "../../../utility/utility";
import { toggleBookmark } from "../../../transcriptSlice";
import ToolTip from "../../../components/ToolTip";

const Timestamp = ({
  index,
  supabase,
  listeningForSpeechIndicator,
  ownerOfTranscript,
  highlightBlurb,
  paidBlurb,
  setNotOwnerPoints,
  setDisplayNotOwnerTooltip,
  setNotOwnerAction,
}) => {
  const { bookmarks, timestamps } = useSelector(
    (state) => state.routes.transcript
  );

  const dispatch = useDispatch();

  return (
    <div
      style={{
        borderRadius: borderRadius,
        paddingLeft: 4,
        paddingRight: 4,
        marginRight: 4,
        marginTop: 10,
        position: "relative",
        cursor: paidBlurb ? "auto" : "pointer",
        fontSize: fontSizes.regular,
        color: bookmarks[index] || highlightBlurb ? "white" : "gray",
        transition: "color 0.5s",
      }}
      className={`timestamp ${index}`}
      id={`transcriptBlurbTimestamp ${index}`}
      onMouseEnter={(event) => {
        if (paidBlurb) {
          return;
        }
        if (!ownerOfTranscript) {
          return;
        }

        if (!bookmarks[index]) {
          event.currentTarget.style.backgroundColor = colors.ossyBlue2;
          event.currentTarget.style.color = "white";
        } else {
          event.currentTarget.style.backgroundColor = colors.ossyBlue2;
          event.currentTarget.style.color = "white";
        }
      }}
      onMouseLeave={(event) => {
        if (paidBlurb) {
          return;
        }
        event.currentTarget.style.backgroundColor = "transparent";
        event.currentTarget.style.color = bookmarks[index] ? "white" : "gray";
      }}
      onClick={(event) => {
        if (paidBlurb) {
          return;
        }

        if (listeningForSpeechIndicator) {
          return;
        }

        if (ownerOfTranscript) {
          // toggles the timestamp bookmark and updates the UI
          dispatch(
            toggleBookmark({
              index: index,
              supabase: supabase,
            })
          );
        } else {
          setNotOwnerPoints({ x: event.pageX, y: event.pageY });
          setNotOwnerAction("bookmark the timestamp");
          setDisplayNotOwnerTooltip(true);
        }
      }}
    >
      {/* if owner of transcript and not listening for speech for indicator display tooltip*/}
      {!listeningForSpeechIndicator && !paidBlurb && (
        <ToolTip text={"Bookmark Timestamp"} />
      )}
      {formatTimestamp(timestamps[index])}
    </div>
  );
};

export default Timestamp;
