import { Button } from "../../../components";
import { FiXCircle } from "react-icons/fi";
import { colors, borderRadius } from "../../../utility/utility";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { GoUnlock } from "react-icons/go";
import { CgLockUnlock } from "react-icons/cg";
import { claimFreeHourStatus } from "../../../utility/utility.mjs";

const MidTranscriptPaywall = ({
  showPaywall,
  setShowPaywall,
  setShowCheckout,
  setShowTranscriptUpgradeButton,
  setShowInviteFriendsPanel,
}) => {
  const paywallRef = useRef(null);

  const claimedStatus = useSelector((state) => state.routes.claimedStatus);

  const { unclaimed, claimed } = claimFreeHourStatus;

  let midTranscript = true;

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        // if the paywall is showing and the user clicks outside of it, then remove the paywall
        if (showPaywall && ref.current && !ref.current.contains(event.target)) {
          setShowPaywall(false);
          setShowTranscriptUpgradeButton(true);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useDetectClickOutside(paywallRef);

  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        backgroundColor: darkMode ? colors.gray3 : "white",
        position: "absolute",
        borderRadius: borderRadius,
        color: darkMode ? "white" : "black",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 16,
        maxWidth: "85%",
        width: 380,
        // display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 24,
        paddingBottom: 24,
        opacity: showPaywall ? 1 : 0,
        // display: "flex",
        visibility: showPaywall ? "visible" : "hidden",
        display: "flex",
        transition: "opacity 1s",
      }}
      ref={paywallRef}
    >
      {/* <FiXCircle style={{ position: "absolute", top: 8, left: 8 }} /> */}
      <p style={{ fontWeight: 700, marginBottom: 16, fontSize: 18 }}>
        You've reached your free weekly limit.
      </p>
      <p style={{ marginBottom: 16 }}>
        {midTranscript
          ? "Don't worry! Your lecture is still being transcribed and saved."
          : "Upgrade to Ossy Plus to unlock unlimited transcription."}
      </p>

      <Button
        content={
          <div
            style={{
              // whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              gap: 4,
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <CgLockUnlock />
            <p
              style={{
                fontSize: 16,
                width: "fit-content",
              }}
            >
              {(() => {
                switch (claimedStatus) {
                  case unclaimed:
                    return "Unlock 1 More Free Hour";
                  default:
                    return "Unlock Unlimited Transcription";
                }
              })()}
            </p>
          </div>
        }
        style={{ fontSize: 18, display: showPaywall ? "flex" : "none" }}
        onClick={() => {
          if (claimedStatus === claimed) {
            setShowCheckout(true);
          } else {
            setShowInviteFriendsPanel(true);
          }

          setShowPaywall(false);
        }}
      />
      <p
        style={{
          marginTop: 12,
          color: colors.ossyBlue1,
          cursor: "pointer",
          fontSize: 14,
        }}
        onMouseEnter={(e) => (e.currentTarget.style.color = colors.ossyBlue2)}
        onMouseLeave={(e) => (e.currentTarget.style.color = colors.ossyBlue1)}
        onClick={() => {
          setShowPaywall(false);
          setShowTranscriptUpgradeButton(true);
        }}
      >
        No thanks, maybe later
      </p>
    </div>
  );
};

export default MidTranscriptPaywall;
