import { useEffect, useState } from "react";
import DarkOverlay from "./DarkOverlay";
import { colors } from "../utility/utility";
import OssyLogo from "./OssyLogo";
import OssyText from "./OssyText";

const Menu = ({ noMenuContent, menuContents = [], breakpoint }) => {
  const [menuColor, setMenuColor] = useState("gray");
  const [showMenu, setShowMenu] = useState(
    window.matchMedia(`(max-width: ${breakpoint}px)`).matches
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowMenu(window.matchMedia(`(max-width: ${breakpoint}px)`).matches);
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  return showMenu ? (
    <div>
      <div
        style={{
          cursor: "pointer",
          borderRadius: 6,
          width: 22,
          height: 18,
          fontSize: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: 0,
        }}
        onClick={() => setIsMenuOpen(true)}
        onMouseEnter={() => setMenuColor("lightgray")}
        onMouseLeave={() => setMenuColor("gray")}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: menuColor,
            height: 2,
          }}
        />
        <div
          style={{
            width: "100%",
            backgroundColor: menuColor,
            height: 2,
          }}
        />
        <div
          style={{
            width: "100%",
            backgroundColor: menuColor,
            height: 2,
          }}
        />
      </div>

      <>
        {isMenuOpen && (
          <DarkOverlay onClick={() => setIsMenuOpen(false)} opacity={0.2} />
        )}
        <div
          style={{
            backgroundColor: "white",
            paddingBottom: 32,
            width: 400,
            maxWidth: "80%",
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            height: "100%",
            right: -400,
            top: 0,

            // animating opening and closing of menu
            transform: isMenuOpen ? "translate(-400px)" : "translate(400px)",
            transition: "transform 0.55s",
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.gray}`,
              display: "flex",
              alignItems: "center",
              height: 70,
              paddingLeft: 20,
              marginBottom: 20,
            }}
          >
            <OssyLogo width={25} marginRight={4} />
            <OssyText big />
          </div>
          {menuContents.map(({ text, onClick }) => (
            <div
              style={{
                paddingLeft: 20,
                paddingTop: 16,
                paddingBottom: 16,
                width: "100%",
                fontSize: 18,
                cursor: "pointer",
                color: colors.black,
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = colors.lightGray)
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "white")
              }
              onClick={onClick}
            >
              <p>{text}</p>
            </div>
          ))}
        </div>
      </>
    </div>
  ) : (
    noMenuContent
  );
};

export default Menu;
