import {
  borderRadius,
  colors,
  formatDate,
  formatTimestamp,
} from "../utility/utility";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTranscriptsMatchingUserQuery,
  goToContent,
} from "../transcriptSlice";
import { debounce } from "lodash";
import { contentTypes } from "../utility/utility.mjs";
import { initialState } from "../data/initialState";

const SearchBar = ({ supabase }) => {
  const [searchQuery, setSearchQuery] = useState(false);
  const [results, setResults] = useState([]);
  const [searchBarSelected, setSearchBarSelected] = useState(false);
  const [searchResultsHover, setSearchResultsHover] = useState(false);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const showSearchResults = searchQuery && searchBarSelected;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showSearchResults) return;

    // convert all the latex to actual math when a search query appears
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }

    return () => window.MathJax.typesetClear();
  }, [results, showSearchResults]);

  const getSearchQueryResultsRef = useRef(async (query) => {
    // get 10 tanscripts, such that either transcripts title or transcript_array contain the query
    const transcriptMatches = await dispatch(
      getTranscriptsMatchingUserQuery({ supabase, query })
    ).unwrap();

    // create the objects that will be used to display the matches
    const searchQueryMatchesObjs = transcriptMatches.map((obj) => {
      let displayObj = {};
      if (obj.is_title_match === true) {
        displayObj = {
          title: obj.transcript_title,
          creationDate: obj.creation_date,
          timestamp: -1,
          transcriptUrl: obj.transcript_url,
        };
      } else {
        const fullText = obj.transcript_array.join(" ");
        const fullQueryText = fullText.toLowerCase();
        const index = fullQueryText.indexOf(query.toLowerCase());

        displayObj = {
          title: obj.transcript_title,
          prevText: fullText.substring(Math.max(index - 10, 0), index),
          query: query,
          nextText: fullText.substring(
            index + query.length,
            Math.min(index + 100, fullText.length - 1)
          ),
          creationDate: obj.creation_date,
          contentArray: obj.transcript_array,
          timestamps: obj.timestamps,
          transcriptUrl: obj.transcript_url,
        };
      }
      return displayObj;
    });
    setResults(searchQueryMatchesObjs);
  });

  const getSearchQueryResults = useCallback(
    debounce(
      (query) => {
        getSearchQueryResultsRef.current(query);
      },
      // delays invoking the func until after wait milliseconds have elapsed
      // since the last time the function was invoked
      100,
      { leading: true, trailing: true }
    ),
    []
  );

  return (
    <div
      style={{
        width: "100%",
        // flexGrow: 0,
      }}
    >
      <div
        style={{
          // justifySelf: "flex-start",
          // alignSelf: "flex-start",
          display: "flex",
          margin: "auto",
          maxWidth: 500 - 16,
          height: 32,
          borderRadius: borderRadius,
          backgroundColor: darkMode ? "#2f2f2f" : colors.lightGray,
          paddingLeft: 8,
          paddingRight: 8,
          alignItems: "center",
          color: darkMode ? "white" : "gray",
          flexDirection: "column",
          justifyContent: "center",
          // display: "absolute",
          // transform: "translate(0%, 0%)",
        }}
        id="searchbar"
      >
        <input
          placeholder="search"
          autoComplete="off"
          style={{
            border: "none",
            fontSize: 14,
            width: "100%",
            backgroundColor: "transparent",
            color: darkMode ? "white" : "gray",
          }}
          id="searchbar_input"
          onFocus={() => setSearchBarSelected(true)}
          onKeyDown={(event) => {
            if (["Escape"].includes(event.key)) {
              event.currentTarget.blur();
            }
          }}
          onBlur={() => {
            setSearchBarSelected(searchResultsHover);
          }}
          onKeyUp={async (event) => {
            const query = event.currentTarget.value.toLowerCase();
            setSearchQuery(query);
            getSearchQueryResults(query);
          }}
        />
      </div>
      {showSearchResults && (
        <div
          style={{
            height: 0,
            maxWidth: 500,
            margin: "auto",
            display: "flex",
            width: "100%",
            flexGrow: 0,

            // margin: "auto",
            // maxWidth: 500,
          }}
          id="search_results"
          onMouseEnter={() => setSearchResultsHover(true)}
          onMouseLeave={() => setSearchResultsHover(false)}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
              // maxWidth: 500,

              width: "100%",
              maxWidth: "100%",
              border: results.length ? `1px solid ${colors.gray}` : "none",
              backgroundColor: darkMode ? colors.gray1 : "white",
              borderRadius: borderRadius,
              overflow: "clip",
              flexGrow: 0,
            }}
            className={"search"}
          >
            {results.map(
              (
                {
                  title,
                  prevText,
                  query,
                  nextText,
                  creationDate,
                  timestamp,
                  contentArray,
                  timestamps,
                  transcriptUrl,
                },
                index
              ) => (
                <div
                  style={{
                    width: "100%",
                    color: darkMode ? "white" : "black",
                    overflow: "clip",
                  }}
                  key={transcriptUrl}
                  className={"search"}
                  onClick={() => {
                    // find the timestamp that corresponds to this query
                    const getTimestamp = (query) => {
                      const queryBlurbIndex = contentArray.findIndex((blurb) =>
                        blurb.toLowerCase().includes(query)
                      );
                      return timestamps[queryBlurbIndex];
                    };

                    // if timestamp hasn't already been set to -1 then find it's value
                    //  has been set to -1 if query was found in title and not in transcript
                    let timestampQuery = timestamp ?? getTimestamp(query);

                    let newHref = `${transcriptUrl}`;
                    if (timestamp !== -1)
                      newHref += `?t=${formatTimestamp(timestampQuery)}`;

                    dispatch(
                      goToContent({
                        contentPathName: newHref,
                        newTab: true,
                        contentType: contentTypes.TRANSCRIPT,
                      })
                    );
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 12,
                      cursor: "pointer",
                      justifyContent: "space-between",
                      overflow: "clip",
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 12,
                      paddingBottom: 12,
                      flexGrow: 0,
                      borderTopLeftRadius: index === 0 ? borderRadius : 0,
                      borderTopRightRadius: index === 0 ? borderRadius : 0,
                      borderBottomLeftRadius:
                        index === results.length - 1 ? borderRadius : 0,
                      borderBottomRightRadius:
                        index === results.length - 1 ? borderRadius : 0,
                    }}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray2
                        : colors.lightGray;
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "clip",
                        justifyContent: "space-between",
                        marginBottom: 4,
                        lineHeight: 0.8,
                        flexGrow: 0,
                      }}
                    >
                      <p style={{ whiteSpace: "nowrap", fontWeight: 500 }}>
                        {title}
                      </p>
                      <p
                        style={{
                          marginLeft: 8,
                          fontSize: 10,
                          minWidth: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDate(creationDate)}
                      </p>
                    </div>
                    {/* text preview */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        // overflow: "clip",
                        flexGrow: 0,
                        marginTop: 2,
                      }}
                    >
                      <p
                        style={{
                          opacity: 0.5,
                          fontWeight: 300,
                          whiteSpace: "pre",
                          lineHeight: 0.8,
                        }}
                      >
                        {prevText}
                      </p>
                      <p
                        style={{
                          opacity: 0.6,
                          fontWeight: 600,
                          whiteSpace: "pre",
                          // color: "gray",
                          lineHeight: 0.8,
                        }}
                      >
                        {query}
                      </p>
                      <p
                        style={{
                          opacity: 0.5,
                          flexGrow: 1,
                          overflowX: "clip",
                          // maxWidth: "0",
                          textOverflow: "ellipsis",
                          fontWeight: 300,
                          whiteSpace: "pre",
                          lineHeight: 0.8,
                        }}
                      >
                        {nextText}
                      </p>
                    </div>
                  </div>

                  {/* off for now & line under each result (off for now) */}
                  {false && index !== results.length - 1 && (
                    <div
                      style={{
                        height: 1,
                        backgroundColor: colors.gray,
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
