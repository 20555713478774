import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../transcriptSlice";
import { useEffect, useRef, useState } from "react";
import {
  borderRadius,
  boxShadowHalf,
  colors,
  ossyDiscordLink,
} from "../utility/utility.mjs";
import { FaDiscord } from "react-icons/fa";
import { PiSignOut } from "react-icons/pi";

const ProfileButton = ({ removeNameWhenSmall, includeName, left, right }) => {
  const dispatch = useDispatch();
  const pfpUrl = useSelector((state) => state.routes.pfpUrl);
  const fullName = useSelector((state) => state.routes.fullName);
  const email = useSelector((state) => state.routes.email);
  const [profileOptionsVisible, setProfileOptionsVisible] = useState(false);
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${removeNameWhenSmall}px)`).matches
  );
  const darkMode = useSelector((state) => state.routes.darkMode);

  const profileButtonRef = useRef(null);
  const profilePanel = useRef(null);

  const useClickOutsideDetector = (ref, action) => {
    useEffect(() => {
      // Alert if clicked on outside of element
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          action();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useClickOutsideDetector(profilePanel, () => {
    setProfileOptionsVisible(false);
  });

  useEffect(() => {
    const handleResize = () => {
      setSmallWindow(
        window.matchMedia(`(max-width: ${removeNameWhenSmall}px)`).matches
      );
    };
    if (removeNameWhenSmall !== undefined) {
      window.addEventListener("resize", handleResize, { passive: true });
    }
    return () => {
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: 130,
        justifyContent: "flex-start",
        margin: 0,
        padding: 0,
        position: "relative",
      }}
      ref={profileButtonRef}
      onClick={() => {
        setProfileOptionsVisible(!profileOptionsVisible);
      }}
    >
      {/* profile dropdown menu */}
      {profileOptionsVisible && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 40,
            display: "flex",
            flexDirection: "column",
            backgroundColor: darkMode ? colors.gray2 : "white",
            boxShadow: boxShadowHalf,
            borderRadius: borderRadius,
            fontSize: 14,
            color: darkMode ? "white" : null,
            width: 232,
            zIndex: 4,
          }}
          ref={profilePanel}
        >
          {[
            {
              element: (
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      whiteSpace: "nowrap",
                      overflow: "clip",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {fullName}
                  </div>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "clip",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {email}
                  </div>
                  <div
                    style={{
                      color: darkMode ? "gray" : "gray",
                    }}
                  >
                    Manage account
                  </div>
                </div>
              ),
              displayHorizontalBar: true,
              displayExtraTopPadding: true,
              displayExtraBottomPadding: false,
              handleOnClick: () => {
                window.location.pathname = "/settings";
              },
            },
            {
              element: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FaDiscord
                      style={{
                        marginRight: 8,
                        color: darkMode ? "white" : "black",
                      }}
                      size={20}
                    />
                    Ossy Discord
                  </div>
                </>
              ),
              displayHorizontalBar: true,
              displayExtraTopPadding: false,
              displayExtraBottomPadding: false,
              handleOnClick: () => {
                window.open(
                  `${ossyDiscordLink}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              },
            },
            {
              element: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      opacity: 0.7,
                      color: "red",
                    }}
                  >
                    <PiSignOut
                      style={{
                        marginRight: 8,
                      }}
                      size={20}
                    />
                    Sign Out
                  </div>
                </>
              ),
              displayHorizontalBar: false,
              displayExtraTopPadding: false,
              displayExtraBottomPadding: true,
              handleOnClick: () => {
                dispatch(logOut({}));
              },
            },
          ].map(
            ({
              element,
              displayHorizontalBar,
              handleOnClick,
              displayExtraTopPadding,
              displayExtraBottomPadding,
            }) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: 1.75,
                      padding: "8px 16px",
                      paddingTop: displayExtraTopPadding ? 16 : 8,
                      paddingBottom: displayExtraBottomPadding ? 16 : 8,
                      borderTopLeftRadius: displayExtraTopPadding
                        ? borderRadius
                        : null,
                      borderTopRightRadius: displayExtraTopPadding
                        ? borderRadius
                        : null,
                      borderBottomLeftRadius: displayExtraBottomPadding
                        ? borderRadius
                        : null,
                      borderBottomRightRadius: displayExtraBottomPadding
                        ? borderRadius
                        : null,
                    }}
                    onClick={() => {
                      setProfileOptionsVisible(false);
                      handleOnClick();
                    }}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray15
                        : colors.lightGray;
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    {element}
                  </div>

                  {displayHorizontalBar && (
                    <hr
                      style={{
                        border: "none",
                        borderTop: ".05px solid",
                        margin: "4px 0",
                        color: darkMode ? colors.gray1 : colors.gray,
                      }}
                    />
                  )}
                </>
              );
            }
          )}
        </div>
      )}

      {pfpUrl && (
        <img
          alt="pfp"
          src={pfpUrl}
          style={{
            width: 32,
            minWidth: 32,
            height: 32,
            maxWidth: 32,
            maxHeight: 32,
            borderRadius: "100%",
          }}
        />
      )}
    </div>
  );
};

export default ProfileButton;
