import {
  SECONDS_IN_AN_HOUR,
  colors,
  formatDuration,
} from "../utility/utility.mjs";
import { useSelector } from "react-redux";
import LimitBar from "./LimitBar";

const LimitBarAndDescription = ({
  showResetDate = false,
  abbreviate = false,
  left,
  center,
  right,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const weekAudioDuration = useSelector(
    (state) => state.routes.weekAudioDuration
  );
  const weekTranscriptionLimitSeconds = useSelector(
    (state) => state.routes.weekTranscriptionLimitSeconds
  );

  let textAlign = "left";

  if (center) {
    textAlign = "center";
  } else if (right) {
    textAlign = "right";
  }

  return (
    <div
      style={{
        marginTop: "auto",
        width: "100%",
        paddingTop: 24,
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          fontWeight: 600,
          whiteSpace: "nowrap",
          lineHeight: 1,
          marginBottom: 2,
          color: darkMode ? "white" : "gray",
          fontSize: 16,
          textAlign: textAlign,
        }}
      >
        <span style={{ fontWeight: 700, color: "inherit" }}>
          {formatDuration({
            duration: weekAudioDuration,
            abbreviated: abbreviate,
            maxDurationHours:
              weekTranscriptionLimitSeconds / SECONDS_IN_AN_HOUR,
          })}
        </span>
        {` / ${formatDuration({
          duration: weekTranscriptionLimitSeconds,
          abbreviated: abbreviate,
          maxDurationHours: weekTranscriptionLimitSeconds / SECONDS_IN_AN_HOUR,
        })}`}
      </p>
      <LimitBar totalDuration={weekAudioDuration} />

      {showResetDate && (
        <span
          style={{
            color: darkMode ? colors.lightGray : "gray",
            fontSize: 12,
            alignSelf: "flex-end",
            lineHeight: 1,
            marginTop: 4,
          }}
        >
          Resets Sunday at 12am
        </span>
      )}
    </div>
  );
};

export default LimitBarAndDescription;
